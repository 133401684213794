import React, { Component } from 'react'
import { compose, withApollo, graphql } from "react-apollo"
import '../DriverDetailNew.css'
import { InvoicesQuery } from "../../Queries"
import { Button, Col, Row } from 'reactstrap'
import DatetimeRenderer from "../../../../../Material/DatetimeRenderer"
import Loader from "../../../../../Material/Loader"
import { CreateLatestInvoiceMutation } from "../../Mutations"
class InvoiceTab extends Component {
    constructor(props) {
        super(props)
    }
    createLatestInvoice = () => {
        if (this.props.driver) {
            this.setState({ loading: true })
            let input = { driverId: this.props.driverId }
            this.props.client.mutate({
                mutation: CreateLatestInvoiceMutation,
                variables: { input }
            }).then((result) => {
                this.setState({ loading: false })
                if (result.data && result.data.createLatestInvoice && result.data.createLatestInvoice.ok) {
                    this.props.refetchInvoicesQuery()
                }
            })
        }
    };
    render() {
        return (
            <div className="tab-container">
                {this.props.loading ? <Loader /> :
                    <Row id="invoices" className="info-container">
                        <Col xs={12}>
                            <Button className="primary" onClick={this.createLatestInvoice}>Generate Latest Invoice</Button>
                        </Col>
                        <Col xs={12}>
                            {this.props.invoices && this.props.invoices.edges && this.props.invoices.edges.length > 0 ?
                                this.props.invoices.edges.map((invoice, i) =>
                                    <>
                                        <Row className="cardRow" key={i}>
                                            <Col xs={6}>
                                                <p className="columnTitle">Due Date</p>
                                                <p className="columnContent">{invoice.node.startDate ? <DatetimeRenderer datetime={invoice.node.startDate} /> : "--"}</p>
                                            </Col>
                                            <Col xs={3}>
                                                <p className="columnTitle">Amount Due ($)</p>
                                                <p className="columnContent">{invoice.node.totalBalance ? invoice.node.totalBalance : 0}</p>
                                            </Col>
                                            <Col xs={3}>
                                                <p className="columnTitle">Action</p>
                                                {invoice.node.uniqueId ? <p className="columnContent"><a target="_blank" href={"/billing/invoice/download_invoice/?unique_id=" + invoice.node.uniqueId}>Download</a></p> : "--"}
                                            </Col>
                                        </Row><br /><br />
                                    </>
                                ) : <><br /><p>No Invice Found</p></>
                            }
                        </Col>
                        <Col xs={12}>
                            {this.props.invoices && this.props.invoices.pageInfo.hasNextPage &&
                                <Button style={{ paddingLeft: 0, color: "#1b4d7e" }} onClick={this.props.loadMoreEntries} color="link linkHoverEffect">Show More <i className={"fa fa-caret-down"}></i></Button>
                            }
                        </Col>
                    </Row>
                }
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(InvoicesQuery, {
        options: ({ driverId }) => ({
            variables: {
                driverId: driverId,
                first: 10
            }
        }),
        props({ data: { loading, invoices, fetchMore, variables } }) {
            return {
                loading,
                invoices,
                variables,
                refetchInvoicesQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = invoices && invoices.edges && invoices.edges.length > 0 ? invoices.edges.length : 30
                    return fetchMore({
                        query: InvoicesQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.invoices.edges
                            const pageInfo = fetchMoreResult.invoices.pageInfo
                            return {
                                invoices: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.invoices.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: InvoicesQuery,
                        variables: {
                            cursor: invoices.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.invoices.edges
                            const pageInfo = fetchMoreResult.invoices.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                invoices: {
                                    edges: [...previousResult.invoices.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.invoices.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    }),
)(InvoiceTab)
