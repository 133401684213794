import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Col, Row } from 'reactstrap'
import DriverRentalDetail from "./DriverRentalDetail"
import Loader from "../../../../../Material/Loader"
import '../DriverDetailNew.css'

import gql from 'graphql-tag'
import { UncontrolledTooltip } from "reactstrap/lib/Uncontrolled"


const AgreementQuery = gql`query AllRentals($cursor: String, $first:Int!, $driverId:ID!){
    rentals: allRentals(first:$first, after:$cursor, driverId:$driverId, orderBy:["-start_date"]){
        edges{
            node{
                id
                pk
                stage
                car{
                    id
                    pk
                    vin
                    year
                    carModel{
                        id
                        pk
                        name
                        make
                    }
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}
`
class DriverRentalTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeRentalId: this.props.rentals && this.props.rentals && this.props.rentals.edges.length > 0 ? this.props.rentals.edges[0].node.id : null,
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.rentals !== this.props.rentals || (prevProps.activeTab !== this.props.activeTab && this.props.activeTab === "rentals")) && this.props.rentals && this.props.rentals.edges.length > 0) {
            if (this.props.location.search) {
                let queryParams = new URLSearchParams(this.props.location.search)
                let rentalId = this.props.rentals.edges.find(rental => rental.node.id === queryParams.get("agreementId"))
                this.setState({ activeRentalId: rentalId ? rentalId.node.id : this.props.rentals.edges[0].node.id })
            } else {
                this.props.history.push({ hash: this.props.location.hash, search: `agreementId=${this.props.rentals.edges[0].node.id}` })
                this.setState({ activeRentalId: this.props.rentals.edges[0].node.id })
            }
        }
    }
    setRentalId = (id) => {
        const queryString = new URLSearchParams({ "agreementId": id }).toString()
        this.props.history.push({ hash: this.props.location.hash, search: queryString })
        this.setState({ activeRentalId: id })
    }
    render() {
        return (
            <div className="rental-container">
                <Row>
                    <Col className="agreements-list col-2">
                        {this.props.loading ? <Loader /> : <>
                            {
                                this.props.rentals && this.props.rentals.edges && this.props.rentals.edges.length > 0 ?
                                    this.props.rentals.edges.map((rental, i) =>
                                        <div className={"car-agreement" + (this.state.activeRentalId === rental.node.id ? " active" : "")} onClick={() => this.setRentalId(rental.node.id)} key={i}>
                                            <div className={"sidebar-listing-wrapper-icon"}>
                                                <i className={rental.node.stage === "_1" ? "fa fa-2x fa-ban" : "fa fa-2x fa-car"} style={{ color: rental.node.stage && rental.node.stage === "_1" ? "red" : rental.node.stage === "A_10" ? "green" : "orange" }} aria-hidden="true"></i>&nbsp;

                                            </div>
                                            <div className={"sidebar-listing-wrapper-text"}>
                                                <p>
                                                    {rental.node && rental.node.car && rental.node.car.year} {rental.node && rental.node.car && rental.node.car.carModel && rental.node.car.carModel.make}  {rental.node && rental.node.car && rental.node.car.carModel && rental.node.car.carModel.name}</p>
                                                <small>{rental.node && rental.node.car && rental.node.car.pk} - {rental.node && rental.node.car && rental.node.car.vin}</small>
                                            </div>
                                        </div>
                                    ) : <p className="text-center no-rentals">No Rentals Found!</p>
                            }
                            <div id="more-agreements">
                                {this.props.rentals && this.props.rentals.pageInfo.hasNextPage && <>
                                    <i className={"fa fa-2x fa-arrow-circle-down"} style={{ color: "#1B4D7E" }} onClick={this.props.loadMoreAgreements} aria-hidden="true" id={"viewMore"}></i>
                                    <UncontrolledTooltip target={"viewMore"} placement={"left"}>View More</UncontrolledTooltip>
                                </>
                                }
                            </div>
                        </>}
                    </Col>
                    {this.state.activeRentalId ?
                        <DriverRentalDetail id={this.state.activeRentalId} currentUser={this.props.currentUser} /> :
                        <div className="zero-padding">
                            <p>No Rental Info Found!</p>
                        </div>
                    }
                </Row>
            </div >
        )
    }
}

export default compose(
    withApollo,
    graphql(AgreementQuery, {
        options: ({ id }) => ({
            variables: {
                driverId: id,
                first: 8,
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true
            }
        }),
        props({ data: { loading, rentals, fetchMore, variables } }) {
            return {
                loading,
                rentals,
                variables,
                loadMoreAgreements: () => {
                    return fetchMore({
                        query: AgreementQuery,
                        variables: {
                            cursor: rentals.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.rentals.edges
                            const pageInfo = fetchMoreResult.rentals.pageInfo

                            return {
                                rentals: {
                                    edges: [...previousResult.rentals.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.rentals.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    }),
)(DriverRentalTab)