import React, { Component } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import './Interactions.css'

class AttachmentViewer extends Component {
    render() {
        return (
            <Modal isOpen={this.props.open} className="attachment-viewer-modal">
                <ModalHeader>{this.props.attachment.name}</ModalHeader>
                <ModalBody>
                    {this.props.attachment.contentType.startsWith('image/') ? <img height={"60%"} width="auto" src={`data:${this.props.attachment.contentType};base64,${this.props.attachment.content}`} alt={this.props.attachment.name} />
                        : <iframe
                            src={`data:${this.props.attachment.contentType};base64,${this.props.attachment.content}`}
                            title={this.props.attachment.name}
                            width="100%"
                            height="600px"
                            style={{ border: 'none' }}
                        ></iframe>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.handleClose}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
export default AttachmentViewer