/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState } from 'react'
import { compose, graphql } from 'react-apollo'
import { Button, Table, Form, FormGroup, Label, Input, Row, Col, Alert, Progress, ModalHeader, Modal, ModalBody } from 'reactstrap'
import { withApollo } from 'react-apollo/index'
import moment from 'moment'
import gql from 'graphql-tag'
import ModalFooter from "reactstrap/lib/ModalFooter"

const AllEquipmentItems = gql`{
  allEquipmentItems{
    edges {
      node {
        id
        pk
        name
      }
    }
  }
}`

const CreateEquipment = gql`
    mutation CreateEquipment($input: CreateEquipmentMutationInput!){
        createEquipment(input: $input){
            ok
            errors{
                field
                messages
            }
        }
    }`

const RemoveEquipment = gql`
    mutation RemoveEquipment($input: RemoveEquipmentMutationInput!){
        removeEquipment(input: $input){
            ok
            errors{
                field
                messages
            }
        }
    }`

const CarEquipmentModal = ({ id, carEquipments, isFleetManagement, allEquipmentItems, refetchQuery, client, open, handleClose }) => {
  const [popoverOpen, setPopoverOpen] = useState("first")
  const [equipmentInput, setEquipmentInput] = useState({ equipment: null, customId: null })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const toggle = (val) => setPopoverOpen(val)

  const addCarEquipment = (e) => {
    e.preventDefault()
    if (id && equipmentInput.equipment) {
      setLoading(true)
      let input = { carPk: id, equipmentItemPk: equipmentInput.equipment }
      if (equipmentInput.customId)
        input['customDeviceId'] = equipmentInput.customId

      client.mutate({
        mutation: CreateEquipment,
        variables: { input },
      }).then((result) => {
        if (result && result.data && result.data.createEquipment && result.data.createEquipment.ok) {
          refetchQuery()
          setLoading(false)
          toggle("first")
        } else {
          setError("An error has occurred. Please check your input or contact admin.")
          setLoading(false)
        }
      }).catch((err) => {
        setLoading(false)
        setError(err)
      })
    }
  }

  const removeCarEquipment = (equipmentId) => {
    if (id && equipmentId) {
      setLoading(true)
      // let input = { carPk: id, equipmentItemPk: equipmentId}
      let input = { equipmentId: equipmentId }
      client.mutate({
        mutation: RemoveEquipment,
        variables: { input },
      }).then((result) => {
        if (result && result.data && result.data.removeEquipment && result.data.removeEquipment.ok) {
          refetchQuery()
          setLoading(false)
        } else {
          setError("An error has occurred. Please check your input or contact admin.")
          setLoading(false)
        }
      }).catch((err) => {
        setLoading(false)
        setError(err.toString())
      })
    }
  }

  return (
    <Modal isOpen={open}>
      <ModalHeader>{popoverOpen === "first" ? "Car Equipments " + id : "Add Car Equipments For " + id}</ModalHeader>
      <ModalBody>
        {loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
        {error && <Row><Col xs={12}><Alert color="danger">{error}</Alert></Col></Row>}
        {popoverOpen === "first" && <>
          <Table bordered>
            <thead>
              <tr>
                <th>Equipment</th>
                <th>Installation Date</th>
                {isFleetManagement && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {carEquipments && carEquipments.edges && carEquipments.edges.length > 0 ? carEquipments.edges.map((item, i) =>
                item && item.node && item.node.equipmentItems &&
                <tr key={i}>
                  <td>{item.node.equipmentItems.name}</td>
                  <td>{moment(item.node.dateAdded).tz("America/New_York").format("MMM D YYYY")}</td>
                  {isFleetManagement && <td><Button color="danger" className="float-right p-1" onClick={() => removeCarEquipment(item.node.id)}><small><i className="fa fa-times" aria-hidden="true"></i></small></Button></td>}
                </tr>
              ) :
                <tr>
                  No Equipments
                </tr>
              }
            </tbody>
          </Table>
          {isFleetManagement && <Button color="primary" className="float-right mb-3 py-1" onClick={() => toggle("second")}><small><i className="fa fa-plus" aria-hidden="true"></i> Add New Equipment</small></Button>}
        </>}
        {popoverOpen === "second" && <>
          <Form onSubmit={addCarEquipment}>
            <FormGroup>
              <Label for="equipmentItem" className="float-left"><b>Equipment Name</b></Label>
              <Input type="select" name="equipmentItem" id="equipmentItem" defaultValue="" value={equipmentInput.equipment} onChange={(e) => setEquipmentInput({ ...equipmentInput, equipment: e.target.value })}>
                <option disabled value="">-- Select Equipment To Add -- </option>
                {allEquipmentItems && allEquipmentItems.edges && allEquipmentItems.edges.length > 0 && allEquipmentItems.edges.map(item =>
                  <option value={item.node.pk}>{item.node.name}</option>
                )}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="customDeviceId" className="float-left"><b>Custom Device ID</b></Label>
              <Input type="text" name="customDeviceId" id="customDeviceId" value={equipmentInput.customId} onChange={(e) => setEquipmentInput({ ...equipmentInput, customId: e.target.value })} placeholder="Custom Device ID (if any)" />
            </FormGroup>
            <FormGroup>
              <Button type="submit" color="primary" className="float-right mb-3"><i className="fa fa-plus" aria-hidden="true"></i> Add</Button>
            </FormGroup>
          </Form>
          <Button color="primary" className="float-right mb-3 py-1" onClick={() => toggle("first")}><small> Equipments List</small></Button>&nbsp;&nbsp;&nbsp;
        </>}
      </ModalBody>
      <ModalFooter style={{ width: "100%" }}>
        <Button outline color="secondary" className="float-right" onClick={handleClose}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default compose(
  withApollo,
  graphql(AllEquipmentItems, { props({ data: { allEquipmentItems } }) { return { allEquipmentItems } } }),
)(CarEquipmentModal)

// export default CarEquipmentModal;