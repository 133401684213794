import React, { Component } from "react"
import { compose, withApollo } from 'react-apollo'
import PDFViewer from "./PDFViewer"
import Lightbox from "react-awesome-lightbox"
import "react-awesome-lightbox/build/style.css"

class DocumentViewer extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <>
                {
                    this.props && this.props.documentName && this.props.documentName.toUpperCase().slice(-4) === ".PDF" ?
                        <PDFViewer documentURL={this.props.documentURL} handleClose={this.props.handleClose} /> :
                        <Lightbox image={this.props.documentURL} onClose={this.props.handleClose} />
                }
            </>
        )
    }
}

export default compose(
    withApollo,
)(DocumentViewer)