import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, withApollo, graphql } from 'react-apollo'
import { useState } from 'react'
import Loader from "../../../../../Material/Loader"
import { CardHeader, Card, CardBody, CardTitle, Button, Row, Col, Popover, PopoverBody } from 'reactstrap'
import { AllPalencaAccountsQuery } from "../../../../../CustomerLeads/Queries"

const AllArgyleAccountsQuery = gql`
query AllArgyleAccounts($argyleUserId: String){
    allArgyleAccounts(argyleUserId: $argyleUserId){
        accountId
        employers
        userId
        source
        connectionStatus
        connectionCode
    }
}`

const BranchDetails = gql`
query BranchDetails($id: ID!){
    branch(id: $id) {
        id
        name
        branchSettings {
            edges {
                node {
                    id
                    value
                    key {
                        id
                        name
                    }
                }
            }
        }
    }
}`


const UpdateDriver = gql`
    mutation updateDriverMutation($input: UpdateDriverMutationInput!){
    updateDriver(input:$input){
        ok
        errors{
            messages
            }
        }
    } `


export function ArgyleAccountPopOver({ isOpen, toggle, target, employer, status, statusCode, lastSuccessfulConnection, accountId }) {
    console.log(`is Open: ${isOpen}`)
    console.log(`toggle: ${toggle}`)
    console.log(`target: ${target}`)
    console.log(`employer: ${employer}`)
    console.log(`status: ${status}`)
    console.log(`statusCode: ${statusCode}`)
    console.log(`lastSuccessfulConnection: ${lastSuccessfulConnection}`)
    return (
        <Popover placement="bottom" target={target} isOpen={isOpen} toggle={toggle}>
            <PopoverBody>
                {/* Show information about status, account name and source. One data point per line */}
                <Row>
                    <Col xs={12} className="d-flex justify-content-between">
                        <span>Account Name</span>
                        {accountId
                            ? <a target="_blank" href={`https://www.console.palenca.com/logins/details/${accountId}`}>{employer || ""}</a>
                            : <span>{employer || ""}</span>
                        }

                    </Col>
                    <Col xs={12} className="d-flex justify-content-between">
                        <span>Status</span>
                        <span>{status ? status.toUpperCase() : ""}</span>
                    </Col>
                    {statusCode &&
                        <Col xs={12} className="d-flex justify-content-between">
                            <span>Source</span>
                            <span>{statusCode}</span>
                        </Col>
                    }
                    {lastSuccessfulConnection &&
                        <Col xs={12} className="d-flex justify-content-between">
                            <span>Last Successful connection</span>
                            <span>{new Date(lastSuccessfulConnection).toISOString().split("T")[0]}</span>
                        </Col>
                    }
                </Row>
            </PopoverBody>
        </Popover>
    )
}

export function ArgyleCard(props) {
    const [popoverOpen, setPopoverOpen] = useState("")
    const [selectedAccount, setSelectedAccount] = useState(null)
    return (
        <Card>
            <CardHeader>
                <img id="argyle-logo" src="https://argyle.com/images/logos/argyle-logo.svg" />
            </CardHeader>
            <CardBody>
                <CardTitle tag="h5">Argyle Connected Accounts</CardTitle>
                {popoverOpen && selectedAccount &&
                    <ArgyleAccountPopOver
                        isOpen={popoverOpen}
                        toggle={() => setPopoverOpen("")}
                        target={selectedAccount && selectedAccount.accountId ? "accountID" + selectedAccount.accountId.replace("-", "") : "argyle-integration"}
                        employer={selectedAccount ? selectedAccount.employers.join().toUpperCase() : ""}
                        status={selectedAccount ? selectedAccount.connectionStatus : ""}
                        statusCode={selectedAccount ? selectedAccount.connectionCode : ""}
                    />
                }
                {props.allArgyleAccounts && props.allArgyleAccounts.map((account, index) => {
                    return (
                        <Button
                            className={`${account.connectionStatus != "connected" ? "btn-danger" : "success"}` + " mb-2"}
                            id={account.accountId ? "accountID" + account.accountId.replace("-", "") : ""}
                            onClick={() => { setSelectedAccount(account); setPopoverOpen(popoverOpen ? "" : account.source) }}
                        >
                            {account.employers && account.employers.length ? account.employers.join().toUpperCase() : ""}
                        </Button>
                    )
                })}
                {!(props.allArgyleAccounts && props.allArgyleAccounts.length && !props.loading) && <span>No connected accounts</span>}
            </CardBody>
        </Card>
    )
}

export function PalencaCard(props) {
    const [popoverOpen, setPopoverOpen] = useState("")
    const [selectedAccount, setSelectedAccount] = useState(null)
    console.log(props)

    const updateDriverPalencaUserId = async () => {
        const input = {
            id: props.driver.id,
            palencaUserId: "--"
        }

        try {
            const result = await props.client.mutate({
                mutation: UpdateDriver,
                variables: { input }
            })
            if (result && result.data && result.data.updateDriver && result.data.updateDriver.ok) {
                console.log(`SUCCESS: ${JSON.stringify(result, undefined, 2)}`)
            } else {
                console.log(`ERROR: ${JSON.stringify(result, undefined, 2)}`)
            }
        } catch (err) {
            console.log(`An Error happened: ${err.message}`)
        }

    }

    const getPalencaWidgetUrl = () => {
        const url = new URL("https://widget.palenca.com/")

        const params = new URLSearchParams({
            widget_id: "ada028cc-608d-4852-81fd-7e5096b02852",
            external_id: atob(props.driver.id).split(":").pop(),
            // external_id: props.driver.id,
            is_sandbox: true
        })

        url.search = params.toString()

        return url.toString()
    }
    return (
        <Card>
            <CardHeader tag="h4" style={{ color: "#FFF" }} className="d-flex justify-content-between align-items-center">
                Palenca
                {/* <Button
                    onClick={async () => {
                        if (!props.palencaUserId) await updateDriverPalencaUserId()
                        // Redirect to palenca

                        window.open(getPalencaWidgetUrl(), "_blank")
                    }}
                >Login Account</Button> */}
            </CardHeader>
            <CardBody>
                <CardTitle tag="h5">Palenca Connected Accounts</CardTitle>
                {popoverOpen && selectedAccount &&
                    <ArgyleAccountPopOver
                        isOpen={popoverOpen}
                        toggle={() => setPopoverOpen("")}
                        target={selectedAccount && selectedAccount.accountId ? "accountID" + selectedAccount.accountId.replace("-", "") : "argyle-integration"}
                        employer={selectedAccount ? selectedAccount.platform.toUpperCase() : ""}
                        status={selectedAccount ? selectedAccount.status : ""}
                        lastSuccessfulConnection={selectedAccount ? selectedAccount.lastSuccessfulConnection : ""}
                        accountId={selectedAccount ? selectedAccount.accountId : ""}
                    />
                }
                {props.allPalencaAccounts && props.allPalencaAccounts.map((account, index) => {
                    return (
                        <Button
                            key={index}
                            className={`${account.status != "success" ? "btn-danger" : "success"}` + " mb-2"}
                            id={account.accountId ? "accountID" + account.accountId.replace("-", "") : ""}
                            onClick={() => { setSelectedAccount(account); setPopoverOpen(popoverOpen ? "" : account.platform) }}
                        >
                            {account.platform && account.platform.length ? account.platform.toUpperCase() : ""}
                        </Button>
                    )
                })}
                {!(props.allPalencaAccounts && props.allPalencaAccounts.length && !props.loading) && <span>No connected accounts</span>}
            </CardBody>
        </Card>
    )
}

function RiskIntegrationDetails(props) {
    if (props.loading || props.branchSettings.loading) return <Loader />
    return (
        <Row>
            <Col xs={12} className="d-flex justify-content-start"><h4>Integrations</h4></Col>
            <Col xs={12}>
                <Row>
                    <Col xs={12} className="argyle-integration" id="argyle-integration">
                        {props.branchSettings.palencaEnabled ? <PalencaCard {...props} /> : <ArgyleCard {...props} />}
                    </Col>
                </Row>
            </Col >
        </Row >
    )
}

export default compose(
    withApollo,
    graphql(AllArgyleAccountsQuery, {
        options: (props) => {
            const { argyleUserId } = props
            return { variables: { argyleUserId }, fetchPolicy: 'cache-and-network' }
        },
        props({ data: { allArgyleAccounts, loading, variables } }) {
            return {
                allArgyleAccounts, loading, variables
            }
        }
    }),
    graphql(AllPalencaAccountsQuery, {
        options: (props) => {
            const { palencaUserId } = props
            return { variables: { palencaUserId }, fetchPolicy: 'cache-and-network' }
        },
        props({ data: { allPalencaAccounts, loading, variables } }) {
            return {
                allPalencaAccounts, loading, variables
            }
        }
    }),
    graphql(BranchDetails, {
        options: (props) => {
            const { driver } = props
            return {
                variables: {
                    id: driver.branch.id
                }
            }
        },
        props({ data: { branch, loading } }) {
            let palencaEnabled = false
            if (branch) {
                palencaEnabled = branch.branchSettings.edges.some((setting) => {
                    const key = setting.node.key.name
                    const value = setting.node.value
                    return key === "ENABLE_PALENCA_WORKFLOW" && value === "true"
                })
            }

            return {
                branchSettings: {
                    palencaEnabled,
                    loading
                }
            }
        }
    })
)(RiskIntegrationDetails)
