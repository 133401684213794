import React, { Component } from "react"
import gql from "graphql-tag"
import { debounce } from "lodash"
import { compose, withApollo } from "react-apollo"
import { Input, Alert, Row, Col } from "reactstrap"
import "./DriverSearch.css"
import { Link } from "react-router-dom"

const AvailableCarsQuery = gql`query AvailableCarsQuery($searchTerm: String,$driverId:ID) {
    availableCars(,searchTerm:$searchTerm,driverId:$driverId){
        edges{
            node{
                id
                pk
                carModel{
                    id
                    make
                    name
                    series
                    groupType{
                        id
                        name
                    }
                }
                year
                color
                group
                vin  
            }      
          }
    } }`


class CarSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {
        this.updateCarSearchInput("")
    }

    updateCarSearchInput = debounce((searchTerm) => {
        let driverId = this.props.driverId
        this.setState({ loading: true })
        this.props.client.query({
            query: AvailableCarsQuery,
            variables: { searchTerm, driverId, first: 60 }
        }).then((result) => {
            this.setState({ loading: false })
            if (result.data && result.data.availableCars && result.data.availableCars.edges && result.data.availableCars.edges.length > 0) {
                this.setState({ carSuggestions: result.data.availableCars.edges })
            }
        })
    }, 500)

    render() {
        return (
            <div className="search">
                <span className="d-flex flex-row align-middle position-relative">
                    <Input type="text" onChange={(e) => this.updateCarSearchInput(e.target.value)} name="driverSearch" id="driverSearch" placeholder="Search by Car ID, VIN, Plate" />
                    {this.state.loading &&
                        <div className="snippet" data-title="dot-pulse" style={{ position: "absolute", right: 30, top: 15 }} >
                            <div className="stage">
                                <div className="dot-pulse"></div>
                            </div>
                        </div>
                    }
                </span>
                <div className="option-list">
                    {
                        this.state.carSuggestions && this.state.carSuggestions.length > 0 && !this.state.loading ? this.state.carSuggestions.map((car, index) =>
                            <Row key={index} className="option" onClick={() => this.props.setCar(car.node.id)}>
                                <Col>
                                    <div className={"option-list-wrapper-text"} style={{ margin: "-5px" }}>
                                        <p>{car.node.year} {car.node.carModel && car.node.carModel.make}  {car.node.carModel && car.node.carModel.name}  {car.node.carModel && car.node.carModel.series && "(" + car.node.carModel.series + ")"}</p>
                                        <small>{car.node.pk} - {car.node.vin}</small>
                                    </div>
                                </Col>
                            </Row>) :
                            <Alert color="warning">There are no cars available at the moment. Please try later.</Alert>
                    }
                </div>
            </div>
        )
    }
}

export default compose(withApollo)(CarSearch)