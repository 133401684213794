import gql from "graphql-tag"

const locateCar = gql`
    mutation locateCar($input:  TrackerStatusChangeMutationInput!){
        changeTrackerStatus(input: $input){
            ok
            errors{
                field
                messages
            }
            car {
                id
                pk
                trackerStatus
                hasTracker
            }
            location{
                address
                longitude
                latitude
                speed
                date
            }
        }
    }`

const updateTracker = gql`
    mutation UpdateTracker($input:  TrackerStatusChangeMutationInput!){
        changeTrackerStatus(input: $input){
            ok
            errors{
                field
                messages
            }
            car {
                id
                pk
                trackerStatus
                hasTracker
                dmvPlate
                vin
                model
                color

            }
        }
    }`

export {
    updateTracker,
    locateCar
}