import React, { useState, useEffect } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Container, Row, Col, Button } from "reactstrap"
import './TaskManagementContainer.css'
import './TaskManagement.css'
import TaskManagementListHeader from "./TaskManagementListHeader"
import TaskList from "./TaskList"
import TaskDetailsSidePanel from "./TaskDetailsSidePanel"
import { Link, Route, Switch } from "react-router-dom"
import { AllBoardsQuery } from "./Queries"

function TaskManagementContainer({ allBoards, ...props }) {
    const boardId = props && props.match && props.match.params && props.match.params.boardId ? props.match.params.boardId : null
    const [filterValues, setFilterValues] = useState(() => {
        // Retrieve filters from localStorage, if they exist
        const savedFilters = localStorage.getItem('TaskManagementFilters')
        return savedFilters ? JSON.parse(savedFilters) : { searchTerm: "", statuses: ["10_OPEN", "20_IN_PROGRESS"] }
    })

    useEffect(() => {
        // Save filters to localStorage whenever they change
        localStorage.setItem('TaskManagementFilters', JSON.stringify(filterValues))
    }, [filterValues])

    return (
        <div className="TaskManagementContainer">
            <Switch>
                <Route path="/boards/:boardId/tasks/:taskId" render={(props) => <TaskDetailsSidePanel {...props} />} />
                <Route path="/boards/tasks/:taskId" render={(props) => <TaskDetailsSidePanel {...props} />} />
            </Switch>
            <Container fluid>
                <TaskManagementListHeader
                    activeTab="all"
                    filterValues={filterValues}
                    setFilterValues={(res) => setFilterValues({ ...res })}
                />
                <Row>
                    {/* Two columns, one for navigating board and the other for table of tasks */}
                    <Col xs="2" className="TaskBoardColumn">
                        <ul className="TaskBoardList">
                            <li className={(!boardId || boardId === "tasks") ? "active" : ""}>
                                <Link to="/boards/tasks/">All Tasks</Link>
                                {/* <span className="icon"><i className="fa fa-edit" aria-hidden="true"></i></span> */}
                            </li>
                            {allBoards && allBoards.edges && allBoards.edges.length > 0 && allBoards.edges.map((board, idx) => (
                                <li className={boardId === board.node.id ? "active" : ""} key={idx}>
                                    <Link to={`/boards/${board.node.id}/tasks/`}>{board.node.title}</Link>
                                    {/* <span className="icon"><i className="fa fa-edit" aria-hidden="true"></i></span> */}
                                </li>
                            ))}
                            {/* Load more button */}
                            {allBoards && allBoards.pageInfo.hasNextPage && (
                                <li>
                                    <Button outline color="secondary" onClick={props.loadMoreEntries}>Load more...</Button>
                                </li>
                            )}
                        </ul>
                    </Col>
                    <Col xs="10">
                        <Switch>
                            <Route exact path="/boards/:boardId/tasks/*" render={(props) => <TaskList {...props} {...filterValues} />} />
                            <Route exact path="/boards/tasks/*" render={(props) => <TaskList {...props} {...filterValues} />} />
                        </Switch>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default compose(
    withApollo,
    graphql(AllBoardsQuery, {
        options: ({ searchTerm }) => ({
            variables: {
                searchTerm,
                orderBy: ["date_added"],
                isArchived: false,
                first: 10
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),
        props({ data: { loading, allBoards, fetchMore, searchTerm, variables, refetch } }) {
            return {
                loading,
                allBoards,
                variables,
                refetch,
                refetchDriversQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = allBoards && allBoards.edges ? allBoards.edges.length : 1
                    return fetchMore({
                        query: AllBoardsQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allBoards.edges
                            const pageInfo = fetchMoreResult.allBoards.pageInfo
                            return {
                                allBoards: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allBoards.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: AllBoardsQuery,
                        variables: {
                            cursor: allBoards.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allBoards.edges
                            const pageInfo = fetchMoreResult.allBoards.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                allBoards: {
                                    edges: [...previousResult.allBoards.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allBoards.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    })
)(TaskManagementContainer)

