import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import {
    Popover, PopoverBody, PopoverHeader, Button, FormGroup, Label, Input, Progress, Alert
} from 'reactstrap'
import DateTimePicker from "../../Material/DateTimePicker"
import moment from "moment"

const UpdatePaymentScheduleMutation = gql`
    mutation updatePaymentSchedule($input: UpdatePaymentScheduleMutationInput!){
        updatePaymentSchedule(input:$input){
            ok
            errors{
            messages
            }
        }
    } `

const MINIMUM_SCHEDULE_DATETIME = moment().tz("America/New_York").format(moment.HTML5_FMT.DATETIME_LOCAL)
const MAXIMUM_SCHEDULE_DATETIME = moment().tz("America/New_York").add(5, 'day').format(moment.HTML5_FMT.DATETIME_LOCAL)

class UpdatePaymentScheduleInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            paymentPlan: props.paymentPlan,
            input: {},
            loading: false
        }
    }

    updatePaymentSchedule = (isDelete = false) => {
        try {
            this.setState({ loading: true })
            let mutationInput = { ...this.state.input }

            // Check if only paymentScheduleId is provided without any changes
            const hasChanges = Object.keys(mutationInput).some(key => mutationInput[key])
            if (!hasChanges) {
                this.setState({ error: "No changes detected. Please modify the schedule details before updating.", loading: false })
                return
            }

            mutationInput["paymentScheduleId"] = this.props.paymentScheduleId

            this.props.client.mutate({
                mutation: UpdatePaymentScheduleMutation,
                variables: { input: mutationInput },
            }).then((result) => {
                if (result && result.data && result.data.updatePaymentSchedule && result.data.updatePaymentSchedule.ok) {
                    this.props.refetchQuery()
                    this.props.handleClose()
                }
                else if (result && result.data && result.data.updatePaymentSchedule && result.data.updatePaymentSchedule.errors[0] && result.data.updatePaymentSchedule.errors[0].messages) {
                    this.setState({ error: result.data.updatePaymentSchedule.errors[0].messages, loading: false })
                }

                this.setState({ loading: false })
            }).catch((err) => {
                let error = "An error has occurred " + err
                this.setState({ error: error, loading: false })
            })
        }
        catch (err) {
            let error = "An error has occurred " + err
            this.setState({ error: error, loading: false })
        }
    }

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.type === "number") {
            value = parseFloat(value) || 0
        }
        this.setState(prevState => ({
            input: {
                ...prevState.input,
                [name]: value,
            }
        }))
    }

    render() {
        return (
            <div>
                <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader onClick={this.props.handleClose}>{this.props.title} <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                    <PopoverBody>
                        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                        <FormGroup>
                            {
                                ["datetime-local", "datetime"].find(type => type === this.props.type) ?
                                    <DateTimePicker
                                        type="datetime-local"
                                        min={MINIMUM_SCHEDULE_DATETIME}
                                        max={MAXIMUM_SCHEDULE_DATETIME}
                                        name="scheduleDatetime"
                                        id="scheduleDatetime"
                                        value={this.props.value}
                                        placeholder="Select schedule datetime"
                                        setError={(error) => this.setState({ error })}
                                        updateInput={(name, value) => this.setState({ input: { ...this.state.input, paymentScheduleId: this.props.paymentScheduleId, [name]: value } })}
                                    />
                                    :
                                    <Input autoComplete={"off"} type={this.props.type} maxLength={this.props.maxLength ? this.props.maxLength : 200}
                                        name={this.props.name} id={this.props.name} defaultValue={this.props.value}
                                        placeholder={this.props.title} onChange={this.updateInput}
                                    />
                            }
                        </FormGroup >
                        {this.state.loading ? <Progress animated color="info" value={100} /> : <Button type="button" size="sm" onClick={this.updatePaymentSchedule}>Submit</Button>}
                    </PopoverBody >
                </Popover >
            </div >
        )
    }
}

export default compose(
    withApollo,
)(UpdatePaymentScheduleInfo)