import React, { useState } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Container, Col, Row, Label, Input, Badge } from 'reactstrap'
import Loader from "../Material/Loader"
import { HasPermissionsQuery } from "../Functions/querys"
import { AutomationQuery } from "./Queries"
import DatetimeRenderer from "../Material/DatetimeRenderer"
import ActionForm from "./ActionForm"
import TriggerForm from "./TriggerForm"
import ConditionForm from "./ConditionForm"
import "./Automately.css"
import ConfirmationPopup from "../Material/ConfirmationPopup"
import { DeleteActionMutation, UpdateAutomationMutation, DeleteTriggerMutation, DeleteConditionMutation } from "./Mutations"
import CopyToClipboard from "../Material/CopyToClipboard"

const OverviewTab = (props) => {
	const { actionLoading, automation, refetchQuery, automationId, deleteAction, deleteTrigger, deleteCondition, updateAutomation } = props
	const [error, setError] = useState(null)
	const [openModal, setOpenModal] = useState("")
	const [loading, setLoading] = useState(false)
	const [actionDeleted, setActionDeleted] = useState(false)
	const [triggerDeleted, setTriggerDeleted] = useState(false)
	const [conditionDeleted, setConditionDeleted] = useState(false)
	const [editField, setEditField] = useState("")
	const [value, setValue] = useState("")

	const toggleModal = (modalName) => {
		if (openModal === modalName) {
			setOpenModal("")
		} else {
			setOpenModal(modalName)
		}
	}

	const updateInput = (e) => {
		let value = e.target.value
		setValue(value)
	}

	const automationUpdate = (name, value) => {
		setLoading(true)
		updateAutomation({
			variables: {
				input: {
					id: automationId,
					[name]: value,
				},
			},
		}).then(({ data }) => {
			if (data.updateAutomation.ok) {
				setLoading(false)
				setEditField("")
				refetchQuery()
			} else {
				setError(data.updateAutomation.errors[0].messages[0])
				setLoading(false)
			}
		})
	}

	const actionDelete = (actionId) => {
		setLoading(true)
		deleteAction({
			variables: {
				input: {
					id: actionId,
				},
			},
		}).then(({ data }) => {
			if (data.deleteAction.ok) {
				setLoading(false)
				toggleModal("")
				setActionDeleted(true)
				refetchQuery()
			} else {
				setError(data.deleteAction.errors[0].messages[0])
				setLoading(false)
			}
		})
	}

	const triggerDelete = (triggerId) => {
		setLoading(true)
		deleteTrigger({
			variables: {
				input: {
					id: triggerId,
				},
			},
		}).then(({ data }) => {
			if (data.deleteTrigger.ok) {
				setLoading(false)
				toggleModal("")
				setTriggerDeleted(true)
				refetchQuery()
			} else {
				setError(data.deleteTrigger.errors[0].messages[0])
				setLoading(false)
			}
		})
	}

	const conditionDelete = (conditionId) => {
		setLoading(true)
		deleteCondition({
			variables: {
				input: {
					id: conditionId,
				},
			},
		}).then(({ data }) => {
			if (data.deleteCondition.ok) {
				setLoading(false)
				toggleModal("")
				setConditionDeleted(true)
				refetchQuery()
			} else {
				setError(data.deleteCondition.errors[0].messages[0])
				setLoading(false)
			}
		})
	}

	return (
		<Container fluid>
			{actionLoading || !automation ? <Loader /> : <>
				<Row>
					{openModal === "newAction" && <ActionForm isOpen={openModal === "newAction"} automationId={automationId} isUpdate={false} handleClose={() => setOpenModal("")} refetchAutomationQuery={refetchQuery} />}
					{openModal === "newTrigger" && <TriggerForm isOpen={openModal === "newTrigger"} automationId={automationId} isUpdate={false} handleClose={() => setOpenModal("")} refetchAutomationQuery={refetchQuery} />}
					{openModal === "newCondition" && <ConditionForm isOpen={openModal === "newCondition"} automationId={automationId} isUpdate={false} handleClose={() => setOpenModal("")} refetchAutomationQuery={refetchQuery} />}
					<Col>
						<h4>AUTOMATION INFO</h4>
					</Col>
					<Col xs={12} className="bos-object-section-wrapper">
						<Row className="bos-object-section">
							<Col xs={3}>
								<Row>
									<Col xs={6}>
										<Label>Name</Label>
									</Col>
									<Col className="text-right" xs={6}>
										{editField !== "name" ?
											<>
												<i className="fa fa-lg fa-pencil-square" style={{ marginTop: "10px" }} onClick={() => setEditField("name")}></i>&nbsp;&nbsp;
												<CopyToClipboard textToCopy={automation && automation.name ? automation.name : ""} displayText={"name"} />
											</>
											:
											<div>
												<i className="fa fa-lg fa-times" style={{ marginTop: "10px" }} onClick={() => setEditField("")}></i>&nbsp;&nbsp;
												<i className="fa fa-lg fa-check" onClick={() => automationUpdate("name", value)}></i>
											</div>
										}

									</Col>
								</Row>
								<Input disabled={editField !== "name"} defaultValue={automation && automation.name ? automation.name : "--"} onChange={updateInput} />

							</Col>
							<Col xs={3}>
								<Label>Date Added</Label>
								<DatetimeRenderer datetime={automation.dateAdded} />
							</Col>
							<Col xs={3}>
								<Label>Date Modified</Label>
								<DatetimeRenderer datetime={automation.dateModified} />
							</Col>
							<Col xs={3}>
								<Label>Created By</Label>
								<Input disabled className="box-object-property-input" value={automation.createdBy ? automation.createdBy.username : "--"} />
							</Col>
						</Row>
					</Col>
					<Col>
						<h4>TRIGGERS</h4>
					</Col>
					<Col>
						<a id="newTrigger" className="driver-list-button float-right" onClick={() => toggleModal("newTrigger")}>
							New Trigger | <i className="fa fa-filter" aria-hidden="true"></i>
						</a>
					</Col>
					<Col xs={12} className="bos-object-section-wrapper">
						<Row className="bos-object-section">
							<Col xs={12}>
								<Row>
									<Col><Label>#</Label></Col>
									<Col><Label>Trigger Type</Label></Col>
									<Col><Label>Model</Label></Col>
									<Col><Label>Related Actions</Label></Col>
									<Col><Label>Action</Label></Col>
								</Row>
							</Col>
							{automation.triggerSet && automation.triggerSet.edges && automation.triggerSet.edges.map((trigger, index) =>
								<React.Fragment key={trigger.node.id}>
									{openModal === "editTrigger" + index && <TriggerForm triggerId={trigger.node.id} isOpen={openModal === "editTrigger" + index} isUpdate={true} handleClose={() => setOpenModal("")} refetchQuery={refetchQuery} />}
									{openModal === "deleteTrigger" + index &&
										<ConfirmationPopup
											open={openModal === "deleteTrigger" + index}
											message={"Are you sure you want to delete this trigger?"}
											loading={loading}
											handleClose={() => toggleModal("")}
											action={"Yes"}
											confirmAction={() => triggerDelete(trigger.node.id)}
											color="danger"
											error={error ? error : ""}
											success={triggerDeleted ? "Trigger Deleted Successfully" : ""}
										/>
									}
									<Col>
										<p>{index}</p>
									</Col>
									<Col>
										<p>{trigger.node.triggerType}</p>
									</Col>
									<Col>
										<p>{trigger.node.contentType.model}</p>
									</Col>
									<Col>
										<p>{trigger.node.actionSet && trigger.node.actionSet.edges && trigger.node.actionSet.edges.length > 0 ? trigger.node.actionSet.edges.map((action, index) => (
											<Badge color="primary">
												{action.node.actionType}
											</Badge>
										)) : "--"}</p>
									</Col>
									<Col>
										{!(trigger.node.actionSet && trigger.node.actionSet.edges && trigger.node.actionSet.edges.length > 0) ?
											<>
												<i className="fa fa-pencil fa-lg" onClick={() => setOpenModal("editTrigger" + index)} />
												{" "} | {" "}
												<i className="fa fa-trash text-danger fa-lg" onClick={() => setOpenModal("deleteTrigger" + index)} />
											</> : "cannot delete or edit trigger with actions."}
									</Col>
								</React.Fragment>
							)}
						</Row>
					</Col>
					<hr />
					<Col>
						<h4>Conditions</h4>
					</Col>
					<Col>
						<a id="newCondition" className="driver-list-button float-right" onClick={() => toggleModal("newCondition")}>
							New Condition | <i className="fa fa-filter" aria-hidden="true"></i>
						</a>
					</Col>
					<Col xs={12} className="bos-object-section-wrapper">
						<Row className="bos-object-section">
							<Col xs={12}>
								{automation.conditiongroupSet && automation.conditiongroupSet.edges && automation.conditiongroupSet.edges.map((conditionGroup, index) =>
									<Row key={conditionGroup.node.id}>
										<Col xs={12}>
											<h5>{conditionGroup.node.logicalOperator}</h5>
										</Col>
										<Col xs={12}>
											<Row>
												<Col><Label>#</Label></Col>
												<Col><Label>Condition Type</Label></Col>
												<Col><Label>Field Name</Label></Col>
												<Col><Label>Condition Value</Label></Col>
												<Col><Label>Action</Label></Col>
											</Row>
											{conditionGroup.node.conditionSet && conditionGroup.node.conditionSet.edges && conditionGroup.node.conditionSet.edges.map((condition, index) =>
												<Row key={condition.node.id}>

													{openModal === "deleteCondition" + index &&
														<ConfirmationPopup
															open={openModal === "deleteCondition" + index}
															message={"Are you sure you want to delete this condition?"}
															loading={loading}
															handleClose={() => toggleModal("")}
															action={"Yes"}
															confirmAction={() => conditionDelete(condition.node.id)}
															color="danger"
															error={error ? error : ""}
															success={conditionDeleted ? "Condition Deleted Successfully" : ""}
														/>
													}
													<Col>
														<p>{index}</p>
													</Col>
													<Col>
														<p>{condition.node.conditionType}</p>
													</Col>
													<Col>
														<p>{condition.node.fieldName}</p>
													</Col>
													<Col>
														<p>{condition.node.conditionValue}</p>
													</Col>
													<Col>
														<i className="fa fa-trash text-danger fa-lg" onClick={() => setOpenModal("deleteCondition" + index)} />
													</Col>
												</Row>
											)}
										</Col>
									</Row>
								)}
							</Col>
						</Row>
					</Col>
					<hr />
					<Col>
						<h4>ACTIONS</h4>
					</Col>
					<Col>
						<a id="newAction" className="driver-list-button float-right" onClick={() => toggleModal("newAction")}>
							New Action | <i className="fa fa-filter" aria-hidden="true"></i>
						</a>
					</Col>
					<Col xs={12} className="bos-object-section-wrapper">
						<Row className="bos-object-section">
							<Col xs={12}>
								<Row>
									<Col><Label>#</Label></Col>
									<Col><Label>Action Type</Label></Col>
									<Col><Label>Trigger Type</Label></Col>
									<Col><Label>Action Params</Label></Col>
									<Col><Label>Action</Label></Col>
								</Row>
							</Col>
							{automation.actionSet && automation.actionSet.edges && automation.actionSet.edges.map((action, index) =>
								<React.Fragment key={action.node.id}>
									{openModal === "action" + index && <ActionForm actionId={action.node.id} automationId={automation.id} isOpen={openModal === "action" + index} isUpdate={true} handleClose={() => setOpenModal("")} refetchQuery={refetchQuery} />}
									{openModal === "deleteAction" + index &&
										<ConfirmationPopup
											open={openModal === "deleteAction" + index}
											message={"Are you sure you want to delete this action?"}
											loading={loading}
											handleClose={() => toggleModal("")}
											action={"Yes"}
											confirmAction={() => actionDelete(action.node.id)}
											color="danger"
											error={error ? error : ""}
											success={actionDeleted ? "Action Deleted Successfully" : ""}
										/>
									}
									<Col>
										<p>{index}</p>
									</Col>
									<Col>
										<p>{action.node.actionType}</p>
									</Col>
									<Col>
										<p>{action.node.trigger.triggerType + " | " + action.node.trigger.contentType.model}</p>
									</Col>
									<Col>
										<p>{action.node.actionParams}</p></Col>
									<Col>
										<i className="fa fa-pencil fa-lg" onClick={() => setOpenModal("action" + index)} />
										{" "} | {" "}
										<i className="fa fa-trash text-danger fa-lg" onClick={() => setOpenModal("deleteAction" + index)} />
									</Col>
								</React.Fragment>
							)}
						</Row>
					</Col>
				</Row>
			</>}
		</Container >
	)
}


export default compose(
	withApollo,
	graphql(AutomationQuery, {
		options: ({ automationId }) => ({ variables: { id: automationId } }),
		props({ data: { automation, loading, refetch, variables } }) {
			return {
				automation, actionLoading: loading, variables,
				refetchQuery: () => {
					return refetch({
						query: AutomationQuery,
						variables: {
							...variables,
						},
						updateQuery: (previousResult, { fetchMoreResult }) => {
							return { automation: fetchMoreResult.automation }
						},
					})
				},
			}
		}
	}),
	graphql(HasPermissionsQuery, {
		options: () => ({ variables: { userPermissions: ["custom_can_update_branch_status", "add_transactionsetting"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }),
		props({ data: { hasPermissions, loading, variables } }) {
			return { hasPermissions, loading, variables }
		}
	}),
	graphql(DeleteActionMutation, { name: 'deleteAction' }),
	graphql(DeleteTriggerMutation, { name: 'deleteTrigger' }),
	graphql(DeleteConditionMutation, { name: 'deleteCondition' }),
	graphql(UpdateAutomationMutation, { name: 'updateAutomation' }),
)(OverviewTab)
