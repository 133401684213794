import React, { Component } from 'react'
import { Container, Row, Col, Label } from 'reactstrap'
import './Auth.css'
import '../Shared/ListContainer.css'


class LoginContainer extends Component {
    getQueryParam(name) {
        const queryParams = new URLSearchParams(window.location.search)
        return queryParams.get(name)
    }
    getNextPath() {
        let nextPath = this.getQueryParam('next')
        if (nextPath) {
            return nextPath
        }
        return window.location.pathname + window.location.hash
    }
    render() {
        const nextValue = this.getNextPath()
        const isStaging = window.location.hostname.includes('staging') // TODO: Use frontend env variable
        return (
            <Container fluid className="bos-login-container">
                <Row className="bos-login-title-container"><Col><Label>BOS Login</Label></Col></Row>
                <Row className="bos-login-options-container">
                    <Col>
                        <a className="bos-login-option" href={`/saml2/login/?idp=${isStaging ? "jumpcloud_idp_entity_id_bos_staging" : "jumpcloud_idp_entity_id_bos"}&next=${nextValue}`}>
                            <img className="LoginProviderLogo" src="https://www.vectorlogo.zone/logos/jumpcloud/jumpcloud-ar21.svg" aria-hidden="true" alt="" /><br />
                            <Label>Login with Jumpcloud</Label>
                        </a>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default LoginContainer
