import gql from "graphql-tag"

const StripeCards = gql`query StripeCards($id: ID!){
    cards: getStripeCards(id: $id){
        id
        cards {
            id
            expYear
            expMonth
            last4
            brand
            default
        }
    } 
}`

const StripeCharges = gql`
    query StripeCharges($driverId:ID!, $cursor: String){
        stripeCharges(driverId:$driverId,first:20, after: $cursor){
            id
            amount
            amountRefunded
            status
            disputed
            refunded
            metadata
            createdAt
            last4
            hasNext
        }
    }
`

export { StripeCards, StripeCharges }