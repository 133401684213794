import gql from "graphql-tag"

const UpdateInsuranceStatus = gql`
mutation UpdateInsuranceStatus($input: UpdateInsuranceStatusMutationInput!){
    updateInsuranceStatus(input:$input){
    ok
    errors{
        messages
    }
}} `

export { UpdateInsuranceStatus }