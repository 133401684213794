import React, { Component } from "react"
import { compose, graphql } from 'react-apollo'
import gql from "graphql-tag"
import { Table, Badge } from "reactstrap"
import PrimaryListingContainer from "../Material/PrimaryListingContainer"
import "../Material/ListingHeader.css"
import DatetimeRenderer from "../Material/DatetimeRenderer"
import ArgyleAccountDetailModal from "./ArgyleAccountDetailModal"
import "./Argyle.css"
const AllArgyleUsersQuery = gql`query AllArgyleUsersQuery($first:Int!, $endCursor: String) {
    allArgyleUsers(first:$first, endCursor: $endCursor){
        userId
        createdAt
        employers
        endCursor
        createdAt
        driver{
            id
            firstName
            email
            name
            notes
            branch{
                id
                name
            }
        }
    } 
}`

const tableHeaders = [
    { id: "pk", name: "ID" },
    { id: "created_at", name: "Created At" },
    { id: "driver_info", name: "Driver Info" },
    { id: "driver_branch", name: "Driver Branch" },
    { id: "driver_notes", name: "Driver Notes" },
    { id: "platform", name: "Platform" },
    { id: "action", name: "Action" },
]

class ArgyleUserList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectAll: false,
            openModal: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    loadMore = () => {
        const { allArgyleUsers } = this.props
        const lastItem = allArgyleUsers[allArgyleUsers.length - 1]
        const { endCursor } = lastItem
        this.props.fetchMore({
            variables: {
                endCursor: endCursor,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                return Object.assign({}, prev, {
                    allArgyleUsers: [...prev.allArgyleUsers, ...fetchMoreResult.allArgyleUsers],
                })
            },
        })
    }

    render() {
        return (
            <PrimaryListingContainer listLoading={this.props.loading} loadMoreEntries={this.loadMore}>
                <Table responsive>
                    <thead>
                        <tr>
                            {tableHeaders.map(header => (
                                <th key={header.id}>{header.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.allArgyleUsers && this.props.allArgyleUsers.length > 0 ? this.props.allArgyleUsers.map((argyle, i) =>
                            <tr key={i}>
                                <td scope="row">{i + 1}</td>
                                <td scope="row"><DatetimeRenderer datetime={argyle.createdAt} placement={"right"} /></td>
                                <td>{argyle.driver && argyle.driver.name}</td>
                                <td><Badge color="primary" style={{ fontSize: 13 }}>{argyle.driver && argyle.driver.branch && argyle.driver.branch.name}</Badge></td>
                                <td>{argyle.driver && argyle.driver.notes ? argyle.driver.notes : "--"}</td>
                                <td >
                                    {argyle.employers && argyle.employers.length}
                                </td>
                                <td id={"accountDetails" + i}>
                                    <i onClick={() => this.toggleModal("accountDetails" + i)} style={{ fontSize: 15, fontWeight: 500 }} className="fa fa-eye" aria-hidden="true" />
                                </td>
                                {this.state.openModal === ("accountDetails" + i) &&
                                    <ArgyleAccountDetailModal
                                        open={this.state.openModal === ("accountDetails" + i)}
                                        handleClose={() => this.toggleModal("")}
                                        argyleUserId={argyle.userId}
                                        driver={argyle.driver}
                                    />
                                }
                            </tr>
                        ) : !this.props.loading &&
                        <tr>
                            <td>No Argyle Account Found</td>
                        </tr>
                        }
                    </tbody>
                </Table>
            </PrimaryListingContainer >
        )
    }
}

export default compose(
    graphql(AllArgyleUsersQuery, {
        options: () => ({
            variables: {
                first: 30,
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),
        props({ data: { allArgyleUsers, loading, fetchMore } }) { return { allArgyleUsers, loading, fetchMore } }
    }),
)(ArgyleUserList)
