import React, { Component } from "react"
import { Container, Col, Row, Input, Nav, NavItem, Button } from "reactstrap"
import TaxManagementList from "./TaxManagementList"
import NewTaxRateModal from "./NewTaxRateModal"

const tabs = [
    { id: "#financeAccounts", name: "Finance Accounts" },
    { id: "#taxes", name: "Tax Management" }
]

class TaxManagementTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchTerm: null,
            orderBy: "-id",
            activeTab: null
        }
    }

    changeTab = (e) => {
        const { name, value } = e.target
        const tabIndex = tabs.findIndex(tab => tab.name === value)
        const selectedTab = tabs[tabIndex]
        window.location.hash = selectedTab.id
        this.setState({ [name]: value })
    }

    updateInput = (e) => {
        let { name, value } = e.target
        if (value === "null") value = null
        if (e.target.dataset.type) value = value === "false" ? false : true
        this.setState({ [name]: value })
    }

    updateOrderBy = (name) => {
        name = name && name.includes(".") ? name.replace(".", "__") : name
        this.setState((prevState) => { return { ...prevState, orderBy: prevState.orderBy === name ? "-" + name : name } })
    }

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) this.setState({ openModal: modalName })
        else this.setState({ openModal: "" })
    }

    render() {
        return (
            <>
                <Row>
                    <Col xs={4}>
                        <span className="driver-search-filter">
                            <Input type="text" name="searchTerm" placeholder="Search" onChange={this.updateInput} />
                            <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                        </span>
                    </Col>
                    <Col xs={8} className="text-right mt-2">
                        <a id="newTaxRate" className="driver-list-button" onClick={() => this.toggleModal("NewTaxRate")}>
                            New Tax | <i className="fa fa-plus" aria-hidden="true"></i>
                        </a>
                        {this.state.openModal === "NewTaxRate" && <NewTaxRateModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "NewTaxRate"} />}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col className="bos-object-section-wrapper">
                        <TaxManagementList currentUser={this.props.currentUser} searchTerm={this.state.searchTerm} orderBy={this.state.orderBy} updateOrderBy={this.updateOrderBy} />
                    </Col>
                </Row>
            </>
        )
    }
}

export default TaxManagementTab
