import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert, Progress, FormGroup, Label, Input } from 'reactstrap'
import Select from 'react-select'
import "./DNRModal.css"

const ALL_CUSTOMER_BLOCK_REASONS_QUERY = gql`
  query AllBlockedReasons {
    allBlockedReasons {
      edges {
        node {
          id
          pk
          name
          description
        }
      }
    }
  }
`
export const BLOCK_CUSTOMER_MUTATION = gql`
    mutation BlockCustomerMutation($input: BlockCustomerMutationInput!){
        blockCustomer(input:$input){
            ok 
            errors{ 
                field 
                messages
            }
        }
    }
`
const AddToDNR = ({ client, driverId, open, handleClose, refetchQuery }) => {
    const [allBlockedReasons, setAllBlockedReasons] = useState(null)
    const [input, setInput] = useState({
        reasonId: null,
        notes: "",
        driverId: driverId
    })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (client && open) {
            setLoading(true)
            client.query({
                query: ALL_CUSTOMER_BLOCK_REASONS_QUERY,
            }).then(response => {
                if (response.data && response.data.allBlockedReasons && response.data.allBlockedReasons.edges && response.data.allBlockedReasons.edges.length > 0)
                    setAllBlockedReasons(response.data.allBlockedReasons)
                setLoading(false)
            }).catch(err => {
                setError(err.toString())
                setLoading(false)
            })
        }
    }, [client, open])


    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)
        setError('')
        if (!driverId || !input.reasonId) {
            setError('Missing Required Field: Make sure to select all the required fields')
            setLoading(false)
        }
        else {
            try {
                const { data } = await client.mutate({
                    mutation: BLOCK_CUSTOMER_MUTATION,
                    variables: { input: input }
                })
                if (data && data.blockCustomer && data.blockCustomer.ok) {
                    setLoading(false)
                    refetchQuery()
                    window.location.reload()
                    handleClose()
                } else if (data && data.blockCustomer && data.blockCustomer.errors && data.blockCustomer.errors.length > 0) {
                    setError(data.blockCustomer.errors[0].messages[0])
                    setLoading(false)
                }

            } catch (error) {
                setError(error.message ? error.message : "Error creating address")
                setLoading(false)
            }
        }
    }

    return (
        <Modal isOpen={open} toggle={handleClose} className="dnr-modal">
            <ModalHeader>Add Driver TO DNR</ModalHeader>
            <ModalBody>
                {loading && <Progress animated color="info" value="100" />}
                {error && <Alert color="danger">{error}</Alert>}
                {allBlockedReasons && (
                    <FormGroup>
                        <Label for="notes">Select Block Reason*</Label>
                        <Select
                            className="bos-custom-select"
                            classNamePrefix="bos-select"
                            options={allBlockedReasons && allBlockedReasons.edges && allBlockedReasons.edges.length > 0 && allBlockedReasons.edges.map(reason => ({ value: reason.node.id, label: reason.node.name + "  |  " + reason.node.description }))}
                            onChange={(option) => setInput({ ...input, reasonId: option.value })}
                        />
                    </FormGroup>
                )}
                <FormGroup>
                    <Label for="notes">Notes</Label>
                    <Input type="text" name="notes" placeholder="Notes" onChange={(e) => setInput({ ...input, notes: e.target.value })} />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button className="secondary" onClick={handleClose}>Close</Button>
                <Button className="dnr-modal-btn" onClick={handleSubmit} disabled={loading}>ADD TO DNR</Button>
            </ModalFooter>
        </Modal>
    )
}

export default withApollo(AddToDNR)