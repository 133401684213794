import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import {
    Row, Col, Label, Alert,
    Button, FormGroup, Input, Modal, ModalBody, ModalFooter
} from 'reactstrap'
import './NewTransaction.css'

const CreateTransaction = gql`
mutation createTransaction($input: CreateTransactionMutationInput!){
    createTransaction(input:$input){
    ok
    errors{
      messages
    }
}
} `

const AllChargeTypeCategories = gql`
    query AllChargeTypeCategories{
        allChargeTypeCategories:__type(name: "ChargeTypeCategory") {
            values: enumValues {
                name
                description
            }
        }
    }`

const AllChargeTypes = gql`query AllChargeTypes{
    allChargeTypes(isActive:true,isVisible:true,orderBy:["name"]){
        edges {
          node {
            id
            name
            category
          }
        }
      }
}`

const AllowedChargeTypes = gql`query AllowedChargeTypes($driverId: ID!){
    allowedChargeTypes(isActive:true,isVisible:true,orderBy:["name"], driverId: $driverId){
        edges {
          node {
            id
            name
            category
          }
        }
    }
}`

const AllGroupTypes = gql`query AllGroupTypes($driverId: ID){
    allGroupTypes(isActive:true, driverId: $driverId){
        edges {
            node {
                id
                name
                defaultAmount
                chargeType{
                    name
                    id
                }
            }
        }
    }
}`

class NewTransaction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            warning: null,
            loading: false,
            chargeType: {},
            amount: "",
            notes: "",
            checkNo: "",
            groupTypeId: "",
            page: 1,
            selectedCategoryId: null,
            selectedCategoryOptions: [],
        }
    }
    updateInput = (e) => {
        if (this.props.driverId) {
            let name = e.target.name
            let value = e.target.value
            let toSet = { warning: null, error: null }
            if (name === "amount") {
                if (isNaN(parseFloat(value))) {
                    //pass
                } else {
                    value = parseFloat(value)
                }
            }
            if (name === "chargeType") {
                toSet["groupTypeId"] = ""
                value = this.props.allowedChargeTypes && this.props.allowedChargeTypes.edges && this.props.allowedChargeTypes.edges.length > 0 && this.props.allowedChargeTypes.edges.find(item => { return item.node.id === value })
                value = value && value.node ? value.node : null
            }
            if (name === "groupTypeId" && this.props.allTransactionGroupTypes && this.props.allTransactionGroupTypes.edges && this.props.allTransactionGroupTypes.edges.length > 0
                && this.props.allTransactionGroupTypes.edges.map(item => item.node.chargeType).includes(this.state.chargeType) && value) {
                let groupType = this.props.allTransactionGroupTypes.edges.find(item => item.node.id === value)
                if (!this.state.amount && groupType.node.defaultAmount) {
                    toSet["amount"] = groupType.node.defaultAmount
                }
            } else if (name === "groupTypeId" &&
                this.state.chargeType && this.props.allGroupTypes && this.props.allGroupTypes.edges && this.props.allGroupTypes.edges.length > 0
                && this.props.allGroupTypes.edges.map(item => item.node.chargeType ? item.node.chargeType.id : null).includes(this.state.chargeType.id) && !value) {
                toSet["warning"] = "Please choose a category for this chargetype"
            }
            toSet[name] = value
            this.setState({ ...toSet })
        }
    }
    runValidations = () => {
        let warning = []
        if (this.state.chargeType && this.props.allGroupTypes && this.props.allGroupTypes.edges && this.props.allGroupTypes.edges.length > 0 && this.props.allGroupTypes.edges.map(item => item.node.chargeType ? item.node.chargeType.id : null).includes(this.state.chargeType.id) && !this.state.groupTypeId) {
            warning.push('Please choose a category for this chargetype')
        }
        this.setState({ warning: warning.join(' | ') })
        return warning
    }
    togglePage = () => {
        let currentState = this.state
        if (currentState && currentState.page === 1 && this.runValidations().length > 0) {
            return
        }
        let newPage = currentState.page === 1 ? 2 : 1
        this.setState({ ...currentState, page: newPage })
    }
    createTransaction = (e) => {
        if (this.props.driverId && this.state.amount && this.state.amount !== 0 && this.state.chargeType && this.state.chargeType.id) {
            try {
                this.setState({ loading: true })
                let input = {}
                input["driverId"] = this.props.driverId
                input["amount"] = this.state.amount
                input["chargeTypeId"] = this.state.chargeType.id
                if (this.state.groupTypeId) {
                    input["groupTypeId"] = this.state.groupTypeId
                }
                input["notes"] = this.state.notes
                this.props.client.mutate({
                    mutation: CreateTransaction,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.createTransaction && result.data.createTransaction.ok) {
                        this.props.refetchQuery()
                        this.props.handleClose()
                    } else {
                        let error = "An error occurred, could not complete request."
                        if (result.data && result.data.createTransaction && result.data.createTransaction.errors && result.data.createTransaction.errors[0] && result.data.createTransaction.errors[0].messages[0]) {
                            error = result.data.createTransaction.errors[0].messages[0]
                        }
                        this.setState({ error: error })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    this.setState({ error: err, loading: false })
                })
            }
            catch (err) {
                let error = "An error has occured"
                this.setState({ error: error, loading: false })
            }
        } else {
            let error = "Invalid input. Please check your values."
            this.setState({ error: error })
        }
    }

    render() {
        return (
            <div className="NewTransaction">
                <Modal isOpen={this.props.open} className="NewTransaction">
                    <ModalBody>
                        <Row>
                            {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                            {this.state.warning && <Col xs={12}><Alert color="warning">{this.state.warning}</Alert></Col>}
                        </Row>
                        {this.state.page === 1 &&
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="category">What would you like to do?</Label>
                                        <div>
                                            {this.props.allChargeTypeCategories && this.props.allChargeTypeCategories.values && this.props.allChargeTypeCategories.values.map((item, i) =>
                                                <Button name="chargeTypeCategory" onClick={() => { this.setState({ selectedCategoryOptions: item.name, chargeType: "" }) }} className={this.state.selectedCategoryOptions === item.name ? "categoryOption active" : "categoryOption"}>Create {item.description}</Button>
                                            )}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="chargeType">Select charge type</Label>
                                        <Input type="select" value={this.state.chargeType ? this.state.chargeType.id : ''} name="chargeType" id="chargeType" onChange={this.updateInput} placeholder="Select charge type">
                                            <option value={""}></option>
                                            {this.props.allowedChargeTypes && this.props.allowedChargeTypes.edges && this.props.allowedChargeTypes.edges.filter(item => item.node.category === this.state.selectedCategoryOptions) &&
                                                this.props.allowedChargeTypes.edges.filter(item => item.node.category === this.state.selectedCategoryOptions).map((chargeType, i) =>
                                                    <option key={i} value={chargeType.node.id}>{chargeType.node.name}</option>
                                                )}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                {this.state.chargeType && this.props.allGroupTypes && this.props.allGroupTypes.edges && this.props.allGroupTypes.edges.length > 0
                                    && this.props.allGroupTypes.edges.map(item => item.node.chargeType ? item.node.chargeType.id : null).includes(this.state.chargeType.id) &&
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label for="groupTypeId">{`Which type of ${this.state.chargeType.name}`} is this?</Label>
                                            <Input type="select" value={this.state.groupTypeId} name="groupTypeId" id="groupTypeId" onChange={this.updateInput}>
                                                <option value={""}></option>
                                                {this.props.allGroupTypes && this.props.allGroupTypes.edges && this.props.allGroupTypes.edges.filter(groupType => groupType.node.chargeType && groupType.node.chargeType.id === this.state.chargeType.id).map((groupType, i) =>
                                                    <option key={i} value={groupType.node.id}>{groupType.node.name}</option>
                                                )}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                }
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="amount">Enter transaction amount</Label>
                                        <Input type="number" name="amount" id="amount" value={this.state.amount} onChange={this.updateInput} placeholder="Enter transaction amount" />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="notes">Notes</Label>
                                        <Input type={"text"} value={this.state.notes} name="notes" id="notes" onChange={this.updateInput} placeholder="Notes" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                        {this.state.page === 2 &&
                            <Row>
                                <Col xs={12}><p>Please confirm that you're about to create this transaction</p></Col>
                                <Col xs={12}>
                                    <strong>Charge type: {this.state.chargeType ? this.state.chargeType.name : '--'}</strong><br />
                                    <strong>Amount: {this.state.amount}</strong><br />
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                    {this.state.page === 1 &&
                        <ModalFooter>
                            <Button color="primary" onClick={this.togglePage} disabled={this.state.loading || this.state.warning || !this.state.amount || this.state.amount === 0 || isNaN(parseFloat(this.state.amount)) || !(this.state.chargeType && this.state.chargeType.id)}>{this.state.loading ? "Loading..." : "Continue"}</Button>{' '}
                            <Button color="secondary" onClick={this.props.handleClose}>Cancel</Button>
                        </ModalFooter>
                    }
                    {this.state.page === 2 &&
                        <ModalFooter>
                            <Button color="primary" onClick={this.createTransaction} disabled={this.state.loading || this.state.warning || !this.state.amount || this.state.amount === 0 || isNaN(parseFloat(this.state.amount)) || !(this.state.chargeType && this.state.chargeType.id)}>{this.state.loading ? "Loading..." : "Create Transaction"}</Button>{' '}
                            <Button color="secondary" onClick={this.togglePage}>Go back</Button>{" "}
                            <Button color="secondary" onClick={this.props.handleClose}>Cancel</Button>
                        </ModalFooter>
                    }
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllChargeTypes, { props({ data: { allChargeTypes } }) { return { allChargeTypes } } }),
    graphql(AllowedChargeTypes, {
        options: ({ driverId }) => ({ variables: { driverId } }),
        props({ data: { allowedChargeTypes } }) { return { allowedChargeTypes } }
    }),
    graphql(AllChargeTypeCategories, { props({ data: { allChargeTypeCategories } }) { return { allChargeTypeCategories } } }),
    graphql(AllGroupTypes, {
        options: ({ driverId }) => ({ variables: { driverId } }),
        props({ data: { loading, allGroupTypes } }) { return { loading, allGroupTypes } },
    }),
)(NewTransaction)