import React, { Component } from "react"
import "./Interactions.css"

class AudioPlayer extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="audio-player">
                <audio controls>
                    <source src={this.props.audio} type="audio/mpeg" />
                </audio>
            </div>
        )
    }
}

export default AudioPlayer