import React, { Component } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import toTitleCase from "../Functions/toTitleCase"

class CopyToClipboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            copied: false,
            error: "",
            toolTipId: `popover-${Math.random().toString(36).substr(2, 9)}`,
        }
    }

    handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(this.props.textToCopy)
            this.setState({ copied: true, error: "" })
            setTimeout(() => {
                this.setState({ copied: false, error: "" })
            }, 2000)
        } catch (error) {
            this.setState({ error: error, copied: false })
        }
    };

    render() {
        return (
            <>
                <i id={this.state.toolTipId} onClick={this.handleCopy} className="fa fa-solid fa-lg fa-clipboard" aria-hidden="true"></i>
                <UncontrolledTooltip target={this.state.toolTipId} placement="top">{this.state.copied ? toTitleCase(this.props.displayText) + ' Copied To Clipboard!' : this.state.error ? this.state.error : 'Click To Copy ' + toTitleCase(this.props.displayText)}</UncontrolledTooltip>
            </>
        )
    }
}

export default CopyToClipboard
