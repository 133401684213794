import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Col, Row, Label, Button } from 'reactstrap'
import Loader from "../../../../../Material/Loader"
import '../DriverDetailNew.css'
import DriverReservationDetails from "./DriverReservationDetails"
import gql from 'graphql-tag'

const DriverReservations = gql`query AllReservations($driverId: ID!){
    allReservations(driverId:$driverId, orderBy:["-id"]){
        edges{
            node{
                id
                status
                car{
                    id
                    pk
                    year
                    vin
                    carModel{
                        id
                        make
                        name
                    }
                }
            }
        }
    }
}
`
class DriverReservationTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            activeReservationId: this.props.allReservations && this.props.allReservations.edges && this.props.allReservations.edges.length > 0 ? this.props.allReservations.edges[0].node.id : null,
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.allReservations !== this.props.allReservations || (prevProps.activeTab !== this.props.activeTab && this.props.activeTab === "reservations")) && this.props.allReservations && this.props.allReservations.edges && this.props.allReservations.edges.length > 0) {
            if (this.props.location.search) {
                let queryParams = new URLSearchParams(this.props.location.search)
                let reservationId = this.props.allReservations.edges.find(reservation => reservation.node.id === queryParams.get("reservationId"))
                this.setState({ activeReservationId: reservationId ? reservationId.node.id : this.props.allReservations.edges[0].node.id })
            } else {
                this.props.history.push({ hash: this.props.location.hash, search: `reservationId=${this.props.allReservations.edges[0].node.id}` })
                this.setState({ activeReservationId: this.props.allReservations.edges[0].node.id })
            }
        }
    }

    setReservationId = (id) => {
        const queryString = new URLSearchParams({ "reservationId": id }).toString()
        this.props.history.push({ hash: this.props.location.hash, search: queryString })
        this.setState({ activeReservationId: id })
    }

    render() {
        return (
            <div className="rental-container">
                <Row>
                    <Col className="agreements-list col-2">
                        {this.props.loading ? <Loader /> : <>
                            {this.props.allReservations && this.props.allReservations.edges && this.props.allReservations.edges.length > 0 ?
                                this.props.allReservations.edges.map((reservation, i) =>
                                    <div
                                        className={"car-agreement" + (this.state.activeReservationId === reservation.node.id ? " active" : "")}
                                        onClick={() => this.setReservationId(reservation.node.id)}
                                        key={i}
                                    >
                                        <div className={"sidebar-listing-wrapper-icon"}>
                                            <i className={reservation.node.status === "_1" ? "fa fa-2x fa-ban " : "fa fa-2x fa-car " + (reservation.node.status && reservation.node.status === "Open" ? "text-warning" : "text-danger")} aria-hidden="true"></i>&nbsp;
                                        </div>
                                        <div className={"sidebar-listing-wrapper-text"}>
                                            {reservation.node && reservation.node.car ?
                                                <>
                                                    <p>{reservation.node && reservation.node.car && reservation.node.car.year} {reservation.node && reservation.node.car && reservation.node.car.carModel && reservation.node.car.carModel.make}  {reservation.node && reservation.node.car && reservation.node.car.carModel && reservation.node.car.carModel.name}</p>
                                                    <small>{reservation.node && reservation.node.car && reservation.node.car.pk} - {reservation.node && reservation.node.car && reservation.node.car.vin}</small>
                                                </> :
                                                <p className={reservation.node.status === "Open" ? "text-warning" : "text-danger"}>{`${reservation.node.status === "Open" ? "Awaiting" : reservation.node.status} Reservation`}</p>
                                            }
                                        </div>
                                    </div>
                                ) : <p className="text-center no-rentals">No Reservation Found!</p>
                            }
                        </>}
                    </Col>
                    {this.state.activeReservationId ? <DriverReservationDetails id={this.state.activeReservationId} /> :
                        <div className="zero-padding">
                            <p>No Reservation Info Found!</p>
                        </div>
                    }
                </Row>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(DriverReservations, {
        options: ({ id }) => ({ variables: { driverId: id }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true }),
        props({ data: { allReservations, loading, refetch, variables } }) {
            return {
                allReservations, loading, variables,
                refetchDriverQuery: () => {
                    return refetch({
                        query: DriverReservations,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { driver: fetchMoreResult.driver }
                        },
                    })
                },
            }
        }
    }),
)(DriverReservationTab)
