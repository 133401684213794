import React, { Component } from 'react';
import {Button} from 'reactstrap';
import './StyledToast.css';


class StyledToast extends Component{
    render() {
        return (
        <div {...this.props} className={this.props.className?this.props.className+" StyledToast":"StyledToast"}>
            {this.props.title&&<p className="toastHeader">{this.props.title}</p>}
            {this.props.children}
        </div>
        );
    }
}

export default StyledToast