import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Row, Col, UncontrolledTooltip, Button } from 'reactstrap'
import DatetimeRenderer from "../../Material/DatetimeRenderer"
import { AllDriverInteractionsQuery, BranchSettingQuery } from "./Queries"
import moment from 'moment'
import AudioPlayer from "./AudioPlayer"
import AttachmentViewer from "./AttachmentViewer"
import './Interactions.css'

class InteractionsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: null,
            openModal: "",
            messageBody: null,
            loading: false,
            justSent: null
        }
    }

    getTenantNameTooltip = (number, index) => {
        let tenantName = this.props.allBranchSettings && this.props.allBranchSettings.edges && this.props.allBranchSettings.edges.filter(setting => setting.node.value === number).filter((settings, index, arr) => arr.findIndex(obj => obj.node.branch.tenant.name === settings.node.branch.tenant.name) === index).map(obj => obj.node.branch.tenant.name).join(" | ")
        return <UncontrolledTooltip placement="right" target={'repUsername' + index}><i className="fa fa-mobile" aria-hidden="true"></i>&nbsp;&nbsp;{tenantName}</UncontrolledTooltip>
    }

    render() {
        let prevDate = null
        return (
            <div className='bos-interaction-wrapper'>
                {/* Sending Message is disabled */}
                {this.state.justSent &&//To fetch message that was just sent due to delay in messages sent and messages fetched
                    <Row className={"outbound-interaction"}>
                        <Col xs={12}>
                            <Row>
                                <Col xs={12}>{"Now"}</Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            {this.state.justSent}
                        </Col>
                    </Row>
                }

                {this.props.allDriverInteractions && this.props.allDriverInteractions.length > 0 && this.props.allDriverInteractions.map((chat, index) =>
                    <>
                        {function () {
                            if (!prevDate || prevDate !== moment(chat.dateSent).tz("America/New_York").format("dddd, MMMM Do YYYY")) {
                                prevDate = chat.dateSent
                                return <Row>
                                    <Col xs={{ offset: 3, size: 6 }} className="interaction-date">{<DatetimeRenderer datetime={prevDate} format={"dddd, MMMM Do YYYY"} />}&nbsp;&nbsp;<i className="fa fa-angle-double-down" aria-hidden="true"></i></Col>
                                </Row>
                            } else {
                                prevDate = chat.dateSent
                            }
                        }()}
                        <div key={index} className={"interaction " + (chat.type == "Call" ? "call-interaction " : chat.type == "Office Visit" ? "office-interaction " : "") + (chat.direction && chat.direction.toLowerCase() === "inbound" ? "inbound-interaction" : "outbound-interaction")}>
                            {<span className={"interaction-type-icon"}>
                                {chat.type === "Call" ? <i className="fa fa-phone" aria-hidden="true"></i> : chat.type === "App" ? <i className="fa fa-mobile" aria-hidden="true"></i> : chat.type === "Office Visit" ? <i className="fa fa-building-o" aria-hidden="true"></i> : chat.type === "Email" ? <i className="fa fa-envelope" aria-hidden="true"></i> : chat.type === "Chat" ? <i className="fa fa-comments-o" aria-hidden="true"></i> : <i className="fa fa-mobile" aria-hidden="true"></i>}
                            </span>}
                            <span className='interaction-details-wrapper'>
                                <span className="interaction-details">
                                    <span className='interaction-user'>
                                        <p>
                                            {chat.direction === "Inbound" ? <><i className="fa fa-mobile" aria-hidden="true"></i>&nbsp;&nbsp;{chat.from_}</> : <>
                                                <span id={'repUsername' + index}><i className={"fa " + (chat.rep ? "fa-user-o" : "fa-cog")} aria-hidden="true"></i>&nbsp;&nbsp;{chat.from_} {chat.rep && chat.rep.username ? (chat.rep.username) : " (System)"}</span>
                                                {this.getTenantNameTooltip(chat.from_, index)}
                                            </>}
                                            &nbsp;&nbsp;<i className="fa fa-long-arrow-right" aria-hidden="true"></i>&nbsp;&nbsp;
                                            {chat.direction === "Inbound" ? <>
                                                <span id={'repUsername' + index}><i className={"fa " + (chat.rep ? "fa-user-o" : "fa-cog")} aria-hidden="true"></i>&nbsp;&nbsp;{chat.to} {chat.rep && chat.rep.username ? (chat.rep.username) : " (System)"}</span>
                                                {this.getTenantNameTooltip(chat.to, index)}
                                            </> : <><i className="fa fa-mobile" aria-hidden="true"></i>&nbsp;&nbsp;{chat.to}</>}
                                        </p>
                                    </span>
                                    {chat.attachments && chat.attachments.length > 0 && <span className='interaction-msg'>
                                        {chat.type !== "Call" && chat.attachments.filter(item => item).length > 0 && <i className="fa fa-file-image-o" aria-hidden="true"></i>}
                                        {chat.type === "Call" ? (chat.attachments && chat.attachments.length > 0 ? chat.attachments.map(item => {
                                            return <AudioPlayer audio={item.url} index={index} />
                                        }) : <b>No Audio Found</b>)
                                            : (
                                                chat.attachments.filter(item => item).map((item, i) =>
                                                    item.url ? <>
                                                        &nbsp;&nbsp;<a key={i} className="attachment-link" href={item.url} target="_blank"><i className="fa fa-paperclip" aria-hidden="true"></i>&nbsp;&nbsp;{i + 1}</a>
                                                    </> : <>
                                                        &nbsp;&nbsp;<i key={i} className="attachment-link fa fa-paperclip" aria-hidden="true" id={`attachment-${i}`} onClick={() => this.setState({ openModal: `ViewAttachment-Message-${index}-attachment-${i}` })}>&nbsp;{item.name}</i>
                                                        {this.state.openModal === `ViewAttachment-Message-${index}-attachment-${i}` && <AttachmentViewer attachment={item} handleClose={() => this.setState({ openModal: "" })} open={this.state.openModal === `ViewAttachment-Message-${index}-attachment-${i}`} />}
                                                    </>)
                                            )}
                                    </span>}
                                    {chat.type !== "Call" && chat.body && <span className='interaction-msg'>
                                        <p>
                                            {chat.type !== "Office Visit" && <> <i className="fa fa-comments-o" style={{ verticalAlign: "top" }} aria-hidden="true"></i>&nbsp;&nbsp; </>}
                                            <span dangerouslySetInnerHTML={{ __html: chat.body }} />
                                        </p>
                                    </span>}
                                </span>
                                <span className='interaction-time'>
                                    <p>&nbsp;&nbsp;{chat.dateSent ? <DatetimeRenderer datetime={chat.dateSent} format={"hh:mm A"} /> : "--"}&nbsp;&nbsp;
                                        {chat.isSent ?
                                            <><i style={{ color: "green", fontSize: "13px" }} id={'success' + index} className="fa fa-check" aria-hidden="true"></i><UncontrolledTooltip placement="left" target={'success' + index}>Delivered</UncontrolledTooltip></> :
                                            <><i style={{ color: "red", fontSize: "13px" }} id={'error' + index} className="fa fa-exclamation-circle" aria-hidden="true"></i><UncontrolledTooltip placement="left" target={'error' + index}>Failed: {chat.clientResponse}</UncontrolledTooltip></>
                                        }
                                    </p>
                                </span>
                            </span>
                        </div>
                    </>
                )}
                {this.props.loading ?
                    <p className='text-center' style={{ opacity: ".6" }}><i className="fa fa-spinner" aria-hidden="true"></i>&nbsp;&nbsp;Loading Driver Interactions...</p> :
                    this.props.allDriverInteractions && this.props.allDriverInteractions.length > 0 ?
                        <>
                            <p className='text-center' style={{ opacity: ".6" }}>Showing last {this.props.allDriverInteractions.length} messages only</p>
                            <Button className={"ghost float-right text-center"} onClick={this.props.loadMoreInteractions} >Show More <i className={"fa fa-caret-down"}></i></Button>
                        </>
                        : <p className='text-center' style={{ opacity: ".6" }}>No interactions found</p>}
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllDriverInteractionsQuery, {
        options: ({ driverId, interactionType, direction, interactionDate, handles }) => ({ variables: { id: driverId, interactionDate, interactionType, direction, handles, first: 30 }, notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' }),
        props({ data: { loading, allDriverInteractions, fetchMore, variables } }) {
            return {
                loading, allDriverInteractions,
                refetchAllDriverInteractionsQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    return fetchMore({
                        query: AllDriverInteractionsQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return {
                                allDriverInteractions: fetchMoreResult.allDriverInteractions
                            }
                        },
                    })
                },
                loadMoreInteractions: () => {
                    return fetchMore({
                        query: AllDriverInteractionsQuery,
                        variables: {
                            after: allDriverInteractions && allDriverInteractions.at(-1) ? allDriverInteractions.at(-1).cursor : null,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newInteractions = fetchMoreResult.allDriverInteractions

                            return {
                                allDriverInteractions: [...previousResult.allDriverInteractions, ...newInteractions],
                            }
                        },
                    })
                },
            }
        },
    }),
    graphql(BranchSettingQuery, {
        options: { fetchPolicy: 'cache-first', variables: { "settingName": "SMS_FROM_DEFAULT" } },
        props({ data: { loading, allBranchSettings } }) {
            return { loading, allBranchSettings }
        },
    }),
)(InteractionsList)