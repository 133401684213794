import React, { Component } from 'react'
import { compose, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Popover, PopoverBody, PopoverHeader, Button, Row, Col, Progress } from 'reactstrap'
import './DriverNotes.css'
import DatetimeRenderer from "../Material/DatetimeRenderer"

const DriverNotesHistory = gql`
    query DriverNotesHistory($driverId:ID!, $noteType:String, $objectId:ID, $first:Int){
        driverNotesHistory(driverId:$driverId, noteType: $noteType, objectId: $objectId, first:$first){
            edges{
                node{
                    dateAdded
                    user {
                        username
                    }
                    changedFrom
                    changedTo
                }
            }
            pageInfo{
                hasNextPage
            }
      }
    }
`


class DriverNotes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            limit: 5,
            notesHistory: null,
            hasNextPage: false
        }
    }
    fetchMore = () => {
        let limit = this.state.limit
        limit = limit + 5
        this.setState({ limit: limit })
        this.fetchData()
    }
    fetchData = () => {
        if (this.props.driver && this.props.driver.id) {
            this.setState({ loading: true })
            let input = { driverId: this.props.driver.id, first: this.state.limit }
            if (this.props.noteType)
                input["noteType"] = this.props.noteType
            if (this.props.objectId)
                input["objectId"] = this.props.objectId
            this.props.client.query({
                query: DriverNotesHistory,
                variables: input,
            }).then((result) => {
                if (result && result.data && result.data.driverNotesHistory) {
                    let edges = result.data.driverNotesHistory.edges
                    let hasNextPage = false
                    if (result.data.driverNotesHistory.pageInfo && result.data.driverNotesHistory.pageInfo.hasNextPage) {
                        hasNextPage = result.data.driverNotesHistory.pageInfo.hasNextPage
                    }
                    this.setState({ notesHistory: edges, hasNextPage: hasNextPage, loading: false })
                }
            }).catch((err) => {

            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // Typical usage (don't forget to compare props):
        if (this.props.driver !== prevProps.driver || (this.props.driver && prevProps.open !== this.props.open) || prevState.limit !== this.state.limit) {
            this.fetchData()
        }
    }
    componentDidMount() {
        this.fetchData()
    }

    render() {
        return (
            <div>
                <Popover className="notes_history" placement={this.props.placement ? this.props.placement : "right"} isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader onClick={this.props.handleClose} className="d-flex justify-content-center">{this.props.title ? this.props.title : "Notes History"}</PopoverHeader>
                    <PopoverBody>
                        {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                        <div className="notes_container">
                            {!this.state.loading && this.state.notesHistory && this.state.notesHistory.length > 0 ? this.state.notesHistory.map((note, i) =>
                                <p key={i}>
                                    <b>{<DatetimeRenderer datetime={note.node.dateAdded} />}</b><br />
                                    <i>{note.node.user ? note.node.user.username : "--"} updated notes:<br /></i>
                                    <b>Current:</b> {note.node.changedTo}<br />
                                    <b>Previous:</b> {note.node.changedFrom}
                                </p>
                            ) : !this.state.loading && "No Notes Found!"}
                        </div>
                        <hr />
                        <Row>
                            <Col className="text-rigth">
                                {this.state.hasNextPage &&
                                    <div className="float-right">
                                        &nbsp;&nbsp;&nbsp;
                                        <Button className="show_more" type="button" size="sm" onClick={this.fetchMore}>Show More</Button>
                                    </div>
                                }
                                <Button className="float-right close_history" type="button" size="sm" onClick={this.props.handleClose}>Close</Button>
                            </Col>
                        </Row>
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

export default compose(
    withApollo,
)(DriverNotes)