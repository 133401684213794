import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Row, Col, Alert, Progress, Label, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import "./AddToDidntTakeCar.css"
const addToDidntTakeCarMutation = gql`
mutation addToDidntTakeCarMutation($input: AddToDidntTakeCarMutationInput!){
    addToDidntTakeCar(input:$input){
    ok
    errors{
        messages
    }
}
} `
const AllDidntTakeCarReason = gql`
    query allDidntTakeCarReason{
        allDidntTakeCarReason{
            edges{
                node{
                    id
                    name
                }
            }
      }
    }
`
class AddToDidntTakeCar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            addedToDidntTakeCar: false,
            openModal: "",
            reason: null
        }
    }
    handleClose = (e) => {
        //Reset state
        let state = {
            loading: false,
            error: null,
            addedToDidntTakeCar: false,
            openModal: "",
            reason: null
        }
        this.setState({ ...state })
        this.props.handleClose()
    };
    addToDidntTakeCar = () => {
        this.setState({ loading: true })
        let input = {
            driverId: this.props.driverId ? this.props.driverId : "",
            reasonId: this.state.reason
        }

        this.props.client.mutate({
            mutation: addToDidntTakeCarMutation,
            variables: { input },
        }).then((result) => {
            if (result.data && result.data.addToDidntTakeCar && result.data.addToDidntTakeCar.ok) {
                this.setState({ addedToDidntTakeCar: true, error: null, loading: false })
                if (this.props.refetchQuery) {
                    this.props.refetchQuery()
                    this.handleClose()
                }
            } else if (result.data && result.data.addToDidntTakeCar.errors && result.data.addToDidntTakeCar.errors.length > 0) {
                this.setState({ loading: false, addedToDidntTakeCar: false, error: result.data.addToDidntTakeCar.errors[0].messages[0] })
            } else {
                this.setState({ loading: false, addedToDidntTakeCar: false, error: "An error has occured. Please contact the admin." })
            }
        }).catch((error) => {
            let errorMessage = "An error has occured. Please contact the admin."
            this.setState({ error: errorMessage, loading: false, addedToDidntTakeCar: false, })
        })
    };
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        // To change string inputs to boolean because only strings can be passed to html options
        if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        this.setState({ [name]: value })
    };
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        return (

            <Modal className="dtac-modal" isOpen={this.props.open}>
                {this.props.driverId &&
                    <>
                        <ModalHeader>Add driver to didnt take car</ModalHeader>
                        <ModalBody>
                            {this.state.error && !this.state.addedToDidntTakeCar && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                            {this.state.addedToDidntTakeCar && !this.state.error && <Row><Col xs={12}><Alert color="success">Added to didnt take a car successfully!</Alert></Col></Row>}
                            {!this.state.addedToDidntTakeCar &&
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label for="reason">Please choose didnt take car reason?</Label>
                                            <Input type="select" name="reason" id="reason" onChange={this.updateInput} placeholder="Didnt take a car reason">
                                                <option key={-1} value={null}>Choose reason</option>
                                                {this.props.allDidntTakeCarReason && this.props.allDidntTakeCarReason.edges && this.props.allDidntTakeCarReason.edges.length > 0 && this.props.allDidntTakeCarReason.edges.map((reason, i) =>
                                                    <option key={i} value={reason.node.id}>{reason.node.name}</option>
                                                )}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                            {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                        </ModalBody>
                    </>}
                <ModalFooter>
                    {!this.state.loading &&
                        <Row>
                            <Col xs={12}>
                                <Button className="dtac-modal-secondary-btn" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                <Button className="dtac-modal-primary-btn" onClick={this.addToDidntTakeCar} disabled={!this.state.reason || this.state.addedToDidntTakeCar || this.state.loading}>Add to didn't take car</Button>&nbsp;&nbsp;
                            </Col>
                        </Row>
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllDidntTakeCarReason, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allDidntTakeCarReason } }) {
            return { loading, allDidntTakeCarReason }
        },
    })
)(AddToDidntTakeCar)