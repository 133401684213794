import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { CarStages } from '../Functions/querys'
import { Container, Button, Nav, Col, Row, NavItem, Card, CardBody, FormGroup, Input, Form } from 'reactstrap'
import Select from 'react-select'
import RegistrationsList from './Registrations/RegistrationsList'
import InspectionsList from './Inspections/InspectionsList'
import { AllRegistrationTypesQuery, CarInspectionStatus } from './Queries'
import InspectionModal from "./Inspections/InspectionModal"
import RegistrationModal from "./Registrations/RegistrationModal"
import renderEnumToInt from "../Functions/renderEnumToInt"
import './Registrations.css'

const INSPECTION_TABS = [
    { id: 1, name: "Upcoming" }, { id: 2, name: "All Inspections" },
]
const REGISTRATION_TABS = [
    { id: 1, name: "Installed" }, { id: 2, name: "Not Installed" }, { id: 3, name: "All Registrations" },
]

class RegistrationsContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            orderBy: this.props.isInspectionView ? "-id" : ["-registration_type__name", "expiration_date"],
            status: null,
            openModal: "",
            seletedRegistrationTypes: null,
            inInspection: null,
            seletedCarStages: null,
            selectedInspectionStatuses: null,
            activeTab: this.props.isInspectionView ? "Upcoming" : "Installed",
            searchTerm: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.dataset.type) {
            if (value === "false") {
                value = false
            } else if (value === "true") {
                value = true
            }
        }
        if (value === "null") {
            value = null
        }
        if (e.target.type === "date" && !value) {
            value = null
        }
        this.setState({ [name]: value })
    };

    updateOrder = (name) => {
        name = name && name.includes(".") ? name.replace(".", "__") : name
        this.setState({ orderBy: (this.state.orderBy === name ? "-" + name : name) })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isInspectionView != this.props.isInspectionView) {
            this.setState({
                loading: false,
                error: null,
                orderBy: this.props.isInspectionView ? "-id" : "expiration_date",
                status: null,
                inInspection: null,
                openModal: "",
                seletedRegistrationTypes: null,
                seletedCarStages: null,
                selectedInspectionStatuses: null,
                activeTab: this.props.isInspectionView ? "Upcoming" : "Installed",
                searchTerm: "",
            })
        }
    }

    render() {
        let statusOptions = [{ value: "Installed", label: "Installed" }, { value: "Active", label: "Installed & Active" }, { value: "Inactive", label: "Installed But Inactive" }, { value: "Uninstalled", label: "Not Installed" }]
        // statusOptions = [{ value: "Installed", label: "Installed" }, { value: "Uninstalled", label: "Not Installed" }]
        if (!this.props.isInspectionView) {
            if (this.state.activeTab === "Installed") {
                statusOptions = [{ value: "Active", label: "Active" }, { value: "Inactive", label: "Inactive" }]
                // statusOptions = []
            } else if (this.state.activeTab === "Not Installed") {
                statusOptions = []
            }
        }

        return (
            <Container fluid className='car-registration-container'>
                <Nav pills className="registration-tabs">
                    {this.props.isInspectionView ? <>
                        {INSPECTION_TABS.map(tab =>
                            <NavItem key={tab.id}>
                                <Button color="primary" name={"activeTab"} value={tab.name} className={this.state.activeTab === tab.name ? "active" : "inactive"} onClick={this.updateInput}>
                                    {tab.name && tab.name === "Upcoming" ? <i className="fa fa-calendar-check-o text-success" aria-hidden="true"></i> : <i className="fa fa-list" aria-hidden="true"></i>}
                                    &nbsp;&nbsp;{tab.name}
                                </Button>
                            </NavItem>
                        )}
                        {/* {this.state.openModal === "addNewInspectionModal" && <InspectionModal open={this.state.openModal === "addNewInspectionModal"} handleClose={() => this.toggleModal("")} refetchQuery={() => { return true }} />}
                        <Button className="bos-btn-primary" style={{ right: "3%", position: 'absolute' }} onClick={() => this.toggleModal("addNewInspectionModal")}>
                            <i className="fa fa-calendar-plus-o" aria-hidden="true"></i>&nbsp;&nbsp;
                            NEW INSPECTION
                        </Button> */}
                    </> : <>
                        {REGISTRATION_TABS && REGISTRATION_TABS.length > 0 && REGISTRATION_TABS.map(tab =>
                            <NavItem key={tab.id}>
                                <Button color="primary" name={"activeTab"} value={tab.name} className={this.state.activeTab === tab.name ? "active" : "inactive"} onClick={this.updateInput}>
                                    <i className={"fa " + (tab.name === "Installed" ? "fa-check-circle text-success" : tab.name === "Not Installed" ? "fa-circle-o text-danger" : "fa-list")} aria-hidden="true"></i>
                                    &nbsp;&nbsp;{tab.name}
                                </Button>
                            </NavItem>
                        )}
                        {this.state.openModal && this.state.openModal === "createRegistration" && <RegistrationModal open={this.state.openModal === "createRegistration"} handleClose={() => this.toggleModal("createRegistration")} refetchQuery={() => { return true }} />}
                        <Button className="bos-btn-primary" style={{ right: "3%", position: 'absolute' }} onClick={() => this.toggleModal("createRegistration")}>
                            <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;&nbsp;
                            NEW REGISTRATION
                        </Button>
                    </>}
                </Nav>
                <Card className='car-registration-filters-container'>
                    <CardBody>
                        <Row>
                            {this.props.isInspectionView && this.state.activeTab === "All Inspections" && <Col>
                                <Select
                                    className="bos-custom-select" classNamePrefix="bos-select"
                                    isLoading={!(this.props.carInspectionStatus && this.props.carInspectionStatus.edges && this.props.carInspectionStatus.edges.length > 0)}
                                    options={this.props.carInspectionStatus && this.props.carInspectionStatus.edges && this.props.carInspectionStatus.edges.length > 0 && this.props.carInspectionStatus.edges.map(item => ({ value: item.description, label: item.name }))}
                                    placeholder="All Inspection Statuses"
                                    isClearable
                                    isMulti
                                    onChange={(status) => this.setState({ selectedInspectionStatuses: (status.map(item => item.value)) })}
                                />
                            </Col>}
                            <Col>
                                <FormGroup>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!(this.props.allRegistrationTypes && this.props.allRegistrationTypes.edges && this.props.allRegistrationTypes.edges.length > 0)}
                                        options={this.props.allRegistrationTypes && this.props.allRegistrationTypes.edges && this.props.allRegistrationTypes.edges.length > 0 && this.props.allRegistrationTypes.edges.map(item => ({ value: item.node.id, label: (item.node.name + " - " + item.node.stateCode) }))}
                                        placeholder="All Registration Types"
                                        isClearable
                                        isMulti
                                        onChange={(registrationTypes) => this.setState({ seletedRegistrationTypes: (registrationTypes.map(item => item.value)) })}
                                    />
                                </FormGroup>
                            </Col>
                            {!this.props.isInspectionView && <Col>
                                <FormGroup>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        options={[{ value: true, label: "In Inspection" }, { value: false, label: "Not In Inspection" }]}
                                        placeholder="All Inspection Statuses"
                                        isClearable
                                        onChange={(inInspection) => this.setState({ inInspection: inInspection ? inInspection.value : null })}
                                    />
                                </FormGroup>
                            </Col>}
                            {!this.props.isInspectionView && this.state.activeTab === "Installed" && <Col>
                                <FormGroup className="mb-0">
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!(this.props.carStages && this.props.carStages.states && this.props.carStages.states.length > 0)}
                                        options={this.props.carStages && this.props.carStages.states && this.props.carStages.states.length > 0 && this.props.carStages.states.map(item => ({ value: renderEnumToInt(item.name), label: "Stage " + renderEnumToInt(item.name) + " - " + item.description }))}
                                        placeholder="All Car Stages"
                                        isMulti
                                        onChange={(stages) => this.setState({ seletedCarStages: (stages.map(item => item.value)) })} />
                                </FormGroup>
                            </Col>}
                            <Col>
                                <span className={(!statusOptions || statusOptions.length == 0) && "d-none"}>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!(this.props.allRegistrationTypes && this.props.allRegistrationTypes.edges && this.props.allRegistrationTypes.edges.length > 0)}
                                        options={statusOptions}
                                        placeholder="All Registration Statuses"
                                        isClearable
                                        onChange={(status) => this.setState({ status: status ? status.value : null })}
                                    />
                                </span>
                            </Col>
                            <Col className="searchterm-wrapper">
                                <FormGroup>
                                    <Input type="text" name="searchTerm" value={this.state.searchTerm} placeholder="Search" onChange={this.updateInput} />
                                    <Button disabled>
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                {this.props.isInspectionView ?
                    <InspectionsList
                        orderBy={this.state.orderBy}
                        registrationsFor={this.props.type}
                        registrationTypeIn={this.state.seletedRegistrationTypes && this.state.seletedRegistrationTypes.length > 0 ? this.state.seletedRegistrationTypes : null}
                        inspectionStatusIn={this.state.activeTab === "Upcoming" ? ["PENDING", "APPLIED"] : this.state.selectedInspectionStatuses && this.state.selectedInspectionStatuses.length > 0 ? this.state.selectedInspectionStatuses : null}
                        searchTerm={this.state.searchTerm}
                        isInstalled={this.state.status ? ["Installed", "Active", "Inactive"].includes(this.state.status) ? true : false : null}
                        isActive={this.state.status === "Active" ? true : this.state.status === "Inactive" ? false : null}
                        updateOrder={this.updateOrder}
                    /> :
                    <RegistrationsList
                        orderBy={this.state.orderBy}
                        registrationsFor={this.props.type}
                        inInspection={this.state.inInspection}
                        registrationTypeIn={this.state.seletedRegistrationTypes && this.state.seletedRegistrationTypes.length > 0 ? this.state.seletedRegistrationTypes : null}
                        searchTerm={this.state.searchTerm}
                        carStageIn={this.state.seletedCarStages && this.state.seletedCarStages.length > 0 ? this.state.seletedCarStages : null}
                        isInstalled={this.state.activeTab === "Installed" ? true : this.state.activeTab === "Not Installed" ? false : this.state.status ? ["Installed", "Active", "Inactive"].includes(this.state.status) ? true : false : null}
                        isActive={this.state.status === "Active" ? true : this.state.status === "Inactive" ? false : null}
                        updateOrder={this.updateOrder}
                    />
                }
            </Container>
        )
    }
}

export default compose(
    withApollo,
    graphql(CarStages, { props({ data: { carStages } }) { return { carStages } } }),
    graphql(AllRegistrationTypesQuery, {
        options: () => ({ variables: { typeFor: "Car" }, fetchPolicy: 'cache-first' }),
        props({ data: { allRegistrationTypes } }) {
            return { allRegistrationTypes }
        }
    }),
    graphql(CarInspectionStatus, {
        options: () => ({ fetchPolicy: 'cache-first' }),
        props({ data: { carInspectionStatus } }) {
            return { carInspectionStatus }
        }
    }),
)(RegistrationsContainer)