import React, { useState } from "react"
import { compose, graphql } from "react-apollo"
import { Modal, ModalBody, ModalHeader, Form, FormGroup, Input, Label, Row, Col, Progress, Button } from "reactstrap"
import { CreateConditionMutation } from "./Mutations"
import Select from "react-select"
import { ConditionTypes, AllConditionGroupsQuery, AllTriggersQuery, AllAvailableModelsQuery } from "./Queries"

const ConditionForm = ({ createCondition, conditionTypes, allConditionGroups, allAvailableModels, allTriggers, loading, handleClose, isOpen, isUpdate, refetchAutomationQuery }) => {

    const [logicalOperator, setLogicalOperator] = useState('')
    const [condition, setCondition] = useState({ fieldName: '', conditionType: '', conditionValue: '' })
    const [error, setError] = useState(null)
    const [availableModel, setAvailableModel] = useState(null)
    const [stateLoading, setStateLoading] = useState(false)
    const [triggerId, setTriggerId] = useState(null)

    const handleConditionChange = (field, value) => {
        let newCondition = { ...condition }
        newCondition[field] = value
        setCondition(newCondition)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setStateLoading(true)
        createCondition({
            variables: {
                input: {
                    conditionGroupId: logicalOperator,
                    fieldName: condition.fieldName,
                    conditionType: condition.conditionType,
                    conditionValue: condition.conditionValue,
                }
            }
        }).then((response) => {
            if (response.data.createCondition.ok) {
                refetchAutomationQuery()
                handleClose()
            } else {
                setError(response.data.createCondition.errors[0].messages)
            }
            setStateLoading(false)
        })
    }
    return (
        <Modal isOpen={isOpen} toggle={handleClose} >
            <ModalHeader toggle={handleClose}>Condition</ModalHeader>
            <ModalBody>
                {error && <div className="alert alert-danger">{error}</div>}
                {(loading || stateLoading) && <Progress animated color="info" value="100" />}
                <Form className="ActionForm" onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="logicalOperator">Logical Operator</Label>
                        <Select className="bos-custom-select"
                            classNamePrefix="bos-select"
                            id="logicalOperator"
                            options={
                                allConditionGroups && allConditionGroups.edges.map((conditionGroup) => ({
                                    label: conditionGroup.node.logicalOperator ? conditionGroup.node.logicalOperator : '',
                                    value: conditionGroup.node.id,
                                }))
                            }
                            placeholder="Select Logical Operator"
                            onChange={(option) => setLogicalOperator(option.value)}
                        />
                    </FormGroup>

                    {!isUpdate && <div>
                        <label>Select Trigger</label>
                        <Select className="bos-custom-select"
                            classNamePrefix="bos-select" isLoading={false}
                            options={
                                allTriggers && allTriggers.edges.map((item) => ({
                                    label: item.node.contentType.model + " | " + item.node.triggerType,
                                    value: item.node,
                                }))
                            }
                            value={availableModel && availableModel.contentType && {
                                label: availableModel.contentType.model.toUpperCase() + " | " + allTriggers.edges.find(trigger => trigger.node.contentType.model === availableModel.contentType.model).node.triggerType,
                                value: availableModel,
                            }}
                            isDisabled={isUpdate}
                            placeholder="Select Trigger Type"
                            onChange={(option) => {
                                setTriggerId(option.value.id)
                                setAvailableModel(allAvailableModels.edges.find((model) => model.node.contentType.model === option.value.contentType.model).node)
                            }}
                        />
                    </div>}
                    <br />
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="fieldName">Field Name</Label>
                                <Select className="bos-custom-select"
                                    classNamePrefix="bos-select" isLoading={false}
                                    options={
                                        availableModel ? availableModel.availableModelFields.map((field) => ({
                                            label: field ? field.replaceAll('_', ' ').toUpperCase() : '',
                                            value: field,
                                        })) : []
                                    }
                                    placeholder="Select Field of the the trigger model"
                                    onChange={(option) => handleConditionChange('fieldName', option.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="conditionType">Condition Type</Label>
                                <Select className="bos-custom-select"
                                    classNamePrefix="bos-select" isLoading={false}
                                    options={
                                        conditionTypes && conditionTypes.states.map((item) => ({
                                            label: item.name ? item.name.replaceAll('_', ' ').toUpperCase() : '',
                                            value: item.name,
                                        }))
                                    }
                                    placeholder="Select Condition Type"
                                    onChange={(option) => handleConditionChange('conditionType', option.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="conditionValue">Condition Value</Label>
                                <Input
                                    type="text"
                                    id="conditionValue"
                                    placeholder="Enter Condition Value"
                                    value={condition.conditionValue}
                                    onChange={(e) => handleConditionChange('conditionValue', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="text-right">
                        <Button color="primary" type="submit" disabled={!availableModel}>Add Condition</Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default compose(
    graphql(ConditionTypes, {
        options: () => ({ variables: {}, notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' }),
        props({ data: { loading, conditionTypes } }) {
            return { loading, conditionTypes }
        },
    }),
    graphql(AllTriggersQuery, {
        options: ({ automationId }) => ({
            variables: { automation: automationId },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),
        props({ data: { loading, allTriggers } }) {
            return {
                allTriggersloading: loading, allTriggers,
            }
        },
    }),
    graphql(AllAvailableModelsQuery, {
        options: () => ({
            variables: {},
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),
        props({ data: { allAvailableModels } }) {
            return {
                allAvailableModels,
            }
        },
    }),
    graphql(AllConditionGroupsQuery, {
        options: ({ automationId }) => ({ variables: { automation: automationId }, notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' }),
        props({ data: { loading: stateloading, allConditionGroups } }) {
            return { stateloading, allConditionGroups }
        },
    }),
    graphql(CreateConditionMutation, { name: 'createCondition' }),
)(ConditionForm)
