import React, { Component } from 'react'
import moment from 'moment'
import { PreferredDateTimeContext } from "./Context/PreferredDateTimeContext"
export default class Clock extends Component {
    constructor() {
        super()
        this.state = {
            currentDateTime: new Date(),
        }
        this.interval = this.interval.bind(this)
    }

    componentDidMount() {
        this.interval()
    }
    componentWillUnmount() {
        clearInterval(this.interval)
    }

    interval = () => {
        setInterval(() => {
            this.setState({ currentDateTime: new Date() })
        }, 1000)
    }

    render() {
        return (
            <PreferredDateTimeContext.Consumer>
                {({ preferredDateTimeInfo }) => (
                    <>{moment(this.state.currentDateTime).tz(preferredDateTimeInfo.timezone).format(preferredDateTimeInfo.dateFormat + " " + preferredDateTimeInfo.timeFormat)} {moment.tz(preferredDateTimeInfo.timezone).format('z')}</>
                )}
            </PreferredDateTimeContext.Consumer>
        )
    }
}
