import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, graphql, withApollo } from 'react-apollo'
import { Container, Col, Row, Label, Input, Button } from 'reactstrap'
import Loader from "../../../Material/Loader"
import CreateUpdateSetting from '../../BranchMangement/CreateUpdateSetting'
import toTitleCase from "../../../Functions/toTitleCase"

const BranchOverviewQuery = gql`query Branch($id: ID!){
    branch(id: $id){
        id
        settings
    }
}`

const BranchSettingQuery = gql`query AllBranchSettings($branchId: ID!){
    allBranchSettings(branchId: $branchId){
        edges {
        node {
            id
            key{
                id
                name
                description
                isRequired
            }
            value
        }
        }
    }
}`

class SettingTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            openModal: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    pObj = (jsonString) => {
        try {
            return JSON.parse(jsonString)
        } catch (error) {
            return {}
        }
    }
    objectToString = (obj) => {
        if (typeof obj === "object") {
            return JSON.stringify(obj)
        } else {
            return obj
        }
    }
    render() {
        return (
            <Container fluid>
                {this.props.loading || !this.props.branch ? <Loader /> : <>
                    <Row className="px-2"><Col className="px-4 text-right pb-4">
                        <Button id="newSettingsKeyEdit" type="button" className="bos-btn-primary" size="sm" onClick={() => this.toggleModal('newSettingsKey')}>ADD/UPDATE SETTINGS</Button>
                        {this.state.openModal === "newSettingsKey" && <CreateUpdateSetting branchId={this.props.branch.id} target={"newSettingsKeyEdit"} open={this.state.openModal === "newSettingsKey"}
                            handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchSettingsQuery}
                        />}
                    </Col></Row>
                    <Row>
                        <Col className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                {this.props.allBranchSettings && this.props.allBranchSettings.edges && this.props.allBranchSettings.edges.map((setting, i) =>
                                    <Col xs={6} key={i}>
                                        <Label>{toTitleCase(setting.node.key.name)}{setting.node.key.isRequired && "*"}</Label>
                                        <Input disabled className="box-object-property-input" value={this.objectToString(setting.node.value)} />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row style={{ opacity: "0.5" }}>
                        <Col xs={12}>
                            <h3>Old Settings</h3>
                        </Col>
                        <Col className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                {this.pObj(this.props.branch.settings) !== null && this.pObj(this.props.branch.settings) !== undefined && Object.entries(this.pObj(this.props.branch.settings)).map(([key, value], i) =>
                                    <Col xs={6} key={i} id={`${key.replace(/\s+/g, '_')}Edit`}>
                                        <Label id={`${key.replace(/\s+/g, '_')}Edit`}>{toTitleCase(key)}</Label>
                                        <Input disabled className="box-object-property-input" value={this.objectToString(value)} />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </>}
            </Container>
        )
    }
}


export default compose(
    withApollo,
    graphql(BranchOverviewQuery, {
        options: ({ branchId }) => ({ variables: { id: branchId } }),
        props({ data: { branch, loading, refetch, variables } }) {
            return {
                branch, loading, variables,
                refetchQuery: () => {
                    return refetch({
                        query: BranchOverviewQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { branch: fetchMoreResult.branch }
                        },
                    })
                },
            }
        }
    }),
    graphql(BranchSettingQuery, {
        options: ({ branchId }) => ({ variables: { branchId } }),
        props({ data: { allBranchSettings, loading, refetch, variables } }) {
            return {
                allBranchSettings, loading, variables,
                refetchSettingsQuery: () => {
                    return refetch({
                        query: BranchSettingQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { allBranchSettings: fetchMoreResult.allBranchSettings }
                        },
                    })
                },
            }
        }
    }),
)(SettingTab)