import React, { Component } from 'react'
import { Container } from 'reactstrap'
import moment from 'moment'
import InteractionsListHeader from "./InteractionsListHeader"
import InteractionsList from "./InteractionsList"
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import './Interactions.css'

class InteractionContainer extends Component {
    constructor() {
        super()
        this.state = {
            interactionType: "",
            direction: "",
            interactionDate: null,
            filterValues: {},
        }
    }

    render() {
        return (
            <Container fluid className="DriversListContainer">
                <div className=" mb-2">
                    <InteractionsListHeader
                        filterValues={this.state.filterValues}
                        setFilterValues={(filterValues) => this.setState({ filterValues })}
                        customerId={this.props.driverId}
                        interactionsType={this.props.interactionsType}
                    />
                </div>
                <div className="cardRow container-fluid">
                    <InteractionsList
                        driverId={this.state.filterValues && this.state.filterValues.handles ? null : this.props.driverId}
                        interactionType={this.state.filterValues && this.state.filterValues.interactionType ? this.state.filterValues.interactionType : null}
                        handles={this.state.filterValues && this.state.filterValues.handles ? this.state.filterValues.handles : null}
                        direction={this.state.filterValues && this.state.filterValues.direction ? this.state.filterValues.direction : null}
                        interactionDate={this.state.filterValues && this.state.filterValues.interactionDate ? moment(this.state.filterValues.interactionDate).format(moment.HTML5_FMT.DATE) : null}
                    />
                </div>
            </Container>
        )
    }

}

export default InteractionContainer