import React, { Component } from "react"
import CollectionsListContainerNew from "./CollectionsListContainerNew"
import "./CollectionsListContainer.css"
class CollectionsViews extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <>
                <CollectionsListContainerNew curretUser currentUser={this.props.currentUser} {...this.props} />
            </>
        )
    }

}

export default CollectionsViews