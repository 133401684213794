import React, { Component } from 'react'
import '../DriverDetailNew.css'
import DocumentListContainer from "../../../../../Material/DocumentModal/DocumentListContainer"
class DocumentsTab extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="documents-tab">
                <DocumentListContainer
                    colSize={"4"}
                    id={this.props.id}
                    objectType={"driver"}
                    currentUser={this.props.currentUser}
                />
            </div>
        )
    }
}

export default (DocumentsTab)
