import React, { Component } from 'react'
import '../DriverDetailNew.css'
import RelatedProfileContainer from "../../../../../Material/RelatedProfiles/RelatedProfileContainer"
class RelatedProfileTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: "email",
        }
    }
    render() {
        return (
            <div className="driver-detail-container">
                <br /><br />
                <RelatedProfileContainer driverId={this.props.id ? this.props.id : null} currentUser={this.props.currentUser} dmvLicense={this.props.dmvLicense} basedOnTenant={true} colspan={"4"} listingType={"relatedProfiles"} />
            </div >
        )
    }
}

export default (RelatedProfileTab)
