import gql from 'graphql-tag';

export default gql `
    mutation collectionsListCharge($input:CollectionsListChargeMutationInput!){
        collectionsListCharge(input:$input){
            ok
            messages
            errors{
                field
                messages
            }
        }
    } 
`;