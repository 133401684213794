import React, { Component, Fragment } from 'react'
import { Nav, NavItem, NavLink, Row, Col, Button, Label } from 'reactstrap'
import DatetimeRenderer from "../../../../../Material/DatetimeRenderer"
import gql from 'graphql-tag'
import '../DriverDetailNew.css'
import { CarlessmilesDiscountQuery, UberTripsQuery } from "../../Queries"
import { withApollo, compose, graphql } from "react-apollo"
import moment from 'moment-timezone'

const DriverQuery = gql`query Driver($id: ID!){
    driver(id: $id){
        id
        name
        phone
        email
        dmvLicense
        tlcLicense
        canDelete
        history{
            date
            comment
            user
          }
        actionSet(first:10,orderBy:"-date_added"){
            edges{
              node{
                actionType
                status
                dateAdded
                representative
              }
            }
        }
        mobileappdriver {
            id
        }
        tags{
            id
            name
        }
        branch{
            id
            name
        }
    }
}
`

class HistoryTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            totalMiles: 0,
            addedMiles: [],
        }
    }

    toggleAddMiles = (node) => {
        if (this.state.addedMiles && this.state.addedMiles.includes(node.id)) {
            let newAddedMiles = this.state.addedMiles.filter(item => item !== node.id)
            let totalMiles = parseFloat(this.state.totalMiles) - parseFloat(node.mileageDifference)
            totalMiles = totalMiles.toFixed(2)
            this.setState({ addedMiles: newAddedMiles, totalMiles: totalMiles })
        } else {
            let newAddedMiles = this.state.addedMiles
            newAddedMiles.push(node.id)
            let totalMiles = parseFloat(this.state.totalMiles) + parseFloat(node.mileageDifference)
            totalMiles = totalMiles.toFixed(2)
            this.setState({ addedMiles: newAddedMiles, totalMiles: totalMiles })
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        let { driver } = this.props
        return (
            <div className="tab-container">
                <h4>DRIVER PROFILE HISTORY</h4>
                <div className="info-container set-max-height">
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xs={3}><Label>Date</Label></Col>
                                <Col xs={3}><Label>Comment</Label></Col>
                                <Col xs={3}><Label>Rep</Label></Col>
                            </Row>
                            {driver && driver.history && driver.history.length > 0 ? driver.history.map((history, i) =>
                                <Row key={i}>
                                    <Col xs={3}>
                                        <p className="columnContent">{history.date ? <DatetimeRenderer datetime={history.date} format={moment.HTML5_FMT.DATE} /> : ""}</p>
                                    </Col>
                                    <Col xs={3}>
                                        <p className="columnContent">{history.comment || ""}</p>
                                    </Col>
                                    <Col xs={3}>
                                        <p className="columnContent">{history.user || ""}</p>
                                    </Col>
                                </Row>
                            ) : <Row><Col><p>No profile history recorded for driver</p></Col></Row>
                            }
                        </Col>
                    </Row>
                </div>
                <br /><br />
                <h4>DISABLE - ENABLE HISTORY</h4>
                <div className="info-container set-max-height">
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xs={3}><Label>Date Added</Label></Col>
                                <Col xs={3}><Label>Type</Label></Col>
                                <Col xs={3}><Label>Status</Label></Col>
                                <Col xs={3}><Label>Rep</Label></Col>
                            </Row>
                            {driver && driver.actionSet && driver.actionSet.edges && driver.actionSet.edges.length > 0 ? driver.actionSet.edges.map((action, i) =>
                                <Row key={i}>
                                    <Col xs={3}>
                                        <p className="columnContent">{action.node.dateAdded ? <DatetimeRenderer datetime={action.node.dateAdded} format={moment.HTML5_FMT.DATE} /> : ""}</p>
                                    </Col>
                                    <Col xs={3}>
                                        <p className="columnContent">{action.node.actionType ? action.node.actionType : ""}</p>
                                    </Col>
                                    <Col xs={3}>
                                        <p className="columnContent">{action.node.status ? action.node.status : ""}</p>
                                    </Col>
                                    <Col xs={3}>
                                        <p className="columnContent">{action.node.representative ? action.node.representative : ""}</p>
                                    </Col>
                                </Row>
                            ) : <Row><Col><p>No Enable Disable History Found</p></Col></Row>
                            }
                        </Col>
                    </Row>
                </div>
                <br /><br />
                <h4>MILEAGE HISTORY</h4>
                <div className="info-container set-max-height">
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xs={6}><Label>Date</Label></Col>
                                <Col xs={6}><Label>Miles Driven</Label></Col>
                            </Row>
                            {this.state.totalMiles > 0 && <Col xs={12}>Total Miles: {this.state.totalMiles}</Col>}
                            {this.props.allCarlessmilesDiscount && this.props.allCarlessmilesDiscount.edges && this.props.allCarlessmilesDiscount.edges.length > 0 ? this.props.allCarlessmilesDiscount.edges.map((miles, i) =>
                                <Row key={i}>
                                    <Col xs={6}>
                                        <p className="columnContent">{miles.node.dateRecorded ? <DatetimeRenderer datetime={miles.node.dateRecorded} format={moment.HTML5_FMT.DATE} /> : ""}</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="columnContent">{miles.node.mileageDifference}{" "}
                                            <i onClick={() => this.toggleAddMiles(miles.node)} className={this.state.addedMiles && this.state.addedMiles.includes(miles.node.id) ? "fa fa-minus-circle" : "fa fa-plus-circle"} aria-hidden="true"></i>
                                        </p>
                                    </Col>
                                </Row>
                            ) : <Row><Col><p>No Mileage History Found</p></Col></Row>
                            }
                        </Col>
                        {this.props.allCarlessmilesDiscount && this.props.allCarlessmilesDiscount.edges && this.props.allCarlessmilesDiscount.pageInfo && this.props.allCarlessmilesDiscount.pageInfo.hasNextPage &&
                            <Button size="sm" onClick={this.props.loadMoreCarlessmilesDiscount} className={"ghost"}>Show More...</Button>
                        }
                    </Row>
                </div>
                <br /><br />
                <h4>UBER TRIPS</h4>
                <div className="info-container set-max-height">
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xs={3}><Label>Date</Label></Col>
                                <Col xs={7}><Label>Car Vin</Label></Col>
                                <Col xs={2}><Label>Trips</Label></Col>
                            </Row>
                            {this.props.allUberTrips && this.props.allUberTrips.edges && this.props.allUberTrips.edges.length > 0 ? this.props.allUberTrips.edges.map((uberTrip, i) =>
                                <Row key={i}>
                                    <Col xs={3}>
                                        <p className="columnContent">{uberTrip.node.tripWeek ? <DatetimeRenderer datetime={uberTrip.node.tripWeek} format={moment.HTML5_FMT.DATE} /> : ""}</p>
                                    </Col>
                                    <Col xs={7}>
                                        <p className="columnContent">{uberTrip.node.vin}</p>
                                    </Col>
                                    <Col xs={2}>
                                        <p className="columnContent">{uberTrip.node.tripCount}</p>
                                    </Col>
                                </Row>
                            ) : <Row><Col><p>No Uber Trip Found</p></Col></Row>
                            }
                            {this.props.allUberTrips && this.props.allUberTrips.edges && this.props.allUberTrips.pageInfo && this.props.allUberTrips.pageInfo.hasNextPage &&
                                <Button size="sm" onClick={this.props.loadMoreUberTrips} className={"ghost"}>Show More...</Button>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(DriverQuery, {
        options: ({ id }) => ({ variables: { id }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, }),
        props({ data: { driver, loading, refetch, variables } }) {
            return {
                driver, loading, variables,
                refetchDriverQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    return refetch({
                        query: DriverQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { driver: fetchMoreResult.driver }
                        },
                    })
                },
            }
        }
    }),
    graphql(CarlessmilesDiscountQuery, {
        options: ({ id }) => ({ variables: { driverId: id, first: 8 } }),
        props({ data: { allCarlessmilesDiscount, fetchMore, loading, variables } }) {
            return {
                allCarlessmilesDiscount, loading, variables,
                loadMoreCarlessmilesDiscount: () => {
                    return fetchMore({
                        query: CarlessmilesDiscountQuery,
                        variables: {
                            cursor: allCarlessmilesDiscount.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allCarlessmilesDiscount.edges
                            const pageInfo = fetchMoreResult.allCarlessmilesDiscount.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                allCarlessmilesDiscount: {
                                    edges: [...previousResult.allCarlessmilesDiscount.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allCarlessmilesDiscount.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    }),
    graphql(UberTripsQuery, {
        options: ({ id }) => ({ variables: { driverId: id, first: 10 } }),
        props({ data: { allUberTrips, fetchMore, loading, variables } }) {
            return {
                allUberTrips, loading, variables,
                loadMoreUberTrips: () => {
                    return fetchMore({
                        query: UberTripsQuery,
                        variables: {
                            cursor: allUberTrips.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allUberTrips.edges
                            const pageInfo = fetchMoreResult.allUberTrips.pageInfo
                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                allUberTrips: {
                                    edges: [...previousResult.allUberTrips.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allUberTrips.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    }),
)(HistoryTab)