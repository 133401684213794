import React, { useState } from 'react'
import { Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip } from 'reactstrap'
import { UpdateTaskMutation } from "./Mutations"

//  define async function to update task columns that can be used in other components

export const updateTask = async ({ taskId, input, client }) => {
    try {
        if (!client) {
            throw new Error("GQL Client not found")
        }
        if (!taskId) {
            throw new Error("Task ID is required")
        }
        const newInput = {
            taskId: taskId,
            ...input
        }

        const result = await client.mutate({
            mutation: UpdateTaskMutation,
            refetchQueries: ["AllTasks", "Task"],
            variables: { input: newInput },
        })
        if (!result) {
            throw new Error("Error updating task")
        }
        if (result.data && result.data.updateTask && result.data.updateTask.ok) {
            return true
        } else {
            const errorMessage = result.data && result.data.updateTask && result.data.updateTask.errors && result.data.updateTask.errors.messages ? result.data.updateTask.errors.messages[0] : "Unknown Error"
            throw new Error(errorMessage)
        }
    }
    catch (error) {
        console.log("Error updating task", error)
        throw new Error(error)
    }
}

export default function StatusDropDown({ taskId, taskStatus, client }) {
    if (!taskId || !taskStatus) return null
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")


    const updateTaskStatus = async (status) => {
        try {
            if (!status) {
                throw new Error("Status is required")
            }
            if (loading) {
                return
            }
            setLoading(true)
            const result = await updateTask({ taskId, input: { status }, client: client })
            if (result) {
                setError("")
            }
        } catch (error) {
            setError("Error updating task" + error.toString())
        }
        setLoading(false)
    }

    return <div>
        {error && <div className="text-danger">{error}</div>}
        <UncontrolledDropdown direction="right">
            <DropdownToggle
                caret
                color={taskStatus === "A_10_OPEN" ? "dark" : taskStatus === "A_20_IN_PROGRESS" ? "warning" : "success"}
            >
                {taskStatus === "A_10_OPEN" ? "OPEN" : taskStatus === "A_20_IN_PROGRESS" ? "IN-PROGRESS" : "DONE"}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={(e) => updateTaskStatus("10_OPEN")} style={{ backgroundColor: "#1D2124", color: "#FFFFFF" }}>
                    OPEN
                </DropdownItem>
                {/* Add divider */}
                <DropdownItem divider />
                <DropdownItem onClick={(e) => updateTaskStatus("20_IN_PROGRESS")} style={{ backgroundColor: "#FFC107", color: "#000000" }}>
                    IN-PROGRESS
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={(e) => updateTaskStatus("30_DONE")} style={{ backgroundColor: "#28A745", color: "#FFFFFF" }}>
                    DONE
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    </div>
}

export function TooltipComponent({ tooltipOpen, toggle, target, message, ...args }) {
    return (
        <div>
            <Tooltip
                {...args}
                isOpen={tooltipOpen}
                target={target}
                toggle={toggle}
            >
                {message}
            </Tooltip>
        </div>
    )
}