import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import '../BranchMangement/BranchListContainer.css'
import { Table } from 'reactstrap'
import { AllGroups } from "../Queries"
import "../../Material/ListingHeader.css"
import PrimaryListingContainer from "../../Material/PrimaryListingContainer"
import { Link } from 'react-router-dom'


const tableHeaders = [
    { id: "pk", name: "ID", sortable: false },
    { id: "name", name: "Name", sortable: false },
]

class GroupsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            loading: false
        }
    }

    render() {
        return (
            <>
                <PrimaryListingContainer listLoading={this.props.loading} totalFetchedRows={this.props.allGroups && this.props.allGroups.edges.length} pageInfo={this.props.allGroups && this.props.allGroups.pageInfo} loadMoreEntries={this.props.allGroups && this.props.allGroups.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchQuery}>
                    <Table responsive>
                        <thead>
                            <tr>
                                {tableHeaders.map(tableHeader => {
                                    return (
                                        tableHeader.sortable ?
                                            <th key={tableHeader.id} style={{ cursor: "pointer" }}>{tableHeader.name}</th> :
                                            <th key={tableHeader.id}>{tableHeader.name}</th>
                                    )
                                })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.allGroups && this.props.allGroups.edges && this.props.allGroups.edges.length > 0 ? this.props.allGroups.edges.map((group, i) =>
                                <tr key={i + 1}>
                                    <td>&nbsp;{i + 1}</td>
                                    <td><Link color="secondary" to={'/organization/group/' + group.node.id}>{group.node.name}</Link></td>
                                </tr>
                            ) :
                                <tr>
                                    {!this.props.loading ?
                                        <td colSpan={7}>No Group Found</td> :
                                        <td colSpan={7}>Loading ...</td>
                                    }
                                </tr>
                            }
                        </tbody>
                    </Table>
                </PrimaryListingContainer>
            </>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllGroups, {
        options: ({ searchTerm }) => ({
            fetchPolicy: 'cache-and-network',
            variables: {
                name_Icontains: searchTerm,
                first: 30
            }
        }),
        props({ data: { loading, allGroups, fetchMore, variables } }) {
            return {
                loading,
                allGroups,
                variables,
                refetchQuery: () => {
                    let currentLength = allGroups && allGroups.edges && allGroups.edges.length > 30 ? allGroups.edges.length : 30
                    return fetchMore({
                        query: AllGroups,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allGroups.edges
                            const pageInfo = fetchMoreResult.allGroups.pageInfo
                            return {
                                allGroups: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    __typename: previousResult.allGroups.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: AllGroups,
                        variables: {
                            cursor: allGroups.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allGroups.edges
                            const pageInfo = fetchMoreResult.allGroups.pageInfo

                            return {
                                allGroups: {
                                    edges: [...previousResult.allGroups.edges, ...newEdges],
                                    pageInfo,
                                    __typename: previousResult.allGroups.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    }),
)(GroupsList)
