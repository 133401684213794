import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import { compose, withApollo } from 'react-apollo'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress, Alert, Row } from 'reactstrap'
import { RemoveCar } from "./Mutations"
class DeleteCarModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            loading: false
        }
    }

    handleClose = (e) => {
        this.props.handleClose()
    };
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    };
    handleDeleteCar = (e) => {
        try {
            if (this.props.car && this.props.car.id) {
                this.setState({ loading: true })
                this.props.client.mutate({
                    mutation: RemoveCar,
                    variables: { input: { carId: this.props.car ? this.props.car.id : "" } },
                }).then((result) => {
                    if (result && result.data && result.data.removeCar && result.data.removeCar.ok) {
                        this.setState({ error: "" })
                        this.props.handleClose()
                        let href = "/cars/fleet_management"
                        window.open(href, "_self")
                    } else {
                        let error = "An error occurred, could not complete request."
                        if (result.data && result.data.removeCar && result.data.removeCar.errors && result.data.removeCar.errors[0] && result.data.removeCar.errors[0].messages)
                            error = result.data.removeCar.errors[0].messages.toString()
                        this.setState({ error: error })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    let error = "An error has occured: " + err
                    this.setState({ error: error, loading: false })
                })
            }
            else this.setState({ error: "Missing Required Field" })
        }
        catch (err) {
            let error = "An error has occured: " + err
            this.setState({ error: error, loading: false })
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.open} className="CarIssueModal">
                <ModalHeader toggle={this.handleClose}>Delete Car</ModalHeader>
                <ModalBody>
                    {this.state.loading && <Progress animated color="info" value="100" />}
                    {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                    Are you sure you want to delete this car?
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.handleClose}>Close</Button>
                    <Button color="danger" onClick={this.handleDeleteCar}>Confirm</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default compose(
    withApollo)(DeleteCarModal)