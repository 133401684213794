import gql from "graphql-tag"

export const AllPricingTemplates = gql`
  query AllPricingTemplates($isActive: Boolean, $modelGroupTypeId: ID, $modelTypeIds: [ID], $agreementTypeId: ID, $first: Int, $cursor: String, $orderBy: [String], $searchTerm: String, $isDefault: Boolean) {
    allPricingTemplates(isActive: $isActive, modelGroupTypeId: $modelGroupTypeId, modelTypeIds: $modelTypeIds, agreementTypeId: $agreementTypeId, first: $first, after: $cursor, orderBy: $orderBy, searchTerm: $searchTerm, isDefault: $isDefault) {
      edges {
        cursor
        node {
          id
          pk
          visibleToCustomers
          carModel {
            id
            name
            make
            series
            groupType{
              id
              name
            }
          }
          price
          minimumPrice
          maximumPrice
          carYearMax
          carYearMin
          intervalUnit
          interval
          isActive
          currency { code symbol }
          isDefault
          carColor
          agreementType {
            id
            name
          }
          title
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        length
      }
    }
  }
`

export const AllAgreementTypes = gql`
  {
    allAgreementTypes(isActive: true) {
      edges {
        node {
          id
          name
          isActive
        }
      }
    }
  }
`

export const AllCarModels = gql`
  {
    allCarModels(orderBy: ["-make"]){
      edges {
        node {
          id
          name
          make
          series
          groupType{
            id
            name
          }
        }
      }
    }
  }
`

export const AllCarModelGroupTypes = gql`
  {
    allCarModelGroupTypes{
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const AllPromotionsTemplates = gql`
  query AllPromotionsTemplates($isActive: Boolean, $modelGroupTypeId: ID, $modelTypeIds: [ID], $agreementTypeId: ID, $first: Int, $cursor: String, $orderBy: [String], $searchTerm: String) {
    allPromotionsTemplates(isActive: $isActive, modelGroupTypeId: $modelGroupTypeId, modelTypeIds: $modelTypeIds, agreementTypeId: $agreementTypeId, first: $first, after: $cursor, orderBy: $orderBy, searchTerm: $searchTerm ) {
      edges {
        cursor
        node {
          id
          pk
          visibleToCustomers
          isPercentage
          carModel {
            id
            name
            make
            series
            groupType{
              id
              name
            }
          }
          interval
          intervalUnit
          promoPrice
          minimumPromoPrice
          maximumPromoPrice
          carYearMax
          carYearMin
          cycle
          minCycle
          maxCycle
          isActive
          currency { code symbol }
          startDate
          expirationDate
          agreementType {
            id
            name
          }
          title
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
