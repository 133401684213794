import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Container, Row, Col } from 'reactstrap'
import JobsList from './JobsList'
import moment from 'moment'
import '../Listing.css'
import MaintenanceListHeader from "../MaintenanceListHeader"
import RestrictedSection from "../../Components/RestrictedSection"



class JobContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: "Jobs",
            filterValues: {
            },
        }
    }

    updateOrder = (string) => {
        let order = this.state.orderBy ? [...this.state.orderBy] : []
        let index = order.indexOf(string)
        if (index > -1) {
            order[index] = "-" + string
        } else {
            index = order.indexOf("-" + string)
            index > -1 ? order.splice(index, 1) : order.unshift(string)
        }
        this.setState({ orderBy: order })
    };

    render() {
        return (
            <RestrictedSection requiredPermission="view_job">
                <Container fluid className="bos-listing-container">
                    <MaintenanceListHeader
                        activeTab={this.state.activeTab}
                        filterValues={this.state.filterValues}
                        defaultFilters={["-id"]}
                        setFilterValues={(filterValues) => this.setState({ filterValues })}
                    />
                    <Row>
                        <Col sm="12">
                            <JobsList
                                currentUser={this.props.currentUser}
                                vendorIn={this.state.filterValues && this.state.filterValues.selectedVendors && this.state.filterValues.selectedVendors.length > 0 ? this.state.filterValues.selectedVendors : null}
                                statusIn={this.state.filterValues && this.state.filterValues.selectedStatuses && this.state.filterValues.selectedStatuses.length > 0 ? this.state.filterValues.selectedStatuses : null}
                                jobTypeIn={this.state.filterValues && this.state.filterValues.jobTypes && this.state.filterValues.jobTypes.length > 0 ? this.state.filterValues.jobTypes : null}
                                stageIn={this.state.filterValues && this.state.filterValues.selectedStages && this.state.filterValues.selectedStages.length > 0 ? this.state.filterValues.selectedStages : null}
                                searchTerm={this.state.filterValues && this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : ""}
                                startDate={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].startDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                                endDate={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].endDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                                orderBy={this.state.orderBy}
                                updateOrder={this.updateOrder}
                            />
                        </Col>
                    </Row>
                </Container>
            </RestrictedSection>
        )
    }
}

export default compose(
    withApollo,
)(JobContainer)
