import React from 'react'
import moment from 'moment-timezone'
import { UncontrolledTooltip } from 'reactstrap'
import { compose, withApollo } from 'react-apollo'
import { PreferredDateTimeContext } from '../Context/PreferredDateTimeContext'
import './DatetimeRenderer.css'

class DatetimeRenderer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            systemTimezone: moment.tz.guess(),
            popoverId: `popover-${Math.random().toString(36).substr(2, 9)}`,
            placement: this.props.placement ? this.props.placement : "bottom"
        }
    }

    isDateString(datetime) {
        // Regular expression to match the ISO 8601 date format
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/
        if (dateRegex.test(datetime))
            return true

        return false
    }

    render() {
        return (

            <PreferredDateTimeContext.Consumer>
                {({ preferredDateTimeInfo }) => (

                    <span className="datetime-renderer">
                        <span id={this.state.popoverId}>
                            {this.isDateString(this.props.datetime) ?
                                moment(this.props.datetime).format(`${preferredDateTimeInfo.dateFormat}`) :
                                moment(this.props.datetime).tz(preferredDateTimeInfo.timezone).format(`${preferredDateTimeInfo.dateFormat} ${preferredDateTimeInfo.timeFormat}`)
                            }
                        </span>
                        {!this.props.hideTooltip && !this.isDateString(this.props.datetime) && <UncontrolledTooltip id="datetime-conversion-tooltip" target={this.state.popoverId} placement={this.state.placement}>
                            <table>
                                <thead>
                                    <tr>
                                        <th colSpan="3">
                                            Time conversion
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{moment.tz(preferredDateTimeInfo.timezone).format('z')} &#x2022; Your account</td>
                                        <td>{moment(this.props.datetime).tz(preferredDateTimeInfo.timezone).format("LT")}</td>
                                        <td>{moment(this.props.datetime).tz(preferredDateTimeInfo.timezone).format("ll")}</td>
                                    </tr>
                                    <tr>
                                        <td>UTC</td>
                                        <td>{moment(this.props.datetime).utc().format("LT")}</td>
                                        <td>{moment(this.props.datetime).utc().format("ll")}</td>
                                    </tr>
                                    {this.state.systemTimezone !== preferredDateTimeInfo.timezone && <tr>
                                        <td> {moment.tz(this.state.systemTimezone).format('z')} &#x2022; Your computer</td>
                                        <td>{moment(this.props.datetime).tz(this.state.systemTimezone).format("LT")}</td>
                                        <td>{moment(this.props.datetime).tz(this.state.systemTimezone).format("ll")}</td>
                                    </tr>}
                                </tbody>
                            </table>
                        </UncontrolledTooltip>}
                    </span>
                )}
            </PreferredDateTimeContext.Consumer>
        )
    }
}

export default compose(
    withApollo,
)(DatetimeRenderer)
