import gql from "graphql-tag"

const UpdateDriver = gql`
mutation updateDriverMutation($input: UpdateDriverMutationInput!){
updateDriver(input:$input){
    ok
}
} `


const UpdateCustomerLead = gql`
mutation UpdateCustomerLead($input: UpdateLeadMutationInput!){
    updateCustomerLead(input:$input){
    ok
    errors{
        field
        messages
    }
}
} `

const assignDriverToRepMutation = gql`
mutation CreateNewAssignment ($repID:ID!, $drivers:ID!){
    createAssignment(input:{repId:$repID, drivers:[$drivers]}){
        assignment{
            id
        }
    }
}`

export { UpdateDriver, assignDriverToRepMutation, UpdateCustomerLead }