import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import '../CarDetail.css'
import { Row, Col, Label, Alert, Button, UncontrolledTooltip, Badge } from 'reactstrap'
import gql from 'graphql-tag'
import Loader from "../../../Material/Loader"
import DatetimeRenderer from "../../../Material/DatetimeRenderer"
import CarNotes from "../../../Material/CarNotes"
import UpdateCarIssue from "../../../CarIssues/UpdateCarIssue"
import CarIssueModal from "../../../Maintenance/CarIssueModal"
import DocumentModalNew from "../../../Material/DocumentModal/DocumentModalNew"
import { cleanDocumentUrl } from "../../../Material/DocumentModal/DocumentsListNew"
import { Link } from "react-router-dom"

const CarIssuesSeverityLevelQuery = gql`{
    issueSeverityLevels: __type(name: "CarIssuesSeverity") {
      states: enumValues {
        name
        description
      }
    }
  }
  `


const CarIssuesQuery = gql`query CarIssuesQuery($carId:ID, $cursor: String, $searchTerm: String, $stageIn:[Int], $vendorIn:[ID], $assignedToIn:[ID], $categoryIn:[ID], $statusIn:[String], $first:Int, $startDate:Date, $endDate:Date , $locationIn:[ID] ) {
    carIssues: allCarIssues(carId:$carId, first:$first, after: $cursor, searchTerm:$searchTerm, stageIn:$stageIn, vendorIn:$vendorIn, assignedToIn:$assignedToIn, categoryIn:$categoryIn, statusIn:$statusIn, startDate:$startDate, endDate:$endDate, locationIn:$locationIn, orderBy:["-id"]){
      edges{
        cursor
        node{
            id
            pk
            dateAdded
            dateModified
            issueDesc
            canPickup
            dateClosed
            notes
            metadata
            status
            projectedCost
            severity
            incidentdamage{
                id
                incident{
                    id
                    carCondition{
                        id               
                    }
                }
            }
            pictures{
                edges{
                    node{
                        id
                        dateAdded
                        pictureUrl
                        pictureType
                        description
                    }
                }
            }
            vendor{
                id
                name
            }
            user{
                id
                username
            }
            issueCategory{
                id
                name:category
            }
            car{
                id
                pk
                notes
                location
                assignedTo
            }
        }
      }
      pageInfo{
        endCursor
        hasNextPage
      }
    } 
}`

const UpdateCarIssueMutation = gql`mutation UpdateCarIssueMutation($input: UpdateCarIssueMutationInput!){
    updateCarIssue(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const CAR_ISSUE_TABLE = [
    { id: "date_added", name: "Date Added" },
    { id: "category", name: "Category" },
    { id: "details", name: "Details" },
    { id: "status", name: "Status" },
    { id: "projectedCost", name: "Projected Cost" },
    { id: "severity", name: "Severity" },
    { id: "notes", name: "Notes" },
    { id: "action", name: "Action" },
]
class CarIssueTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: "",
            openModal: "",
        }
    }
    viewPictures = (carPictures, index = 0) => {
        let pictures = carPictures.map(picture => ({ documentUrl: cleanDocumentUrl(picture.node.pictureUrl), id: picture.node.id, name: picture.node.pictureType, description: picture.node.description, dateAdded: picture.node.dateAdded }))
        this.setState({ carPictures: pictures, selectedImage: pictures[index] })
    }
    next = () => {
        const carPictures = this.state.carPictures
        const currentIndex = carPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === carPictures.length - 1) return
        this.setState({ selectedImage: carPictures[currentIndex + 1] })
    }
    prev = () => {
        const carPictures = this.state.carPictures
        const currentIndex = carPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === 0) return
        this.setState({ selectedImage: carPictures[currentIndex - 1] })
    }
    toggleSwitch = (id, toggleValue, updateIssue = false) => {
        if (updateIssue === true) { this.updateCarIssue(id, toggleValue) }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    refetchQuery = () => {
        this.props.refetchCarIssuesQuery()
    }
    updateCarIssue(issueId, canPickup) {
        if (issueId && canPickup != null) {
            this.setState({ loading: true })
            let input = {}
            input["issueId"] = issueId
            input["canPickup"] = canPickup
            this.props.client.mutate({
                mutation: UpdateCarIssueMutation,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updateCarIssue && result.data.updateCarIssue.ok) {
                    this.setState({ loading: false, openModal: "" })
                    this.refetchQuery()
                } else {
                    this.setState({ loading: false, error: "An error has occured. Please try again or contact admin." })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occured. Please try again or contact admin.", err })
            })
        }
    }
    render() {
        return (

            <div className="info-container" style={{ maxHeight: "750px", overflow: "auto" }}>
                {this.state.error && <Row><Col><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                <Row>
                    <Col className="d-flex justify-content-end">
                        <Button size="sm" id="createCarIssue" onClick={() => this.toggleModal("createCarIssue")}><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;&nbsp;CREATE ISSUE</Button>
                        {this.state.openModal && this.state.openModal === "createCarIssue" && <CarIssueModal isCreate carPk={this.props.match && this.props.match.params ? this.props.match.params.pk : null} open={this.state.openModal === "createCarIssue"} handleClose={() => this.toggleModal("createCarIssue")} refetchQuery={this.refetchQuery} />}
                    </Col>
                </Row>
                <Row>
                    {CAR_ISSUE_TABLE.map(header => <Col><Label id={header.id}>{header.name}</Label></Col>)}
                </Row>
                <hr />
                {this.props.loading && <Loader />}
                {this.props.carIssues && this.props.carIssues.edges && this.props.carIssues.edges.length > 0 ?
                    this.props.carIssues.edges.map((carIssue, i) =>
                        <>
                            <Row key={carIssue.node.id} id={carIssue.node.id}>
                                <Col xs>
                                    <p><DatetimeRenderer datetime={carIssue.node.dateAdded} /></p>
                                </Col>
                                <Col>
                                    <p>{carIssue.node.issueCategory ? carIssue.node.issueCategory.name : "---"}</p>
                                </Col>
                                <Col>
                                    <div className="bos-table-td-right-box">
                                        <UncontrolledTooltip placement="bottom" target={"canPickup" + i}>{carIssue.node.canPickup ? "Can Pickup" : "Can't Pickup"}</UncontrolledTooltip>
                                        <div><i id={"canPickup" + i} className={"fa fa-circle " + (carIssue.node.canPickup ? "text-success" : "text-danger")} aria-hidden="true"></i> <b>{carIssue.node.issueCategory ? carIssue.node.issueCategory.name : "--"} | <span className='color-blue-neon'><i className="fa fa-location-arrow" aria-hidden="true" />&nbsp;&nbsp;{carIssue.node.vendor ? carIssue.node.vendor.name : "--"}</span></b></div>
                                        <small className='limit-text-long' style={{ overflow: "hidden", whiteSpace: (this.state.showDesc && this.state.showDesc === carIssue.node.id ? "pre-wrap" : "nowrap") }}><i className={this.state.showDesc && this.state.showDesc === carIssue.node.id ? "fa fa-chevron-up" : "fa fa-chevron-down"} onClick={() => this.setState({ showDesc: (!this.state.showDesc || this.state.showDesc !== carIssue.node.id ? carIssue.node.id : null) })}></i> {carIssue.node.issueDesc ? carIssue.node.issueDesc : "--"}</small>
                                    </div>
                                </Col>
                                <Col>
                                    <Badge style={{ backgroundColor: carIssue.node.status === "OPEN" ? "red" : carIssue.node.status === "CLOSED" ? "green" : carIssue.node.status === "FIXING" ? "orange" : "#DB2D43" }}>{carIssue.node.status ? carIssue.node.status : "---"}</Badge>
                                </Col>
                                <Col>
                                    <p>{carIssue.node.projectedCost ? `$${carIssue.node.projectedCost}` : "---"}</p>
                                </Col>
                                <Col>
                                    <p>{this.props.issueSeverityLevels && this.props.issueSeverityLevels.states && this.props.issueSeverityLevels.states.find(item => item.name === carIssue.node.severity).description}</p>
                                </Col>
                                <Col>
                                    <div className="notes">
                                        {this.state.openModal === "notesHistory" + i &&
                                            <CarNotes car={carIssue.node.car} target={"issueNotesEdit" + i} noteType="Car Issue" title="Issue Notes History" objectId={carIssue.node.id} open={this.state.openModal === "notesHistory" + i} handleClose={() => this.toggleModal("")} />
                                        }
                                        {this.state.openModal === "issueNotesEdit" + i &&
                                            <UpdateCarIssue carIssue={carIssue.node} title="Edit Issue Notes" name="notes" value={carIssue.node.notes} target={'issueNotesEdit' + i} id="notes" type="text" open={this.state.openModal === 'issueNotesEdit' + i} handleClose={() => this.toggleModal('issueNotesEdit' + i)} refetchQuery={this.refetchQuery} />
                                        }
                                        <span id={"issueNotesEdit" + i}>
                                            <i onClick={() => this.toggleModal("notesHistory" + i)} className={"fa fa-history"}></i>&nbsp;&nbsp;
                                            {this.props.currentUser && this.props.currentUser.groupNames && Array.isArray(this.props.currentUser.groupNames) && this.props.currentUser.groupNames.includes("Fleet") &&
                                                <i onClick={() => this.toggleModal("issueNotesEdit" + i)} className={"fa fa-pencil-square-o"}></i>}&nbsp;&nbsp;
                                            <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '150px' }}>
                                                {carIssue.node.notes ? carIssue.node.notes : "--"}
                                            </p>
                                            <UncontrolledTooltip placement="top" target={"issueNotesEdit" + i}>{carIssue.node.notes ? carIssue.node.notes : "--"}</UncontrolledTooltip>
                                        </span>
                                    </div>
                                </Col>
                                <Col>
                                    {this.state.openModal === "editCarIssue" + i && <CarIssueModal open={this.state.openModal === "editCarIssue" + i} isUpdate carIssue={carIssue.node} handleClose={() => this.toggleModal("editCarIssue" + i)} refetchQuery={this.refetchQuery} />}
                                    {carIssue.node.incidentdamage && carIssue.node.incidentdamage.incident ?
                                        <Link target="_blank" to={`/car/${carIssue.node.car.pk}?inspectionId=${carIssue.node.incidentdamage.incident.carCondition.id}&incidentId=${carIssue.node.incidentdamage.incident.id}#inspections`}>
                                            <i className="fa fa-eye" aria-hidden="true"></i>
                                        </Link>
                                        : <i id={"editCarIssue" + i} className="fa fa-pencil-square-o" aria-hidden="true" onClick={() => this.toggleModal("editCarIssue" + i)}></i>
                                    }
                                    {this.state.selectedImage &&
                                        <DocumentModalNew
                                            document={this.state.selectedImage}
                                            handleClose={() => this.setState({ selectedImage: null })}
                                            next={this.next}
                                            prev={this.prev}
                                            objectType={"car"}
                                            isPicture={true}
                                            refetch={this.props.refetch}
                                            currentUser={this.props.currentUser}
                                        />
                                    }
                                    {carIssue.node.pictures && carIssue.node.pictures.edges ?
                                        <span onClick={() => this.viewPictures(carIssue.node.pictures.edges)}>
                                            &nbsp;&nbsp;
                                            <i className="fa fa-file-image-o" aria-hidden="true"></i>
                                            {carIssue.node.pictures.edges.length > 1 &&
                                                <a className="bos-custom-link" target="_blank">
                                                    {" "}+{carIssue.node.pictures.edges.length - 1} more
                                                </a>}
                                        </span> :
                                        "No Pictures Found"}
                                </Col>
                            </Row>
                            <hr />
                        </>
                    ) :
                    !this.props.loading && <Row>
                        <Col>No Issue Found</Col>
                    </Row>
                }
                {this.props.carIssues && this.props.carIssues.pageInfo && this.props.carIssues.pageInfo.hasNextPage &&
                    <Row>
                        <Col className="d-flex justify-content-end">
                            <Button onClick={this.props.loadMoreEntries} >Show More <i className={"fa fa-caret-down"}></i></Button>
                        </Col>
                    </Row>
                }
            </div>
        )
    }
}

export default
    compose(
        withApollo,
        graphql(CarIssuesSeverityLevelQuery, {
            props({ data: { issueSeverityLevels, loading } }) {
                return {
                    issueSeverityLevels,
                    loading
                }
            }
        }),
        graphql(CarIssuesQuery,
            {
                options: ({ id, searchTerm, orderBy, stageIn, categoryIn, statusIn, vendorIn, assignedToIn, startDate, endDate, locationIn }) => ({
                    variables: { carId: id, first: 10, searchTerm, orderBy, stageIn, categoryIn, statusIn, vendorIn, assignedToIn, startDate, endDate, locationIn },
                    notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only',
                }),
                props({ data: { loading, carIssues, fetchMore, variables } }) {
                    return {
                        loading,
                        carIssues,
                        variables,
                        refetchCarIssuesQuery: () => {
                            //No apollo function that refetches in place with pagination considered so this function instead
                            let currentLength = carIssues && carIssues.edges ? carIssues.edges.length : 10
                            return fetchMore({
                                query: CarIssuesQuery,
                                variables: {
                                    ...variables,
                                    first: currentLength,
                                },
                                updateQuery: (previousResult, { fetchMoreResult }) => {
                                    const newEdges = fetchMoreResult.carIssues.edges
                                    const pageInfo = fetchMoreResult.carIssues.pageInfo
                                    return {
                                        carIssues: {
                                            edges: [...newEdges],
                                            pageInfo,
                                            __typename: previousResult.carIssues.__typename
                                        },
                                    }
                                },
                            })
                        },
                        loadMoreEntries: () => {
                            return fetchMore({
                                query: CarIssuesQuery,
                                variables: {
                                    cursor: carIssues.pageInfo.endCursor,
                                    ...variables
                                },
                                updateQuery: (previousResult, { fetchMoreResult }) => {
                                    const newEdges = fetchMoreResult.carIssues.edges
                                    const pageInfo = fetchMoreResult.carIssues.pageInfo
                                    return {
                                        // Put the new comments at the end of the list and update `pageInfo`
                                        // so we have the new `endCursor` and `hasNextPage` values
                                        carIssues: {
                                            edges: [...previousResult.carIssues.edges, ...newEdges],
                                            pageInfo,
                                            __typename: previousResult.carIssues.__typename
                                        },
                                    }
                                },
                            })
                        },
                    }
                },
            })
    )(CarIssueTab)
