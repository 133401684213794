import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, graphql, withApollo } from 'react-apollo'
import './BranchListContainer.css'
import { Col, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import Row from 'reactstrap/lib/Row'
import Container from "reactstrap/lib/Container"
import Loader from "../../Material/Loader"
import OverviewTab from "./BranchDetailTabs/OverviewTab"
import './ObjectDetail.css'
import SettingTab from "./BranchDetailTabs/SettingTab"
import MemberTab from "./BranchDetailTabs/MemberTab"
import HistoryTab from "./BranchDetailTabs/HistoryTab"
import FinancialAccountsTab from "./BranchDetailTabs/FinancialAccountsTab"
import RestrictedSection from "../../Components/RestrictedSection"

const BranchQuery = gql`query Branch($id: ID!){
    branch(id: $id){
        id
        name
        customId
        isActive
        city
        stateCode
        country
        postalCode
    }
}`
const BRANCH_DETAIL_TABS = [
    { id: "#overview", name: "OVERVIEW" },
    { id: "#settings", name: "SETTINGS" },
    { id: "#members", name: "MEMBERS" },
    { id: "#history", name: "HISTORY" },
    { id: "#financialAccounts", name: "FINANCIAL ACCOUNTS" }
]

class BranchDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: "overview",
            rendererdTabPanes: []
        }
    }

    toggleTab = (tabId) => {
        if (!this.state.rendererdTabPanes.includes(tabId)) {
            this.setState({ rendererdTabPanes: [...this.state.rendererdTabPanes, tabId] })
        }
        this.setState({ activeTab: tabId })
    }
    componentDidMount() {
        let defaultTab = BRANCH_DETAIL_TABS[0].id
        if (window.location.hash && BRANCH_DETAIL_TABS.map(item => item.id).includes(window.location.hash)) {
            this.setState({ activeTab: window.location.hash, rendererdTabPanes: [window.location.hash] })
        } else {
            window.location.hash = defaultTab
            this.setState({ activeTab: defaultTab, rendererdTabPanes: [defaultTab] })
        }
    }
    componentDidUpdate(_, prevState) {
        if (this.state.activeTab !== prevState.activeTab) {
            window.location.hash = this.state.activeTab
        }
    }

    render() {
        return (<Container fluid className="bos-object-detail-container">
            {this.props.loading ? <Loader /> : this.props.branch && <>
                <Row className="bos-object-detail-header">
                    <Col xs="1">
                        <span className="box-object-icon">
                            <i className="fa fa-2x fa-code-fork" aria-hidden="true"></i>
                        </span>
                    </Col>
                    <Col xs={5} className="bos-object-title-wrapper">
                        <Label>
                            {this.props.branch.name}
                        </Label>
                        &nbsp;&nbsp;&nbsp;{this.props.branch.isActive ? <i className="fa fa-check-circle fa-2x text-success" aria-hidden="true" /> : <i className="fa fa-times-circle fa-2x text-danger" aria-hidden="true" />}
                    </Col>
                    <Col xs={3} className="bos-object-property-wrapper">
                        <Label>
                            {this.props.branch.customId}
                        </Label>
                    </Col>
                    <Col xs={3} className="bos-object-property-wrapper text-right">
                        <Label>
                            {this.props.branch.city}, {this.props.branch.stateCode}, {this.props.branch.country} {this.props.branch.postalCode}
                        </Label>
                    </Col>
                </Row>
                <Row className="bos-object-detail-body">
                    <Nav tabs>
                        {BRANCH_DETAIL_TABS.map(item =>
                            <NavItem key={item.id} className={this.state.activeTab === item.id ? "active" : "inactive"}>
                                <NavLink className={this.state.activeTab === item.id ? "active" : "inactive"} onClick={() => this.toggleTab(item.id)}>
                                    {item.name}
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                    <div className="bos-object-nav-content">
                        <TabContent activeTab={this.state.activeTab}>
                            {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#overview") && <TabPane tabId="#overview">
                                <RestrictedSection requiredPermission="view_branch">
                                    <OverviewTab branchId={this.props.branch.id} />
                                </RestrictedSection>
                            </TabPane>}
                            {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#settings") && <TabPane tabId="#settings">
                                <SettingTab branchId={this.props.branch.id} />
                            </TabPane>}
                            {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#members") && <TabPane tabId="#members">
                                <RestrictedSection requiredPermission="view_member">
                                    <MemberTab branchId={this.props.branch.id} />
                                </RestrictedSection>
                            </TabPane>}
                            {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#history") && <TabPane tabId="#history">
                                <HistoryTab branchId={this.props.branch.id} />
                            </TabPane>}
                            {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#financialAccounts") && <TabPane tabId="#financialAccounts">
                                <RestrictedSection requiredPermission="view_financialaccount">
                                    <FinancialAccountsTab branchId={this.props.branch.id} />
                                </RestrictedSection>
                            </TabPane>}
                        </TabContent>
                    </div>
                </Row>
            </>
            }
        </Container>)
    }
}

export default compose(
    withApollo,
    graphql(BranchQuery, {
        options: ({ match }) => ({ variables: { id: match.params.id }, fetchPolicy: 'network-only' }),
        props({ data: { branch, loading, refetch, variables } }) {
            return {
                branch, loading, variables,
                refetchBranchQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    return refetch({
                        query: BranchQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { branch: fetchMoreResult.branch }
                        },
                    })
                },
            }
        }
    })
)(BranchDetail)
