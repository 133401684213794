import gql from "graphql-tag";

export const CreatePricingTemplateMutation = gql`
  mutation createPricingTemplate($input: CreatePricingTemplateMutationInput!) {
    createPricingTemplate(input: $input) {
      ok
      errors {
        messages
      }
    }
  }
`;

export const UpdatePricingTemplateMutation = gql`
  mutation updatePricingTemplate($input: UpdatePricingTemplateMutationInput!) {
    updatePricingTemplate(input: $input) {
      ok
      errors {
        messages
      }
    }
  }
`;

export const CreatePromotionTemplateMutation = gql`
  mutation createPromotionTemplate($input: CreatePromotionTemplateMutationInput!) {
    createPromotionTemplate(input: $input) {
      ok
      errors {
        messages
      }
    }
  }
`;

export const UpdatePromotionTemplateMutation = gql`
  mutation updatePromotionTemplate($input: UpdatePromotionTemplateMutationInput!) {
    updatePromotionTemplate(input: $input) {
      ok
      errors {
        messages
      }
    }
  }
`;
