/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { Component } from 'react'
import { compose } from 'react-apollo'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { withApollo } from 'react-apollo/index'

class InfoModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return <Modal isOpen={this.props.open}>
            <ModalHeader> {this.props.title} </ModalHeader>
            <ModalBody>
                {this.props.message}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.props.handleClose}>Close</Button>
            </ModalFooter>
        </Modal>
    }
}

export default compose(
    withApollo,
)(InfoModal)