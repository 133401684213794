import React, { Component, Fragment } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles'
import Modal from "@material-ui/core/Modal"
import Grid from "@material-ui/core/Grid"
import { Alert, Button } from "reactstrap"
import gql from 'graphql-tag'
import TextField from "@material-ui/core/TextField/TextField"
import Select from "@material-ui/core/Select/Select"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import FormControl from "@material-ui/core/FormControl/FormControl"
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'

const DriverDetailQuery = gql`query Driver($id: ID!){
    driver(id: $id){
        id
        deposit
        balance
        address
        name
    }
}`
const left = 50
const styles = theme => ({
    modal: {
        marginTop: `20px`,
        left: `${left}%`,
        transform: `translate(-${left}%)`,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    title: {
        borderBottom: `1px solid ${theme.palette.grey['200']}`,
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: '600',
        fontSize: '14px',
    },
    tagButton: {
        display: `inline-block`,
        padding: `${theme.spacing.unit / 4}px ${theme.spacing.unit}px`,
        textTransform: 'uppercase',
        color: '#fff',
        textAlign: 'center',
        fontWeight: '400',
        fontSize: '11px',
        borderRadius: '5px',
    },
    inactive: {
        backgroundColor: '#666666',
        color: '#fff'
    },
    success: {
        backgroundColor: '#28a745',
        color: '#fff'
    },
    danger: {
        backgroundColor: '#EB5160',
        color: '#fff'
    }
})
const createRefundMutation = gql`
mutation createRefund($input: CreateRefundMutationInput!){
    createRefund(input:$input){
        ok
        errors{
            field
            messages
        }
    }
}`
const updateDriver = gql`
mutation updateDriverMutation($input: UpdateDriverMutationInput!){
    updateDriver(input:$input){
        ok
        errors{
            field
            messages
        }
    }
}
`

class NewRefundModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showAddressInfo: false,
            refundMethod: "---",
            loading: false,
            showRefundForm: false,
            deposit: 0,
            balance: 0,
            editAddress: false,
            newAddress: null,
            error_message: null
        }
    }

    handleRefundMethodChange = (e, name) => {
        if (e.target.value == "Mail Check") {
            this.setState({ showAddressInfo: true, refundMethod: e.target.value })
        } else {
            this.setState({ showAddressInfo: false, refundMethod: e.target.value })
        }
    };
    toggleEditAddress = () => {
        this.setState((prevState) => ({ editAddress: !prevState.editAddress }))
    }
    handleTextInputChange = name => event => {
        this.setState({
            [name]: event.target.value
        })
    };
    updateDriver = () => {
        let newStreetAddress = this.state.newStreetAddress
        let newState = this.state.newState
        let newZipCode = this.state.newZipCode
        let newCity = this.state.newCity
        let input = {
            id: this.props.driver.id,
            streetAddress: newStreetAddress,
            city: newCity,
            state: newState,
            zipCode: newZipCode
        }
        this.props.client.mutate({
            mutation: updateDriver,
            variables: { input }
        }).then((result) => {
            if (result.data && result.data.updateDriver && result.data.updateDriver.ok) {
                this.props.refetchDriverQuery()
                this.setState({ editAddress: false, newAddress: this.props.driver.address })
            }
        })
    }
    submitRefundForm = (e) => {
        const driver = this.props.driver
        if ((driver.deposit - driver.balance > 0)) {
            let refundMethod = this.state.refundMethod
            this.setState({ loading: true })
            let stage = 0
            let mailingAddress = ""
            if (refundMethod === "Keep on file") {
                stage = 3
                refundMethod = ""
            } else if (refundMethod === "Check") {
                mailingAddress = driver.address
            }
            let amount = driver.deposit - driver.balance
            let input = {
                driverId: this.props.driver.id,
                amount: amount,
                method: refundMethod,
                stage: stage,
                mailingAddress: mailingAddress
            }
            this.props.client.mutate({
                mutation: createRefundMutation,
                variables: { input }
            }).then((result) => {
                this.setState({ showRefundForm: false, deposit: 0, balance: 0, loading: false })
                this.props.handleClose()
            })
        } else {
            let error_msg = "Can't create refund. Driver's total balance is: $" + parseFloat(driver.deposit - driver.balance).toFixed(2).toString()
            this.setState({ error_message: error_msg })
        }
    };

    render() {
        const { classes, handleClose } = this.props
        let driver = this.props.driver
        return (
            driver ?
                <Fragment>
                    <Modal
                        open={this.props.open}
                        style={{ overflow: 'scroll' }}
                        disableBackdropClick={false}
                        onClose={this.handleReserveModalClose}>
                        <div className={classes.modal + " " + classes.paper}>
                            <div className={classes.title}>{driver.name + " has a refund of $" + (parseFloat(driver.deposit - driver.balance).toFixed(2).toString()) + ". Please fill this form to confirm driver's refund process."}</div>
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    {this.state.error_message && <Alert color="danger">{this.state.error_message}</Alert>}
                                    <FormControl style={{ width: '100%', marginTop: 15 }}>
                                        <InputLabel htmlFor="refundMethod-helper">How will the driver recieve thier refund?</InputLabel>
                                        <Select
                                            value={this.state.refundMethod}
                                            onChange={this.handleRefundMethodChange}
                                            input={<Input name="refundMethod" id="refundMethod-helper" />}
                                        >
                                            {
                                                ["Keep on file", "Check", "Direct Deposit"].map(rtype =>
                                                    <MenuItem key={rtype} value={rtype}>{rtype}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                    {this.state.refundMethod === "Check" &&
                                        <div>
                                            <p><br />Current address is: {this.state.newAddress ? this.state.newAddress : this.props.driver.address}</p>
                                            <Button style={{ "paddingTop": "10px" }} type="button" color="default" onClick={this.toggleEditAddress}>Update Address</Button>
                                        </div>
                                    }
                                    {this.state.editAddress &&
                                        <div>
                                            <TextField
                                                id="newStreetAddress"
                                                name="newStreetAddress"
                                                label="Street address including apt number"
                                                type="text"
                                                value={this.state.newStreetAddress}
                                                fullWidth
                                                onChange={this.handleTextInputChange('newStreetAddress')}
                                            />
                                            <TextField
                                                id="newCity"
                                                name="newCity"
                                                label="City"
                                                type="text"
                                                value={this.state.newCity}
                                                fullWidth
                                                onChange={this.handleTextInputChange('newCity')}
                                            />
                                            <TextField
                                                id="newState"
                                                name="newState"
                                                label="State Abbr. E.g 'NY'"
                                                maxLength="2"
                                                type="text"
                                                value={this.state.newState}
                                                fullWidth
                                                onChange={this.handleTextInputChange('newState')}
                                            />
                                            <TextField
                                                id="newZipCode"
                                                name="newZipCode"
                                                maxLength="5"
                                                label="5 digit zip code"
                                                type="number"
                                                value={this.state.newZipCode}
                                                fullWidth
                                                onChange={this.handleTextInputChange('newZipCode')}
                                            />
                                            <div style={{ "paddingTop": "20px" }}>
                                                <Button style={{ "marginRight": "10px" }} type="button" color="default" onClick={this.toggleEditAddress}>Cancel</Button>
                                                <Button disabled={((!this.state.newStreetAddress || this.state.newStreetAddress === "") || (!this.state.newZipCode || this.state.newZipCode === "")) ? true : false} type="button" color="primary" onClick={this.updateDriver}>Update Address</Button>
                                            </div>
                                        </div>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type="button" color="default" style={{ "marginRight": "5px", "marginBottom": "5px" }}
                                        name="reserve_cancel_btn" onClick={handleClose}>
                                        CLOSE
                                    </Button>
                                    <Button type="button" color="primary" style={{ "marginBottom": "5px" }}
                                        name="reserve_cancel_btn" onClick={this.submitRefundForm}>
                                        SUBMIT
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Modal>
                </Fragment> : null
        )
    }
}
export default compose(
    withStyles(styles),
    withApollo,
    graphql(DriverDetailQuery, {
        options: ({ driverId }) => ({ variables: { id: driverId } }),
        props({ data: { driver, loading, refetch, variables } }) {
            return {
                driver, loading, variables,
                refetchDriverQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    return refetch({
                        query: DriverDetailQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { driver: fetchMoreResult.driver }
                        },
                    })
                },
            }
        }
    }),
)(NewRefundModal)