import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Popover, PopoverBody, Alert, Progress, Button, PopoverHeader, FormGroup, Label } from 'reactstrap'
import { RepsQuery } from "../Functions/querys"
import Select from 'react-select'

const CreateCarAssignment = gql`
mutation CreateCarAssignment ($input:CreateCarAssignmentInput!){
    createCarAssignment(input:$input){
        errors{
            messages
        }
        ok
        assignment{
            id
        }
    }
}`

class CarRepAssignmentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCars: this.props.selectedCars ? this.props.selectedCars : null,
            selectedRep: null,
            loading: false,
            error: null,
        }
    }

    handleClose = () => {
        this.setState({ selectedCars: [], selectedRep: null, error: null, loading: false })
        this.props.handleClose()
    }


    assignCar = () => {
        this.setState({ loading: true, error: null })
        let input = { repId: this.state.selectedRep, cars: this.state.selectedCars }

        this.props.client.mutate({
            mutation: CreateCarAssignment,
            variables: { input },
        }).then((result) => {
            if (result.data && result.data.createCarAssignment && result.data.createCarAssignment.ok) {
                this.setState({ loading: false, error: null, selectedRep: null })
                this.props.refetch()
                this.handleClose()
            } else {
                this.setState({ loading: false, error: "Error while assigning car/s to rep. Try again" })
            }
        }).catch((error) => {
            this.setState({ error: "An error has occured", loading: false })
        })
    };

    render() {
        return <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.handleClose} style={{ minWidth: '275px' }}>
            <PopoverHeader>Assign Car/s to Rep</PopoverHeader>
            <PopoverBody>
                {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                <FormGroup>
                    <Label for="rep">Assign Car/s to Rep</Label>
                    <Select
                        className="bos-custom-select" classNamePrefix="bos-select"
                        isLoading={!this.props.allReps || !this.props.allReps.edges || !this.props.allReps.edges.length > 0}
                        options={this.props.allReps && this.props.allReps.edges && this.props.allReps.edges.length > 0 && this.props.allReps.edges.map(item => ({ value: item.node.id, label: item.node.username }))}
                        placeholder="Select Rep For Assignment"
                        isClearable
                        onChange={rep => this.setState({ selectedRep: rep ? rep.value : null })}
                    />
                </FormGroup>
                {this.state.loading ?
                    <Progress animated color="info" value={100} />
                    : <Button type="button" color="primary" disabled={this.state.selectedRep && this.state.selectedCars && this.state.selectedCars.length > 0 ? false : true} size="sm" onClick={this.assignCar}>Assign Car</Button>}
            </PopoverBody>
        </Popover>
    }
}

export default compose(
    withApollo,
    graphql(RepsQuery, { options: ({ groups, orderBy }) => ({ variables: { groups, orderBy } }), props({ data: { allReps } }) { return { allReps } } })
)(CarRepAssignmentModal)