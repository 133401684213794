import React, { Component } from 'react'
import { Card, Button, Row, Input, Col, CardBody, NavItem, Container, Label, FormGroup } from 'reactstrap'
import AuditList from './AuditList'
import AuditListHeader from "./AuditListHeader"
import "./AuditListContainer.css"

class AuditListContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedLogs: [],
      filterValues: { "stage": "0" },
      stage: "0",
      orderBy: ['-date_added']
    }
  }

  updateOrder = (string) => {
    let order = [...this.state.orderBy]
    let index = order.indexOf(string)
    if (index > -1) {
      order[index] = "-" + string
    } else {
      index = order.indexOf("-" + string)
      index > -1 ? order.splice(index, 1) : order.unshift(string)
    }
    this.setState({ orderBy: order })
  }

  render() {
    return (
      <Container fluid>
        <AuditListHeader filterValues={this.state.filterValues} setFilterValues={(filterValues) => this.setState({ filterValues })} selectedLogs={this.state.selectedLogs} setSelectedLogs={(selectedLogs) => this.setState({ selectedLogs })} currentUser={this.props.currentUser} />
        <Row>
          <Col>
            <AuditList
              searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
              stage={this.state.filterValues && this.state.filterValues.stage ? this.state.filterValues.stage : null}
              auditReason={this.state.filterValues && this.state.filterValues.auditReason ? this.state.filterValues.auditReason : null}
              representative={this.state.filterValues && this.state.filterValues.representative ? this.state.filterValues.representative : null}
              branch={this.state.filterValues && this.state.filterValues.branch ? this.state.filterValues.branch : null}
              selectedLogs={this.state.selectedLogs}
              setSelectedLogs={(selectedLogs) => this.setState({ selectedLogs })}
              updateOrder={this.updateOrder}
              orderBy={this.state.orderBy}
              currentUser={this.props.currentUser}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default AuditListContainer
