import gql from "graphql-tag"

const CreateGroupObjectPermissionMutation = gql`mutation  createGroupObjectPermission($input:  CreateGroupObjectPermissionMutationInput!){
    createGroupObjectPermission(input:$input){
        ok
        errors{
            messages
        }
    }
}`

const UpdateGroupObjectPermissionMutation = gql`
    mutation updateGroupObjectPermission($input: UpdateGroupObjectPermissionMutationInput!){
        updateGroupObjectPermission(input:$input){
            ok
            errors{
                messages
            }
        }
    } `


export { CreateGroupObjectPermissionMutation, UpdateGroupObjectPermissionMutation }