import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Button, ModalHeader, ModalBody, Modal, ModalFooter, Progress } from 'reactstrap'
import DenyInsurance from '../ApplicationsNew/DenyInsurance'
import SubmitToInsuranceForm from '../ApplicationsNew/SubmitToInsuranceForm'
import "./ProcessInsuranceModal.css"

const DriverQuery = gql`query DriverQuery($id: ID){
    driver(id: $id){
        id
        pk
        missingInsuranceDocuments{
            id
            typeName
        }
    }
}`

class ProcessInsuranceModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
        }
    }
    handleClose = (e) => {
        let state = { openModal: "" }
        this.setState({ ...state })
        this.props.handleClose && this.props.handleClose()
    };
    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.open} id={"process-insurance-modal-popover-contianer"}>
                {this.state.openModal === "DenyInsurance" && <DenyInsurance refetchDriverQuery={this.props.refetchQuery} handleClose={this.handleClose} open={this.state.openModal === "DenyInsurance"} driverId={this.props.driver && this.props.driver.id ? this.props.driver.id : null} />}
                {this.state.openModal === "SubmitToInsuranceForm" && <SubmitToInsuranceForm refetchDriverQuery={this.props.refetchQuery} handleClose={this.handleClose} open={this.state.openModal} driverId={this.props.driver && this.props.driver.id ? this.props.driver.id : null} />}
                <ModalHeader id={"process-insurance-modal-popover-header"}>Process Insurance</ModalHeader>
                <ModalBody id={"process-insurance-modal-popover-body"}>
                    {this.props.driver && this.props.driver.missingInsuranceDocuments && this.props.driver.missingInsuranceDocuments.length > 0 &&
                        <>
                            <p style={{ color: "red" }}>Insurance cannot be processed due to the absence of the following required customer documents.</p>
                            {this.props.driver.missingInsuranceDocuments.map((item, i) => <p style={{ color: "red", fontSize: "15px" }} key={i}>{i + 1}. <strong color="danger">{item.typeName}</strong></p>)}
                        </>
                    }
                    <div className="process-insurance-modal-btn-container">
                        <Button size="sm" onClick={() => this.toggleModal("DenyInsurance")} className={"insurance-primary-btn"} style={{ backgroundColor: "red" }}>Deny Insurance</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button size="sm" onClick={() => this.toggleModal("SubmitToInsuranceForm")} className={"insurance-primary-btn"}>Request Approval</Button>
                        {/* {!(this.props.driver && this.props.driver.missingInsuranceDocuments && this.props.driver.missingInsuranceDocuments.length > 0) &&
                        } */}
                    </div>
                    {this.props.loading && <Progress animated color="info" value="100" />}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => this.props.handleClose()} className={"process-insurance-modal-secondary-btn"}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }

}

export default compose(
    withApollo,
    graphql(DriverQuery, {
        options: ({ id }) => ({ variables: { id: id } }),
        props({ data: { driver, docLoading } }) {
            return { driver, docLoading }
        }
    }),
)(ProcessInsuranceModal)