import React, { Component } from 'react'
import { compose, withApollo, graphql } from 'react-apollo'
import '../DriverDetailNew.css'
import { Button, Col, Row, Label } from 'reactstrap'
import Loader from "../../../../../Material/Loader"
import DatetimeRenderer from "../../../../../Material/DatetimeRenderer"
import { CancelSubscriptionMutation } from "../../../../Subscriptions/Mutations"
import CdwSignup from "../../../../../Material/CdwSignup"
import ConfirmationPopup from "../../../../../Material/ConfirmationPopup"
import gql from 'graphql-tag'
import { HasPermissionsQuery } from "../../../../../Functions/querys"
import toTitleCase from "../../../../../Functions/toTitleCase"

const AllSubscriptions = gql`query AllSubscriptions($driverId: ID!){
    allSubscriptions(driverId: $driverId, first:3, orderBy:["-id"]){
        edges {
            node {
                id
                stripeStatus
                statusDisplay
                dateAdded
                externalLink
                startDate
                endDate
                createdBy{
                    username
                }
                subscriptionType{
                    id
                    name
                    amount
                    deductibleAmount
                }
            }
        }
    }
}
`
class RiskSubscriptionDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            error: null,
            loading: false,
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    refetchQuery = () => {
        this.props.refetchDriverQuery()
    }

    cancelSubscription = (subscriptionId) => {
        try {
            this.setState({ loading: true })
            let input = {
                subscriptionId,
                status: 0
            }
            this.props.client.mutate({
                mutation: CancelSubscriptionMutation,
                variables: { input },
            }).then((result) => {
                if (result.data && result.data.updateSubscription && result.data.updateSubscription.ok) {
                    this.setState({ loading: false, subscriptionCanceled: true, error: null })
                    this.props.refetchAllSubscriptions()
                    this.toggleModal("")
                } else {
                    this.setState({ loading: false, subscriptionCanceled: false, error: "An error has occured. Please contact system admin" })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured"
                this.setState({ error: errorMessage, loading: false, subscriptionCanceled: false, })
            })
        } catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false, subscriptionCanceled: false, })
        }
    };

    render() {
        if (this.props.loading || this.props.subLoading || !this.props.driver) {
            return <Loader />
        }
        return (
            <div>
                <Row>
                    <Col className="d-flex justify-content-start">
                        <h4>Subscriptions</h4>
                    </Col>
                    {this.props.hasPermissions && this.props.hasPermissions.includes("add_subscription") && this.props.driver && this.props.driver.currentAgreement && this.props.driver.currentAgreement.type && this.props.driver.currentAgreement.type && this.props.driver.currentAgreement.type.subscriptiontypeSet && this.props.driver.currentAgreement.type.subscriptiontypeSet.edges && this.props.driver.currentAgreement.type.subscriptiontypeSet.edges.length > 0 && <Col className="d-flex justify-content-end">
                        <Button size="sm" id="ProcessApplicationButtons" onClick={() => this.toggleModal("NewSubscription")}>New Subscription</Button>
                    </Col>}
                </Row>
                <div>
                    {this.props.subLoading ? <Loader /> :
                        <>
                            {this.state.openModal === "NewSubscription" &&
                                <CdwSignup driverId={this.props.driver.id} agreementTypeId={this.props.driver.currentAgreement.type.id} subscriptionCreated={this.props.refetchAllSubscriptions} handleClose={() => this.toggleModal("")} />
                            }
                            {this.props.allSubscriptions && this.props.allSubscriptions.edges && this.props.allSubscriptions.edges.length > 0 ?
                                this.props.allSubscriptions.edges.map((subscription, i) =>
                                    <div style={{ borderBottom: "2px solid #c7c7c7a8", margin: "20px 0" }} key={i} >
                                        <Row>
                                            {this.state.openModal === "cancelSubscription" + subscription.node.id && <ConfirmationPopup
                                                open={this.state.openModal === "cancelSubscription" + subscription.node.id}
                                                message={"Are you sure you want to cancel this subscription?"}
                                                loading={this.state.loading}
                                                handleClose={() => this.toggleModal("")}
                                                action={"Yes"}
                                                confirmAction={() => this.cancelSubscription(subscription.node.id)}
                                                color="danger"
                                                error={this.state.error ? this.state.error : ""}
                                                success={this.state.subscriptionCanceled ? "Subscription Cancelled Successfully" : ""}
                                            />}
                                            <Col xs={3}>
                                                <Label>Date Added</Label>
                                                <p className="columnContent">{subscription.node.dateAdded ? <DatetimeRenderer datetime={subscription.node.dateAdded} format={"MMM Do, YYYY"} /> : "--"}</p>
                                            </Col>
                                            <Col xs={3}>
                                                <Label>Subscription</Label>
                                                <p className="columnContent">{subscription.node.subscriptionType ? subscription.node.subscriptionType.name + " - Deductible: $" + subscription.node.subscriptionType.deductibleAmount + " - Amount: $" + subscription.node.subscriptionType.amount : "--"}</p>
                                            </Col>
                                            <Col xs={3}>
                                                <Label>Start Date</Label>
                                                <p className="columnContent">{subscription.node.startDate ? <DatetimeRenderer datetime={subscription.node.startDate} format={"MMM Do, YYYY"} /> : "--"}</p>
                                            </Col>
                                            <Col xs={3}>
                                                <Label>End Date</Label>
                                                <p className="columnContent">{subscription.node.endDate ? <DatetimeRenderer datetime={subscription.node.endDate} format={"MMM Do, YYYY"} /> : "--"}</p>
                                            </Col>
                                            <Col xs={3}>
                                                <Label>Status</Label>
                                                <p className="columnContent">{subscription.node.statusDisplay ? subscription.node.statusDisplay : "--"}</p>
                                            </Col>
                                            <Col xs={3}>
                                                <Label>Stripe Status</Label>
                                                <p className="columnContent">{subscription.node.stripeStatus ? toTitleCase(subscription.node.stripeStatus) : "--"}</p>
                                            </Col>
                                            <Col xs={3}>
                                                <Label>Added by</Label>
                                                <p className="columnContent">{subscription.node.createdBy ? subscription.node.createdBy.username : "--"}</p>
                                            </Col>
                                            <Col xs={3}>
                                                <Label>Actions</Label>
                                                <p className="columnContent">
                                                    {subscription.node.statusDisplay && subscription.node.statusDisplay !== "Inactive" && this.props.hasPermissions && this.props.hasPermissions.includes("change_subscription") &&
                                                        <Button size="sm" className="primary" onClick={() => this.toggleModal("cancelSubscription" + subscription.node.id)}>CANCEL</Button>
                                                    }
                                                    <a target="_blank" href={subscription.node.externalLink}><i className={"fa fa-cc-stripe fa-2x"}></i></a>
                                                </p>
                                            </Col>
                                        </Row></div>
                                ) : !this.props.loading && <p>No Subscription Found</p>
                            }
                        </>
                    }
                </div>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["add_subscription", "change_subscription", "custom_can_create_insurance_before_denial_period"] }, fetchPolicy: 'cache-first', notifyOnNetworkStatusChange: true, }),
        props({ data: { hasPermissions, variables } }) {
            return {
                hasPermissions, variables,
            }
        }
    }),
    graphql(AllSubscriptions, {
        options: ({ driver }) => ({ variables: { driverId: driver.id }, fetchPolicy: 'cache-first', notifyOnNetworkStatusChange: true, }),
        props({ data: { allSubscriptions, loading, refetch, variables } }) {
            return {
                allSubscriptions, subLoading: loading, variables,
                refetchAllSubscriptions: () => {
                    return refetch({
                        query: AllSubscriptions,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { allSubscriptions: fetchMoreResult.allSubscriptions }
                        },
                    })
                },
            }
        }
    }),
)(RiskSubscriptionDetails)
