import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import './TransactionListNew.css'
import { Alert, Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap'
import Select from "react-select"
import DocumentModalNew from "../../Material/DocumentModal/DocumentModalNew"
import { AllChargeTypes, AllGroupTypes } from "./Queries"

class DownloadTransactions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            reportStartDate: "",
            reportEndDate: "",
            transactionId: null,
            minAmount: null,
            maxAmount: null
        }
    }
    downloadReport = (e) => {
        e.preventDefault()
        fetch("/billing/driver/" + this.props.driverId + "/history/?start_date=" + this.state.reportStartDate + "&end_date=" + this.state.reportEndDate + (this.state.minAmount ? ("&min_amount=" + this.state.minAmount) : "") + (this.state.maxAmount ? ("&max_amount=" + this.state.maxAmount) : "") + (this.state.groupTypeIds ? this.state.groupTypeIds.map(id => `&group_type_id=${id}`).join() : "") + (this.state.chargeTypeIds ? this.state.chargeTypeIds.map(id => `&charge_type_id=${id}`).join() : ""), {
            method: "GET",
        }).then((response) => {
            return response.blob()
        })
            .then((blob) => {
                var reader = new FileReader()
                reader.readAsDataURL(blob)
                return new Promise(resolve => {
                    reader.onloadend = function () {
                        console.log(reader.result)
                        resolve(reader.result)
                    }
                })
            })
            .then((base64) => {
                let docObject = {
                    documentUrl: base64,
                    name: "TransactionHistory"
                }
                this.setState({ downloadSuccess: true, selectedDocument: docObject, openModal: "viewDocument" })
            })
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.handleClose} >
                <ModalHeader>Download Transaction History</ModalHeader>
                <Form onSubmit={(e) => this.downloadReport(e)}>
                    <ModalBody>
                        {this.state.downloadSuccess && <Alert color="success">Download Successful.</Alert>}
                        {this.state.openModal === "viewDocument" &&
                            <DocumentModalNew
                                document={this.state.selectedDocument}
                                handleClose={() => this.setState({ selectedDocument: null, openModal: "" })}
                                objectType={"driver"}
                                isPicture={false}
                                currentUser={this.props.currentUser}
                            />
                        }
                        <Row>
                            <Col xs="">
                                <FormGroup>
                                    <Label for="reportStartDate">Enter start date</Label>
                                    <Input type="date" name="reportStartDate" id="reportStartDate" invalid={this.state.reportStartDate === ""} valid={this.state.reportStartDate !== ""} onChange={(e) => this.setState({ reportStartDate: e.target.value })} placeholder="Start Date" />
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="reportEndDate">Enter end date</Label>
                                    <Input type="date" name="reportEndDate" id="reportEndDate" invalid={this.state.reportEndDate === ""} valid={this.state.reportEndDate !== ""} onChange={(e) => this.setState({ reportEndDate: e.target.value })} placeholder={"End Date"} />
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="chargeTypeId"><small><b>Filter by Charge Type</b></small></Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!this.props.allChargeTypes || !this.props.allChargeTypes.edges || !this.props.allChargeTypes.edges.length > 0}
                                        options={this.props.allChargeTypes && this.props.allChargeTypes.edges && this.props.allChargeTypes.edges.length > 0 && this.props.allChargeTypes.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                        placeholder="Select Charge Types"
                                        isClearable
                                        isMulti
                                        onChange={(chargeTypes) => this.setState({ chargeTypeIds: chargeTypes.map(item => item.value) })}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="chargeTypeId"><small><b>Filter by Sub-Charge Type</b></small></Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!this.props.allGroupTypes || !this.props.allGroupTypes.edges || !this.props.allGroupTypes.edges.length > 0}
                                        options={this.props.allGroupTypes && this.props.allGroupTypes.edges && this.props.allGroupTypes.edges.length > 0 && this.props.allGroupTypes.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                        placeholder="Select Sub-Charge Types"
                                        isClearable
                                        isMulti
                                        onChange={(groupTypes) => this.setState({ groupTypeIds: groupTypes.map(item => item.value) })}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="minAmount"><small><b>Min Amount ${this.state.minAmount ? this.state.minAmount : 0}</b></small></Label>
                                    <Input type="number" name="minAmount" id="minAmount" onChange={(e) => this.setState({ minAmount: e.target.value })} />
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup>
                                    <Label for="maxAmount"><small><b>Max Amount ${this.state.maxAmount ? this.state.maxAmount : 0}</b></small></Label>
                                    <Input type="number" name="maxAmount" id="maxAmount" onChange={(e) => this.setState({ maxAmount: e.target.value })} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={!this.props.reportStartDate && !this.state.reportEndDate}>Download</Button>
                        <Button color="secondary" onClick={this.props.handleClose}>Close</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllChargeTypes, { props({ data: { allChargeTypes } }) { return { allChargeTypes } } }),
    graphql(AllGroupTypes, { props({ data: { allGroupTypes } }) { return { allGroupTypes } } }),
)(DownloadTransactions)