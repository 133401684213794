import React, { Component } from 'react'
import { Container, Row, Col, Input, Nav, NavItem, Button } from 'reactstrap'
import FinancialAccountList from "./FinancialAccountList"
import './FinancialAccountListContainer.css'
import NewFinancialAccountModal from "./NewFinancialAccountModal"
import RestrictedSection from "../../Components/RestrictedSection"
import TaxManagementTab from "../TaxManagement/TaxManagementTab"

const tabs = [
    { id: "#financeAccounts", name: "Finance Accounts" },
    { id: "#taxes", name: "Sales Tax Management" }
]

class FinancialAccountListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchTerm: null,
            orderBy: "-id"
        }
    }

    changeTab = (e) => {
        const { name, value } = e.target
        const tabIndex = tabs.findIndex(tab => tab.name === value)
        const selectedTab = tabs[tabIndex]
        window.location.hash = selectedTab.id
        this.setState({ [name]: value })
    }

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (value === "null") {
            value = null
        }
        // To change string inputs to boolean because only strings can be passed to html options
        if (e.target.dataset.type) {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }

        this.setState({ [name]: value })
    };

    updateOrderBy = (name) => {
        name = name && name.includes(".") ? name.replace(".", "__") : name
        this.setState({ orderBy: this.state.orderBy === name ? "-" + name : name })
    }

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }

    componentDidMount() {
        if (window.location.hash && tabs.map(item => item.id).includes(window.location.hash)) {
            const tabIndex = tabs.findIndex(tab => tab.id === window.location.hash)
            this.setState({ activeTab: tabs[tabIndex].name })
        } else {
            window.location.hash = tabs[0].id
            this.setState({ activeTab: tabs[0].name })
        }
    }

    renderContent = () => {
        switch (this.state.activeTab) {
            case "Sales Tax Management":
                return <TaxManagementTab />
            case "Finance Accounts":
                return (
                    <>
                        <Row>
                            <Col xs={4}>
                                <span className="driver-search-filter">
                                    <Input type="text" name="searchTerm" placeholder="Search" onChange={this.updateInput} />
                                    <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                                </span>
                            </Col>
                            <Col xs={8} className="text-right mt-2">
                                <a id="newFinancialAccount" className="driver-list-button" onClick={() => this.toggleModal("NewFinancialAccount")}>
                                    New Financial Account | <i className="fa fa-plus" aria-hidden="true"></i>
                                </a>
                                {this.state.openModal === "NewFinancialAccount" && <NewFinancialAccountModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "NewFinancialAccount"} />}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col className="bos-object-section-wrapper">
                                <FinancialAccountList currentUser={this.props.currentUser} searchTerm={this.state.searchTerm} orderBy={this.state.orderBy} updateOrderBy={this.updateOrderBy} />
                            </Col>
                        </Row>
                    </>
                )
        }
    }

    render() {
        return (
            <RestrictedSection requiredPermission="view_financialaccount">
                <Container fluid className="bos-listing-container" >
                    <Nav pills className="available-car-wrap">
                        {tabs.map((tab, i) => {
                            return (
                                <NavItem key={i}>
                                    <Button color="primary" name={"activeTab"} value={tab.name} className={this.state.activeTab === tab.name ? "active" : "inactive"} onClick={this.changeTab}>{tab.name}</Button>
                                </NavItem>
                            )
                        })}
                    </Nav>
                    {
                        this.renderContent()
                    }

                </Container>
            </RestrictedSection>
        )
    }
}
export default FinancialAccountListContainer
