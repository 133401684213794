import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Container, Row, Col, Button, Nav, NavItem } from 'reactstrap'
import DealsList from './DealsList'
import moment from 'moment'
import CarDealsHeader from "./CarDealsHeader"
import './Listing.css'
import RestrictedSection from "../Components/RestrictedSection"

const tabs = [{ id: "#selling", name: "Selling Cars" }, { id: "#buying", name: "Buying Cars" }]
class CarDealsContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedReps: [],
            activeTab: "Selling Cars",
            orderBy: ["-event_date"],
            filterValues: {},
            refetchDeals: false
        }
    }
    updateOrder = (string) => {
        let order = this.state.orderBy ? [...this.state.orderBy] : []
        let index = order.indexOf(string)
        if (index > -1) {
            order[index] = "-" + string
        } else {
            index = order.indexOf("-" + string)
            index > -1 ? order.splice(index, 1) : order.unshift(string)
        }
        this.setState({ orderBy: order })
    };
    componentDidMount() {
        if (window.location.hash && tabs.map(item => item.id).includes(window.location.hash)) {
            let tabIndex = tabs.findIndex(tab => tab.id === window.location.hash)
            this.setState({ activeTab: tabs[tabIndex].name })
        } else {
            window.location.hash = tabs[0].id
            this.setState({ activeTab: tabs[0].name })
        }
    }
    changeTab = (e) => {
        let name = e.target.name
        let value = e.target.value
        let tabIndex = tabs.findIndex(tab => tab.name === value)
        window.location.hash = tabs[tabIndex].id
        this.setState({ [name]: value })
    }

    render() {
        return (
            <RestrictedSection requiredPermission="view_cardeal">
                <Container fluid className="bos-listing-container">
                    <Nav pills className="available-car-wrap">
                        {tabs && tabs.length > 0 && tabs.map(tab =>
                            <NavItem key={tab.id}>
                                <Button color="primary" name={"activeTab"} value={tab.name} className={this.state.activeTab === tab.name ? "active" : "inactive"} onClick={this.changeTab}>{tab.name && tab.name === "Selling Cars" ? <i className="fa fa-outdent pr-3" aria-hidden="true"></i> : <i className="fa fa-shopping-cart pr-3" aria-hidden="true"></i>}{tab.name}</Button>
                            </NavItem>
                        )}
                    </Nav>
                    <CarDealsHeader
                        activeTab={this.state.activeTab}
                        filterValues={this.state.filterValues} history={this.props.history}
                        defaultFilters={{ orderBy: "-event_date" }} location={this.props.location}
                        setFilterValues={(filterValues) => this.setState({ filterValues })}
                        location={this.props.location} history={this.props.history}
                        defaultFilters={{ orderBy: ["-event_date"] }}
                        refetchQuery={() => this.setState({ refetchDeals: !this.state.refetchDeals })}
                    />
                    <Row>
                        <Col sm="12">
                            <DealsList
                                key={String(this.state.refetchDeals)}
                                currentUser={this.props.currentUser}
                                dealType={this.state.activeTab === "Selling Cars" ? "SELLING" : "BUYING"}
                                stageIn={this.state.filterValues && this.state.filterValues.stageIn && this.state.filterValues.stageIn.length > 0 ? this.state.filterValues.stageIn : null}
                                assignedToIn={this.state.filterValues && this.state.filterValues.assignedToIn && this.state.filterValues.assignedToIn.length > 0 ? this.state.filterValues.assignedToIn : null}
                                carsInFleet={this.state.filterValues && this.state.filterValues.carsInFleet !== null ? this.state.filterValues.carsInFleet : null}
                                searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                                orderBy={this.state.orderBy}
                                startDate={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].startDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                                endDate={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].endDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                                updateOrder={this.updateOrder}
                            />
                        </Col>
                    </Row>
                </Container>
            </RestrictedSection>
        )
    }
}

export default compose(
    withApollo,
)(CarDealsContainer)
