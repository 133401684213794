import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import "./RestrictedSection.css"
import { HasPermissionsQuery } from "../Functions/querys"
class RestrictedSection extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <>
                {this.props.hasPermissions && this.props.hasPermissions.includes(this.props.requiredPermission) ?
                    <div>
                        {this.props.children}
                    </div> :
                    !this.props.loading && <div className="permission-box">
                        <div className="permission-box-title">
                            <h4> Insufficient Permissions&nbsp;&nbsp;<i class="fa fa-meh-o" aria-hidden="true"></i></h4>
                        </div>
                        <div className="permission-box-body">
                            <p className="permission-box-message">You don't have enough permissions to access this page. Please contact the admin for further assistance.</p>
                        </div>
                        <br /><br />
                        <div className="permission-box-footer">
                            <a className="permission-box-button btn btn-primary" href="/">
                                Go To Homepage
                            </a>
                        </div>
                    </div>
                }
            </>
        )
    }
}
export default compose(
    withApollo,
    graphql(HasPermissionsQuery, {
        options: ({ requiredPermission }) => ({
            variables: { userPermissions: requiredPermission }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true
        }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    }),
)(RestrictedSection)
