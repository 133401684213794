import gql from 'graphql-tag'
const UpdateDriverNotes = gql`
    mutation updateDriverMutation($input: UpdateDriverMutationInput!){
    updateDriver(input:$input){
        ok
        errors{
            messages
        }
    }
}`

const UpdateCarNotes = gql`
    mutation updateCarMutation($input: UpdateCarMutationInput!){
    updateCar(input:$input){
        ok
        errors{
            messages
        }
    }
}`

const UpdateReservation = gql`
mutation updateReservationMutation($input: UpdateReservationMutationInput!){
    updateReservation(input:$input){
        ok
        errors{
            messages
        }
    }
} `
const ChangeReturnMutation = gql`
mutation ChangeReturn ($input:ChangeReturnInput!){
    changeReturn(input:$input){
        ok
        errors{
            field
            messages
        }
    }
}`
const UpdateCustomerLead = gql`
mutation UpdateCustomerLead($input: UpdateLeadMutationInput!){
    updateCustomerLead(input:$input){
    ok
    errors{
        field
        messages
    }
}
} `
const UpdateInsuranceMutation = gql`
mutation UpdateInsuranceMutation($input: UpdateInsuranceStatusMutationInput!){
    updateInsuranceStatus(input:$input){
    ok
    errors{
        field
        messages
    }
}
} `
const UpdateCarDealMutation = gql`mutation UpdateCarDealMutation($input: UpdateCarDealMutationInput!){
    updateCarDeal(input:$input){
        ok
        errors{
            messages
        }
    }
} `
const UpdateCarIssueMutation = gql`mutation UpdateCarIssueMutation($input: UpdateCarIssueMutationInput!){
    updateCarIssue(input:$input){
        ok
        errors{
            messages
        }
    }
} `

export { UpdateDriverNotes, UpdateCarNotes, UpdateReservation, ChangeReturnMutation, UpdateCustomerLead, UpdateInsuranceMutation, UpdateCarDealMutation, UpdateCarIssueMutation }