import gql from "graphql-tag"

const AllWorkOrders = gql`query AllWorkOrder($cursor: String, $searchTerm: String,  $first:Int,  $orderBy: [String], $vendorIn: [ID]){
    allWorkOrders(after: $cursor, searchTerm: $searchTerm,  first: $first, orderBy: $orderBy, vendorIn: $vendorIn){
        edges {
            node {
                id
                completionDate
                salesTax
                metadata
                vendor {
                    id
                    name
                }
                car {
                    id
                    pk
                    vin
                    dmvPlate
                    year
                    color
                    group
                    model
                    stage
                    carpolicySet {
                        edges {
                            node {
                            id
                            status
                                insurancePolicy {
                                    id
                                    startDate
                                    endDate
                                    status
                                    policyNumber
                                    broker {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                    currentAgreement {
                        driver {
                            id
                            tlcLicense
                            name
                        }
                    }
                }
                workitemSet {
                    edges {
                        node {
                        price
                        jobType {
                            id
                            name
                        }
                        quantity
                            id
                        }
                    }
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`
const WorkOrderQuery = gql` query WorkOrderQuery($id: ID){
    workOrder(id:$id){
        id
        vendor {
          id
          name
        }
        car {
          id
          pk
          vin
          dmvPlate
        }
        metadata
        completionDate
        salesTax
        workitemSet {
          edges {
            node {
              id
              jobType {
                name
                id
              }
              price
              quantity
              id
            }
          }
        }
    }
}`
const JobTypeQuery = gql`query JobTypeQuery{
    allJobTypes{
        edges{
            node {
                id
                name
            }
        }
    } 
}`
const VendorsQuery = gql`query VendorsQuery($searchTerm: String){
    allVendors(searchTerm: $searchTerm){
        edges{
            node {
                id
                name
                address
                isActive
            }
        }
    } 
}`

const CarsQuery = gql`query CarsQuery($searchTerm: String){
    allCars(searchTerm: $searchTerm, first:5){
        edges{
            node{
                id
                pk
                vin
                dmvPlate
            }
        }
    } 
}`

export { AllWorkOrders, WorkOrderQuery, JobTypeQuery, VendorsQuery, CarsQuery }