import React, { Component } from 'react'
import '../BranchMangement/BranchListContainer.css'
import { Input, Container, Row, Col } from 'reactstrap'
import GroupsList from "./GroupsList"
import CreateGroupModal from "./CreateGroupModal"
import RestrictedSection from "../../Components/RestrictedSection"
class GroupsListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: null,
            searchTerm: "",
            openModal: ""
        }
    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (value === "null") {
            value = null
        }
        if (e.target.dataset.type) {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }

        this.setState({ [name]: value })
    };

    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }

    render() {
        return (
            <RestrictedSection requiredPermission="view_group">
                <Container fluid className="bos-listing-container">
                    <Row>
                        <Col xs={4}>
                            <span className="driver-search-filter">
                                <Input type="text" name="searchTerm" placeholder="Search" onChange={this.updateInput} />
                                <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                            </span>
                        </Col>
                        <Col xs={8} className="text-right mt-2">
                            <a className="driver-list-button" onClick={() => this.toggleModal("createGroup")}>
                                Create Group | <i className="fa fa-user-plus" aria-hidden="true"></i>
                            </a>
                            {this.state.openModal === "createGroup" && <CreateGroupModal handleClose={() => this.toggleModal("createGroup")} open={this.state.openModal === "createGroup"} />}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <GroupsList searchTerm={this.state.searchTerm ? this.state.searchTerm : ""} />
                        </Col>
                    </Row>
                </Container>
            </RestrictedSection>
        )
    }
}

export default GroupsListContainer