import React, { useState } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from "react-apollo"
import {
    Row, Col,
    Form, Input, FormGroup, Button, Label, Alert,
    Modal, ModalHeader, ModalBody, ModalFooter,

} from "reactstrap"
import { GroupsTypeAhead, RepsTypeAhead } from "./TypeAheadComponents"
import { CreateBoardMutation } from "./Mutations"

function NewBoardModal({ isOpen, closeModal, ...props }) {
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [repsSearchTerm, setRepsSearchTerm] = useState("")
    const [showDriverColumn, setShowDriverColumn] = useState(true)
    const [showVehicleColumn, setShowVehicleColumn] = useState(true)
    const [repIds, setRepIds] = useState([])
    const [groupsSearchTerm, setGroupsSearchTerm] = useState("")
    const [groupIds, setGroupIds] = useState([])
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)


    const createBoard = (e) => {
        e.preventDefault()
        try {
            if (!props.client) {
                throw new Error("GQL Client not found")
            }
            const client = props.client
            if (!client) {
                throw new Error("GQL Client not found")
            }
            if (!title || title.length < 5) {
                setError("Title is required or too short")
                return
            }
            setLoading(true)
            const input = {
                title: title,
                description: description,
                sharedWithUserIds: repIds,
                sharedWithGroupIds: groupIds,
                showDriverColumn: showDriverColumn,
                showVehicleColumn: showVehicleColumn,
            }

            client.mutate({
                mutation: CreateBoardMutation,
                refetchQueries: ["AllBoards"],
                variables: { input },
            }).then((result) => {
                setLoading(false)
                if (!result) {
                    throw new Error("Error creating board")
                }
                if (result.data && result.data.createBoard && result.data.createBoard.ok) {
                    setError("")
                    closeModal("")
                } else {
                    const errorMessage = result.data && result.data.createBoard && result.data.createBoard.errors &&
                        result.data.createBoard.errors.length > 0 && result.data.createBoard.errors[0].messages.length > 0 ?
                        result.data.createBoard.errors[0].messages[0] : "Error creating board"
                    throw new Error(errorMessage)
                }
            }).catch((error) => {
                setError("Error creating board" + error.toString())
                setLoading(false)
            })
        } catch (error) {
            setError("Error creating board" + error.toString())
            setLoading(false)
        }
    }

    return (
        <Modal isOpen={isOpen} className="">
            <ModalHeader>Create Board</ModalHeader>
            <ModalBody>
                <Form onSubmit={createBoard}>
                    <Row>
                        {error && <Col xs={12}><Alert color="danger">{error}</Alert></Col>}
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="title">Title *</Label>
                                <Input type="string" name="title" id="title" placeholder="Give your board a title" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="description">Description</Label>
                                <Input type="string" name="description" id="description" placeholder="Add more details" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="repIds">Share With Groups</Label>
                                <GroupsTypeAhead searchTerm={groupsSearchTerm} setSearchTerm={setGroupsSearchTerm} onChange={(selectedGroupIds) => { setGroupIds(selectedGroupIds) }} />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="repIds">Share With Users</Label>
                                <RepsTypeAhead searchTerm={repsSearchTerm} setSearchTerm={setRepsSearchTerm} onChange={(selectedRepIds) => { setRepIds(selectedRepIds) }} />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" checked={showDriverColumn} onChange={(e) => setShowDriverColumn(e.target.checked)} />{' '}
                                    Show Driver Column
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" checked={showVehicleColumn} onChange={(e) => setShowVehicleColumn(e.target.checked)} />{' '}
                                    Show Vehicle Column
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <br />
                    <ModalFooter>
                        {loading ?
                            <Button color="primary" disabled={true}>Creating Board...</Button> :
                            <Button color="primary" type="submit" disabled={!title || loading}>Create Board</Button>}
                        <Button color="secondary" onClick={closeModal}>Close</Button>
                    </ModalFooter>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default compose(withApollo)(NewBoardModal)
