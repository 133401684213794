import gql from 'graphql-tag'
const AllRideSharingPartners = gql`{
  allRideSharingPartners{
    edges{
      node{
          id
          name
      }
    }
  }
}`
const AllCarLocations = gql`{
    allCarLocations(isPickupLocation:true,orderBy:["name"]){
      edges{
        node{
            id
            name
        }
      }
    }
}`
const ReservationsSummaryByPickupDate = gql`query reservationsSummaryByPickupDate($carIsnull:Boolean){
    reservationsSummaryByPickupDate(carIsnull:$carIsnull){
        summary
        details{
            name
            value
        }
    }
}`
const ReservationsSummaryByPickupType = gql`query reservationsSummaryByPickupType($startDate: DateTime,$endDate: DateTime,$status:String,$dateColumnToFilter:String,$carIsnull:Boolean){
    reservationsSummaryByPickupType(startDate:$startDate,endDate:$endDate,status:$status,dateColumnToFilter:$dateColumnToFilter,carIsnull:$carIsnull){
        key
        value
        details{
          key
          value
        }
    }
}`
const ReservationsSummaryByStatus = gql`query reservationsSummaryByStatus($startDate: DateTime,$endDate: DateTime,$dateColumnToFilter:String,$carIsnull:Boolean){
    reservationsSummaryByStatus(startDate:$startDate,endDate:$endDate,dateColumnToFilter:$dateColumnToFilter,carIsnull:$carIsnull){
        key
        value
        details{
            key
        }
    }
}`
const AllAgreementTypes = gql`{
    allAgreementTypes{
      edges{
        node{
            id
            name
        }
      }
    }
}`
const AllBrokers = gql`{
    allBrokers(isActive:true){
      edges{
        node{
          name
          id
          isActive
          isMain
          emails
          isUnderwriter
        }
      }
    }
}`

const pickupTypes = [{ name: "All", value: "all" }, { name: "New Drivers", value: "new" }, { name: "Returning Drivers", value: "returning" }, { name: "Switches", value: "switch" }, { name: "Future RD", value: "future" }]
const pickupDateRanges = [{ name: "All", value: "all" }, { name: "Next 7 days", value: "7" }, { name: "A Week From Today", value: "8" }, { name: "No Pickup Date", value: "0" }]
export { AllCarLocations, ReservationsSummaryByPickupDate, ReservationsSummaryByPickupType, ReservationsSummaryByStatus, pickupTypes, pickupDateRanges, AllRideSharingPartners, AllAgreementTypes, AllBrokers }