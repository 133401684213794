import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button } from "reactstrap"
import { Route, Switch, Link } from "react-router-dom"
import ChatBotSession from "./ChatBotSession"
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore"
import { firestoreDb } from "../Components/firebase"
import "./ChatBotContainer.css"



export default function ChatBotContainer({ allChats, currentUser, ...props }) {
    const sessionId = props && props.match && props.match.params && props.match.params.sessionId ? props.match.params.sessionId : null
    const [sessions, setSessions] = useState([])

    const getSessions = async () => {
        const q = query(
            collection(firestoreDb, "chatbot_session"),
            where("is_archived", "==", false),
            orderBy("added_on", "desc")
        )
        onSnapshot(q, (snapshot) => {
            const sessions = []
            snapshot.forEach((doc) => {
                sessions.push(doc.data())
            })
            setSessions(sessions)
        })
    }

    useEffect(() => {
        getSessions()
    }, [])



    return (
        <Container fluid>
            <Row>
                {/* Two columns, one for navigating history and the other for chat */}
                <Col xs="2" className="ChatBotColumn">
                    <ul className="ChatBotList">
                        <li className={!sessionId ? "active" : ""}>
                            <Link to="/chatbot/">New Chat</Link>
                        </li>
                        {sessions && sessions.length > 0 && sessions.map((session, idx) => (
                            <li className={sessionId === session.session_id ? "active" : ""} key={idx}>
                                <Link to={`/chatbot/${session.session_id}/`}>{session.title}</Link>
                            </li>
                        ))}
                        {/* Load more button */}
                        {/* {SESSIONS && allChats.pageInfo.hasNextPage && (
                            <li>
                                <Button outline color="secondary" onClick={props.loadMoreEntries}>Load more...</Button>
                            </li>
                        )} */}
                    </ul>
                </Col>
                <Col xs="10">
                    <ChatBotSession sessionIdFromParent={sessionId} currentUser={currentUser} />
                </Col>
            </Row>

        </Container>
    )
}