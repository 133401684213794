import gql from "graphql-tag"

const CreateWorkOrderMutation = gql`mutation CreateWorkOrderMutationInput($input: CreateWorkOrderMutationInput!){
    createWorkOrder(input: $input) {
        ok
        clientMutationId
        errors {
          field
          messages
        }
      }
}`

const UpdateWorkOrderMutation = gql`mutation UpdateWorkOrderMutation($input: UpdateWorkOrderMutationInput!){
  updateWorkOrder(input: $input) {
      ok
      clientMutationId
      errors {
        field
        messages
      }
    }
}`

const UpdateWorkItemMutation = gql`mutation UpdateWorkItemMutation($input: UpdateWorkItemMutationInput!){
  updateWorkItem(input: $input) {
      ok
      clientMutationId
      errors {
        field
        messages
      }
    }
}`

const CreateWorkItemMutation = gql`mutation CreateWorkItemMutation($input: CreateWorkItemMutationInput!){
  createWorkItem(input: $input) {
      ok
      clientMutationId
      errors {
        field
        messages
      }
    }
}`

export { CreateWorkOrderMutation, UpdateWorkOrderMutation, UpdateWorkItemMutation, CreateWorkItemMutation }
