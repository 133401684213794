import React, { Component } from 'react'
import { graphql, compose } from 'react-apollo'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import RentalsList from './RentalsList'
import { debounce } from 'lodash'
import "./Rentals.css"
import RentalsListHeader from "./RentalsListHeader"

class RentalsListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterValues: {
                orderBy: ["-start_date", "-driver__in_office"],
                stage: 10
            },
            selectedDrivers: []
        }
    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (value === "null") {
            value = null
        }
        if (value === "false") {
            value = false
        } else {
            value = true
        }
        this.setState({ [name]: value })
    };
    updateSelection = (selection) => {
        if (Array.isArray(selection)) {
            this.setState({ selectedDrivers: selection })
        } else {
            if (this.state.selectedDrivers.includes(selection))
                this.setState({ selectedDrivers: this.state.selectedDrivers.filter(item => item != selection) })
            else
                this.setState({ selectedDrivers: [...this.state.selectedDrivers, selection] })
        }
    }
    updateSearchTerm = debounce((searchTerm) => {
        this.setState({ searchTerm: searchTerm })
    }, 500)

    updateOrder = (string) => {
        let order = [...this.state.filterValues.orderBy]
        if (!order.includes(string) && !order.includes("-" + string)) {
            order = [order.find(item => item.includes("-driver__in_office")), string]
        } else {
            let index = order.indexOf(string)
            if (order.indexOf(string) > -1) {
                order[index] = "-" + string
            } else {
                index = order.indexOf("-" + string)
                index > -1 && (order[index] = string)
            }
        }
        this.setState({ filterValues: { ...this.state.filterValues, orderBy: order } })
    }

    render() {
        return (
            <Container fluid className="rental-list-container">
                {this.state.stage != "40" && <RentalsListHeader
                    activeTab="All Rentals"
                    filterValues={this.state.filterValues} defaultFilters={this.state.filterValues}
                    setFilterValues={(filterValues) => this.setState({ filterValues })}
                    selectedDrivers={this.state.selectedDrivers} history={this.props.history} location={this.props.location}
                    resetSelectedDrivers={() => this.setState({ selectedDrivers: [] })}
                />}
                <Row>
                    <Col>
                        <RentalsList
                            updateOrder={this.updateOrder}
                            orderBy={this.state.filterValues && this.state.filterValues.orderBy ? this.state.filterValues.orderBy : null}
                            searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                            stage={this.state.filterValues && this.state.filterValues.stage ? this.state.filterValues.stage : null}
                            contractSigned={this.state.filterValues && this.state.filterValues.contractSigned !== null ? this.state.filterValues.contractSigned : null}
                            pickupTypes={this.state.filterValues && this.state.filterValues.pickupTypes ? this.state.filterValues.pickupTypes : null}
                            agreementTypes={this.state.filterValues && this.state.filterValues.agreementTypes ? this.state.filterValues.agreementTypes : null}
                            pikcupLocations={this.state.filterValues && this.state.filterValues.pikcupLocations ? this.state.filterValues.pikcupLocations : null}
                            selectedDrivers={this.state.selectedDrivers}
                            updateDriversSelection={this.updateSelection}
                            startDate_Gte={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? this.state.filterValues.selectionRange[0].startDate : null}
                            startDate_Lte={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? this.state.filterValues.selectionRange[0].endDate : null}
                            hasPromo={this.state.filterValues && this.state.filterValues.hasPromo != null ? this.state.filterValues.hasPromo : null}
                            promoNames={this.state.filterValues && this.state.filterValues.promoNames != null ? this.state.filterValues.promoNames : null}
                            carModelNames={this.state.filterValues && this.state.filterValues.carModelNames ? this.state.filterValues.carModelNames : null}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default compose(
)(RentalsListContainer)

