import React, { useState, useEffect } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import gql from 'graphql-tag'
import "react-awesome-lightbox/build/style.css"
import "../Material/ListingHeader.css"
import { collection, onSnapshot, or, orderBy, query, where, updateDoc, doc } from "firebase/firestore"
import { firestoreDb } from "../Components/firebase"
import ReactMarkdown from 'react-markdown'

const CREATE_RESPONSE_FOR_PROMPT = gql`
    mutation createResponseForPrompt($input: CreateResponseForPromptMutationInput!) {
        createResponseForPrompt(input: $input) {
            ok
            errors {
                messages
                field
            }
            sessionId
        }
    }
`

const CHATBOT_SESSION_MESSAGES_COLLECTION_NAME = "chatbot_session_messages"

const ChatBotSession = ({ sessionIdFromParent, currentUser, createResponseForPrompt, ...props }) => {
    if (!currentUser) return (
        <div></div>
    )



    const [messages, setMessages] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [currentPrompt, setCurrentPrompt] = useState('')
    const [sessionId, setSessionId] = useState(sessionIdFromParent)


    useEffect(() => {
        setSessionId(sessionIdFromParent)
    }, [sessionIdFromParent])

    const getMessages = async () => {
        if (!sessionId) {
            setMessages([])
            return
        }
        const q = query(
            collection(firestoreDb, CHATBOT_SESSION_MESSAGES_COLLECTION_NAME),
            where("is_archived", "==", false),
            where("session_id", "==", sessionId),
            orderBy("added_on", "asc")
        )
        onSnapshot(q, (snapshot) => {
            const messages = []
            snapshot.forEach((doc) => {
                messages.push({ id: doc.id, ...doc.data() })
            })
            setMessages(messages)
        })
    }

    useEffect(() => {
        getMessages()
    }, [sessionId])


    const onEnterPress = (e) => {
        if (e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault()
            handleSubmit(e)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (loading) return
        // Validations
        if (!currentPrompt) {
            setError('All fields are required')
            return
        }
        setLoading(true)

        // Run mutation
        createResponseForPrompt({
            variables: {
                input: {
                    prompt: currentPrompt,
                    sessionId: sessionId
                },
            },
        }).then(({ data }) => {
            if (data.createResponseForPrompt.ok && data.createResponseForPrompt.sessionId) {
                if (data.createResponseForPrompt.sessionId !== sessionId) {
                    setSessionId(data.createResponseForPrompt.sessionId)
                    // Redirect to new session
                    window.location.href = `/chatbot/${data.createResponseForPrompt.sessionId}/`
                    return
                }
                setSessionId(data.createResponseForPrompt.sessionId)
                setCurrentPrompt('')
                getMessages().then(() => {
                    setError(null)
                    setLoading(false)
                    // Scroll to bottom
                    const chatMessages = document.querySelector('.ChatBotSessionMessages')
                    chatMessages.scrollTop = chatMessages.scrollHeight

                })
            } else {
                let errors = []
                if (data && data.createResponseForPrompt && data.createResponseForPrompt.errors && data.createResponseForPrompt.errors.length > 0 && data.createResponseForPrompt.errors[0].messages) {
                    errors = data.createResponseForPrompt.errors[0].messages.toString()
                } else {
                    errors = 'An error occurred. Please try again.'
                }

                setError(errors)
                setLoading(false)
            }
        })
    }

    const voteForResponse = (responseId, quality) => {
        // Update firebase
        if (!responseId) return
        if (!quality) return
        const docRef = doc(firestoreDb, CHATBOT_SESSION_MESSAGES_COLLECTION_NAME, responseId)
        updateDoc(docRef, {
            quality: quality
        })
    }



    return (
        <div>
            <div className="ChatBotSession">
                <div className="ChatBotSessionBody">
                    {error && <div className="alert alert-danger">{error}</div>}
                    <div className="ChatBotSessionMessages">
                        {messages && messages.length > 0 && messages.map((msg, idx) => (
                            <div className={`ChatBotMessage ${msg.sender === "bot" ? "ChatBotMessageBot" : "ChatBotMessageUser"}`} key={idx}>
                                <div className="ChatBotSessionMessageContent">
                                    {msg.message && <ReactMarkdown source={msg.message.replaceAll("\n", " ")} />}
                                </div>
                                {msg.sender === "bot" && <div className="ChatBotSessionMessageActions" >
                                    <i
                                        onClick={() => voteForResponse(msg.id, "good")}
                                        className={`fa fa-thumbs-up ${msg.quality === "good" ? "active" : ""}`} aria-hidden="true">
                                    </i>
                                    <i
                                        onClick={() => voteForResponse(msg.id, "bad")}
                                        className={`fa fa-thumbs-down ${msg.quality === "bad" ? "active" : ""}`} aria-hidden="true">
                                    </i>
                                </div>}

                            </div>
                        ))}
                    </div>
                    <div className="ChatBotSessionInput">
                        <form onSubmit={handleSubmit} className="">
                            <textarea type="text" rows="3" name="body" onKeyDown={onEnterPress} placeholder="Ask a question..." value={currentPrompt} onChange={(e) => setCurrentPrompt(e.target.value)} />
                            <button type="submit" className="btn btn-primary" disabled={loading}>{loading ? 'Sending...' : 'Send'}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default compose(
    withApollo,
    graphql(CREATE_RESPONSE_FOR_PROMPT, { name: 'createResponseForPrompt' }),
)(ChatBotSession)
