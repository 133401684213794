import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, graphql, withApollo } from 'react-apollo'
import { Container, Col, Row, Label, Input, FormGroup, Alert, UncontrolledTooltip } from 'reactstrap'
import Loader from "../../../Material/Loader"
import moment from "moment"
import UpdateUser from "../UpdateUser"
import { AllGroups, AllPermissions, AllBranches, UserPermissions } from "../../Queries"
import Button from "reactstrap/lib/Button"
import "./OverviewTab.css"
import { HasPermissionsQuery } from "../../../Functions/querys"

const UpdateUserMutation = gql`
mutation UpdateUser($input: UpdateUserMutationInput!){
    updateUser(input:$input){
        ok
        errors{
            messages
        }
    }
} `
const MembersDetailQuery = gql`query Member($id: ID!){
    member(id: $id) {
      id
      type
      dateAdded
      dateModified
      selectedBranches {
        edges {
          node {
            id
            name
          }
        }
      }
      tenant {
        id
        name
      }
      user {
        id
        username
        email
        firstName
        lastName
        isActive
        isStaff
        lastLogin
        dateJoined
        groups {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      branches {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }  
`

class OverviewTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            loading: false,
            openModal: "",
            assignedGroups: [],
            assignedPermissions: [],
            assignedBranches: [],
            selectedBranchIds: [],
            groupSearchTerm: "",
            permissionSearchTerm: "",
            branchSearchTerm: "",
            selectedBranchSearhTerm: "",
            disableUpdateBtn: true,
            input: {},
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    updateUser = () => {
        if (this.props.member && this.props.member.user && this.props.member.user.id) {
            this.setState({ loading: true })
            let input = this.state.input
            input["userId"] = this.props.member && this.props.member.user && this.props.member.user.id
            if (this.state.assignedGroups)
                input["groups"] = this.state.assignedGroups.map(item => { return item.value })
            if (this.state.assignedPermissions)
                input["userPermissions"] = this.state.assignedPermissions.map(item => { return item.value })
            if (this.state.assignedBranches)
                input["branchIds"] = this.state.assignedBranches.map(item => { return item.value })
            if (this.state.selectedBranchIds)
                input["selectedBranches"] = this.state.selectedBranchIds.map(item => { return item.value })
            this.props.client.mutate({
                mutation: UpdateUserMutation,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updateUser && result.data.updateUser.ok) {
                    this.props.refetchQuery()
                    this.props.refetchUserPermissionsQuery()
                    this.setState({ input: {}, loading: false, error: null })
                } else if (result.data.updateUser && result.data.updateUser.errors) {
                    this.setState({ loading: false, error: String(result.data.updateUser.errors[0].messages) })
                } else {
                    this.setState({ loading: false, error: "An error has occurred. Please check your input or contact admin." })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occurred. Please contact admin." })
            })
        } else {
        }
    }

    handleAdd = (sourceId, stateKey) => {
        const selectedElement = document.getElementById(sourceId)
        if (selectedElement) {
            const selectedOptions = Array.from(selectedElement.options).filter((option) => option.selected).map((option) => ({
                value: option.value,
                label: option.text,
            }))
            const updatedState = [...this.state[stateKey], ...selectedOptions]
            const updatedStateKeys = { [stateKey]: updatedState }
            updatedStateKeys["disableUpdateBtn"] = false

            this.setState(updatedStateKeys)
        } else {
            console.error(`Element with ID ${sourceId} does not exist.`)
        }
    };

    handleRemove = (sourceId, stateKey) => {
        const selectedElement = document.getElementById(sourceId)
        if (selectedElement) {
            const selectedOptions = Array.from(selectedElement.options).filter((option) => option.selected).map((option) => option.value)
            const updatedState = this.state[stateKey].filter((item) => !selectedOptions.includes(item.value))
            const updatedStateKeys = { [stateKey]: updatedState }
            updatedStateKeys["disableUpdateBtn"] = false

            this.setState(updatedStateKeys)
        } else {
            console.error(`Element with ID ${sourceId} does not exist.`)
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props.member !== prevProps.member) {
            let assignedGroupsArr = []
            let assignedBranchesArr = []
            let selectedBranchesArr = []
            if (this.props.member && this.props.member.user && this.props.member.user.groups && this.props.member.user.groups.edges && this.props.member.user.groups.edges.length > 0) {
                this.props.member.user.groups.edges.map((item) =>
                    assignedGroupsArr.push({ value: item.node.id, label: item.node.name })
                )
            }
            if (this.props.member && this.props.member.branches && this.props.member.branches.edges && this.props.member.branches.edges.length > 0) {
                this.props.member.branches.edges.map((item) =>
                    assignedBranchesArr.push({ value: item.node.id, label: item.node.name })
                )
            }
            if (this.props.member && this.props.member.selectedBranches && this.props.member.selectedBranches.edges && this.props.member.selectedBranches.edges.length > 0) {
                this.props.member.selectedBranches.edges.map((item) =>
                    selectedBranchesArr.push({ value: item.node.id, label: item.node.name })
                )
            }

            this.setState({ assignedGroups: assignedGroupsArr, assignedBranches: assignedBranchesArr, selectedBranchIds: selectedBranchesArr })
        }
        if (this.props.userPermissions !== prevProps.userPermissions) {
            let assignedPermissionsArr = []
            if (this.props.userPermissions && this.props.userPermissions.edges && this.props.userPermissions.edges.length > 0) {
                this.props.userPermissions.edges.map((item) =>
                    assignedPermissionsArr.push({ value: item.node.id, label: item.node.name })
                )
            }
            this.setState({ assignedPermissions: assignedPermissionsArr })
        }
    }

    render() {
        let assignedGroupsIds = []
        if (this.state.assignedGroups) {
            assignedGroupsIds = this.state.assignedGroups.map(group => group.value)
        }
        let assignedPermissionIds = []
        if (this.state.assignedPermissions) {
            assignedPermissionIds = this.state.assignedPermissions.map(permission => permission.value)
        }
        let assignedBranchIds = []
        if (this.state.assignedBranches) {
            assignedBranchIds = this.state.assignedBranches.map(assignedBranch => assignedBranch.value)
        }
        let selectedBranchIds = []
        if (this.state.selectedBranchIds) {
            selectedBranchIds = this.state.selectedBranchIds.map(selectedBranch => selectedBranch.value)
        }
        return (
            <Container fluid>
                {this.props.loading || this.state.loading || !this.props.member ? <Loader /> : <>
                    {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                    <Row>
                        <h4>&nbsp;&nbsp;&nbsp;MEMBER INFO</h4>
                        <Col xs={12} className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                <Col xs={3}>
                                    <Label>Date Added</Label>
                                    <Input disabled className="box-object-property-input" value={moment(this.props.member.dateAdded).tz("America/New_York").format('lll')} />
                                </Col>
                                <Col xs={3}>
                                    <Label>Date Modified</Label>
                                    <Input disabled className="box-object-property-input" value={moment(this.props.member.dateModified).tz("America/New_York").format('lll')} />
                                </Col>
                                <Col xs={3}>
                                    <Label>Username</Label>
                                    <Input disabled className="box-object-property-input" value={this.props.member && this.props.member.user && this.props.member.user.username ? this.props.member.user.username : "--"} />
                                </Col>
                                <Col xs={3}>
                                    <Label>Tenant</Label>
                                    <Input disabled className="box-object-property-input" value={this.props.member && this.props.member.tenant && this.props.member.tenant.name ? this.props.member.tenant.name : "--"} />
                                </Col>
                                <Col xs={3}>
                                    <Label>First Name {this.props.hasPermissions && this.props.hasPermissions.includes("change_member") && <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('firstNameEdit')} />}</Label>
                                    <Input id={"firstNameEdit"} disabled className="box-object-property-input" value={this.props.member && this.props.member.user && this.props.member.user.firstName ? this.props.member.user.firstName : "--"} />
                                    {this.state.openModal === 'firstNameEdit' &&
                                        <UpdateUser
                                            userId={this.props.member && this.props.member.user && this.props.member.user.id}
                                            title="Edit First Name"
                                            name="firstName"
                                            value={this.props.member && this.props.member.user && this.props.member.user.firstName && this.props.member.user.firstName}
                                            target="firstNameEdit"
                                            type="text"
                                            open={this.state.openModal === 'firstNameEdit'}
                                            handleClose={() => this.toggleModal("")}
                                            refetchQuery={this.props.refetchQuery}
                                        />
                                    }
                                </Col>
                                <Col xs={3}>
                                    <Label>Last Name {this.props.hasPermissions && this.props.hasPermissions.includes("change_member") && <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('lastNameEdit')} />}</Label>
                                    <Input id={"lastNameEdit"} disabled className="box-object-property-input" value={this.props.member && this.props.member.user && this.props.member.user.lastName ? this.props.member.user.lastName : "--"} />
                                    {this.state.openModal === 'lastNameEdit' &&
                                        <UpdateUser
                                            userId={this.props.member && this.props.member.user && this.props.member.user.id}
                                            title="Edit Last Name"
                                            name="lastName"
                                            value={this.props.member && this.props.member.user && this.props.member.user.lastName && this.props.member.user.lastName}
                                            target="lastNameEdit"
                                            type="text"
                                            open={this.state.openModal === 'lastNameEdit'}
                                            handleClose={() => this.toggleModal("")}
                                            refetchQuery={this.props.refetchQuery}
                                        />
                                    }
                                </Col>
                                <Col xs={3}>
                                    <Label>Email {this.props.hasPermissions && this.props.hasPermissions.includes("change_member") && <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('emailEdit')} />}</Label>
                                    <Input id={"emailEdit"} disabled className="box-object-property-input" value={this.props.member && this.props.member.user && this.props.member.user.email ? this.props.member.user.email : "--"} />
                                    {this.state.openModal === 'emailEdit' &&
                                        <UpdateUser
                                            userId={this.props.member && this.props.member.user && this.props.member.user.id}
                                            title="Edit Last Name"
                                            name="email"
                                            value={this.props.member && this.props.member.user && this.props.member.user.email && this.props.member.user.email}
                                            target="emailEdit"
                                            type="text"
                                            open={this.state.openModal === 'emailEdit'}
                                            handleClose={() => this.toggleModal("")}
                                            refetchQuery={this.props.refetchQuery}
                                        />
                                    }
                                </Col>
                                <Col xs={3}>
                                    <Label>Member Type {this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_edit_member_sensitive_info") && <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('memberTypeEdit')} />}</Label>
                                    <Input id={"memberTypeEdit"} disabled className="box-object-property-input" value={this.props.member && this.props.member.type ? this.props.member.type : "--"} />
                                    {this.state.openModal === 'memberTypeEdit' &&
                                        <UpdateUser
                                            userId={this.props.member && this.props.member.user && this.props.member.user.id}
                                            title="Edit Member Type"
                                            name="memberType"
                                            value={this.props.member && this.props.member.type && this.props.member.type.toLowerCase()}
                                            target="memberTypeEdit"
                                            type="select"
                                            open={this.state.openModal === 'memberTypeEdit'}
                                            handleClose={() => this.toggleModal("")}
                                            refetchQuery={this.props.refetchQuery}
                                        />
                                    }
                                </Col>
                                <Col xs={3}>
                                    <Label id="isStaffEdit">Is Staff {this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_edit_member_sensitive_info") && <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('isStaffEdit')} />}</Label>
                                    {this.state.openModal === 'isStaffEdit' &&
                                        <UpdateUser
                                            userId={this.props.member && this.props.member.user && this.props.member.user.id}
                                            title="Edit Is Staff Status"
                                            name="isStaff"
                                            value={this.props.member.user.isStaff}
                                            target="isStaffEdit"
                                            type="boolean"
                                            open={this.state.openModal === 'isStaffEdit'}
                                            handleClose={() => this.toggleModal("")}
                                            refetchQuery={this.props.refetchQuery}
                                        />
                                    }
                                    <span>YES</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="isStaff"
                                            id="isStaffEdit"
                                            disabled
                                            checked={!this.props.member.user.isStaff}
                                        />
                                        <label className="toggle-switch-label" htmlFor="isStaffEdit">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>NO</span>
                                </Col>
                                <Col xs={3}>
                                    <Label id="isActiveEdit">Is Active {this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_edit_member_sensitive_info") && <i className="fa fa-pencil-square fa-lg" aria-hidden="true" onClick={() => this.toggleModal('isActiveEdit')} />}</Label>
                                    {this.state.openModal === 'isActiveEdit' &&
                                        <UpdateUser
                                            userId={this.props.member && this.props.member.user && this.props.member.user.id}
                                            title="Edit Is Active Status"
                                            name="isActive"
                                            value={this.props.member.user.isActive}
                                            target="isActiveEdit"
                                            type="boolean"
                                            open={this.state.openModal === 'isActiveEdit'}
                                            handleClose={() => this.toggleModal("")}
                                            refetchQuery={this.props.refetchQuery}
                                        />
                                    }
                                    <span>YES</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="isActive"
                                            id="isActiveEdit"
                                            disabled
                                            checked={!this.props.member.user.isActive}
                                        />
                                        <label className="toggle-switch-label" htmlFor="isActiveEdit">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>NO</span>
                                </Col>
                                <Col xs={3}>
                                    <Label>Date Joined</Label>
                                    <Input disabled className="box-object-property-input" value={moment(this.props.member.user.dateJoined).tz("America/New_York").format('lll')} />
                                </Col>
                                <Col xs={3}>
                                    <Label>Last Login</Label>
                                    <Input disabled className="box-object-property-input" value={moment(this.props.member.user.lastLogin).tz("America/New_York").format('lll')} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={6} className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                <Col>
                                    <Row>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>AVAILABLE GROUPS</h4></b></Label>
                                                <Input className="mb-2" name="groupSearchTerm" id="groupSearchTerm" onChange={(e) => this.setState({ groupSearchTerm: e.target.value })} placeholder="Search Groups" />
                                                <Input
                                                    type="select"
                                                    name="selectedGroupIds"
                                                    id="selectedGroupIds"
                                                    multiple
                                                    style={{ height: "300px" }}
                                                >
                                                    {this.props.allGroups && this.props.allGroups.edges && this.props.allGroups.edges.length > 0 && this.props.allGroups.edges.filter(item => !assignedGroupsIds.some(groupId => groupId === item.node.id))
                                                        .filter(item => {
                                                            if (this.state.groupSearchTerm) {
                                                                return item.node.name.toLowerCase().includes(this.state.groupSearchTerm.toLowerCase())
                                                            } else {
                                                                return true
                                                            }
                                                        })
                                                        .map((item) =>
                                                            <option key={item.node.id} value={item.node.id}>{item.node.name}</option>
                                                        )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={2} className="arrow-btn-container">
                                            <Button onClick={() => this.handleAdd("selectedGroupIds", "assignedGroups")} className="arrow-btn" disabled={!(this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_edit_member_sensitive_info"))} id={"group-right-arrow"}>
                                                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                            </Button>
                                            {!(this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_edit_member_sensitive_info")) && <UncontrolledTooltip placement="right" target={"group-right-arrow"}>User don't have enough permission to perform this action</UncontrolledTooltip>}
                                            <br />
                                            <Button onClick={() => this.handleRemove("groups", "assignedGroups")} className="arrow-btn" disabled={!(this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_edit_member_sensitive_info"))} id={"group-left-arrow"}>
                                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                            </Button>
                                            {!(this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_edit_member_sensitive_info")) && <UncontrolledTooltip placement="right" target={"group-left-arrow"}>User don't have enough permission to perform this action</UncontrolledTooltip>}
                                        </Col>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>ASSIGNED GROUPS</h4></b></Label>
                                                <Input
                                                    type="select"
                                                    name="groups"
                                                    id="groups"
                                                    multiple
                                                    style={{ height: "335px" }}
                                                >
                                                    {this.state.assignedGroups && this.state.assignedGroups.map((item) =>
                                                        <option key={item && item.value} value={item && item.value}>{item && item.label}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        {/* Removed assigning permission section on Levi's request */}
                        {/* <Col xs={6} className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                <Col>
                                    <Row>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>AVAILABLE PERMISSIONS</h4></b></Label>
                                                <Input className="mb-2" name="permissionSearchTerm" id="permissionSearchTerm" onChange={(e) => this.setState({ permissionSearchTerm: e.target.value })} placeholder="Search Permissions Ex, Can Delete Car" />
                                                <Input
                                                    type="select"
                                                    name="selectedPermissionIds"
                                                    id="selectedPermissionIds"
                                                    multiple
                                                    style={{ height: "300px" }}
                                                >
                                                    {this.props.allPermissions && this.props.allPermissions.edges && this.props.allPermissions.edges.length > 0 && this.props.allPermissions.edges.filter(item => !assignedPermissionIds.some(permissionIds => permissionIds === item.node.id))
                                                        .filter(item => {
                                                            if (this.state.permissionSearchTerm) {
                                                                return item.node.name.toLowerCase().includes(this.state.permissionSearchTerm.toLowerCase())
                                                            } else {
                                                                return true
                                                            }
                                                        })
                                                        .map((item) =>
                                                            <option key={item.node.id} value={item.node.id}>{item.node.name}</option>
                                                        )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={2} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                            <Button onClick={() => this.handleAdd("selectedPermissionIds", "assignedPermissions")} className="arrow-btn">
                                                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                            </Button>
                                            <br />
                                            <Button onClick={() => this.handleRemove("assignedPermissions", "assignedPermissions")} className="arrow-btn">
                                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                            </Button>
                                        </Col>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>ASSIGNED PERMISSIONS</h4></b></Label>
                                                <Input
                                                    type="select"
                                                    name="assignedPermissions"
                                                    id="assignedPermissions"
                                                    multiple
                                                    style={{ height: "335px" }}
                                                >
                                                    {this.state.assignedPermissions && this.state.assignedPermissions.map((item) =>
                                                        <option key={item && item.value} value={item && item.value}>{item && item.label}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>*/}
                        <Col xs={6} className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                <Col>
                                    <Row>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>AVAILABLE BRANCHES</h4></b></Label>
                                                <Input className="mb-2" name="branchSearchTerm" id="branchSearchTerm" onChange={(e) => this.setState({ branchSearchTerm: e.target.value })} placeholder="Search By Branch Name" />
                                                <Input
                                                    type="select"
                                                    name="branchAccessIds"
                                                    id="branchAccessIds"
                                                    multiple
                                                    style={{ height: "300px" }}
                                                >
                                                    {this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.filter(item => !assignedBranchIds.some(branchIds => branchIds === item.node.id))
                                                        .filter(item => {
                                                            if (this.state.branchSearchTerm) {
                                                                return item.node.name.toLowerCase().includes(this.state.branchSearchTerm.toLowerCase())
                                                            } else {
                                                                return true
                                                            }
                                                        })
                                                        .map((item) =>
                                                            <option key={item.node.id} value={item.node.id}>{item.node.name}</option>
                                                        )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={2} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                            <Button onClick={() => this.handleAdd("branchAccessIds", "assignedBranches")} className="arrow-btn" disabled={!(this.props.hasPermissions && this.props.hasPermissions.includes("change_member"))} id={"av-branches-right-arrow"}>
                                                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                            </Button>
                                            {!(this.props.hasPermissions && this.props.hasPermissions.includes("change_member")) && <UncontrolledTooltip placement="right" target={"av-branches-right-arrow"}>User don't have enough permission to perform this action</UncontrolledTooltip>}
                                            <br />
                                            <Button onClick={() => this.handleRemove("assignedBranchIds", "assignedBranches")} className="arrow-btn" disabled={!(this.props.hasPermissions && this.props.hasPermissions.includes("change_member"))} id={"av-branches-left-arrow"}>
                                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                            </Button>
                                            {!(this.props.hasPermissions && this.props.hasPermissions.includes("change_member")) && <UncontrolledTooltip placement="right" target={"av-branches-left-arrow"}>User don't have enough permission to perform this action</UncontrolledTooltip>}
                                        </Col>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>ASSIGNED BRANCHES</h4></b></Label>
                                                <Input
                                                    type="select"
                                                    name="assignedBranchIds"
                                                    id="assignedBranchIds"
                                                    multiple
                                                    style={{ height: "335px" }}
                                                >
                                                    {this.state.assignedBranches && this.state.assignedBranches.map((item) =>
                                                        <option key={item && item.value} value={item && item.value}>{item && item.label}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={6} className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                <Col>
                                    <Row>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>AVAILABLE SELECTED BRANCHES</h4></b></Label>
                                                <Input className="mb-2" name="selectedBranchSearhTerm" id="selectedBranchSearhTerm" onChange={(e) => this.setState({ selectedBranchSearhTerm: e.target.value })} placeholder="Search By Branch Name" />
                                                <Input
                                                    type="select"
                                                    name="selectedBranchAccessIds"
                                                    id="selectedBranchAccessIds"
                                                    multiple
                                                    style={{ height: "300px" }}
                                                >
                                                    {this.state.assignedBranches && this.state.assignedBranches.filter(item => !selectedBranchIds.some(branchIds => branchIds === item.value))
                                                        .filter(item => {
                                                            if (this.state.selectedBranchSearhTerm) {
                                                                return item.label.toLowerCase().includes(this.state.selectedBranchSearhTerm.toLowerCase())
                                                            } else {
                                                                return true
                                                            }
                                                        })
                                                        .map((item) =>
                                                            <option key={item.value} value={item.value}>{item.label}</option>
                                                        )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={2} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                            <Button onClick={() => this.handleAdd("selectedBranchAccessIds", "selectedBranchIds")} className="arrow-btn" disabled={!(this.props.hasPermissions && this.props.hasPermissions.includes("change_member"))} id={"av-selected-branches-right-arrow"}>
                                                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                            </Button>
                                            {!(this.props.hasPermissions && this.props.hasPermissions.includes("change_member")) && <UncontrolledTooltip placement="right" target={"av-selected-branches-right-arrow"}>User don't have enough permission to perform this action</UncontrolledTooltip>}
                                            <br />
                                            <Button onClick={() => this.handleRemove("selectedBranchIds", "selectedBranchIds")} className="arrow-btn" disabled={!(this.props.hasPermissions && this.props.hasPermissions.includes("change_member"))} id={"av-selected-branches-left-arrow"}>
                                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                            </Button>
                                            {!(this.props.hasPermissions && this.props.hasPermissions.includes("change_member")) && <UncontrolledTooltip placement="right" target={"av-selected-branches-left-arrow"}>User don't have enough permission to perform this action</UncontrolledTooltip>}
                                        </Col>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>SELECTED BRANCHES</h4></b></Label>
                                                <Input
                                                    type="select"
                                                    name="selectedBranchIds"
                                                    id="selectedBranchIds"
                                                    multiple
                                                    style={{ height: "335px" }}
                                                >
                                                    {this.state.selectedBranchIds && this.state.selectedBranchIds.map((item) =>
                                                        <option key={item && item.value} value={item && item.value}>{item && item.label}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-end pr-5"><Button onClick={this.updateUser} className="overview-primary-btn" disabled={this.state.disableUpdateBtn}>UPDATE</Button></Col>
                    </Row>
                </>}
            </Container>
        )
    }
}


export default compose(
    withApollo,
    graphql(HasPermissionsQuery, {
        options: () => ({
            variables: { userPermissions: ["custom_can_edit_member_sensitive_info", "change_member"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true
        }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    }),
    graphql(MembersDetailQuery, {
        options: ({ memberId }) => ({
            variables: { id: memberId },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network',
        }),
        props({ data: { member, loading, refetch, variables } }) {
            return {
                member, loading, variables,
                refetchQuery: () => {
                    return refetch({
                        query: MembersDetailQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { member: fetchMoreResult.member }
                        },
                    })
                },
            }
        }
    }),
    graphql(AllBranches, {
        options: () => ({
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network',
        }),
        props({ data: { allBranches } }) { return { allBranches } }
    }),
    graphql(AllGroups, {
        options: () => ({
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network',
        }),
        props({ data: { allGroups, loading } }) { return { allGroups, allGroupsLoading: loading } }
    }),
    graphql(AllPermissions, {
        options: () => ({
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network',
        }),
        props({ data: { allPermissions, loading } }) { return { allPermissions, loading } }
    }),
    graphql(UserPermissions, {
        options: ({ memberId }) => ({
            variables: { memberId: memberId },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network',
        }),
        props({ data: { userPermissions, loading, refetch, variables } }) {
            return {
                userPermissions,
                refetchUserPermissionsQuery: () => {
                    return refetch({
                        query: UserPermissions,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { userPermissions: fetchMoreResult.userPermissions }
                        },
                    })
                },

            }

        }
    }),
)(OverviewTab)
