import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Popover, PopoverBody, PopoverHeader, Button, FormGroup, Label, Input, Progress, Alert } from 'reactstrap'
import Select from 'react-select'
import { MemberType } from "../Queries"
import moment from "moment"

const UpdateUserMutation = gql`
mutation UpdateUser($input: UpdateUserMutationInput!){
    updateUser(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const dateFormats = [
    { label: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
    { label: 'DD-MM-YYYY', value: 'DD-MM-YYYY' },
    { label: 'MMM D, YYYY', value: 'MMM D, YYYY' },
    { label: 'ddd, MMM D YYYY', value: 'ddd, MMM D YYYY' },
]

const timeFormats = [
    { label: 'h:mm A (12 Hours)', value: 'h:mm A ' },
    { label: 'HH:mm:s A (12 Hours)', value: 'HH:mm:s A' },
    { label: 'H:mm (24 Hours)', value: 'H:mm ' },
    { label: 'H:mm:s (24 Hours)', value: 'H:mm:s ' },
]

class UpdateUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            input: {},
            loading: false
        }
    }

    updateUser = () => {
        if (this.props.userId) {
            this.setState({ loading: true })
            let input = this.state.input
            input["userId"] = this.props.userId
            this.props.client.mutate({
                mutation: UpdateUserMutation,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updateUser && result.data.updateUser.ok) {
                    this.props.refetchQuery()
                    this.props.handleClose()
                    this.setState({ input: {}, loading: false, error: null })
                } else if (result.data.updateUser && result.data.updateUser.errors) {
                    this.setState({ loading: false, error: String(result.data.updateUser.errors[0].messages) })
                } else {
                    this.setState({ loading: false, error: "An error has occurred. Please check your input or contact admin." })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occurred. Please contact admin." })
            })
        } else {
        }
    }


    updateInput = (e) => {
        this.setState({ error: "" })
        if (this.props.userId) {
            let name = e.target.name
            let value = e.target.value
            if (name === "email") {
                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                if (emailRegex.test(value))
                    this.setState({ error: null })
                else
                    this.setState({ error: "Please enter a valid email address." })
            }
            if (e.target.type === "number") {
                if (isNaN(parseFloat(value))) {
                    value = 0
                } else {
                    value = parseFloat(value)
                }
            }
            let input = this.state.input
            input[name] = value
            this.setState({ input: input })
        }
    }

    componentDidMount() {
        if (this.props.type === "boolean") {
            this.setState({ input: { [this.props.name]: this.props.value } })
        }
    }

    render() {
        return (
            <Popover placement={"bottom"} isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose} >
                <PopoverHeader onClick={this.props.handleClose} >{this.props.title} <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                <PopoverBody>
                    {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                    <FormGroup>
                        <Label for={this.props.name}>{this.props.title}</Label>
                        {this.props.name === "memberType" ?
                            <Select
                                className="bos-custom-select" classNamePrefix="bos-select"
                                isLoading={!(this.props.MemberType && this.props.MemberType.values && this.props.MemberType.values.length > 0)}
                                options={this.props.MemberType && this.props.MemberType.values && this.props.MemberType.values.length > 0 ? this.props.MemberType.values.map(item => ({ value: item.description, label: item.name })) : []}
                                defaultValue={this.props.MemberType && this.props.MemberType.values && this.props.MemberType.values.length > 0 && this.props.MemberType.values.filter(item => item.description === this.props.value).map(item => ({ value: item.description, label: item.name }))}
                                placeholder="Select Member Type"
                                onChange={(memberType) => this.setState({ input: { [this.props.name]: memberType.value } })} />
                            : this.props.name === "preferredDateFormat" ?
                                <Select
                                    className="bos-custom-select" classNamePrefix="bos-select"
                                    options={dateFormats.map(item => ({ value: item.value, label: item.label }))}
                                    defaultValue={dateFormats.find(item => item.value === this.props.value)}
                                    placeholder="Select Date Format"
                                    onChange={(preferredDateFormat) => this.setState({ input: { [this.props.name]: preferredDateFormat.value } })} />
                                : this.props.name === "preferredTimeFormat" ?
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        options={timeFormats.map(item => ({ value: item.value, label: item.label }))}
                                        defaultValue={timeFormats.find(item => item.value === this.props.value)}
                                        placeholder="Select Date Format"
                                        onChange={(preferredTimeFormat) => this.setState({ input: { [this.props.name]: preferredTimeFormat.value } })} />
                                    : this.props.type === "boolean" ?
                                        <>
                                            <br />
                                            <div>
                                                <span>Yes</span>
                                                <div className="toggle-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="toggle-switch-checkbox"
                                                        name={this.props.name}
                                                        id={"userInfo"}
                                                        checked={!this.state.input[this.props.name]}
                                                        onChange={() => this.setState({ input: { [this.props.name]: !this.state.input[this.props.name] } })}
                                                    />
                                                    <label className="toggle-switch-label" htmlFor={"userInfo"}>
                                                        <span className="toggle-switch-inner" />
                                                        <span className="toggle-switch-switch" />
                                                    </label>
                                                </div>
                                                <span>No</span>
                                            </div>
                                        </>
                                        : <Input autoComplete={"off"} type={this.props.type} maxLength={this.props.maxLength ? this.props.maxLength : 200}
                                            name={this.props.name} id={this.props.name} defaultValue={this.props.value}
                                            placeholder={this.props.title} onChange={this.updateInput}
                                        />
                        }
                    </FormGroup>
                    {this.state.loading ? <Progress animated color="info" value={100} /> :
                        <Button type="button" size="sm" onClick={this.props.updateUser ? () => this.props.updateUser({ ...this.state.input }) : this.updateUser} disabled={!(this.props.userId) || this.state.error}>Submit</Button>
                    }
                </PopoverBody>
            </Popover>
        )
    }
}

export default compose(
    withApollo,
    graphql(MemberType, {
        options: () => ({
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network',
        }),
        props({ data: { MemberType } }) { return { MemberType } }
    }),
)(UpdateUser)
