import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Alert, Button, Row, Col, Progress, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { CreateMember, UpdateMember, BulkCreateUpdateMember } from "../Mutations"
import { RepsQuery, AllBranches } from "../Queries"
import Select from 'react-select'

class UpdateCreateMemberModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            selectedBranchesIDs: (this.props.action === "delete" || this.props.isUpdate || this.props.isMultiUpdate) && this.props.memberDetails && this.props.memberDetails.node && this.props.memberDetails.node.branches && this.props.memberDetails.node.branches.edges && this.props.memberDetails.node.branches.edges.length > 0 ? this.props.memberDetails.node.branches.edges.map(item => { return item.node.id }) : [],
            // selectedDefaultBranch: this.props.isUpdate && this.props.memberDetails && this.props.memberDetails.node && this.props.memberDetails.node.defaultBranch ? this.props.memberDetails.node.defaultBranch.id : null,
            selectedUser: this.props.isUpdate && this.props.memberDetails && this.props.memberDetails.node && this.props.memberDetails.node.user ? this.props.memberDetails.node.user : this.props.isMultiUpdate ? this.props.memberDetails : [],
            selectedMemberId: (this.props.action === "delete" || this.props.isUpdate) && this.props.memberDetails && this.props.memberDetails.node ? this.props.memberDetails.node.id : [],
            selectedUserType: this.props.isUpdate && this.props.memberDetails && this.props.memberDetails.node ? this.props.memberDetails.node.type : "",
            // globalViewStatus: this.props.isUpdate && this.props.memberDetails && this.props.memberDetails.node && this.props.memberDetails.node.globalView,
            userSuggestions: [],
            openModal: "",
            loading: false
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    };

    // toggleChange = (e) => {
    //     this.setState({ globalViewStatus: e.target.checked })
    // }



    handleSubmit = () => {
        if (this.props.action === "delete" || (this.props.isMultiUpdate && this.state.selectedBranchesIDs) || (this.state.selectedUser && this.state.selectedBranchesIDs && this.state.selectedUserType)) {
            try {
                this.setState({ loading: true })
                let input = {}
                if (this.props.isUpdate && this.props.isMultiUpdate) {
                    input = {
                        // tenantId: "VGVuYW50Tm9kZToz",
                        userByIds: this.state.selectedUser ? this.state.selectedUser : null,
                        branchIds: this.state.selectedBranchesIDs ? this.state.selectedBranchesIDs : null,
                    }
                }
                else {
                    input = {
                        userId: this.props.action != "delete" && this.state.selectedUser && this.state.selectedUser.id ? this.state.selectedUser.id : null,
                        // defaultBranchId: (this.props.action != "delete" && this.state.selectedDefaultBranch) ? this.state.selectedDefaultBranch : "",
                        branchIds: this.props.action != "delete" && this.state.selectedBranchesIDs ? this.state.selectedBranchesIDs : null,
                        type: this.props.action != "delete" && this.state.selectedUserType.toLowerCase() || "internal"
                    }
                    // if (!this.props.isUpdate && this.props.action != "delete")
                    //     input["tenantId"] = "VGVuYW50Tm9kZToz"
                    if (this.props.isUpdate && this.props.action != "delete") {
                        input["memberId"] = this.state.selectedMemberId
                        // input["globalView"] = this.props.action != "delete" && this.state.globalViewStatus
                    }
                    if (this.props.action === "delete") {
                        input["memberId"] = this.state.selectedMemberId
                        input["branchIdsToRemove"] = this.props.branchId ? this.props.branchId : this.state.selectedBranchesIDs ? this.state.selectedBranchesIDs : null
                    }
                }

                this.props.client.mutate({
                    mutation: (this.props.isUpdate || this.props.action === "delete") ? this.props.isMultiUpdate ? BulkCreateUpdateMember : UpdateMember : CreateMember,
                    variables: { input },
                }).then((result) => {
                    if (result.data && ((this.props.isUpdate && result.data.updateMember && result.data.updateMember.ok) || (!this.props.isUpdate && result.data.createMember && result.data.createMember.ok))) {
                        this.props.refetchQuery()
                        this.setState({ loading: false, error: null })
                    } else {
                        let errorMessage = "An error occurred, could not complete request."
                        if (!this.props.isUpdate && result.data && result.data.createMember && result.data.createMember.errors && result.data.createMember.errors[0] && result.data.createMember.errors[0].messages[0])
                            errorMessage = result.data.createMember.errors[0].messages[0]
                        if ((this.props.isUpdate || this.props.action === "delete") && result.data && result.data.updateMember && result.data.updateMember.errors && result.data.updateMember.errors[0] && result.data.updateMember.errors[0].messages[0])
                            errorMessage = result.data.updateMember.errors[0].messages[0]
                        this.setState({ loading: false, error: errorMessage })
                    }
                    this.props.refetchQuery()
                    this.props.handleClose()
                }).catch((error) => {
                    let errorMessage = "An error has occured " + error
                    this.setState({ error: errorMessage, loading: false })
                })
            }
            catch (error) {
                let errorMessage = "An error has occured " + error
                this.setState({ error: errorMessage, loading: false })
            }
        } else {
            this.setState({ error: "Error: Required fields are missing" })
        }
    }

    updateUserSearchInput = (e) => {
        if (e.target.value.length >= 2) {
            let variables = {}
            let value = e.target.value
            if (value.includes('@')) {
                variables['email'] = value
            } else {
                variables['username'] = value
            }
            this.props.client.query({
                query: RepsQuery,
                variables: variables
            }).then((response) => {
                if (response && response.data && response.data.reps && response.data.reps.edges) {
                    this.setState({ userSuggestions: response.data.reps.edges })
                } else {
                    this.setState({ userSuggestions: null })
                }
            }).catch((err) => {
                this.setState({ userSuggestions: null })
            })
        } else {
            this.setState({ userSuggestions: null })
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} >
                <ModalHeader >{(this.props.isUpdate || this.props.action === "delete") ? this.props.action === "delete" ? "Remove Branch" : "Update Member" : "Add Member"}</ModalHeader>
                <ModalBody>
                    {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                    {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}

                    {this.props.action != "delete" ?
                        <>
                            {!this.props.isMultiUpdate &&
                                <Row>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <Label>{this.state.selectedUser ? `Selected user: ${this.state.selectedUser.username} · ${this.state.selectedUser.email}` : "No user selected"}</Label><br />
                                            {!this.props.isUpdate &&
                                                <ButtonDropdown className="w-100" isOpen={this.state.openModal === "AddMemberUserFinder"} toggle={() => { this.toggleModal("AddMemberUserFinder") }}>
                                                    <DropdownToggle caret>Choose user to add</DropdownToggle>
                                                    <DropdownMenu className='w-100 px-2'>
                                                        <Label for="userSearch">Search by username or email</Label>
                                                        <Input type="text" onChange={this.updateUserSearchInput} name="userSearch" id="userSearch" />
                                                        {this.state.userSuggestions && this.state.userSuggestions.length > 0 && this.state.userSuggestions.map((user, i) =>
                                                            <DropdownItem key={i} style={{ display: "content" }} onClick={() => this.setState({ selectedUser: user.node })}>{user.node.username} · {user.node.email} {user.node.name}</DropdownItem>
                                                        )}
                                                    </DropdownMenu>
                                                </ButtonDropdown>
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <Label for="notes">User Type</Label>
                                            <Select
                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                defaultValue={this.props.isUpdate && this.props.memberDetails && this.props.memberDetails.node && this.props.memberDetails.node ? ({ value: this.props.memberDetails.node.type, label: this.props.memberDetails.node.type + "  USER" }) : null}
                                                options={[{ value: "internal", label: "INTERNAL USER" }, { value: "external", label: "EXTERNAL USER" }]}
                                                placeholder="Select User Type"
                                                isClearable
                                                onChange={(type) => this.setState({ selectedUserType: type.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {/* <Col xs={6}>
                                        <FormGroup className="mb-0">
                                            <Label for="notes">Default Branch</Label>
                                            <Select
                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                defaultValue={this.props.isUpdate && this.props.memberDetails && this.props.memberDetails.node && this.props.memberDetails.node.defaultBranch ? ({ value: this.props.memberDetails.node.defaultBranch.id, label: this.props.memberDetails.node.defaultBranch.name }) : null}
                                                options={this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                                placeholder="Select Default Branch"
                                                isClearable
                                                onChange={(branch) => this.setState({ selectedDefaultBranch: branch.value })}
                                            />
                                        </FormGroup>
                                    </Col> */}
                                </Row>
                            }
                            <Row>
                                <Col xs={12}>
                                    <FormGroup className="mb-0">
                                        <Label for="notes">User Branches</Label>
                                        <Select
                                            className="bos-custom-select" classNamePrefix="bos-select"
                                            defaultValue={this.props.isUpdate && this.props.memberDetails && this.props.memberDetails.node && this.props.memberDetails.node.branches && this.props.memberDetails.node.branches.edges && this.props.memberDetails.node.branches.edges.length > 0 && this.props.memberDetails.node.branches.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                            options={this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                            placeholder="Select Other Branches"
                                            isClearable
                                            isMulti
                                            onChange={(branches) => this.setState({ selectedBranchesIDs: (branches.map(item => item.value)) })}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/* <br></br>
                            {this.props.isUpdate && !this.props.isMultiUpdate &&
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="notes">Global View</Label>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Input
                                                type="checkbox"
                                                defaultChecked={this.state.globalViewStatus}
                                                onChange={this.toggleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            } */}
                        </> : this.props.branchId ? "Are you sure you want to remove this member?" : "Are you sure you want to remove all the branches for this member?"
                    }
                </ModalBody>
                <ModalFooter>
                    {this.props.action === "delete" ?
                        <Button className="float-left" color="danger" onClick={() => this.handleSubmit()}>Remove</Button> :
                        <Button color="primary" onClick={() => this.handleSubmit()}>{this.props.isUpdate ? "Update" : "Add"}</Button>
                    }
                    <Button color="secondary" onClick={() => { this.props.handleClose() }}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllBranches, { props({ data: { allBranches } }) { return { allBranches } } }),
)(UpdateCreateMemberModal)
