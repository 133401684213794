import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import {
    Row, Col, Alert, Progress, Label, Button, FormGroup,
    Input, Modal, ModalHeader, ModalBody, ModalFooter, FormText,
    UncontrolledTooltip
} from 'reactstrap'
import moment from 'moment-timezone'
import { HasPermissionsQuery } from "../Functions/querys"

const CarIssueQuery = gql`query carIssue($id: ID!){
    carIssue(id: $id){
        id
        status
        issueCategory{
            id
            name:category
            requiredClosingProof
        }
        car{
            id
            pk
        }
    } 
}`
const UpdateCarIssueMutation = gql`mutation UpdateCarIssueMutation($input: UpdateCarIssueMutationInput!){
    updateCarIssue(input:$input){
        ok
        errors{
            messages
        }
    }
} `

class CloseCarIssue extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            document: null,
            issueFixDate: null,
        }
    }
    handleClose = (e) => {
        //Reset state
        let state = {
            loading: false,
            error: null,
            document: null,
        }
        this.setState({ ...state })
        this.props.handleClose()
    };
    updateCarIssue = () => {
        if (this.props.carIssue && this.props.carIssue.id) {
            try {
                this.setState({ loading: true })
                let input = {}
                input["issueId"] = this.props.carIssue.id
                input["issueFixDate"] = this.state.issueFixDate
                input["status"] = "Closed"
                if (this.state.document) {
                    input["pictureProof"] = this.state.document
                }
                this.props.client.mutate({
                    mutation: UpdateCarIssueMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateCarIssue && result.data.updateCarIssue.ok) {
                        this.props.refetchQuery()
                        this.handleClose()
                    } else {
                        this.setState({ loading: false, error: "An error has occured. Please try again or contact admin." })
                    }
                }).catch((err) => {
                    this.setState({ loading: false, error: "An error has occured. Please try again or contact admin." })
                })
            } catch (err) {
                this.setState({ loading: false, error: "An error has occured. Please try again or contact admin." })
            }
        } else {
        }
    }
    updateInput = (e) => {
        if (e.target && e.target.value) {
            let name = e.target.name
            let value = e.target.value
            // To change string inputs to boolean because only strings can be passed to html options
            if (e.target.dataset.type && e.target.dataset.type === "boolean") {
                if (value === "false") {
                    value = false
                } else {
                    value = true
                }
            }
            this.setState({ [name]: value })
        }
    };
    updateDocument = (e) => {
        let base64Img = e.target.files[0]
        let name = e.target.files[0].name
        let _this = this
        var reader = new FileReader()
        reader.readAsDataURL(base64Img)
        reader.onload = function (e) {
            let imageInBase64 = reader.result
            imageInBase64 = imageInBase64.substring(imageInBase64.indexOf(',') + 1)
            _this.setState({ document: imageInBase64, fileName: name })
        }
    }
    render() {
        return (
            <div>
                <Modal className="closeIssue" isOpen={this.props.open}>
                    {this.props.carIssue && this.props.carIssue.car && this.props.carIssue.car.pk &&
                        <>
                            <ModalHeader>Close Issue For Car <strong>{this.props.carIssue.car.pk}</strong></ModalHeader>
                            <ModalBody>
                                {!this.state.document ?
                                    <Row><Col><Alert color="info">Please include a picture reciept or document verifying that this issue was fixed.</Alert></Col></Row> :
                                    (!this.state.issueFixDate || moment().tz("America/New_York").format(moment.HTML5_FMT.DATE) < moment(this.state.issueFixDate).tz("America/New_York").format(moment.HTML5_FMT.DATE)) && <Row><Col><Alert color="info">Also, please include a correct date.<br /><small>Date should be less than today and in the last three months</small></Alert></Col></Row>
                                }
                                {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                                <Row>
                                    {this.props.carIssue && this.props.carIssue.issueCategory && this.props.carIssue.issueCategory.requiredClosingProof && <Col xs={12}>
                                        <FormGroup>
                                            <Label for="document"> </Label>
                                            <Input type="file" name="document" id="document" onChange={this.updateDocument} />
                                            <FormText color="muted">
                                                Accepted files include pdf,jpg,jpeg and pngs
                                            </FormText>
                                        </FormGroup>
                                    </Col>}
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label for="issueFixDate">When was the {this.props.carIssue.issueCategory ? this.props.carIssue.issueCategory.name : "fix"} done</Label>
                                            <Input type="date" min={moment().subtract(4, 'months').tz("America/New_York").format(moment.HTML5_FMT.DATE)} max={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} name="issueFixDate" value={this.state.issueFixDate} onChange={this.updateInput} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                            </ModalBody>
                        </>}
                    <ModalFooter>
                        {!this.state.loading &&
                            <Row>
                                <Col xs={12}>
                                    <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    <Button color="secondary" id={"closeIssue"} onClick={this.updateCarIssue} disabled={this.props.hasPermissions && !this.props.hasPermissions.includes("custom_can_close_car_issue") || ((this.props.carIssue && this.props.carIssue.issueCategory && this.props.carIssue.issueCategory.requiredClosingProof) && !this.state.document) || this.state.loading || !this.state.issueFixDate || moment().tz("America/New_York").format(moment.HTML5_FMT.DATE) < moment(this.state.issueFixDate).tz("America/New_York").format(moment.HTML5_FMT.DATE)}>Close Issue</Button>&nbsp;&nbsp;
                                    {this.props.hasPermissions && !this.props.hasPermissions.includes("custom_can_close_car_issue") && <UncontrolledTooltip target="closeIssue">Not enough permissions to perform this action</UncontrolledTooltip>}
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(CarIssueQuery, {
        options: ({ id }) => ({ variables: { id } }),
        props({ data: { carIssue, loading } }) {
            return { carIssue }
        }
    }),
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["custom_can_close_car_issue"] } }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    })
)(CloseCarIssue)