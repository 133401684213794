import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import {
    Popover, PopoverBody, PopoverHeader, Button, FormGroup, Label, Input, Progress
} from 'reactstrap'
import Alert from 'reactstrap/lib/Alert'
import toTitleCase from "../Functions/toTitleCase"

const UpdateAgreement = gql`
mutation UpdateAgreement($input:  UpdateAgreementMutationInput!){
    updateAgreement(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateAgreementDeposit = gql`
mutation UpdateAgreementDeposit($input:  UpdateAgreementDepositMutationInput!){
    updateAgreementDeposit(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`
const AgreementDepositScheme = gql`query AgreementDepositScheme{
    __type(name: "AgreementDepositScheme") {
      enumValues {
          name
          description
      }
    }
}`

class UpdateAgreementInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            input: this.props.value ? (typeof this.props.value === "object" ? this.props.value : { [this.props.name]: this.props.value }) : {},
            loading: false
        }
    }
    updateAgreementDeposit = () => {
        try {
            if (this.state.input.depositScheme) {
                this.setState({ loading: true })
                let input = this.state.input
                if (input.depositScheme !== "Fixed") {
                    input.deposit = null
                }
                this.props.client.mutate({
                    mutation: UpdateAgreementDeposit,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateAgreementDeposit && result.data.updateAgreementDeposit.ok) {
                        this.props.refetchQuery()
                        this.setState({ input: {}, loading: false, error: null })
                        this.props.handleClose()
                    } else {
                        const msg = result && result.data && result.data.updateAgreementDeposit && result.data.updateAgreementDeposit.errors && result.data.updateAgreementDeposit.errors.length > 0 && result.data.updateAgreementDeposit.errors[0].messages ? result.data.updateAgreementDeposit.errors[0].messages.join(",") : "An error has occurred. Please contact admin."
                        this.setState({ error: msg, loading: false })
                    }
                }).catch((err) => {
                    this.setState({ error: "An error has occurred. Please contact admin.", loading: false })
                })
            } else {
                this.setState({ error: "Please provide input values", loading: false })
            }
        } catch (err) {
            this.setState({ error: "An error has occurred. Please contact admin.", loading: false })
        }
    }
    updateAgreement = () => {
        try {
            if (this.state.input.agreement) {
                this.setState({ loading: true })
                let input = this.state.input
                this.props.client.mutate({
                    mutation: UpdateAgreement,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateAgreement && result.data.updateAgreement.ok) {
                        this.props.refetchQuery()
                        this.setState({ input: {}, loading: false, error: null })
                        this.props.handleClose()
                    } else {
                        const msg = result && result.data && result.data.updateAgreement && result.data.updateAgreement.errors && result.data.updateAgreement.errors.length > 0 && result.data.updateAgreement.errors[0].messages ? result.data.updateAgreement.errors[0].messages.join(",") : "An error has occurred. Please contact admin."
                        this.setState({ error: msg, loading: false })
                    }
                }).catch((err) => {
                    this.setState({ error: "An error has occurred. Please contact admin.", loading: false })
                })
            } else {
            }
        } catch (err) {
            this.setState({ error: "An error has occurred. Please contact admin.", loading: false })
        }

    }
    updateInput = (e) => {
        if (this.props.agreementId) {
            let name = e.target.name
            let value = e.target.value
            if (e.target.type === "number" && isNaN(parseFloat(value))) {
                value = 0
            }
            let input = this.state.input
            input["agreement"] = this.props.agreementId
            input[name] = value
            if (name == "stage") {
                input["notes"] = ""
            }
            let error = (this.props.name === "stage" && (!this.state.input.notes || this.state.input.notes.length < 5)) ? 'Please add a reason for this change' : ''
            this.setState({ input: input, error: error })
        }
    }
    render() {
        return (
            <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                <PopoverHeader onClick={this.props.handleClose}>{this.props.title} &nbsp;&nbsp; <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                <PopoverBody>
                    {this.state.error && <Alert color="danger">{this.state.error}</Alert>}

                    {this.props.name === "stage" ?
                        <>
                            <FormGroup>
                                <Label for={this.props.name}>{this.props.title}</Label>
                                <Input type="select" name="stage" id="stage" onChange={this.updateInput} placeholder="Select Stage">
                                    <option value={""}>--Select New Stage--</option>
                                    {[['Active', 10], ['Canceled', -1]].filter(i => i[0] !== this.props.value).map((item, i) =>
                                        <option value={item[1]}>{item[0]}</option>
                                    )}
                                </Input>
                            </FormGroup>
                            {this.props.returnStage && ["A_3", "A_4"].includes(this.props.returnStage) && this.state.input && this.state.input["stage"] == 10 ?
                                <FormGroup>
                                    <Label for="notes">Why are you changing the stage?</Label>
                                    <Input type="select" name="notes" id="notes" onChange={this.updateInput}>
                                        <option value={""}>--Select Reason--</option>
                                        {this.props.returnStage === "A_3" ?
                                            <>
                                                <option value={"Wrong Car Returned"}>Wrong Car Returned</option>
                                                <option value={"Damage Added Mistakenly"}>Damage Added Mistakenly</option>
                                                <option value={"False Return"}>False Return</option>
                                            </> :
                                            <option value={"Re approved and keeping the car"}>Re approved and keeping the car</option>
                                        }
                                    </Input>
                                </FormGroup> :
                                <FormGroup>
                                    <Label for="notes">Why are you changing the stage</Label>
                                    <Input type="text" name="notes" id="notes" placeholder="Change reason" onChange={this.updateInput} />
                                </FormGroup>
                            }
                        </>
                        : this.props.name === "deposit" ?
                            <>
                                <FormGroup>
                                    <Label for="depositScheme">Deposit Scheme</Label>
                                    {this.props.agreementDepositSchemes && this.props.agreementDepositSchemes.enumValues && <Input type="select" name="depositScheme" id="depositScheme" defaultValue={this.props.value && this.props.value.depositScheme ? this.props.value.depositScheme : null} placeholder="Change Deposit Scheme" onChange={this.updateInput}>
                                        {this.props.agreementDepositSchemes.enumValues.map(item => <option value={item.description}>{item.description}</option>)}
                                    </Input>}
                                </FormGroup>
                                {this.state.input && this.state.input.depositScheme && this.state.input.depositScheme === "Fixed" && <FormGroup>
                                    <Label for="deposit">Deposit Amount</Label>
                                    <Input type="number" name="deposit" id="deposit" onChange={this.updateInput} placeholder="Enter Deposit Amount" />
                                </FormGroup>}
                            </> :
                            <FormGroup>
                                <Label for={this.props.name}>{this.props.title}</Label>
                                <Input type={this.props.type} maxLength={this.props.maxLength ? this.props.maxLength : 200} name={this.props.name} defaultValue={this.props.value && this.props.value.deposit ? parseFloat(this.props.value.deposit) : this.props.value} id={this.props.name} placeholder={this.props.title} onChange={this.updateInput} />
                            </FormGroup>
                    }
                    {this.state.loading ? <Progress animated color="info" value={100} /> : (this.props.name === "deposit" ?
                        <Button disabled={(this.state.loading || !this.props.agreementId) ? true : false} type="button" size="sm" onClick={this.updateAgreementDeposit}>Update Deposit</Button> :
                        <Button disabled={(this.state.loading || !this.props.agreementId || (this.props.name === "stage" && (!this.state.input.notes || this.state.input.notes.length < 5))) ? true : false} type="button" size="sm" onClick={this.updateAgreement}>Submit</Button>)
                    }
                </PopoverBody>
            </Popover>
        )
    }
}

export default compose(
    withApollo,
    graphql(AgreementDepositScheme, { props({ data: { __type: agreementDepositSchemes } }) { return { agreementDepositSchemes } } }),
)(UpdateAgreementInfo)