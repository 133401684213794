import React, { Component } from 'react'
import { Container, } from 'reactstrap'
import BranchList from '../../BranchMangement/BranchList'
import '../../BranchMangement/BranchListContainer.css'
import RestrictedSection from "../../../Components/RestrictedSection"

class BranchTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            orderBy: ["name"],
            filterValues: {

            }
        }
    }

    updateOrder = (string) => {
        let order = [...this.state.orderBy]
        let index = order.indexOf(string)
        if (index > -1) {
            order[index] = "-" + string
        } else {
            index = order.indexOf("-" + string)
            index > -1 ? order.splice(index, 1) : order.unshift(string)
        }
        this.setState({ orderBy: order })
    };

    render() {
        return (
            <RestrictedSection requiredPermission="view_branch">
                <Container fluid className="bos-listing-container" >
                    <BranchList
                        currentUser={this.props.currentUser}
                        searchTerm={null}
                        stateCode={null}
                        tenant={this.props.tenantId}
                        city={null}
                        orderBy={this.state.orderBy}
                        updateOrder={this.updateOrder}
                    />
                </Container>
            </RestrictedSection>
        )
    }
}

export default BranchTab
