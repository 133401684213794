import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Button, Popover, PopoverHeader, PopoverBody, Table, UncontrolledTooltip, Row, Col, Card, CardBody } from 'reactstrap'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { ReactComponent as LocationIcon } from '../assets/images/icons/location-icon.svg'
import CarModelGroupIcon from '../CA/CarModelGroupIcon'
import toTitleCase from '../Functions/toTitleCase'
import CarNotes from '../Material/CarNotes'
import CarInfoModal from '../CA/CarInfoModal'
import UpdateCarDeal from './UpdateCarDeal'
import renderEnumToInt from '../Functions/renderEnumToInt'
import CarRepAssignmentModal from '../Material/CarRepAssignmentModal'
import moment from 'moment'
import gql from 'graphql-tag'
import './CarDeals.css'
import PrimaryListingContainer from "../Material/PrimaryListingContainer"
import { HasPermissionsQuery } from "../Functions/querys"

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={16}
        defaultCenter={{ lat: props.latitude, lng: props.longitude }}
    >
        {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
    </GoogleMap>
))

const AllTitleOwnerQuery = gql`query AllTitleOwnerQuery{
    allTitleOwners: allCarDealEntities(isActive:true, entityType: "Title Owner"){
        edges {
            node {
                id
                pk
                name
                entityType
                isActive
            }
        }
    }
}`

const CarDealsQuery = gql`query CarDealsQuery($first:Int, $orderBy: [String], $cursor: String, $searchTerm: String, $stageIn:[String], $dealTypeIn:[String], $assignedToIn:[ID], $startDate:Date, $endDate:Date, $carsInFleet:Boolean) {
    carDeals: allCarDeals(first:$first, orderBy:$orderBy, after: $cursor, searchTerm:$searchTerm, car_Stage_In:$stageIn, dealType_In:$dealTypeIn, assignedToIn:$assignedToIn, startDate:$startDate, endDate:$endDate, carsInFleet:$carsInFleet){
      edges{
        cursor
        node{
            id
            dateAdded
            dateModified
            dealType
            buyer{
                id
                name
                entityType
            }
            seller{
                id
                name
                entityType
            }
            carPrice
            eventDate
            metadata
            car{
                id
                pk
                year
                carLocation{
                    name
                }
                color
                notes
                stage
                carModel{
                    make
                    name
                    series
                    groupType{
                        name
                    }
                }
                assignedTo
                carpolicySet{
                    edges{
                        node{
                            id
                            status
                            insurancePolicy{
                                id
                                startDate
                                endDate
                                status
                                policyNumber
                                broker{
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
                currentAgreement{
                    driver{
                        id
                        tlcLicense
                        name
                    }
                }
            }
        }
      }
      pageInfo{
          endCursor
          hasNextPage
          length
      }
    } 
}`

const locateCar = gql`
    mutation locateCar($input: TrackerStatusChangeMutationInput!){
        changeTrackerStatus(input: $input){
            ok
            errors{
                field
                messages
            }
            car {
                id
                pk
                trackerStatus
                hasTracker
            }
            location{
                address
                longitude
                latitude
                speed
                date
            }
        }
    }`

const tableHeaders = [
    { id: "Deal", name: "Deal Details", sortable: false },
    { id: "title_owner", name: "Title Owner" },
    { id: "delivery_disposal_date", name: "Delivery Date" },
    { id: "id", name: "Car", sortable: true },
    { id: "location", name: "Car Location", sortable: true }, { id: "assigned_to", name: "Assigned To", sortable: false }, { id: "notes", name: "Deal Notes", sortable: false },
    { id: "action", name: "Actions", sortable: false }
]

class DealsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            location: null,
            loading: false,
            error: "",
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    locateCar = (carId, modalName) => {
        if (carId) {
            this.setState({ loading: true })
            let input = { carId: carId, command: 'Locate' }
            let location = { address: "", longitude: "", latitude: "", speed: "", date: "" }

            this.props.locate(input).then(result => {
                this.setState({ changingStatus: false })
                if (result.data.changeTrackerStatus.location) {
                    location = result.data.changeTrackerStatus.location
                    location = {
                        address: location.address, longitude: Number(location.longitude), latitude: Number(location.latitude), speed: location.speed, date: location.date
                    }
                    this.setState({ location: location, loading: false, openModal: modalName })
                } else {
                    this.setState({ error: "An error occured while retrieving the location", openModal: modalName, loading: false })
                }
            }).catch(error => {
                this.setState({ error: "An error occured while retrieving the location", loading: false })
            })
        }
    }

    fetchMetaData = (metadata = null, name) => {
        if (!metadata || metadata === "")
            return
        try {
            metadata = JSON.parse(metadata.replace(/'/g, '"'))
        } catch {
            metadata = null
        }
        return metadata && metadata[name] ? metadata[name] : null
    }

    render() {
        return (

            <PrimaryListingContainer listLoading={this.props.loading || this.state.loading} totalFetchedRows={this.props.carDeals && this.props.carDeals.edges.length} pageInfo={this.props.carDeals && this.props.carDeals.pageInfo} loadMoreEntries={this.props.carDeals && this.props.carDeals.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchQuery}>
                <Table responsive>
                    <thead>
                        <tr>
                            <th onClick={this.toggleSelectAllClick} style={{ cursor: "pointer" }}> {this.state.selectAll ? <i className="fa fa-plus-square" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
                            {tableHeaders.filter(item => (!["delivery_disposal_date", "title_owner"].includes(item.id) && this.props.dealType === "SELLING") || this.props.dealType === "BUYING").map(tableHeader => tableHeader.sortable ?
                                <th key={tableHeader.id} onClick={() => this.props.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{tableHeader.name}</th> :
                                <th key={tableHeader.id}>{tableHeader.name}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.carDeals && this.props.carDeals.edges && this.props.carDeals.edges.length > 0 ? this.props.carDeals.edges.map((deal, i) =>
                            <tr>
                                <td scope="row">{i + 1}</td>
                                <td>
                                    <div className="bos-table-td-left-box">
                                        <UncontrolledTooltip target={"editCarDeal" + i}>Use FleetSync To Edit</UncontrolledTooltip>
                                        <i id={"editCarDeal" + i} className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </div>

                                    <div className="bos-table-td-right-box">
                                        <small>{deal.node.eventDate ? deal.node.eventDate : "--"} | ${deal.node.carPrice ? deal.node.carPrice : "--"} | {this.fetchMetaData(deal.node.metadata, "created_by")}</small>
                                        {deal.node.dealType && deal.node.dealType === "SELLING" ?
                                            <div><b>{deal.node.seller ? deal.node.seller.name : "--"}&nbsp;&nbsp;<i className="fa fa-long-arrow-right fa-lg" style={{ color: '#FFAA32' }} aria-hidden="true" />&nbsp;&nbsp;{deal.node.buyer ? deal.node.buyer.name : "--"}</b></div> :
                                            <div><b>{deal.node.buyer ? deal.node.buyer.name : "--"}&nbsp;&nbsp;<i className="fa fa-long-arrow-left fa-lg" style={{ color: '#28a745' }} aria-hidden="true" />&nbsp;&nbsp;{deal.node.seller ? deal.node.seller.name : "--"}</b></div>
                                        }
                                        <small>Date Added: {deal.node.dateAdded ? moment(deal.node.dateAdded).tz("America/New_York").format('ll') : "--"}</small>
                                    </div>
                                </td>
                                {deal.node.dealType && deal.node.dealType === "BUYING" &&
                                    <td>
                                        {(() => {
                                            let metaObject = JSON.parse(deal.node.metadata.replace(/'/g, '"'))
                                            return metaObject && metaObject.titleOwner && this.props.allTitleOwners && this.props.allTitleOwners.edges && this.props.allTitleOwners.edges.length > 0 ? toTitleCase(this.props.allTitleOwners.edges.find(item => item.node.pk == metaObject.titleOwner).node.name) : "--"
                                        })()}
                                    </td>
                                }
                                {deal.node.dealType && deal.node.dealType === "BUYING" &&
                                    <td>
                                        {(() => {
                                            let metaObject = JSON.parse(deal.node.metadata.replace(/'/g, '"'))
                                            return metaObject && metaObject.delivery_disposal_date && this.props ? metaObject.delivery_disposal_date : "--"
                                        })()}
                                    </td>
                                }
                                <td className="py-3">
                                    <div className="cars-wrapper">
                                        <div className="bos-table-td-left-box">
                                            {this.state.openModal === "CarInfo" + deal.node.id && <CarInfoModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "CarInfo" + deal.node.id} id={deal.node.car.pk} carPk={deal.node.car.pk} carId={deal.node.car.id} isFleetManagement={this.props.isFleetManagement} refetchQuery={this.props.refetchQuery} />}
                                            <small>{deal.node.car.pk}</small>
                                            <br />
                                            <a onClick={() => this.toggleModal("CarInfo" + deal.node.id)} style={{ cursor: "pointer" }}>
                                                {/* <i className="fa fa-cog" style={{fontSize: "22px"}} aria-hidden="true"></i> */}
                                                <CarModelGroupIcon carGroup={deal.node.car && deal.node.car.carModel && deal.node.car.carModel.groupType && deal.node.car.carModel.groupType.name ? deal.node.car.carModel.groupType.name : "--"} carColor={deal.node.car.color} id={deal.node.car.pk} key={i + 1} />
                                            </a>
                                            <br />
                                            <small>Stage {renderEnumToInt(deal.node.car.stage)}</small>
                                        </div>

                                        <div className="bos-table-td-right-box">
                                            <span>
                                                <small>{deal.node.car.year} | {deal.node.car.color} {deal.node.car && deal.node.car.carModel && deal.node.car.carModel.groupType && deal.node.car.carModel.groupType.name ? "| " + deal.node.car.carModel.groupType.name : ""}</small>
                                            </span>
                                            <div className="car-name">
                                                <a className="bos-custom-link" href={"/car/" + deal.node.car.pk} target="_blank">
                                                    {toTitleCase(deal.node.car.carModel.make)} {toTitleCase(deal.node.car.carModel.name)}
                                                </a>&nbsp;
                                                <small>{deal.node.car.carModel.series ? "(" + deal.node.car.carModel.series + ")" : ""}</small>
                                                {(deal.node.car.stage == 10 || (deal.node.car.currentAgreement && deal.node.car.currentAgreement.id)) && <>
                                                    <i id={"driverIsActive" + i} className="ml-2 fa fa-circle text-success" ></i>
                                                    <UncontrolledTooltip placement="right" target={"driverIsActive" + i}>Active Car</UncontrolledTooltip>
                                                </>}
                                            </div>
                                            <small>
                                                {deal.node.car.carpolicySet && deal.node.car.carpolicySet.edges && deal.node.car.carpolicySet.edges.length > 0 &&
                                                    deal.node.car.carpolicySet.edges.filter(policy => policy.node.insurancePolicy && policy.node.status === 'A_1').length > 0 ?
                                                    deal.node.car.carpolicySet.edges.filter(policy => policy.node.insurancePolicy && policy.node.status === 'A_1')[0].node.insurancePolicy.policyNumber
                                                    : "No Insurance Found!"}
                                            </small>
                                        </div>
                                    </div>
                                </td>
                                <td className="py-3">
                                    <div className="cars-wrapper">
                                        <b>{deal.node.car.carLocation ? deal.node.car.carLocation.name : "Not Found!"}</b>
                                    </div>
                                </td>
                                <td>
                                    {this.state.openModal && this.state.openModal === "carAssignment" + i && <CarRepAssignmentModal
                                        open={this.state.openModal === "carAssignment" + i}
                                        target={"carAssignment" + i}
                                        selectedCars={[deal.node.car.id]}
                                        handleClose={() => this.toggleModal("carAssignment" + i)}
                                        refetch={this.props.refetchQuery}
                                        groups={["Fleet"]}
                                    />}
                                    <span id={"carAssignment" + i}>
                                        <i onClick={() => this.toggleModal("carAssignment" + i)} className={"fa fa-pencil"}></i>&nbsp;&nbsp;{deal.node.car && deal.node.car.assignedTo ? deal.node.car.assignedTo : "--"}
                                    </span>
                                </td>
                                <td>
                                    <div className="notes">
                                        {this.state.openModal === "notesHistory" + i &&
                                            <CarNotes car={deal.node.car} target={"dealNotesEdit" + i} noteType="Deal" title="Deal Notes History" objectId={deal.node.id} open={this.state.openModal === "notesHistory" + i} handleClose={() => this.toggleModal("")} />
                                        }
                                        {this.state.openModal === "dealNotesEdit" + i &&
                                            <UpdateCarDeal carDeal={deal.node} title="Edit Deal Notes" name="notes" value={this.fetchMetaData(deal.node.metadata, "notes")} target={'dealNotesEdit' + i} id="notes" type="text" open={this.state.openModal === 'dealNotesEdit' + i} handleClose={() => this.toggleModal('dealNotesEdit' + i)} refetchQuery={this.props.refetchQuery} />
                                        }

                                        <span id={"dealNotesEdit" + i}>
                                            <i onClick={() => this.toggleModal("notesHistory" + i)} className={"fa fa-history"}></i>&nbsp;&nbsp;
                                            {this.props.hasPermissions && this.props.hasPermissions.includes("change_cardeal") &&
                                                <i onClick={() => this.toggleModal("dealNotesEdit" + i)} className={"fa fa-pencil-square-o"}></i>}&nbsp;&nbsp;
                                            <small className="note-text">
                                                {this.fetchMetaData(deal.node.metadata, "notes") ? this.fetchMetaData(deal.node.metadata, "notes") : "--"}
                                            </small>
                                            <UncontrolledTooltip placement="top" target={"dealNotesEdit" + i}>{this.fetchMetaData(deal.node.metadata, "notes") ? this.fetchMetaData(deal.node.metadata, "notes") : "--"}</UncontrolledTooltip>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <ul className="actions pt-3">
                                        <li>
                                            {this.state.openModal === "Location" + i &&
                                                <Popover placement="left" isOpen={this.state.openModal === "Location" + i} target={"LocationPopup" + i} toggle={() => this.setState({ openModal: "" })}>
                                                    <PopoverHeader>Car Location</PopoverHeader>
                                                    <PopoverBody>
                                                        {this.state.location && this.state.location.latitude && this.state.location.longitude ?
                                                            <MyMapComponent
                                                                isMarkerShown
                                                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDg4oSLbYS6boBTyYBWHmpIOUU__8Mfh4I&v=3.exp&libraries=geometry,drawing,places"
                                                                loadingElement={<div style={{ height: `100%` }} />}
                                                                containerElement={<div style={{ height: `300px` }} />}
                                                                mapElement={<div style={{ height: `100%` }} />}
                                                                latitude={this.state.location.latitude}
                                                                longitude={this.state.location.longitude}
                                                            /> :
                                                            "Car Location Not Found!"
                                                        }
                                                    </PopoverBody>
                                                </Popover>
                                            }
                                            <a onClick={() => this.locateCar(deal.node.car.id, "Location" + i)} id={"LocationPopup" + i}><LocationIcon width={20} fill="#303E67" /></a>
                                            <UncontrolledTooltip placement="top" target={"LocationPopup" + i}>Locate Car</UncontrolledTooltip>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        ) : <tr>
                            <td colSpan={10}>{this.props.loading ? "Loading..." : "No Car deals Found!"}</td>
                        </tr>}
                    </tbody>
                </Table>
            </PrimaryListingContainer>

        )
    }
}

export default compose(
    withApollo,
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["change_cardeal"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    }),
    graphql(AllTitleOwnerQuery, { options: { fetchPolicy: 'cache-first' }, props({ data: { allTitleOwners } }) { return { allTitleOwners } } }),
    graphql(locateCar, {
        props: ({ mutate }) => ({
            locate: (input) => mutate({ variables: { input } }),
        })
    }),
    graphql(CarDealsQuery,
        {
            options: ({ searchTerm, orderBy, stageIn, dealType, assignedToIn, startDate, endDate, carsInFleet }) => ({
                variables: { searchTerm, orderBy, stageIn, dealTypeIn: [dealType], first: 30, assignedToIn, startDate, endDate, carsInFleet },
                notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only'
            }),
            props({ data: { loading, carDeals, fetchMore, variables } }) {
                return {
                    loading,
                    carDeals,
                    variables,
                    refetchQuery: () => {
                        //No apollo function that refetches in place with pagination considered so this function instead
                        let currentLength = carDeals && carDeals.edges ? carDeals.edges.length : 30
                        return fetchMore({
                            query: CarDealsQuery,
                            variables: {
                                ...variables,
                                first: currentLength,
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                const newEdges = fetchMoreResult.carDeals.edges
                                const pageInfo = fetchMoreResult.carDeals.pageInfo
                                return {
                                    carDeals: {
                                        edges: [...newEdges],
                                        pageInfo,
                                        searchTerm: previousResult.searchTerm,
                                        __typename: previousResult.carDeals.__typename
                                    },
                                }
                            },
                        })
                    },
                    loadMoreEntries: () => {
                        return fetchMore({
                            query: CarDealsQuery,
                            variables: {
                                cursor: carDeals.pageInfo.endCursor,
                                ...variables
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                const newEdges = fetchMoreResult.carDeals.edges
                                const pageInfo = fetchMoreResult.carDeals.pageInfo

                                return {
                                    carDeals: {
                                        edges: [...previousResult.carDeals.edges, ...newEdges],
                                        pageInfo,
                                        orderBy: previousResult.orderBy,
                                        searchTerm: previousResult.searchTerm,
                                        stageIn: previousResult.stageIn,
                                        dealTypeIn: previousResult.dealTypeIn,
                                        startDate: previousResult.startDate,
                                        endDate: previousResult.endDate,
                                        assignedToIn: previousResult.assignedToIn,
                                        __typename: previousResult.carDeals.__typename
                                    },
                                }
                            },
                        })
                    },
                }
            },
        })
)(DealsList)