import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import '../CarDetail.css'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import { AgreementsQuery } from '../Queries'
import { EnumQuery } from '../../../Functions/querys'
import RentalDetail from './RentalDetail'
import Loader from "../../../Material/Loader"

class RentalTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeRentalId: this.props.rentals && this.props.rentals && this.props.rentals.edges.length > 0 ? this.props.rentals.edges[0].node.id : null,
            // dataAvailable: true
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rentals !== this.props.rentals && this.props.rentals && this.props.rentals.edges.length > 0) {
            this.setState({ activeRentalId: this.props.rentals.edges[0].node.id })
        }
    }
    render() {
        return (
            <div className="rental-container">
                <Row>
                    <Col className="agreements-list col-2">
                        {this.props.loading ? <Loader /> : <>
                            {
                                this.props.rentals && this.props.rentals.edges && this.props.rentals.edges.length > 0 ?
                                    this.props.rentals.edges.map((rental, i) =>
                                        <div
                                            className={"car-agreement" + (this.state.activeRentalId === (rental && rental.node && rental.node.id) ? " active" : "")}
                                            onClick={() => this.setState({ activeRentalId: rental && rental.node && rental.node.id })}
                                            key={i}
                                        >
                                            <div className={"sidebar-listing-wrapper-icon"}>
                                                <i className={rental && rental.node && rental.node.stage === "_1" ? "fa fa-2x fa-ban" : "fa fa-2x fa-user"} style={{ color: rental && rental.node && rental.node.stage === "_1" ? "red" : rental && rental.node && rental.node.stage === "A_10" ? "green" : "orange" }} aria-hidden="true"></i>&nbsp;
                                            </div>
                                            <div className={"sidebar-listing-wrapper-text"}>
                                                <p>{rental && rental.node && rental.node.driver ? rental.node.driver.name : "---"}</p>
                                            </div>
                                        </div>
                                    ) : <p className="text-center no-rentals">No Rentals Found</p>
                            }
                            <div id="more-agreements">
                                {this.props.rentals && this.props.rentals.pageInfo.hasNextPage &&
                                    <>
                                        <i className={"fa fa-2x fa-arrow-circle-down"} style={{ color: "#1B4D7E" }} onClick={this.props.loadMoreAgreements} aria-hidden="true" id={"viewMore"}></i>
                                        <UncontrolledTooltip target={"viewMore"} placement={"left"}>View More</UncontrolledTooltip>
                                    </>
                                }
                            </div>
                        </>}
                    </Col>
                    {this.state.activeRentalId && <RentalDetail id={this.state.activeRentalId} detailPageType="car" />}
                </Row>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(EnumQuery, { props({ data: { agreementStates, accountTypes, chargeTypes } }) { return { agreementStates, accountTypes, chargeTypes } } }),
    graphql(AgreementsQuery, {
        options: ({ match }) => ({
            variables: {
                carPk: match && match.params && match.params.pk,
                first: 8,
                fetchPolicy: 'cache-and-network',
                notifyOnNetworkStatusChange: true
            }
        }),
        props({ data: { loading, rentals, fetchMore, variables } }) {
            return {
                loading,
                rentals,
                variables,
                loadMoreAgreements: () => {
                    return fetchMore({
                        query: AgreementsQuery,
                        variables: {
                            cursor: rentals.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.rentals.edges
                            const pageInfo = fetchMoreResult.rentals.pageInfo

                            return {
                                rentals: {
                                    edges: [...previousResult.rentals.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.rentals.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    }),
)(RentalTab)
