import React, { Component } from "react"
import { Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Label, Button, Input, FormFeedback, Alert, Row, Col, Progress } from "reactstrap"
import Select from "react-select"
import moment from 'moment'
import { withApollo } from "react-apollo/index"
import { compose, graphql } from "react-apollo"
import { AllCarModels, AllAgreementTypes } from "./Queries"
import { CreatePromotionTemplateMutation } from './Mutations'
import toTitleCase from "../Functions/toTitleCase"

class AddPromotionModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      input: {
        title: null,
        promoPrice: null,
        minimumPromoPrice: null,
        maximumPromoPrice: null,
        cycle: null,
        minCycle: null,
        maxCycle: null,
        interval: 1,
        intervalUnit: '',
        carYearMin: null,
        carYearMax: null,
        visibleToCustomers: false,
        startDate: null,
        expirationDate: null,
        isPercentage: false,
        carModels: null
      },
      createAnother: false,
      loading: false,
      error: "",
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    let input = this.state.input
    if (!input.promoPrice || !input.minimumPromoPrice || !input.maximumPromoPrice || !input.cycle || !input.minCycle || !input.maxCycle || !input.carModels || !input.agreementType || !input.intervalUnit) {
      this.setState({ loading: false, error: "Please provide all required fields, for creating a pricing template!" })
      return
    }

    try {
      input = {
        ...input, promoPrice: parseFloat(input.promoPrice),
        minimumPromoPrice: parseFloat(input.minimumPromoPrice), maximumPromoPrice: parseFloat(input.maximumPromoPrice),
        cycle: parseFloat(input.cycle), minCycle: parseFloat(input.minCycle),
        maxCycle: parseFloat(input.maxCycle), carYearMin: parseInt(input.carYearMin),
        carYearMax: parseInt(input.carYearMax),
        isPercentage: this.state.input.isPercentage === "true", interval: parseInt(input.interval),
        intervalUnit: input.intervalUnit.charAt(0).toUpperCase() + input.intervalUnit.substr(1).toLowerCase(),
      }
      // input = {...input, promoPrice: parseFloat(input.promoPrice), minimumPromoPrice: parseFloat(input.minimumPromoPrice), maximumPromoPrice: parseFloat(input.maximumPromoPrice), interval: parseInt(input.interval), carYearMin: parseInt(input.carYearMin), carYearMax: parseInt(input.carYearMax),isPercentage:this.state.input.isPercentage === "true" }

      this.setState({ loading: true })
      this.props.client.mutate({
        mutation: CreatePromotionTemplateMutation,
        variables: { input },
      }).then((result) => {
        if (result.data && result.data.createPromotionTemplate && result.data.createPromotionTemplate.ok) {
          this.props.refetch()
          if (!this.state.createAnother) {
            this.props.toggleModal()
          }
          this.setState({ loading: false, error: "" })
        } else if (result.data && result.data.createPromotionTemplate && !result.data.createPromotionTemplate.ok && result.data.createPromotionTemplate.errors && result.data.createPromotionTemplate.errors[0]) {
          this.setState({ loading: false, error: result.data.createPromotionTemplate.errors[0].messages.toString() })
        } else {
          this.setState({ loading: false, error: "Something went wrong, please contact admin for support!" })
        }
      }).catch((err) => {
        this.setState({ loading: false, error: err.message })
      })
    } catch (err) {
      this.setState({ loading: false, error: err })
    }
  };

  handleChange = (e, number = false) => {
    if (e.target.type === "checkbox")
      this.setState({ input: { ...this.state.input, [e.target.name]: !this.state.input[e.target.name] } })
    else if (number) {
      this.setState({ input: { ...this.state.input, [e.target.name]: parseFloat(e.target.value) } })
    }
    else {
      this.setState({ input: { ...this.state.input, [e.target.name]: e.target.value } })
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} className="bos-car-attr-modal" style={{ maxWidth: 800 }}>
        <ModalHeader> Add New Promotion </ModalHeader>
        <ModalBody>
          {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
          {(this.state.loading || this.props.loading) && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
          <Row>
            <Col>
              <Label check>Is Percentage/Flat Promo ?</Label>
              <Input name='isPercentage' value={this.state.input.isPercentage} placeholder="Is Percentage" type="select" onChange={(e) => this.handleChange(e)}>
                <option value="true">Percentage</option>
                <option value="false">Flat Price</option>
              </Input>
            </Col>
          </Row>
          <hr />
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="templateTitle">Title</Label>
                  <Input type="text" step="any" name="title" id="templateTitle" placeholder="Title" value={this.state.input.title} onChange={(e) => this.handleChange(e)} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <FormGroup>
                  <Label for="templatePrice">Default Value {this.state.input.isPercentage ? "(%)" : "($)"} *</Label>
                  <Input type="number" step="any" name="promoPrice" id="templatePrice" placeholder="$ Price" value={this.state.input.promoPrice} onChange={(e) => this.handleChange(e, true)} />
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup>
                  <Label for="templateminimumPromoPrice">Minimum Value {this.state.input.isPercentage ? "(%)" : "($)"} *</Label>
                  <Input valid={this.state.input.promoPrice && this.state.input.minimumPromoPrice && this.state.input.minimumPromoPrice < this.state.input.promoPrice}
                    invalid={this.state.input.promoPrice && this.state.input.minimumPromoPrice && this.state.input.minimumPromoPrice > this.state.input.promoPrice}
                    type="number" step="any" name="minimumPromoPrice" id="templateminimumPromoPrice" placeholder="$ Minimum Price"
                    value={this.state.input.minimumPromoPrice} onChange={(e) => this.handleChange(e, true)} />
                  <FormFeedback>Minimum Price must be less than the default Price</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup>
                  <Label for="templatemaximumPromoPrice">Maximum Value {this.state.input.isPercentage ? "(%)" : "($)"} *</Label>
                  <Input
                    valid={this.state.input.promoPrice && this.state.input.maximumPromoPrice && this.state.input.maximumPromoPrice > this.state.input.promoPrice}
                    invalid={this.state.input.promoPrice && this.state.input.maximumPromoPrice && this.state.input.maximumPromoPrice < this.state.input.promoPrice}
                    type="number" step="any" name="maximumPromoPrice" id="templatemaximumPromoPrice" placeholder="$ Maximum Price"
                    value={this.state.input.maximumPromoPrice} onChange={(e) => this.handleChange(e, true)} />
                  <FormFeedback>Maximum Price must be higher than the default Price</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup>
                  <Label for="templateCycle">Default Promotion Cycle *</Label>
                  <Input type="number" step="any" name="cycle" id="templatePrice" placeholder="Cycle" value={this.state.input.cycle} onChange={(e) => this.handleChange(e, true)} />
                </FormGroup>
              </Col>

              <Col xs={4}>
                <FormGroup>
                  <Label for="templateminCycle">Minimum Cycle *</Label>
                  <Input
                    valid={this.state.input.cycle && this.state.input.minCycle && this.state.input.minCycle < this.state.input.cycle}
                    invalid={this.state.input.cycle && this.state.input.minCycle && this.state.input.minCycle > this.state.input.cycle}
                    type="number" step="any" name="minCycle" id="templateminCycle" placeholder="Minimum Cycle"
                    value={this.state.input.minCycle} onChange={(e) => this.handleChange(e, true)} />
                  <FormFeedback>Minimum Cycle must be less than the default Cycle</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup>
                  <Label for="templatemaxCycle">Maximum Cycle *</Label>
                  <Input
                    valid={this.state.input.cycle && this.state.input.maxCycle && this.state.input.maxCycle > this.state.input.cycle}
                    invalid={this.state.input.cycle && this.state.input.maxCycle && this.state.input.maxCycle < this.state.input.cycle}
                    type="number" step="any" name="maxCycle" id="templatemaxCycle" placeholder="Maximum Cycle"
                    value={this.state.input.maxCycle} onChange={(e) => this.handleChange(e, true)} />
                  <FormFeedback>Maximum Cycle must be higher than the default Cycle</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup>
                  <Label for="templateInterval">Interval *</Label>
                  <Input type="number" name="interval" id="templateInterval" placeholder="Interval" min="1" max="9" value={this.state.input.interval} onChange={(e) => this.handleChange(e)} />
                </FormGroup>
              </Col>
              <Col xs={4}><FormGroup>
                <Label for="templateIntervalUnit">Interval Unit *</Label>
                <Input type="select" name="intervalUnit" id="templateIntervalUnit" placeholder="Interval Unit" value={this.state.input.intervalUnit} onChange={(e) => this.handleChange(e)}>
                  <option value='' disabled>Select Interval Unit</option>
                  {['Day', 'Week', 'Month'].map(item => <option value={item}>{item}</option>)}
                </Input>
              </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label for="carModel">Car Model *</Label>
                  <Select
                    isLoading={!(this.props.allCarModels && this.props.allCarModels.edges && this.props.allCarModels.edges.length > 0)}
                    options={this.props.allCarModels && this.props.allCarModels.edges.length > 0 && this.props.allCarModels.edges.filter(item => item.node.make && item.node.name).map((item) => ({
                      value: item.node.id, label: toTitleCase(item.node.make) + " " + item.node.name + (item.node.series ? " | " + item.node.series : "") + (item.node.groupType ? " | " + item.node.groupType.name : " | No Group Selected")
                    }))}
                    isMulti
                    placeholder="Select Model" onChange={(carModel) => this.setState({ input: { ...this.state.input, carModels: carModel && carModel.length > 0 ? carModel.map(model => model.value) : null } })} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label for="agreementType">Agreement Type *</Label>
                  <Select isLoading={!(this.props.allAgreementTypes && this.props.allAgreementTypes.edges && this.props.allAgreementTypes.edges.length > 0)} options={this.props.allAgreementTypes && this.props.allAgreementTypes.edges.length > 0 && this.props.allAgreementTypes.edges.map((item) => ({ value: item.node.id, label: item.node.name }))}
                    placeholder="Select Agreement Type" onChange={(agreementType) => this.setState({ input: { ...this.state.input, agreementType: agreementType && agreementType.value ? agreementType.value : null } })} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="carYearMin">Car Year Min</Label>
                  <Input type="number" name="carYearMin" id="carYearMin" placeholder="Car Year Minimum" min={2000} max={3000} value={this.state.input.carYearMin} onChange={(e) => this.handleChange(e)} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="carYearMax">Car Year Max</Label>
                  <Input type="number" name="carYearMax" id="carYearMax" placeholder="Car Year Maximum" min={this.state.input.carYearMin ? this.state.input.carYearMin : 2000} max={3000} value={this.state.input.carYearMax} onChange={(e) => this.handleChange(e)} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="startDate">Start Date</Label>
                  <Input name="startDate" placeholder="Start Date" type="date" min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} onChange={(e) => this.handleChange(e)} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="expirationDate">Expiration Date</Label>
                  <Input name="expirationDate" placeholder="Expiration Date" type="date" min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} onChange={(e) => this.handleChange(e)} />
                  <Label for="expirationDate"><b>Expiration Date can only Be Sunday!</b></Label>{" "}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label>
                    Visible to Customers&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Input name='visibleToCustomers' placeholder="Visible To Customers" type="checkbox" onChange={(e) => this.handleChange(e)} />
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <br />
            <ModalFooter>
              <FormGroup check>
                <Label check>
                  <Input name='createAnother' type="checkbox" onChange={(e) => () => this.setState({ createAnother: !this.state.createAnother })} />
                  {" "}
                  Create another
                </Label>
              </FormGroup>
              <Button color="primary" type="submit">Create Promo</Button>
              <Button color="secondary" onClick={() => this.props.toggleModal()}>Close</Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal >
    )
  }
}

export default compose(
  withApollo,
  graphql(AllCarModels, {
    options: { fetchPolicy: "network-only" },
    props({ data: { loading, allCarModels } }) {
      return { loading, allCarModels }
    },
  }),
  graphql(AllAgreementTypes, {
    options: { fetchPolicy: "network-only" },
    props({ data: { loading, allAgreementTypes } }) {
      return { loading, allAgreementTypes }
    },
  })
)(AddPromotionModal)
