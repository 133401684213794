import gql from "graphql-tag"


const updateDefaultCardMutation = gql`mutation updateDefaultCard($input:UpdateDefaultCardMutationInput!){
    updateDefaultCard(input:$input){
        ok
        errors{
            field
            messages
        }
    }
}`

const createStripePaymentMutation = gql`mutation createStripePayment($input: CreateStripePaymentMutationInput!){
    createStripePayment(input:$input){
        ok
        errors{
            field
            messages
        }
    }
}`
const deleteCardMutation = gql`mutation deleteCard($input:DeleteCardMutationInput!){
    deleteCard(input:$input){
      ok
      errors{
        field
        messages
      }
    }
}`

const requestPaymentFromDriverMutation = gql`mutation requestPaymentFromDriver($input:RequestPaymentFromDriverMutationInput!){
    requestPaymentFromDriver(input:$input){
      ok
      errors{
        field
        messages
      }
    }
}`
const requestCardFromDriverMutation = gql`mutation requestCardFromDriver($input:RequestCardFromDriverMutationInput!){
    requestCardFromDriver(input:$input){
      ok
      errors{
        field
        messages
      }
    }
}`
const RefundStripePaymentMutation = gql`mutation RefundStripePaymentMutation($input:RefundStripePaymentMutationInput!){
  refundStripePayment(input:$input){
    ok
    errors{
      field
      messages
    }
  }
}`

export { updateDefaultCardMutation, createStripePaymentMutation, deleteCardMutation, requestPaymentFromDriverMutation, requestCardFromDriverMutation, RefundStripePaymentMutation }