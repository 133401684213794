import React, { Component } from 'react'
import Select from 'react-select'
import ObjectPermissionsConfigurations from "./ObjectPermissionsConfigurations"
import { AllContentTypes, AllGroups } from './Queries'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Button, Progress, Alert } from 'reactstrap'
import { compose, graphql, withApollo, Query } from 'react-apollo'
import { CreateGroupObjectPermissionMutation } from "./Mutation"

class NewObjectPermissionModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contentType: null,
            secondDropdownValue: null,
            selectedGroups: []
        }
    }


    createGroupObjectPermission = () => {
        try {
            if (this.state.contentType && this.state.secondDropdownValue && this.state.selectedGroups) {
                this.setState({ loading: true })
                let input = {
                    contentTypeId: this.props.allContentTypes && this.props.allContentTypes.edges && this.props.allContentTypes.edges.filter(contentTypes => contentTypes.node.model === this.state.contentType).map(item => item.node.id)[0],
                    objectId: this.state.secondDropdownValue,
                    groups: this.state.selectedGroups,
                }
                this.props.client.mutate({
                    mutation: CreateGroupObjectPermissionMutation,
                    variables: { input },
                }).then((result) => {
                    if (result.data && result.data.createGroupObjectPermission && result.data.createGroupObjectPermission.ok) {
                        this.setState({ error: null, loading: false })
                        window.location.reload()
                    } else {
                        this.setState({ loading: false, error: result.data.createGroupObjectPermission.errors[0].messages[0] })
                    }
                }).catch((error) => {
                    let errorMessage = "An error has occured"
                    this.setState({ error: errorMessage, loading: false })
                })

            } else {
                this.setState({ error: "Missing required fields, Please make sure to add all the required values.", loading: false })
            }
        } catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false })

        }
    }

    handleContentTypeChange = (selectedOption) => {
        this.setState({ contentType: selectedOption.value, secondDropdownValue: null })
    };

    handleSecondDropdownChange = (selectedOption) => {
        this.setState({ secondDropdownValue: selectedOption.value })
    };



    render() {
        const { contentType } = this.state
        return (
            <div>
                <Modal className="create-permission-modal" isOpen={this.props.open}>
                    <ModalHeader>Create New Object Permission</ModalHeader>
                    <ModalBody>
                        {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                        {(this.state.loading || this.props.loading) && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                        <Row>
                            <Col xs={12}>
                                <Label>Select Content Type*</Label>
                                <Select
                                    className="bos-custom-select"
                                    classNamePrefix="bos-select"
                                    isLoading={!(this.props.allContentTypes && this.props.allContentTypes.edges && this.props.allContentTypes.edges.length > 0)}
                                    options={
                                        this.props.allContentTypes &&
                                        this.props.allContentTypes.edges &&
                                        this.props.allContentTypes.edges.length > 0 &&
                                        this.props.allContentTypes.edges.map((contentType) => ({ value: contentType.node.model, label: contentType.node.appLabel + ' | ' + contentType.node.model }))
                                    }
                                    placeholder="Select Content Type"
                                    onChange={this.handleContentTypeChange}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={12}>
                                {contentType && ObjectPermissionsConfigurations[contentType] ? (
                                    <Query key={contentType} query={ObjectPermissionsConfigurations[contentType].query}>
                                        {({ loading, error, data }) => {
                                            if (loading) return <p>Loading...</p>
                                            if (error) return <p>Error: {error.message}</p>

                                            const dropdownOptions = data.dataList.edges.map(({ node }) => ({
                                                label: node.name,
                                                value: node.id,
                                            }))

                                            return (
                                                <>
                                                    <Label>Select Object*</Label>
                                                    <Select
                                                        className="bos-custom-select"
                                                        classNamePrefix="bos-select"
                                                        options={dropdownOptions}
                                                        onChange={this.handleSecondDropdownChange}
                                                        placeholder="Select Object"
                                                        isDisabled={!contentType}
                                                    />
                                                </>
                                            )
                                        }}
                                    </Query>
                                ) : contentType && "No Object Found Against Selected Content Type"
                                }
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            {contentType && ObjectPermissionsConfigurations[contentType] &&
                                <Col xs={12}>
                                    <Label>Select Groups*</Label>
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        isLoading={!this.props.allGroups || !this.props.allGroups.edges || !this.props.allGroups.edges.length > 0}
                                        options={this.props.allGroups && this.props.allGroups.edges && this.props.allGroups.edges.length > 0 && this.props.allGroups.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                        placeholder="Select Groups"
                                        isMulti
                                        onChange={(selectedGroups) => this.setState({ selectedGroups: selectedGroups.map(item => item.value) })}
                                    />
                                </Col>
                            }
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline className="create-permission-modal-secondary-btn" onClick={this.props.handleClose}>Close</Button>
                        <Button className="create-permission-modal-primary-btn" onClick={this.createGroupObjectPermission} disabled={this.state.loading}>Add New Object Permission</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllContentTypes, {
        options: ({ }) => ({
            fetchPolicy: 'cache-and-network',
            variables: {
                allowedForObjectPermission: true,
            }
        }),
        props: ({ data: { loading, allContentTypes } }) => ({ loading, allContentTypes }),
    }),
    graphql(AllGroups, {
        options: { fetchPolicy: 'cache-and-network' },
        props: ({ data: { loading, allGroups } }) => ({ loading, allGroups }),
    })
)(NewObjectPermissionModal)