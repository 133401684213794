import React, { Component } from "react"
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Container, Nav, NavItem, Card, CardBody, Button } from "reactstrap"
import InsuranceListHeader from "./InsuranceListHeader"
import InsuranceList from "./InsuranceList"
import ManagePreferenceHOC from "../Material/ManagePreferenceHOC"

const tabs = [
    { id: "#commercial", name: "Pending - Commercial", filters: { statuses: 3, hasMissingDocs: true, orderBy: ["-driver__in_office", "-date_modified"] } },
    { id: "#uw", name: "Pending - UW", filters: { statuses: 3, hasMissingDocs: false, orderBy: ["-driver__in_office", "-date_modified"] } },
    { id: "#active", name: "Active", filters: { statuses: 1, orderBy: ["-driver__in_office", "-date_added"] } },
    { id: "#all", name: "All Insurances", filters: { orderBy: ["-driver__in_office", "-date_added"] } },
]

// Note: If you are chaging any ID in the tableheader make sure to match that id in the listing page as well.
const tableHeaders = [
    { id: "date_added", name: "Date Added", sortable: true, view: ["Active", "All Insurances"], visible: true, showInPicker: true, positonIsFixed: false },
    { id: "date_modified", name: "Date Modified", sortable: true, view: ["Pending - Commercial", "Pending - UW"], visible: true, showInPicker: true, positonIsFixed: false },
    { id: "driver__last_name", name: "Driver Info", sortable: true, view: ["Pending - Commercial", "Pending - UW", "Active", "All Insurances"], visible: true, showInPicker: false, positonIsFixed: true },
    { id: "branch_id", name: "Branch", sortable: true, view: ["Pending - Commercial", "Pending - UW", "Active", "All Insurances"], visible: true, showInPicker: true, positonIsFixed: false },
    { id: "missing_docs", name: "Required Docs", sortable: false, view: ["Pending - Commercial", "Pending - UW"], visible: true, showInPicker: true, positonIsFixed: false },
    { id: "status", name: "Status", sortable: false, view: ["All Insurances"], visible: true, showInPicker: true, positonIsFixed: false },
    { id: "insurance_notes", name: "Insurance Notes", sortable: false, view: ["Pending - Commercial", "Pending - UW", "Active", "All Insurances"], visible: true, showInPicker: true, positonIsFixed: false },
    { id: "driver_notes", name: "Driver Notes", sortable: false, view: ["Pending - Commercial", "Pending - UW", "Active", "All Insurances"], visible: true, showInPicker: true, positonIsFixed: false },
    { id: "tags", name: "Tags", sortable: false, view: ["Pending - Commercial", "Pending - UW", "Active", "All Insurances"], visible: true, showInPicker: true, positonIsFixed: false },
    { id: "assigned_to", name: "Assigned To", sortable: false, view: ["Pending - Commercial", "Pending - UW", "Active", "All Insurances"], visible: true, showInPicker: true, positonIsFixed: false },
]

class InsuranceListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterValues: {
                statuses: 3, hasMissingDocs: true, orderBy: ["-driver__in_office", "-date_modified"]
            },
            activeTab: null,
            selectedInsurances: [],
            refetchInsurances: false,
        }
    }

    changeTab = (e) => {
        let name = e.target.name
        let value = e.target.value
        let tabIndex = tabs.findIndex(tab => tab.name === value)
        window.location.hash = tabs[tabIndex].id
        this.setState({ [name]: value, filterValues: { ...tabs[tabIndex].filters } })
    }

    updateOrder = (string) => {
        let order = [...this.state.filterValues.orderBy]
        if (!order.includes(string) && !order.includes("-" + string)) {
            order = [order.find(item => item.includes("-driver__in_office")), string]
        } else {
            let index = order.indexOf(string)
            if (order.indexOf(string) > -1) {
                order[index] = "-" + string
            } else {
                index = order.indexOf("-" + string)
                index > -1 && (order[index] = string)
            }
        }
        this.setState({ filterValues: { ...this.state.filterValues, orderBy: order } })
    }

    componentDidMount() {
        if (window.location.hash && tabs.map(item => item.id).includes(window.location.hash)) {
            let tabIndex = tabs.findIndex(tab => tab.id === window.location.hash)
            this.setState({ filterValues: { ...tabs[tabIndex].filters }, activeTab: tabs[tabIndex].name })
        } else {
            window.location.hash = tabs[0].id
            this.setState({ tab: tabs[0].id, filterValues: { ...tabs[0].filters }, activeTab: tabs[0].name })
        }
    }

    render() {
        return (
            <Container fluid className="bos-listing-container">
                <Nav pills className="available-car-wrap">
                    {tabs.map((tab, i) => {
                        return (
                            <NavItem key={i}>
                                <Button color="primary" name={"activeTab"} value={tab.name} className={this.state.activeTab === tab.name ? "active" : "inactive"} onClick={this.changeTab}>{tab.name}</Button>
                            </NavItem>
                        )
                    })}
                </Nav>
                <InsuranceListHeader
                    activeTab={this.state.activeTab}
                    filterValues={this.state.filterValues}
                    setFilterValues={(filterValues) => this.setState({ filterValues })}
                    selectedInsurances={this.state.selectedInsurances}
                    resetSelectedInsurances={() => this.setState({ selectedInsurances: [] })}
                    defaultFilters={this.state.activeTab && tabs.find(tab => tab.name === this.state.activeTab).filters}
                    currentUser={this.props.currentUser}
                    location={this.props.location}
                    history={this.props.history}
                    refetchQuery={() => this.setState({ refetchInsurances: !this.state.refetchInsurances })}
                    columnConfig={this.props.columnConfig}
                    preferenceType={"insurance"}
                    refetchPreferences={this.props.refetchPreferences}
                />
                <InsuranceList
                    selectedTab={this.state.activeTab}
                    key={String(this.state.refetchInsurances)}
                    columnConfig={this.props.columnConfig}
                    searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                    hasMissingDocs={this.state.filterValues && this.state.filterValues.hasMissingDocs !== null ? this.state.filterValues.hasMissingDocs : null}
                    hasRental={this.state.filterValues && this.state.filterValues.hasRental !== null ? this.state.filterValues.hasRental : null}
                    inOffice={this.state.filterValues && this.state.filterValues.inOffice !== null ? this.state.filterValues.inOffice : null}
                    assignedTo={this.state.filterValues && this.state.filterValues.assignedTo ? this.state.filterValues.assignedTo : null}
                    driverStages={this.state.filterValues && this.state.filterValues.driverStages ? this.state.filterValues.driverStages : null}
                    driverTags={this.state.filterValues && this.state.filterValues.driverTags ? this.state.filterValues.driverTags : null}
                    orderBy={this.state.filterValues && this.state.filterValues.orderBy ? this.state.filterValues.orderBy : ["-id"]}
                    updateOrder={this.updateOrder}
                    statuses={this.state.filterValues && this.state.filterValues.statuses !== null ? this.state.filterValues.statuses : null}
                    types={this.state.filterValues && this.state.filterValues.types !== null ? this.state.filterValues.types : null}
                    selectedInsurances={this.state.selectedInsurances}
                    currentUser={this.props.currentUser}
                    setSelectedInsurances={(selectedInsurances) => this.setState({ selectedInsurances })}
                />
            </Container>

        )
    }
}

export default compose(
    withApollo,
    ManagePreferenceHOC("insurance", tableHeaders)
)(InsuranceListContainer)
