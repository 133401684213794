import gql from "graphql-tag"

export const CreatePaymentScheduleMutation = gql`
    mutation createPaymentSchedule($input: CreatePaymentScheduleMutationInput!){
        createPaymentSchedule(input:$input){
        ok
        errors{
            messages
        }
    } 
}`

export const UpdatePaymentScheduleMutation = gql`
    mutation updatePaymentSchedule($input: UpdatePaymentScheduleMutationInput!){
        updatePaymentSchedule(input:$input){
        ok
        errors{
            messages
        }
    } 
}`
