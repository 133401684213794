import React, { Component } from 'react'
import { compose, graphql, Query } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Button, Progress, Table, Card, CardBody, Row, Col } from 'reactstrap'
import AttributeModal from './AttributeModal'
import moment from 'moment'
import "../Material/ListingHeader.css"
import PrimaryListingContainer from "../Material/PrimaryListingContainer"
import renderEnumToInt from "../Functions/renderEnumToInt"

class AttributeList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            loading: false,
            error: "",
            orderBy: this.props.configurations && this.props.configurations.defaultOrder ? this.props.configurations.defaultOrder : null,
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    updateOrder = (name) => {
        name = name === "pk" ? "id" : name
        name = name && name.includes(".") ? name.replace(".", "__") : name
        this.setState({ orderBy: (this.state.orderBy === name ? "-" + name : name) })
    }

    render() {
        let configurations = this.props.configurations
        return (<>
            <Query query={configurations.query} variables={{ ...this.props.filters, first: 30, orderBy: (this.state.orderBy ? [this.state.orderBy] : null) }} fetchPolicy="cache-and-network">
                {({ loading, error, data, refetch, fetchMore }) => {
                    return <>
                        {/* <Card className="bos-attributes-listing-table-wrapper">
                            <CardBody>
                                <Row>
                                    <Col sm="12">
                                        <div className="table-container"> */}
                        <PrimaryListingContainer
                            listLoading={loading}
                            totalFetchedRows={data.dataList && data.dataList.edges.length}
                            pageInfo={data.dataList && data.dataList.pageInfo}
                            loadMoreEntries={data.dataList && data.dataList.pageInfo && data.dataList.pageInfo.hasNextPage ? () => fetchMore({
                                variables: { cursor: data.dataList.pageInfo.endCursor },
                                updateQuery: (prev, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) return prev
                                    return Object.assign({}, prev, {
                                        dataList: { ...fetchMoreResult.dataList, edges: [...prev.dataList.edges, ...fetchMoreResult.dataList.edges] }
                                    })
                                }
                            }) : null}
                            refetchListQuery={() => refetch()}>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {configurations.tableHeaders.map(tableHeader =>
                                            tableHeader.sortable ? <th key={tableHeader.id} onClick={() => this.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{tableHeader.name}&nbsp;<i className={"fa " + (this.state.orderBy === (tableHeader.id == "pk" ? "id" : tableHeader.id) ? "fa-sort-asc" : this.state.orderBy === "-" + (tableHeader.id == "pk" ? "id" : tableHeader.id) ? "fa-sort-desc" : "fa-sort")} aria-hidden="true"></i></th> : <th key={tableHeader.id}>{tableHeader.name}</th>)
                                        }
                                        {this.props.hasUpdatePermission && configurations.updateMutation && configurations.updateMutation.mutation && <th>Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {error && <tr><td colSpan={100}>{`Error! ${error.message}`}</td></tr>}
                                    {!loading && !error && data.dataList && data.dataList.edges && data.dataList.edges.length > 0 ? data.dataList.edges.map((item, i) =>
                                        <tr key={i}>
                                            {configurations.tableHeaders.map((tableHeader, j) =>
                                                <td key={i + "_" + j} className={tableHeader.type === "boolean" ? "" : ""} style={tableHeader.maxWidth && { maxWidth: tableHeader.maxWidth }}>
                                                    {((props = tableHeader.id.split(".")) => {
                                                        let value = props.length > 1 ? (props.reduce((a, prop) => a && a[prop] ? a[prop] : "", item.node)) : (item.node[tableHeader.id])
                                                        if (tableHeader.type === "boolean") {
                                                            return <i className={"fa fa-circle " + (value === true ? "text-success" : "text-danger")} ></i>
                                                        } else if (!value) {
                                                            return "--"
                                                        } else if (tableHeader.type === "datetime") {
                                                            return moment(value).tz("America/New_York").format('lll')
                                                        } else if (tableHeader.type === "select" && tableHeader.optionsQuery && tableHeader.fetchValue) {
                                                            return <Query query={tableHeader.optionsQuery} fetchPolicy="cache-first">
                                                                {({ loading, error, data }) => {
                                                                    return <>
                                                                        {loading && <Progress animated color="info" value="100" />}
                                                                        {error && <span>--</span>}
                                                                        {data.dataList && data.dataList.edges && data.dataList.edges.length > 0 && (() => {
                                                                            let dataNode = data.dataList.edges.filter(option => (option.node ? option.node[tableHeader.optionSelector] === value : option[tableHeader.optionSelector] === value))
                                                                            return dataNode && dataNode[0] ? dataNode[0].node && dataNode[0].node.name ? dataNode[0].node.name : dataNode[0].description ? dataNode[0].description : "--" : "--"
                                                                        })()
                                                                        }
                                                                    </>
                                                                }}
                                                            </Query>
                                                        } else {
                                                            return String(value)
                                                        }
                                                    })()}
                                                </td>
                                            )}
                                            {this.props.hasUpdatePermission && configurations.updateMutation && configurations.updateMutation.mutation && <td><i className="fa fa-pencil-square-o" aria-hidden="true" onClick={() => this.toggleModal("editAttribute" + i)} />
                                                {this.state.openModal === "editAttribute" + i && <AttributeModal open={this.state.openModal === "editAttribute" + i} handleClose={() => this.toggleModal(this.state.openModal === "editAttribute" + i)} isUpdate configurations={configurations} data={item.node} refetch={() => refetch()} />}
                                            </td>}
                                        </tr>
                                    ) : <tr>No data found!</tr>}
                                </tbody>
                            </Table>
                        </PrimaryListingContainer>
                        {/* </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Button onClick={() => refetch()} color="outline-info" className="mt-2" disabled={loading || error}>Reload&nbsp;&nbsp;<i className={"fa fa-refresh"} /></Button>
                        <Button
                            className="float-right mt-2"
                            color="outline-dark"
                            disabled={!(data.dataList && data.dataList.pageInfo && data.dataList.pageInfo.hasNextPage)}
                            onClick={() => fetchMore({
                                variables: { cursor: data.dataList.pageInfo.endCursor },
                                updateQuery: (prev, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) return prev
                                    return Object.assign({}, prev, {
                                        dataList: { ...fetchMoreResult.dataList, edges: [...prev.dataList.edges, ...fetchMoreResult.dataList.edges] }
                                    })
                                }
                            })} >
                            Show More&nbsp;&nbsp;<i className={"fa fa-angle-double-down"} />
                        </Button> */}
                    </>
                }}
            </Query>
        </>)
    }
}

export default compose(
    withApollo,
)(AttributeList)