import React, { Component } from 'react'
import { compose, withApollo, Query } from 'react-apollo'
import { Button, Col, Input, Label, Row, Alert } from 'reactstrap'
import Loader from '../Loader'
import './NotesHistory.css'
import DatetimeRenderer from '../DatetimeRenderer'
import { DriverNotesDetails, CarNotesDetails } from "./Queries"
import { UpdateCarNotes, UpdateDriverNotes, UpdateReservation, ChangeReturnMutation, UpdateCustomerLead, UpdateInsuranceMutation, UpdateCarDealMutation, UpdateCarIssueMutation } from "./Mutation"
class NotesHistoryDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: '',
            input: {
                [this.props.name]: ''
            },
            error: null,
            loading: false,
        }
    }

    updateInput = (e) => {
        const { name, value } = e.target
        this.setState(prevState => ({
            input: {
                ...prevState.input,
                [name]: value,
            },
        }))
    };

    updateNotes = () => {
        this.setState({ loading: true })
        if (this.props.driverId || this.props.carId) {
            let input = this.state.input
            let objectIdKey = null
            let mutationToUse = null
            let resultDataKey = null

            switch (this.props.notesType) {
                case 'CAR':
                case 'GENERAL':
                    if (!['RESERVATION', 'RETURN', 'LEAD', 'INSURANCE', 'DEAL', 'CAR ISSUE'].includes(this.props.notesType)) {
                        objectIdKey = 'id'
                        mutationToUse = UpdateCarNotes
                        resultDataKey = 'updateCar'
                    }
                    break
                case 'RESERVATION':
                    objectIdKey = 'reservationId'
                    mutationToUse = UpdateReservation
                    resultDataKey = 'updateReservation'
                    break
                case 'RETURN':
                    objectIdKey = 'carReturnId'
                    mutationToUse = ChangeReturnMutation
                    resultDataKey = 'changeReturn'
                    break
                case 'LEAD':
                    objectIdKey = 'id'
                    mutationToUse = UpdateCustomerLead
                    resultDataKey = 'updateCustomerLead'
                    break
                case 'INSURANCE':
                    objectIdKey = 'insuranceStatusId'
                    mutationToUse = UpdateInsuranceMutation
                    resultDataKey = 'updateInsuranceStatus'
                    break
                case 'DEAL':
                    objectIdKey = 'dealId'
                    mutationToUse = UpdateCarDealMutation
                    resultDataKey = 'updateCarDeal'
                    break
                case 'CAR ISSUE':
                    objectIdKey = 'issueId'
                    mutationToUse = UpdateCarIssueMutation
                    resultDataKey = 'updateCarIssue'
                    break
                default:
                    objectIdKey = 'id'
                    mutationToUse = UpdateDriverNotes
                    resultDataKey = 'updateDriver'
                    break
            }

            if (objectIdKey) {
                input[objectIdKey] = this.props.objectId || this.props.carId || this.props.driverId
            }

            this.props.client.mutate({
                mutation: mutationToUse,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data[resultDataKey] && result.data[resultDataKey].ok) {
                    this.props.refetchQuery()
                    this.setState({ input: {}, loading: false, error: null })
                } else {
                    let error = 'An error occurred, could not complete request.'
                    if (result && result.data && result.data[resultDataKey] && result.data[resultDataKey].errors[0] && result.data[resultDataKey].errors[0].messages) {
                        error = result.data[resultDataKey].errors[0].messages.toString()
                    }
                    this.setState({ error })
                }
                this.setState({ loading: false })
            }).catch((err) => {
                this.setState({ loading: false, error: 'An error has occurred. Please contact admin: ' + err })
            })
        } else {
            this.setState({ loading: false, error: 'Missing Required Field' })
        }
    };


    render() {
        const { notesId, objectType, loading, recentNotesId } = this.props
        const query = objectType === 'carNotes' ? CarNotesDetails : DriverNotesDetails
        return (
            <>
                {this.props.loading || this.state.loading ? <Loader /> :
                    <div className="notes-history-details">
                        {notesId && notesId !== null ?
                            <Query query={query} variables={{ notesId }} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true} >
                                {({ loading: queryLoading, data, error }) => {
                                    if (queryLoading || loading) return <Loader />
                                    const notesDetails = data ? data[`${objectType}Details`] : null
                                    if (notesDetails && this.state.input[this.props.name] === '' && notesDetails.changedTo !== this.state.input[this.props.name]) {
                                        this.setState({
                                            input: { [this.props.name]: notesDetails.changedTo }
                                        })
                                    }
                                    return notesDetails && (
                                        <div className="notes-history-details-container">
                                            {(error || this.state.error) && <Alert color="danger">{this.state.error ? this.state.error : error}</Alert>}
                                            <Row>
                                                <Col>
                                                    <Label>Date Added</Label><br />
                                                    <small><DatetimeRenderer datetime={notesDetails.dateAdded || '0000-00-00'} /></small>
                                                </Col>
                                                <Col>
                                                    <Label>Username</Label><br />
                                                    <small>{notesDetails.user ? notesDetails.user.username : '--'}</small>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Label>{recentNotesId === notesDetails.id ? 'Previous' : 'Changed From'}</Label><br />
                                                    <small>{notesDetails.changedFrom || '--'}</small>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Label>{recentNotesId === notesDetails.id ? 'Current' : 'Changed To'}</Label>
                                                    {recentNotesId === notesDetails.id ?
                                                        <Input
                                                            type="textarea"
                                                            disabled={recentNotesId !== notesDetails.id}
                                                            name={this.props.name}
                                                            id={this.props.name}
                                                            defaultValue={notesDetails.changedTo}
                                                            placeholder="Edit Notes"
                                                            onChange={this.updateInput}
                                                            style={{ fontSize: '10px' }}
                                                        /> :
                                                        <><br /><small>{notesDetails.changedTo || '--'}</small></>
                                                    }
                                                </Col>
                                            </Row>
                                            {recentNotesId === notesDetails.id &&
                                                <Row><Col className="text-right pt-3">
                                                    <Button
                                                        size="sm"
                                                        className="primary"
                                                        onClick={this.updateNotes}
                                                        disabled={this.state.input[this.props.name] === notesDetails.changedTo}
                                                    >Update</Button>
                                                </Col></Row>
                                            }
                                        </div>
                                    )
                                }}
                            </Query> :
                            <div className="notes-history-details-container pt-2">
                                {(this.state.error) && <Alert color="danger">{this.state.error ? this.state.error : "--"}</Alert>}
                                <Row>
                                    <Col>
                                        <Label>Add New Notes</Label>
                                        <Input
                                            type="textarea"
                                            name={this.props.name}
                                            id={this.props.name}
                                            placeholder="Edit Notes"
                                            onChange={this.updateInput}
                                            style={{ fontSize: '10px' }}
                                        />
                                    </Col>
                                </Row>
                                <Row><Col className="text-right pt-3">
                                    <Button
                                        size="sm"
                                        className="primary"
                                        onClick={this.updateNotes}
                                    >Add Notes</Button>
                                </Col></Row>
                            </div>
                        }
                    </div>
                }
            </>
        )
    }
}

export default compose(
    withApollo,
)(NotesHistoryDetail)
