/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { Component } from 'react'
import { compose, Query } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import Select from 'react-select'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, NavItem, FormGroup, Nav, InputGroupAddon, InputGroup, Label, Input, Card, Collapse, CardBody, Form, Progress, Alert, InputGroupText } from 'reactstrap'
import renderEnumToInt from '../Functions/renderEnumToInt'
import toTitleCase from '../Functions/toTitleCase'

class AttributeModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: "",
            input: null,
            openModal: "",
            tableHeader: this.props.configurations && this.props.configurations.tableHeaders ? this.props.configurations.tableHeaders : null,
            crudConfig: this.props.configurations ? (this.props.isUpdate && this.props.configurations.updateMutation ? this.props.configurations.updateMutation : this.props.configurations.createMutation ? this.props.configurations.createMutation : null) : null,
        }
    }

    toggleModal = (openModal) => {
        if (openModal && openModal == this.state.openModal) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: openModal })
        }
    }

    handleClose = () => {
        this.setState({
            loading: false,
            error: "",
            input: null,
            openModal: "",
            tableHeader: null,
            crudConfig: null,
        })
        this.props.handleClose()
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let input = this.state.input
        this.setState({ loading: true, error: "" })
        if (this.props.isUpdate) {
            input = {
                ...input,
                [this.props.configurations.tableHeaders[0].inputIdentifier]: this.props.data.id,
            }
        }

        let missingFields = this.state.crudConfig.requiredFields && this.state.crudConfig.requiredFields.length > 0 ? this.state.crudConfig.requiredFields.filter(item => !input || input[item] == null) : null
        console.log(input, missingFields, this.state.crudConfig.requiredFields)
        if (missingFields && missingFields.length > 0) {
            this.setState({ loading: true, error: `Following fields are missing ${missingFields.toString()}` })
            return
        }
        try {
            this.props.client.mutate({
                mutation: this.state.crudConfig.mutation,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.result && ((result.data.result.ok != null && result.data.result.ok) || (result.data.result.ok == null && result.data.result.errors == null))) {
                    this.setState({ loading: false })
                    if (this.props.refetch) {
                        this.props.refetch()
                    }
                    this.handleClose()
                } else if (result.data.result.errors && result.data.result.errors.length > 0 && result.data.result.errors[0] && result.data.result.errors[0].messages) {
                    this.setState({ loading: false, error: result.data.result.errors[0].messages.toString() })
                } else {
                    this.setState({ loading: false, error: "An error has occurred. Please check your input or contact admin." })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: err.toString() })
            })
        } catch {
            this.setState({ loading: false, error: "An error has occurred. Please contact admin." })
        }
    }

    componentDidMount() {
        let haveDefaultValues = this.state.crudConfig && this.state.crudConfig.inputFields && this.state.crudConfig.inputFields.length > 0 && this.state.tableHeader ? this.state.tableHeader.filter(item => this.state.crudConfig.inputFields.includes(item.id) && item.defaultValue != null) : null
        let input = this.state.input
        haveDefaultValues.forEach(element =>
            input = { ...input, [element.inputIdentifier ? element.inputIdentifier : element.id]: element.defaultValue }
        )
        this.setState({ input: input })

        if (this.props.isUpdate) {
            let valuesToUpdate = this.state.crudConfig && this.state.crudConfig.inputFields && this.state.crudConfig.inputFields.length > 0 && this.state.tableHeader ? this.state.tableHeader.filter(item => this.state.crudConfig.inputFields.includes(item.inputIdentifier ? item.inputIdentifier : item.id)) : null
            input = {}
            valuesToUpdate.forEach(item => {
                let props = item.id.split(".")
                let value = props.length > 1 ? (props.reduce((a, prop) => a && a[prop] ? a[prop] : "", this.props.data)) : (this.props.data[item.id])
                input = { ...input, [item.inputIdentifier ? item.inputIdentifier : item.id]: value }
            })
            this.setState({ input })
        }
    }

    renderSelect = (props) => {
        let value = this.state.input ? this.state.input[props.item.inputIdentifier ? props.item.inputIdentifier : props.item.id] : null
        let selectedValue = value !== null ? props.options.filter(option =>
            [option.label, option.value].includes(value && String(value).substr(0, 2) === "A_" ? renderEnumToInt(value) : props.item.optionSelector === "description" ? toTitleCase(value) : value)
        )[0] : null
        if (selectedValue && selectedValue.value != this.state.input[props.item.inputIdentifier ? props.item.inputIdentifier : props.item.id]) {
            this.setState({ input: { ...this.state.input, [props.item.inputIdentifier ? props.item.inputIdentifier : props.item.id]: selectedValue.value } })
        }
        return <Select
            className="bos-custom-select" classNamePrefix="bos-select"
            value={selectedValue}
            options={props.options}
            onChange={(option) => this.setState({ input: { ...this.state.input, [props.item.inputIdentifier ? props.item.inputIdentifier : props.item.id]: option.value } })}
        />
    }

    render() {
        return <Modal isOpen={this.props.open}>
            <Form onSubmit={this.handleSubmit}>
                <ModalHeader> {this.props.isUpdate ? "Update " : "Create "} {this.props.configurations.title} </ModalHeader>
                <ModalBody>
                    {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                    {this.state.crudConfig && this.state.crudConfig.inputFields && this.state.crudConfig.inputFields.length > 0 && this.state.tableHeader && this.state.tableHeader.filter(item => this.state.crudConfig.inputFields.includes(item.inputIdentifier ? item.inputIdentifier : item.id) && item.id !== "pk").map(item =>
                        <FormGroup>
                            <Label for={item.inputIdentifier ? item.inputIdentifier : item.id} className="d-block"><small>{item.name}</small></Label>
                            {item.type && item.type === "select" ?
                                item.optionsQuery ?
                                    <Query query={item.optionsQuery} fetchPolicy="cache-and-network">
                                        {({ loading, error, data }) => {
                                            return <>
                                                {loading && <Progress animated color="info" value="100" />}
                                                {error && <span>{`Error! ${error.message}`}</span>}
                                                {data && data.dataList && data.dataList.edges && data.dataList.edges.length > 0 && this.renderSelect({
                                                    options: data.dataList.edges.map(option => (option.node ? { value: option.node[item.optionSelector], label: option.node.name } : { value: option[item.optionSelector] && String(option[item.optionSelector].substr(0, 2)) === "A_" ? renderEnumToInt(option[item.optionSelector]) : item.optionSelector === "description" ? option[item.optionSelector] : option[item.optionSelector], label: toTitleCase(option.description) })),
                                                    item: item,
                                                })}
                                            </>
                                        }}
                                    </Query>
                                    : "No API Found for Fetching Options!"
                                : item.type && item.type === "boolean" ?
                                    <>
                                        <span>YES</span>
                                        <div className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                className="toggle-switch-checkbox"
                                                name={item.inputIdentifier ? item.inputIdentifier : item.id}
                                                id={item.inputIdentifier ? item.inputIdentifier : item.id}
                                                checked={this.state.input && this.state.input[item.inputIdentifier ? item.inputIdentifier : item.id] != null ? !this.state.input[item.inputIdentifier ? item.inputIdentifier : item.id] : null}
                                                onChange={() => this.setState({ input: { ...this.state.input, [item.inputIdentifier ? item.inputIdentifier : item.id]: !this.state.input[item.inputIdentifier ? item.inputIdentifier : item.id] } })}
                                            />
                                            <label className="toggle-switch-label" htmlFor={item.inputIdentifier ? item.inputIdentifier : item.id}>
                                                <span className="toggle-switch-inner" />
                                                <span className="toggle-switch-switch" />
                                            </label>
                                        </div>
                                        <span>NO</span>
                                    </>
                                    :
                                    <Input
                                        id={item.inputIdentifier ? item.inputIdentifier : item.id} name={item.inputIdentifier ? item.inputIdentifier : item.id} type={item.type ? item.type : "text"}
                                        value={this.state.input && this.state.input[item.inputIdentifier ? item.inputIdentifier : item.id] ? this.state.input[item.inputIdentifier ? item.inputIdentifier : item.id] : null}
                                        onChange={(e) => this.setState({ input: { ...this.state.input, [item.inputIdentifier ? item.inputIdentifier : item.id]: e.target.value } })}
                                    />
                            }
                        </FormGroup>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>{this.props.isUpdate ? "Update" : "Create"} {this.props.configurations.title}</Button>
                    <Button color="secondary" onClick={this.handleClose}>Close</Button>
                </ModalFooter>
            </Form>
        </Modal>
    };
}
export default compose(
    withApollo,
)(AttributeModal)