import gql from 'graphql-tag'

const AllBranchesQuery = gql`query AllBranchesQuery{
    dataList:allBranches {
        edges {
            node {
                id
                pk
                name
            }
        }
    }
}`

const AllBranchSettingKeys = gql`query AllBranchSettingKeys{
    dataList:allBranchSettingKeys {
        edges {
            node {
                id
                pk
                name
            }
        }
    }
}`

const AllGroupTypes = gql`query AllGroupTypes{
    dataList:allGroupTypes {
        edges {
            node {
                id
                name
            }
        }
    }
}`

const AllChargeTypes = gql`query AllChargeTypes{
    dataList: allChargeTypes{
        edges {
            node {
                id
                name
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const AllContentTypes = gql`query AllContentTypes($allowedForObjectPermission: Boolean){
    allContentTypes(allowedForObjectPermission:$allowedForObjectPermission){
        edges {
            node {
                id
                appLabel
                model
            }
        }
    }
}`

const AllGroups = gql`query AllGroups{
    allGroups{
        edges {
            node {
                id
                name
            }
        }
    }
}`

const AllGroupObjectPermissions = gql`query AllGroupObjectPermissions($cursor: String, $first:Int){
    allGroupObjectPermissions(first:$first, after: $cursor){
        edges {
            node {
              id
              dateAdded
              groups {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              contentType {
                id
                appLabel
                model
              }
              objectId
              objectName
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`

export {
    AllBranchesQuery, AllChargeTypes, AllBranchSettingKeys, AllContentTypes, AllGroupObjectPermissions, AllGroups, AllGroupTypes
}