

export default function renderEnumToInt(enumStr){
    let enumInt = undefined
    if(enumStr){
        if(enumStr.includes("A_")){//Start with A_ means its positive integer
            enumInt = parseInt(enumStr.split("_")[1])
        }else if(enumStr.includes("_")){//Start with A_ means its negative integer
            enumInt = parseInt(enumStr.split("_")[1])
            enumInt = enumInt * -1
        }
    }
    return enumInt
}