import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Row, Col, Label, Nav, NavItem, NavLink, Container, Badge } from 'reactstrap'
import Loader from "../../Material/Loader"
import OverviewTab from "./PermissionDetailTab/OverviewTab"
import gql from 'graphql-tag'
const PermissionQuery = gql`query Permission($id: ID!){
    permission(id: $id) {
      id
      name
    }
  }  
`

class PermissionDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            activeTab: "overview"
        }
    }

    render() {
        return (

            <Container fluid className="bos-object-detail-container">
                {this.props.loading ? <Loader /> : this.props.permission && <>
                    <Row className="bos-object-detail-header">
                        <Col xs="1">
                            <span id="box-object-icon" style={{ padding: 30 }}>
                                <i style={{ fontSize: 20 }} className="fa fa-eye-slash" aria-hidden="true"></i>
                            </span>
                        </Col>
                        <Col xs={5} className="bos-object-title-wrapper">
                            <Label>
                                {this.props.permission.name ? this.props.permission.name : "-"}
                            </Label>
                        </Col>
                    </Row>
                    <Row className="bos-object-detail-body">
                        <Nav pills>
                            <NavItem>
                                <NavLink onClick={() => this.setState({ activeTab: "overview" })} active={this.state.activeTab === "overview"}>Overview</NavLink>
                            </NavItem>
                        </Nav>
                        <div className="bos-object-nav-content">
                            {this.state.activeTab === "overview" && <OverviewTab permissionId={this.props.permission.id} />}
                        </div>
                    </Row>
                </>
                }
            </Container>
        )

    }
}

export default compose(
    withApollo,
    graphql(PermissionQuery, {
        options: ({ match }) => ({
            fetchPolicy: 'cache-and-network',
            variables: {
                id: match.params.id
            }

        }), props({ data: { permission, loading, variables, refetch } }) {
            return {
                permission,
                loading,
                variables,
                refetchPermissionQuery: () => {
                    return refetch({
                        query: PermissionQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { permission: fetchMoreResult.permission }
                        },
                    })
                },
            }
        }
    }),
)(PermissionDetails)
