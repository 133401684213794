import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, graphql, withApollo } from 'react-apollo'
import { Col, Label, Nav, NavItem, NavLink, Badge } from 'reactstrap'
import Row from 'reactstrap/lib/Row'
import Container from "reactstrap/lib/Container"
import Loader from "../../Material/Loader"
import OverviewTab from "./TenantDetailTabs/OverviewTab"
import MemberTab from "./TenantDetailTabs/MemberTab"
import "./TenantListContainer.css"
import BranchTab from "./TenantDetailTabs/BranchTab"
import RestrictedSection from "../../Components/RestrictedSection"
const TenantQuery = gql`query Tenant($id: ID!){
    tenant(id: $id){
        id
        name
        customId
        isActive
        logoImage
        defaultBranch {
            id
            name
        }
    }
}`

class BranchDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            activeTab: "overview",
        }
    }

    render() {
        return (<Container fluid className="bos-object-detail-container">
            {this.props.loading ? <Loader /> : this.props.tenant && <>
                <Row className="bos-object-detail-header">
                    <Col xs="1">
                        <span id="box-object-icon" >
                            <img width={100} src={this.props.tenant.logoImage} alt={this.props.tenant.name + " Logo"} />
                        </span>
                    </Col>
                    <Col xs={5} className="bos-object-title-wrapper">
                        <Label>
                            {this.props.tenant.name}
                        </Label>
                        &nbsp;&nbsp;&nbsp;{this.props.tenant.isActive ? <i className="fa fa-check-circle fa-2x text-success" aria-hidden="true" /> : <i className="fa fa-times-circle fa-2x text-danger" aria-hidden="true" />}
                    </Col>
                    <Col xs={3} className="bos-object-property-wrapper">
                        <Label>
                            {this.props.tenant.customId}
                        </Label>
                    </Col>
                    <Col xs={3} className="bos-object-property-wrapper">
                        <Label>
                            <Badge className='bos-badge-blue'>{this.props.tenant.defaultBranch ? this.props.tenant.defaultBranch.name : "--"}</Badge>
                        </Label>
                    </Col>
                </Row>
                <Row className="bos-object-detail-body">
                    <Nav pills>
                        <NavItem>
                            <NavLink onClick={() => this.setState({ activeTab: "overview" })} active={this.state.activeTab === "overview"}>Overview</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={() => this.setState({ activeTab: "branch" })} active={this.state.activeTab === "branch"}>Branches</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink onClick={() => this.setState({ activeTab: "members" })} active={this.state.activeTab === "members"}>Members</NavLink>
                        </NavItem>
                    </Nav>

                    <div className="bos-object-nav-content">
                        {this.state.activeTab === "overview" &&
                            <RestrictedSection requiredPermission="view_tenant">
                                <OverviewTab tenantId={this.props.tenant.id} currentUser={this.props.currentUser} />
                            </RestrictedSection>
                        }
                        {this.state.activeTab === "branch" &&
                            <RestrictedSection requiredPermission="view_branch">
                                <BranchTab tenantId={this.props.tenant.id} currentUser={this.props.currentUser} />
                            </RestrictedSection>
                        }
                        {this.state.activeTab === "members" &&
                            <RestrictedSection requiredPermission="view_member">
                                <MemberTab tenantId={this.props.tenant.id} currentUser={this.props.currentUser} />
                            </RestrictedSection>
                        }
                    </div>
                </Row>
            </>
            }
        </Container>)
    }
}

export default compose(
    withApollo,
    graphql(TenantQuery, {
        options: ({ match }) => ({ variables: { id: match.params.id }, fetchPolicy: 'network-only' }),
        props({ data: { tenant, loading, refetch, variables } }) {
            return {
                tenant, loading, variables,
                refetchBranchQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    return refetch({
                        query: TenantQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { tenant: fetchMoreResult.tenant }
                        },
                    })
                },
            }
        }
    })
)(BranchDetail)
