/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import CloseCarIssue from '../CarIssues/CloseCarIssue'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, NavItem, NavLink, Nav, InputGroupAddon, InputGroup, Label, Input, Card, Collapse, CardBody, Form, Progress, Alert, InputGroupText } from 'reactstrap'
import UploadDocumentModal from '../Material/DocumentModal/UploadDocumentModal'
// import { ReactComponent as CarInfo } from '../assets/images/icons/build.svg';
import { ReactComponent as CarIssues } from '../assets/images/icons/car-issues.svg'
import { ReactComponent as DriverIcon } from '../assets/images/icons/driver-icon.svg'
import gql from 'graphql-tag'
import moment from 'moment'
import renderEnumToInt from '../Functions/renderEnumToInt'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import toTitleCase from '../Functions/toTitleCase'

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={16}
        defaultCenter={{ lat: props.latitude, lng: props.longitude }}
    >
        {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
    </GoogleMap>
))

const CarInfoQuery = gql`query CarInfo($id: Int!){
    car(id: $id){
        id
        pk
        mileageSinceLastOilChange
        getDaysTillDmvInspection
        lastDmvInspectionDate
        lastOilChangeDate
        vin
        pk
        hasOpenIssues
        daysInStage
        carModel{
            id
            make
            name
            series
            groupType{
                id
                name
            }
        }
        notes
        location
        weeklyCharge
        isReady
        year
        color
        stage
        dmvPlate
        reservedFor
        currentAgreement{
            id
            startDate
            driver{
                id
                name
                phone
                tlcLicense
            }
        }
        tags{
            id
            name
        }
        activePolicy{
            id
            insurancePolicy{
                id
              policyNumber
              broker{
                  id
                name
              }
            }
        }
        agreementSet{
            edges{
                node{
                    id
                    stage
                    startDate
                    endDate
                    driver{
                        id
                        firstName
                        lastName
                        tlcLicense
                    }
                }
            }
        }
    }
}`

const CarIssuesQuery = gql`query CarIssuesQuery($carId:ID,$first:Int) {
    carIssues: allCarIssues(first:$first,carId:$carId, statusIn:["FIXING","URGENT","OPEN"]){
      edges{
        cursor
        node{
            dateAdded
            dateModified
            id
            pk
            issueDesc
            canPickup
            user{
              username
            }
            issueCategory{
                id
                name:category
            }
            status
        }
      }
      pageInfo{
        endCursor
        hasNextPage
      }
    } 
}`

const locateCar = gql`
mutation locateCar($input: TrackerStatusChangeMutationInput!){
    changeTrackerStatus(input: $input){
        ok
        errors{
            field
            messages
        }
        car {
            id
            pk
            trackerStatus
            hasTracker
        }
        location{
            address
            longitude
            latitude
            speed
            date
        }
    }
}`

const UpdateCarIssueMutation = gql`mutation UpdateCarIssueMutation($input: UpdateCarIssueMutationInput!){
    updateCarIssue(input:$input){
        ok
        errors{
            messages
        }
    }
} `

class CarInfoModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: "Car Info",
            loading: false,
            error: "",
            location: null,
            openModal: "",
            collapse: "",
            modal: ""
        }
        this.locateCar()
    }

    toggleCollapse = (section) => {
        if (section && section == this.state.collapse) {
            this.setState({ collapse: "" })
        } else {
            this.setState({ collapse: section })
        }
    }

    refetchQuery = () => {
        this.props.refetchCarIssuesQuery()
        this.props.refetchQuery()
    }

    locateCar = () => {
        if (this.props.car && this.props.car.id) {
            this.setState({ loading: true })
            let input = { carId: this.props.car.id, command: 'Locate' }
            let location = { address: "", longitude: "", latitude: "", speed: "", date: "" }

            this.props.locate(input).then(result => {
                this.setState({ changingStatus: false })
                if (result.data.changeTrackerStatus.location) {
                    location = result.data.changeTrackerStatus.location
                    location = {
                        address: location.address, longitude: Number(location.longitude), latitude: Number(location.latitude), speed: location.speed, date: location.date
                    }
                    this.setState({ location: location, loading: false })
                } else {
                    this.setState({ error: "An error occured while retrieving the location", loading: false })
                }
            }).catch(error => {
                this.setState({ error: "An error occured while retrieving the location", loading: false })
            })
        }
    }

    updateCarIssue(issueId, canPickup) {
        if (issueId && canPickup != null) {
            this.setState({ loading: true })
            let input = {}
            input["id"] = issueId
            input["canPickup"] = canPickup
            this.props.client.mutate({
                mutation: UpdateCarIssueMutation,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updateCarIssue && result.data.updateCarIssue.ok) {
                    this.setState({ loading: false })
                    this.refetchQuery()
                } else {
                    this.setState({ loading: false, error: "An error has occured. Please try again or contact admin." })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occured. Please try again or contact admin." })
            })
        }
    }

    render() {
        return <Modal isOpen={this.props.open} className="modalcarinfo">
            <ModalHeader> <i className="fa fa-car" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;<span>Details for Car {this.props.car ? this.props.car.pk : ""}</span> </ModalHeader>
            <ModalBody>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={this.state.tab === "Car Info" ? "active" : ""} onClick={() => this.setState({ tab: "Car Info" })}>
                            <i className="fa fa-wrench fa-2x" style={{ color: "#303e67" }} aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;Car Info
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={this.state.tab === "Car Issues" ? "active" : ""} onClick={() => this.setState({ tab: "Car Issues" })}>
                            <CarIssues width={25} fill={this.props.car && this.props.car.hasOpenIssues ? "#DC3546" : "#198754"} />
                            &nbsp;&nbsp;&nbsp;&nbsp;Car Issues
                        </NavLink>
                    </NavItem>
                </Nav>
                {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                {(this.props.loading || this.state.loading) && <Progress animated color="info" value="100" />}
                {this.props.car &&
                    <section className="py-4">
                        {this.state.tab === "Car Info" && <>
                            <Row>
                                <Col sm="4">
                                    <InputGroup>
                                        <Label for="vin">VIN</Label>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-info"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input disabled id="vin" value={this.props.car.vin} />
                                    </InputGroup>
                                </Col>
                                <Col sm="3">
                                    <InputGroup>
                                        <Label for="model">Model</Label>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText><i className="fa fa-info"></i></InputGroupText>
                                        </InputGroupAddon>
                                        <Input disabled id="model" value={this.props.car.year + " " + toTitleCase(this.props.car.carModel.make) + " " + this.props.car.carModel.name} />
                                    </InputGroup>
                                </Col>
                                <Col sm="2">
                                    <InputGroup>
                                        <Label for="model">Model Series</Label>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText><i className="fa fa-info"></i></InputGroupText>
                                        </InputGroupAddon>
                                        <Input disabled id="model" value={this.props.car.carModel.series} />
                                    </InputGroup>
                                </Col>
                                <Col sm="3">
                                    <InputGroup>
                                        <Label for="model">Model Group Type</Label>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText><i className="fa fa-info"></i></InputGroupText>
                                        </InputGroupAddon>
                                        <Input disabled id="model" value={this.props.car.carModel.groupType ? this.props.car.carModel.groupType.name : "--"} />
                                    </InputGroup>
                                </Col>
                                <Col sm="4">
                                    <InputGroup>
                                        <Label for="location">Location</Label>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText><i className="fa fa-info"></i></InputGroupText>
                                        </InputGroupAddon>
                                        <Input disabled id="location" value={this.props.car.location} />
                                    </InputGroup>
                                </Col>
                                <Col sm="3">
                                    <InputGroup>
                                        <Label for="policy">Policy</Label>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-info"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input disabled id="policy" value={this.props.car.activePolicy && this.props.car.activePolicy.insurancePolicy && this.props.car.activePolicy.insurancePolicy.policyNumber ? this.props.car.activePolicy.insurancePolicy.policyNumber : "---"} />
                                    </InputGroup>
                                </Col>
                                <Col sm="2">
                                    <InputGroup>
                                        <Label for="broker">Broker</Label>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText><i className="fa fa-info"></i></InputGroupText>
                                        </InputGroupAddon>
                                        <Input disabled id="broker" value={this.props.car.activePolicy && this.props.car.activePolicy.insurancePolicy && this.props.car.activePolicy.insurancePolicy.broker && this.props.car.activePolicy.insurancePolicy.broker.name ? this.props.car.activePolicy.insurancePolicy.broker.name : "---"} />
                                    </InputGroup>
                                </Col>
                                <Col sm="3">
                                    <InputGroup>
                                        <Label for="dmvPlate">DMV Plate</Label>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText><i className="fa fa-info"></i></InputGroupText>
                                        </InputGroupAddon>
                                        <Input disabled className="readyonly" id="dmvPlate" value={this.props.car.dmvPlate} />
                                    </InputGroup>
                                </Col>
                                <Col sm="4">
                                    <InputGroup>
                                        <Label for="stage">Stage</Label>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-info"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input disabled id="stage" value={"Stage " + renderEnumToInt(this.props.car.stage) + ", since " + this.props.car.daysInStage + " days"} />
                                    </InputGroup>
                                </Col>
                                <Col sm="8">
                                    <InputGroup>
                                        <Label for="notes">Notes</Label>
                                        <Input type="text" disabled name="text" id="notes" value={this.props.car.notes} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <InputGroup>
                                        <Label for="liveLocation">Live Location</Label>
                                        <div className="w-100" style={{ color: "#DC3546" }}>

                                            {this.state.location && this.state.location.latitude && this.state.location.longitude ?
                                                <MyMapComponent
                                                    isMarkerShown
                                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDg4oSLbYS6boBTyYBWHmpIOUU__8Mfh4I&v=3.exp&libraries=geometry,drawing,places"
                                                    loadingElement={<div style={{ height: `100%` }} />}
                                                    containerElement={<div style={{ height: `250px` }} />}
                                                    mapElement={<div style={{ height: `100%` }} />}
                                                    latitude={this.state.location.latitude}
                                                    longitude={this.state.location.longitude}
                                                />
                                                : "Location Not Found!"
                                            }
                                        </div>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <InputGroup>
                                        <Label for="mileageSinceLastOilChange">Miles since last oil change</Label>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-info"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input disabled type="text" id="mileageSinceLastOilChange" value={this.props.car.mileageSinceLastOilChange} />
                                    </InputGroup>
                                </Col>
                                <Col sm="6">
                                    <InputGroup>
                                        <Label for="lastOilChangeDate">Last oil change</Label>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-info"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input disabled type="date" id="lastOilChangeDate" value={this.props.car.lastOilChangeDate ? moment(this.props.car.lastOilChangeDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) : ""} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <InputGroup>
                                        <Label for="getDaysTillDmvInspection">Days DMV inspection due</Label>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-info"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input disabled type="text" placeholder="34" id="getDaysTillDmvInspection" value={this.props.car.getDaysTillDmvInspection} />
                                    </InputGroup>
                                </Col>
                                <Col sm="6">
                                    <InputGroup>
                                        <Label for="lastDmvInspectionDate">Last dmv inspection</Label>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-info"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input disabled type="date" id="lastDmvInspectionDate" value={this.props.car.lastDmvInspectionDate ? moment(this.props.car.lastDmvInspectionDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) : "--"} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <h4>Drivers Who Drove This Car?</h4>
                            <section className="p-4" style={{ maxHeight: '350px', overflowY: 'auto' }}>
                                {this.props.car.agreementSet && this.props.car.agreementSet.edges && this.props.car.agreementSet.edges.length > 0 ? this.props.car.agreementSet.edges.map((rental, j) =>
                                    rental.node ?
                                        <Row className="driver-wrapper">
                                            <Col xs="6" className={(rental.node.stage && rental.node.stage == 10 ? " green" : "")}>
                                                <div className="driver-info">
                                                    <span style={{ verticalAlign: "top" }}>
                                                        <DriverIcon width={45} />&nbsp;&nbsp;
                                                    </span>
                                                    <span>
                                                        <a href={rental.node.driver && rental.node.driver.id ? "/driver/" + rental.node.driver.id : ""}>
                                                            {rental.node.driver && rental.node.driver.firstName ? rental.node.driver.firstName : ""} {rental.node.driver && rental.node.driver.lastName ? " " + rental.node.driver.lastName : ""}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-external-link"></i>
                                                        </a>
                                                        <br />
                                                        <span>{rental.node.driver && rental.node.driver.tlcLicense ? rental.node.driver.tlcLicense : "--"}</span>
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col xs="3">
                                                <strong>Start Date</strong><br />
                                                <span>{rental.node.startDate ? moment(rental.node.startDate).tz("America/New_York").format(moment.HTML5_FMT.DATETIME_LOCAL).replace("T", " ") : "--"}</span>
                                            </Col>
                                            <Col xs="3">
                                                <strong>End Date</strong><br />
                                                <span>{rental.node.endDate ? moment(rental.node.endDate).tz("America/New_York").format(moment.HTML5_FMT.DATETIME_LOCAL).replace("T", " ") : "--"}</span>
                                            </Col>
                                        </Row>
                                        : ""
                                ) : "No driver found on this car!"}
                            </section>
                        </>}
                        {this.state.tab === "Car Issues" && <>
                            <h4>THERE ARE {this.props.carIssues && this.props.carIssues.edges && this.props.carIssues.edges.length > 0 ? this.props.carIssues.edges.filter(carIssue => (carIssue.node.status && ["open", "urgent", "fixing"].includes(carIssue.node.status.toLowerCase()))).length : "0"} OPEN ISSUES FOUND ON THIS CAR</h4>
                            {this.props.carIssues && this.props.carIssues.edges && this.props.carIssues.edges.length > 0 &&
                                this.props.carIssues.edges.filter(carIssue => (carIssue.node.status && ["open", "urgent", "fixing"].includes(carIssue.node.status.toLowerCase()))).map((carIssue, i) => <>
                                    <Button className="carissue-open" color="primary" onClick={() => this.toggleCollapse('openissue' + i)} style={{ marginBottom: '1rem' }}>
                                        {carIssue.node.issueCategory ? carIssue.node.issueCategory.name : null}&nbsp;&nbsp;&nbsp;&nbsp;<small>({carIssue.node.canPickup ? "Can Pickup" : "Can Not Pickup"})</small>
                                        <span> <strong>Status:</strong> {carIssue.node.status ? carIssue.node.status : "---"} </span>
                                    </Button>
                                    <Collapse isOpen={this.state.collapse && this.state.collapse === "openissue" + i}>
                                        <Card className="mb-3">
                                            <CardBody>
                                                <Form>
                                                    <Row>
                                                        <Col sm="6">
                                                            <InputGroup>
                                                                <Label for="status">Status</Label>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>
                                                                        <i className="fa fa-info"></i>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input disabled type="text" value={carIssue.node.status ? carIssue.node.status : "---"} id="status" />
                                                            </InputGroup>
                                                        </Col>
                                                        <Col sm="6">
                                                            <InputGroup>
                                                                <Label for="date">Date Added</Label>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>
                                                                        <i className="fa fa-info"></i>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input disabled type="text" value={carIssue.node.dateAdded ? moment(carIssue.node.dateAdded).tz("America/New_York").format('YYYY-MM-DD') : "0000-00-00"} id="date" />
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <InputGroup>
                                                                <Label for="cat">Category</Label>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>
                                                                        <i className="fa fa-info"></i>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input disabled type="text" id="cat" value={carIssue.node.issueCategory ? carIssue.node.issueCategory.name : "--"} />
                                                            </InputGroup>
                                                        </Col>
                                                        {this.props.isFleetManagement &&
                                                            <Col>
                                                                <InputGroup>
                                                                    <Label for="cat">Can Pickup</Label>
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <Input addon type="checkbox" aria-label="Checkbox for following text input" checked={carIssue.node.canPickup} onChange={() => this.updateCarIssue(carIssue.node.id, !carIssue.node.canPickup)} />
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input placeholder="Can Pickup Car" />
                                                                </InputGroup>
                                                            </Col>
                                                        }
                                                    </Row>
                                                    <Row>
                                                        <Col sm="12">
                                                            <InputGroup>
                                                                <Label for="desc">Issue Description</Label>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>
                                                                        <i className="fa fa-info"></i>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input disabled type="textarea" id="desc" value={carIssue.node.issueDesc ? carIssue.node.issueDesc : "---"} />
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Button color="secondary" className="float-right" onClick={() => this.setState({ openModal: "CloseCarIssue" + i })}>
                                                                <i className={"fa fa-check-square"}></i><small>&nbsp;&nbsp;CLOSE ISSUE</small>
                                                            </Button>
                                                            {this.state.openModal === "CloseCarIssue" + i &&
                                                                <CloseCarIssue handleClose={() => { this.setState({ openModal: "" }) }} open={this.state.openModal === "CloseCarIssue" + i} id={carIssue.node.id} refetchQuery={this.refetchQuery} />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                </>)}
                            {/* {this.props.carIssues && this.props.carIssues.edges && this.props.carIssues.edges.length > 0 &&
                                this.props.carIssues.edges.filter(carIssue => (carIssue.node.status && !["open", "urgent", "fixing"].includes(carIssue.node.status.toLowerCase()))).map((carIssue, i) => <>
                                    <Button className="carissue-closed" color="primary" onClick={() => this.toggleCollapse('closedissue' + i)} style={{ marginBottom: '1rem' }}>{carIssue.node.issueCategory.name} <span> <strong>Status:</strong> {carIssue.node.status ? carIssue.node.status : "---"} </span>   </Button>
                                    <Collapse isOpen={this.state.collapse && this.state.collapse === "closedissue" + i}>
                                        <Card className="mb-3">
                                            <CardBody>
                                                <Form>
                                                    <Row>
                                                        <Col sm="6">
                                                            <InputGroup>
                                                                <Label for="status">Status</Label>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>
                                                                        <i className="fa fa-info"></i>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input disabled type="text" value={carIssue.node.status ? carIssue.node.status : "---"} id="status" />
                                                            </InputGroup>
                                                        </Col>
                                                        <Col sm="6">
                                                            <InputGroup>
                                                                <Label for="date">Date Added</Label>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>
                                                                        <i className="fa fa-info"></i>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input disabled type="text" value={carIssue.node.dateAdded ? moment(carIssue.node.dateAdded).tz("America/New_York").format('YYYY-MM-DD') : "0000-00-00"} id="date" />
                                                            </InputGroup>
                                                        </Col>
                                                        <Col sm="12">
                                                            <InputGroup>
                                                                <Label for="cat">Category</Label>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>
                                                                        <i className="fa fa-info"></i>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input disabled type="text" id="cat" value={carIssue.node.issueCategory.name} />
                                                            </InputGroup>
                                                        </Col>
                                                        <Col sm="12">
                                                            <InputGroup>
                                                                <Label for="desc">Issue Description</Label>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>
                                                                        <i className="fa fa-info"></i>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input disabled type="textarea" id="desc" value={carIssue.node.issueDesc ? carIssue.node.issueDesc : "---"} />
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                </>)} */}
                        </>}
                    </section>
                }
            </ModalBody>
            <ModalFooter>
                {this.props.car && <>
                    <UploadDocumentModal handleClose={() => this.setState({ openModal: "" })} open={this.state.openModal === "UploadDocumentModal" + this.props.car.pk} id={this.props.car.id} objectType="car" refetchQuery={() => { return true }} />
                    <Button color="primary" onClick={() => this.setState({ openModal: "UploadDocumentModal" + this.props.car.pk })}>Upload Car Docs</Button>{' '}</>
                }
                <Button color="secondary" onClick={this.props.handleClose}>Close</Button>
            </ModalFooter>
        </Modal>
    };
}
export default compose(
    withApollo,
    graphql(locateCar, {
        props: ({ mutate }) => ({
            locate: (input) => mutate({ variables: { input } }),
        })
    }),
    graphql(CarInfoQuery, {
        options: ({ id }) => ({ variables: { id: id } }),
        options: { fetchPolicy: 'network-only' },
        props({ data: { car, loading, fetchMore, variables } }) {
            return {
                car, loading, variables,
                refetchCarInfoQuery: () => {
                    return fetchMore({
                        query: CarInfoQuery,
                        variables: { ...variables },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { car: fetchMoreResult.car }
                        },
                    })
                },
            }
        },
    }),
    graphql(CarIssuesQuery,
        {
            options: ({ carId }) => ({ variables: { carId, first: 30 } }),
            props({ data: { loading, carIssues, fetchMore, variables } }) {
                return {
                    loading,
                    carIssues,
                    variables,
                    refetchCarIssuesQuery: () => {
                        //No apollo function that refetches in place with pagination considered so this function instead
                        let currentLength = carIssues && carIssues.edges ? carIssues.edges.length : 10
                        return fetchMore({
                            query: CarIssuesQuery,
                            variables: {
                                ...variables,
                                first: currentLength,
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                const newEdges = fetchMoreResult.carIssues.edges
                                const pageInfo = fetchMoreResult.carIssues.pageInfo
                                return {
                                    carIssues: {
                                        edges: [...newEdges],
                                        pageInfo,
                                        carId: previousResult.carId,
                                        __typename: previousResult.carIssues.__typename
                                    },
                                }
                            },
                        })
                    },
                    loadMoreEntries: () => {
                        return fetchMore({
                            query: CarIssuesQuery,
                            variables: {
                                cursor: carIssues.pageInfo.endCursor,
                                ...variables
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                const newEdges = fetchMoreResult.carIssues.edges
                                const pageInfo = fetchMoreResult.carIssues.pageInfo
                                return {
                                    carIssues: {
                                        edges: [...previousResult.carIssues.edges, ...newEdges],
                                        pageInfo,
                                        __typename: previousResult.carIssues.__typename
                                    },
                                }
                            },
                        })
                    },
                }
            },
        })
)(CarInfoModal)