import gql from "graphql-tag"

const UpdateSubscriptionMutation = gql`
  mutation UpdateSubscription ($input:UpdateSubscriptionMutationInput!){
    updateSubscription(input:$input){
          ok
          errors{
              field
              messages
          }
      }
  }`

const CancelSubscriptionMutation = gql`
  mutation CancelSubscription ($input:UpdateSubscriptionMutationInput!){
    updateSubscription(input:$input){
          ok
          errors{
              field
              messages
          }
      }
  }`

export { UpdateSubscriptionMutation, CancelSubscriptionMutation }