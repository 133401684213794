export default function datetimeToString(datetime) {
    const date = new Date(datetime)
    const now = new Date()

    // Get the difference in milliseconds
    const diffMs = now - date

    // Convert the milliseconds into different units
    const diffSeconds = diffMs / 1000
    const diffMinutes = diffSeconds / 60
    const diffHours = diffMinutes / 60
    const diffDays = diffHours / 24

    // Determine the appropriate message based on the time difference
    if (diffMinutes < 1) {
        return "just now"
    } else if (diffMinutes < 60) {
        return `${Math.round(diffMinutes)} minutes ago`
    } else if (diffHours < 24) {
        return `${Math.round(diffHours)} hours ago`
    } else if (diffDays < 30) {
        return `${Math.round(diffDays)} days ago`
    } else {
        return "more than 30 days ago"
    }
}