import React, { Component } from 'react'
import { compose } from 'react-apollo'
import gql from 'graphql-tag'
import { withApollo, graphql } from 'react-apollo/index'
import { Button, Col, Row, Input } from 'reactstrap'
import ListingFilters from "../../Material/ListingFilters"
import { debounce } from 'lodash'
import toTitleCase from "../../Functions/toTitleCase"
import States from "../../Material/States.json"
import Cities from "../../Material/Cities.json"
import NewBranchModal from "./NewBranchModal"
import updateFilters from "../../Functions/updateFilters"
import getFilterValuesFromQueryString from "../../Functions/getFilterValuesFromQueryString"
import handleConfigurationChange from "../../Functions/handleConfigurationChange"



const AllTenants = gql`query AllTenants($cursor: String, $first:Int,){
    optionsList: allTenants(first:$first, after: $cursor){
        edges {
            node {
                id
                name
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const AllPermissions = gql`query AllPermissions($cursor: String, $first:Int,){
    optionsList: allPermissions(first:$first, after: $cursor){
        edges {
            node {
                id
                name
                contentType{
                    model
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`

class BranchListHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            configurations: null,
            cities: []
        }
    }
    getStateVal = (value) => {
        for (let [key, state] of Object.entries(States)) {
            if (state.trim().toLowerCase() === value.trim().toLowerCase())
                return key
        }
        return undefined
    }
    getStates = () => {
        return Object.keys(Cities).map(state => ({ value: this.getStateVal(state), label: state }))
    }
    getCities = (stateCode) => {
        let cities = stateCode && Object.keys(States).includes(stateCode) && Object.keys(Cities).includes(States[stateCode]) ? Cities[States[stateCode]].map(city => ({ value: city, label: city })) : []
        return cities
    }
    getFilterConfigurations = () => {
        return [
            { type: "select", name: "stateCode", title: "Filter by State", options: this.getStates(), placeholder: "Filter by State", showFilter: true },
            { type: "select", name: "city", title: "Filter By City", options: [], placeholder: "Filter By City", valueSelector: "id", labelSelector: "name", showFilter: true },
            { type: "select", name: "tenant", title: "Filter by Tenant", optionsQuery: AllTenants, placeholder: "Filter by Tenant", valueSelector: "id", labelSelector: "name", showFilter: true },
            { type: "boolean", name: "isActive", title: "Active Branch", optionOne: "YES", optionTwo: "NO", showFilter: true },
        ]
    }
    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }
    updateSearchTerm = (searchTerm) => {
        updateFilters({ ...this.props.filterValues, searchTerm }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
    }
    removeFilter = (filterName) => {
        let filterValues = this.props.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            updateFilters({ ...this.props.filterValues }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
        }
    }
    getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type == "select") {
            let options = filterConfigs["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value == item).label)
                } else {
                    if (value === true) {
                        value = 'true'
                    }
                    else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean")
            value = toTitleCase(value.toString())
        return Array.isArray(value) ? value.join(", ") : value
    }
    setConfigurations = () => {
        let conf = this.getFilterConfigurations()
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                this.props.client.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0)
                        options = result.data.optionsList.edges.map(options => options.node && ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }))
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ configurations: configurations })
    }
    componentDidMount() {
        this.setConfigurations()
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.configurations !== prevState.configurations) {
            let urlFilterValues = getFilterValuesFromQueryString(this.props.location.search, this.state.configurations)
            handleConfigurationChange(prevState.configurations, this.state.configurations, urlFilterValues, this.props.setFilterValues, this.props.history, this.props.location, this.props.defaultFilters, updateFilters)
        }
    }
    render() {
        let updatedConfigurations = this.state.configurations
        if (updatedConfigurations) {
            let cityConfig = updatedConfigurations.find(conf => conf.name === "city")
            cityConfig["options"] = this.getCities(this.props.filterValues["stateCode"])
            updatedConfigurations = [...this.state.configurations.filter(conf => conf.name !== "city"), cityConfig]
        }
        return (
            <Row className="pb-3">
                {this.state.openModal === "viewFilters" &&
                    <ListingFilters open={this.state.openModal === "viewFilters"} handleClose={() => this.toggleModal("")}
                        target="viewFilters" filterValues={this.props.filterValues} setFilterValues={(filters => updateFilters(filters, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues))}
                        filters={updatedConfigurations}
                    />}

                <Col xs={4}>
                    <span className="driver-search-filter">
                        <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => this.updateSearchTerm(e.target.value)} />
                        <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                    </span>
                </Col>
                {this.state.openModal === "NewBranchModal" && <NewBranchModal handleClose={() => this.toggleModal("NewBranchModal")} open={this.state.openModal === "NewBranchModal"} handleSuccess={() => this.setState({ refreshRequired: true })} />}
                <Col xs={8} className="text-right mt-2">
                    {this.props.refreshRequired ?
                        <a className="driver-list-button" color='primary' onClick={() => window.location.reload(false)}><i className="fa fa-refresh" aria-hidden="true" />&nbsp;&nbsp;Refresh Page</a> :
                        <a id="newBranch" className="driver-list-button" onClick={() => this.toggleModal("NewBranchModal")}>
                            Create New Branch | <i className="fa fa-plus" aria-hidden="true"></i> {this.props.filterValues && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && updatedConfigurations && updatedConfigurations.find(setting => setting.name == key) && updatedConfigurations.find(setting => setting.name == key).showFilter).length || ""}
                        </a>}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a id="viewFilters" className="driver-list-button" onClick={() => this.toggleModal("viewFilters")}>
                        Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {this.props.filterValues && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && updatedConfigurations && updatedConfigurations.find(setting => setting.name == key) && updatedConfigurations.find(setting => setting.name == key).showFilter).length || ""}
                    </a>
                </Col>
                <Col xs={12} className="mt-2">
                    {Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && updatedConfigurations && updatedConfigurations.find(setting => setting.name == key) && updatedConfigurations.find(setting => setting.name == key).showFilter).map(key =>
                        <span className="search-filter-preview">
                            <span>
                                <i className="fa fa-times-circle" onClick={() => this.removeFilter(updatedConfigurations.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                                {updatedConfigurations.find(setting => setting.name == key).title}
                            </span>
                            {this.getValueByFilterType(updatedConfigurations.find(setting => setting.name == key), this.props.filterValues[key])}
                        </span>
                    )}
                    {this.props.selectedMembers && this.props.selectedMembers.length > 0 && <span className="search-filter-preview">
                        <span> <i className="fa fa-times-circle" onClick={this.props.resetSelectedMembers}></i> &nbsp;&nbsp; Selected Members</span>
                        {this.props.selectedMembers.length}
                    </span>}
                </Col>
            </Row>
        )
    }
}

export default compose(
    withApollo,
)(BranchListHeader)