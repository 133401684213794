import React from "react"
import { Container, Row, Col } from "reactstrap"
import { useState } from "react"
import PaymentSchedulesListHeader from "./PaymentSchedulesListHeader"
import PaymentSchedulesList from "./PaymentSchedulesList"

const PaymentSchedulesListContainer = ({ location, history, currentUser }) => {
    const [filterValues, setFilterValues] = useState({
        orderBy: ["-id"],
        statuses: ["Scheduled"]
    })

    const updateOrder = (string) => {
        let order = [...filterValues.orderBy]
        if (!order.includes(string) && !order.includes("-" + string)) {
            order = [order.find(item => item.includes("-in_office")), string]
        } else {
            let index = order.indexOf(string)
            if (order.indexOf(string) > -1) {
                order[index] = "-" + string
            } else {
                index = order.indexOf("-" + string)
                index > -1 && (order[index] = string)
            }
        }
        setFilterValues({ ...filterValues, orderBy: order })
    }

    return (
        <Container fluid className="bos-listing-container">
            <PaymentSchedulesListHeader
                filterValues={filterValues}
                setFilterValues={(filterValues) => setFilterValues({ ...filterValues })}
                defaultFilters={filterValues}
                currentUser={currentUser} location={location} history={history}
            />
            <Row>
                <Col>
                    <PaymentSchedulesList
                        key={String("true")}
                        updateOrder={updateOrder}
                        drvierId={filterValues && filterValues.driverId ? filterValues.driverId : null}
                        orderBy={filterValues && filterValues.orderBy ? filterValues.orderBy : []}
                        statuses={filterValues && filterValues.statuses && filterValues.statuses.length > 0 ? filterValues.statuses : []}
                        searchTerm={filterValues && filterValues.searchTerm ? filterValues.searchTerm : null}
                        currentUser={currentUser}
                        history={history}
                        location={location}
                    />

                </Col>
            </Row>
        </Container>

    )
}

export default PaymentSchedulesListContainer