import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Row, Col, Label, Nav, NavItem, NavLink, Container, Badge, TabContent, TabPane } from 'reactstrap'
import Loader from "../../Material/Loader"
import OverviewTab from "./MemberDetailsTab/OverviewTab"
import HistoryTab from "./MemberDetailsTab/HistoryTab"
import gql from 'graphql-tag'
const MemberQuery = gql`query Member($id: ID!){
    member(id: $id) {
      id
      pk
      type
      user{
        id
        isActive
        firstName 
        lastName
      }
      tenant {
        id
        name
        logoImage
      }
      defaultBranch{
        id
        name
      }
    }
  }  
`

const MEMBER_DETAIL_PAGE_TABS = [
    { id: "#overview", name: "OVERVIEW" },
    { id: "#history", name: "HISTORY" },
]

class MemberDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            activeTab: "overview",
            rendererdTabPanes: [],
        }
    }

    componentDidMount() {
        let defaultTab = MEMBER_DETAIL_PAGE_TABS[0].id
        if (window.location.hash && MEMBER_DETAIL_PAGE_TABS.map(item => item.id).includes(window.location.hash)) {
            this.setState({ activeTab: window.location.hash, rendererdTabPanes: [window.location.hash] })
        } else {
            window.location.hash = defaultTab
            this.setState({ activeTab: defaultTab, rendererdTabPanes: [defaultTab] })
        }
    }
    componentDidUpdate(_, prevState) {
        if (this.state.activeTab !== prevState.activeTab) {
            window.location.hash = this.state.activeTab
        }
    }
    toggleTab = (tabId) => {
        if (!this.state.rendererdTabPanes.includes(tabId)) {
            this.setState({ rendererdTabPanes: [...this.state.rendererdTabPanes, tabId] })
        }
        this.setState({ activeTab: tabId })
    }

    render() {
        return (

            <Container fluid className="bos-object-detail-container">
                {this.props.loading ? <Loader /> : this.props.member && <>
                    <Row className="bos-object-detail-header">
                        <Col xs="1">
                            <span id="box-object-icon" >
                                <img width={100} src={this.props.member.tenant.logoImage} alt={this.props.member.tenant.name + " Logo"} />
                            </span>
                        </Col>
                        <Col xs={5} className="bos-object-title-wrapper">
                            <Label>
                                {this.props.member.user.firstName ? this.props.member.user.firstName : "-"}&nbsp;{this.props.member.user.lastName ? this.props.member.user.lastName : "-"}
                            </Label>
                            &nbsp;&nbsp;&nbsp;{this.props.member.user.isActive ? <i className="fa fa-check-circle fa-2x text-success" aria-hidden="true" /> : <i className="fa fa-times-circle fa-2x text-danger" aria-hidden="true" />}
                        </Col>
                        {this.props.member && this.props.member.defaultBranch && <Col xs={3} className="bos-object-property-wrapper mb-2">
                            <Badge color="primary" style={{ fontSize: 12 }} className="p-2">{this.props.member.defaultBranch.name}</Badge><br />
                        </Col>}
                    </Row><br />
                    <div className="bos-object-detail-body">
                        <Nav tabs>
                            {MEMBER_DETAIL_PAGE_TABS.map(item =>
                                <NavItem key={item.id} className={this.state.activeTab === item.id ? "active" : "inactive"}>
                                    <NavLink className={this.state.activeTab === item.id ? "active" : "inactive"} onClick={() => this.toggleTab(item.id)}>
                                        {item.name}
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#overview") && <TabPane tabId="#overview">
                                <div className="bos-object-nav-content">
                                    <OverviewTab memberId={this.props.member.id} toggleTab={(id) => this.toggleTab(id)} />
                                </div>
                            </TabPane>}
                            {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#history") && <TabPane tabId="#history">
                                <div className="bos-object-nav-content">
                                    <HistoryTab memberId={this.props.member.id} toggleTab={(id) => this.toggleTab(id)} />
                                </div>
                            </TabPane>}
                        </TabContent>
                    </div>
                </>
                }
            </Container>
        )

    }
}

export default compose(
    withApollo,
    graphql(MemberQuery, {
        options: ({ match }) => ({
            fetchPolicy: 'cache-and-network',
            variables: {
                id: match.params.id
            }

        }), props({ data: { member, loading, variables, refetch } }) {
            return {
                member,
                loading,
                variables,
                refetchMemberQuery: () => {
                    return refetch({
                        query: MemberQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { member: fetchMoreResult.member }
                        },
                    })
                },
            }
        }
    }),
)(MemberDetails)
