import gql from 'graphql-tag'

const RepsQuery = gql`query RepsQuery($email: String,$username: String){
    reps: allReps(email_Icontains: $email,username_Icontains: $username,first:10,orderBy:["username"]){
        edges{
            node{
                id
                name
                username
                email
            }
        }
    } 
}`

const AllBranches = gql`query AllBranches{
    allBranches{
        edges{
            node{
                id
                pk
                name
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    } 
}`


const AllGroups = gql`query AllGroups($cursor: String, $first:Int, $name_Icontains: String){
    allGroups (first:$first, after: $cursor, name_Icontains:$name_Icontains){
        edges {
            node {
                id
                name
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`

const AllGroupsBasedOnPermission = gql`query AllGroups($permissionId: ID){
    permissionBasedGroups :allGroups (permissionId:$permissionId){
        edges {
            node {
                id
                name
                userSet {
                    edges {
                      node {
                            id
                            username
                            member {
                                id
                            }
                        }
                    }
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`

const AllPermissions = gql`query AllPermissions($cursor: String, $first:Int, $name_Icontains: String){
    allPermissions(first:$first, after: $cursor, name_Icontains:$name_Icontains){
        edges {
            node {
                id
                name
                codename
                contentType{
                    id
                    model
                    appLabel
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`

const UserPermissions = gql`query AllPermissions($memberId: ID){
    userPermissions: allPermissions(memberId:$memberId){
        edges {
            node {
                id
                name
                contentType{
                    id
                    model
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`
const AllTenants = gql`query AllTenants($cursor: String, $first:Int,){
    allTenants(first:$first, after: $cursor){
        edges {
            node {
                id
                name
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const MembersQuery = gql`query MembersQuery($cursor: String, $first:Int, $searchTerm: String, $branchIds: [ID], $isSuperuser: Boolean, $isActive: Boolean, $groups: [ID], $tenant: ID, $permissions:[ID]  ) {
    members: allMembers(first:$first, after: $cursor,searchTerm:$searchTerm branchIds: $branchIds, isSuperuser:$isSuperuser, isActive:$isActive, groups: $groups, tenant: $tenant, permissions:$permissions  ){
        edges{
            node{
                id
                type
                dateModified
                defaultBranch{
                  id
                }
                user{
                    id
                    username
                    email
                    firstName
                    lastName
                    lastLogin
                    dateJoined
                    isActive
                }
                branches {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                }
                selectedBranches {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
            length
        }
    } 
}`

const AllReps = gql`query AllReps($email: String,$username: String){
    reps: allReps(email_Icontains: $email,username_Icontains: $username, first: 10, orderBy:["username"]){
        edges{
            node{
                id
                name
                username
                email
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    } 
}`

const BranchDetailQuery = gql`query Branch($id: ID!){
    branch(id: $id){
        id
        name
        dateAdded
        metadata
        settings
        customId
        description
        isActive
        city
        stateCode
        country
        postalCode
        tenant {
            id
            name
        }
        memberSet {
        edges {
            node {
            id
            user{
                username
            }
            type
            }
        }
        }
    }
}`


const AllCarDocumentTypesQuery = gql` query AllCarDocumentTypesQuery{
    allCarDocumentTypes(isActive:true){
            edges{
                node{
                  typeName
                  id
                }
            }
        }
    }
`

const AllDriverDocumentTypesQuery = gql` query AllDriverDocumentTypesQuery{
    allDriverDocumentTypes(isActive:true){
            edges{
                node{
                  typeName
                  id
                }
            }
        }
    }
`

const AllChargeTypes = gql`query AllChargeTypes{
  allChargeTypes(isActive:true,isVisible:true,orderBy:["name"]){
      edges {
          node {
              id
              name
          }
      }
    }
}`
const AllGroupTypes = gql`query AllGroupTypes{
  allGroupTypes(isActive:true){
      edges {
          node {
              id
              name
              chargeType {
                id
                name
              }
          }
      }
    }
}`

const MemberType = gql`
    query MemberType{
      MemberType:__type(name: "MemberType") {
            values: enumValues {
                name
                description
            }
        }
    }`

const FinancialAccountCarType = gql`
    query FinancialAccountCarType{
      financialAccountCarType:__type(name: "FinancialAccountCarType") {
            values: enumValues {
                name
                description
            }
        }
    }`

export { RepsQuery, AllBranches, MembersQuery, AllReps, BranchDetailQuery, AllCarDocumentTypesQuery, AllDriverDocumentTypesQuery, AllGroups, AllPermissions, AllTenants, AllChargeTypes, AllGroupTypes, FinancialAccountCarType, MemberType, UserPermissions, AllGroupsBasedOnPermission }
