import React, { Component } from "react"
import { Popover, PopoverBody, PopoverHeader, Row, Col, Input, Button } from "reactstrap"
import { compose, Query } from 'react-apollo'
import Select from "react-select"
import { withApollo } from 'react-apollo/index'


class BranchSelectionPopover extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedBranches: this.props.selectedBranches || [],
        }
    }

    updateBranchSelection = (branchId) => {
        let selectedBranches = this.state.selectedBranches.includes(branchId) ? this.state.selectedBranches.filter(branch => branch !== branchId) : [...this.state.selectedBranches, branchId]
        this.setState({ selectedBranches })
    }

    applyFilter = () => {
        this.props.updateSelectedBranches(this.state.selectedBranches)
        this.props.handleClose()
    }

    bulkUpdateBranches = () => {
        let allSelected = this.props.userBranches.filter(item => this.state.selectedBranches.includes(item.node.id)).length === this.props.userBranches.length
        this.setState({ selectedBranches: allSelected ? [] : this.props.userBranches.map(item => item.node.id) })
    }

    render() {
        let allSelected = this.props.userBranches.filter(item => this.state.selectedBranches.includes(item.node.id)).length === this.props.userBranches.length
        return (
            <Popover placement="bottom" target={this.props.target} isOpen={this.props.open} toggle={this.props.handleClose} >
                <PopoverHeader>
                    <Row>
                        <Col xs={{ size: 7 }} className="text-left pr-0">
                            <a className={allSelected ? "text-danger" : "text-primary"} onClick={this.bulkUpdateBranches} style={{ cursor: "pointer" }}>
                                {this.props.userBranches.filter(item => this.state.selectedBranches.includes(item.node.id)).length === this.props.userBranches.length ? "Unselect All" : "Select All"}
                            </a>
                        </Col>
                        <Col xs={{ size: 5 }} className="text-right">
                            <a className="text-primary" onClick={this.applyFilter} style={{ cursor: "pointer" }}>
                                Done
                            </a>
                        </Col>
                    </Row>
                </PopoverHeader>
                <PopoverBody style={{ maxHeight: "200px", overflowY: "scroll" }}>
                    {this.props.userBranches && this.props.userBranches.length > 0 && this.props.userBranches.map((branch) =>
                        <div>
                            <i onClick={() => this.updateBranchSelection(branch.node.id)} className={"fa fa-lg " + (this.state.selectedBranches.includes(branch.node.id) ? "fa-check-square-o" : "fa-square-o")}></i>
                            &nbsp;&nbsp;<span>{branch.node.name}</span>
                        </div>
                    )}
                </PopoverBody>
            </Popover>
        )
    }
}

export default compose(
    withApollo,
)(BranchSelectionPopover)
