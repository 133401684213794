import React, { Component, Fragment } from 'react';
import {compose, graphql, withApollo} from 'react-apollo';
import gql from 'graphql-tag';
import { Popover,PopoverBody,PopoverHeader, Button, FormGroup, Label, Input, Progress, Badge } from 'reactstrap';

// const AllOwnersQuery = gql`query AllOwnersQuery{
//     allOwners{
//         edges{
//             node{
//                 id
//                 pk
//                 name
//             }
//         }
//     }   
// }`;

const UpdatePlate = gql`
mutation updatePlateMutation($input: UpdatePlateMutationInput!){
    updatePlate(input:$input){
        ok
        errors{
            messages
        }
}
} `;
class UpdatePlateInfo extends Component{
    constructor(props) {
        super(props);
        this.state = {
            errorMessage:null,
            plate:props.plate,
            input:{},
            loading:false
        };
    }

    updatePlate = () => {
        try{
            if(this.state.input.id){
                this.setState({loading:true})
                let input = this.state.input;
                this.props.client.mutate({
                    mutation: UpdatePlate,
                    variables: {input},
                }).then((result)=>{
                    if(result && result.data && result.data.updatePlate && result.data.updatePlate.ok){
                        this.props.refetchQuery();
                        this.props.handleClose();
                        this.setState({input:"",loading:false})
                    }else if (result && result.data && result.data.updatePlate && result.data.updatePlate.errors && result.data.updatePlate.errors.length>0){
                        this.setState({loading:false, errorMessage:result.data.updatePlate.errors[0].messages})
                    }
                }).catch((err)=>{
                    this.setState({loading:false, errorMessage: "Something went wrong, please contact admin!"})
                })
            }
        }catch(err){
            this.setState({loading:false, errorMessage: "Something went wrong, please contact admin!"})
        }
        
    }

    updateInput = (e) => {
        if(this.state.plate){
            let name = e.target.name;
            let value = e.target.type=="checkbox" ? e.target.checked : e.target.value; 
            let input = {
                id: this.state.plate.id,
                [name]: value
            };
            this.setState({input:input})
        }  
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.plate !== prevProps.plate) {
            let plate = this.props.plate;
            this.setState({plate:plate});
        }
    }
    
    render() {
        return (
            <div>
                <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader>{this.props.title}</PopoverHeader>
                    <PopoverBody>
                        <FormGroup>
                            <Label for={this.props.name}>{this.props.title}</Label>
                            {this.props.name==="owner" ? 
                            <Input type="select" name={this.props.name} id={this.props.name} defaultValue={this.props.value} onChange={this.updateInput}>
                                <option value="">{this.props.title}</option>
                                {this.props.allOwners && this.props.allOwners.edges && this.props.allOwners.edges.length > 0 && this.props.allOwners.edges.map((owner, i) => <option value={owner.node.id} key={i}>{owner.node.name}</option>)}
                            </Input>
                            :this.props.type === "boolean" ? <> <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input type="checkbox" name={this.props.name} id={this.props.name} defaultValue={this.props.value} placeholder={this.props.title} onChange={this.updateInput} defaultChecked={this.props.value}/>{this.props.name}</>
                            :<Input type={this.props.type} name={this.props.name} id={this.props.name} defaultValue={this.props.value} placeholder={this.props.title} onChange={this.updateInput}/>
                            }
                        </FormGroup>
                        {this.state.errorMessage && <Badge color="danger">{this.state.errorMessage}</Badge>}
                        {this.state.loading?<Progress animated color="info" value={100} />:<Button type="button" size="sm" onClick={this.updatePlate}>Submit</Button>}
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

export default compose(
    withApollo,
    // graphql(AllOwnersQuery, {options:{fetchPolicy: 'cache-first'}, props({data:{allOwners}}){return {allOwners};}}),
)(UpdatePlateInfo);