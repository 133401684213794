import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Popover, PopoverBody, PopoverHeader, Button, FormGroup, Label, Input, Progress, Alert } from 'reactstrap'
import Select from 'react-select'


const UpdateTenant = gql`mutation updateTenantMutation($input: UpdateTenantMutationInput!){
    updateTenant(input:$input){
        ok
        errors{
            field
            messages
        }
    }
} `

const AllBranches = gql`query AllBranches{
    allBranches{
        edges{
            node{
                id
                pk
                name
            }
        }
    } 
}`

class UpdateTenantInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            input: {},
            newSettingsKey: "",
            loading: false
        }
    }

    updateTenant = () => {
        if (this.props.tenantId) {
            this.setState({ loading: true })
            let input = this.state.input
            input["tenantId"] = this.props.tenantId
            this.props.client.mutate({
                mutation: UpdateTenant,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updateTenant && result.data.updateTenant.ok) {
                    this.props.refetchTenantQuery()
                    this.props.handleClose()
                    this.setState({ input: {}, loading: false, error: null })
                } else if (result.data.updateTenant && result.data.updateTenant.errors) {
                    this.setState({ loading: false, error: String(result.data.updateTenant.errors[0].messages) })
                } else {
                    this.setState({ loading: false, error: "An error has occurred. Please check your input or contact admin." })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occurred. Please contact admin." })
            })
        } else {
        }
    }


    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.type === "number") {
            if (isNaN(parseFloat(value))) {
                value = 0
            } else {
                value = parseFloat(value)
            }
        }
        let input = this.state.input
        input[name] = value
        this.setState({ input: input })
    }

    componentDidMount() {
        if (this.props.type === "boolean") {
            this.setState({ input: { [this.props.name]: this.props.value } })
        }
    }

    render() {
        return (
            <div>
                <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader onClick={this.props.handleClose}>{this.props.title} <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                    <PopoverBody>
                        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                        <FormGroup>
                            <Label for={this.props.name}>{this.props.title}</Label>
                            {["defaultBranch"].includes(this.props.name) ?
                                this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && <Select
                                    className="bos-custom-select" classNamePrefix="bos-select"
                                    isLoading={this.props.allBranchesLoading}
                                    options={!this.props.allBranchesLoading && this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 ? this.props.allBranches.edges.map(item => ({ value: item.node.id, label: item.node.name })) : []}
                                    defaultValue={!this.props.allBranchesLoading && this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.filter(item => item.node.id === this.props.value).length > 0 ? this.props.allBranches.edges.filter(item => item.node.id === this.props.value).map(item => ({ value: item.node.id, label: item.node.name })) : null}
                                    placeholder="Default Branch"
                                    isClearable={false}
                                    disabled={!this.props.allBranchesLoading}
                                    onChange={(defaultBranch) => this.setState({ input: { [this.props.name]: defaultBranch.value } })} />
                                :
                                this.props.type === "boolean" ?
                                    <>
                                        <br />
                                        <div>
                                            <span>Yes</span>
                                            <div className="toggle-switch">
                                                <input
                                                    type="checkbox"
                                                    className="toggle-switch-checkbox"
                                                    name={this.props.name}
                                                    id={"tenantInfo"}
                                                    checked={!this.state.input[this.props.name]}
                                                    onChange={() => this.setState({ input: { [this.props.name]: !this.state.input[this.props.name] } })}
                                                />
                                                <label className="toggle-switch-label" htmlFor={"tenantInfo"}>
                                                    <span className="toggle-switch-inner" />
                                                    <span className="toggle-switch-switch" />
                                                </label>
                                            </div>
                                            <span>No</span>
                                        </div>
                                    </>
                                    : <Input autoComplete={"off"} type={this.props.type} maxLength={this.props.maxLength ? this.props.maxLength : 200}
                                        name={this.props.name} id={this.props.name} defaultValue={this.props.value}
                                        placeholder={this.props.title} onChange={this.updateInput}
                                    />
                            }
                        </FormGroup>
                        {this.state.loading ? <Progress animated color="info" value={100} /> :
                            <Button type="button" size="sm" onClick={this.updateTenant} disabled={!(this.props.tenantId)}>Submit</Button>
                        }
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllBranches, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allBranches } }) {
            return { allBranchesLoading: loading, allBranches }
        },
    })
)(UpdateTenantInfo)
