import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import '../CarDetail.css'
import { Col, Row, Label } from 'reactstrap'
import toTitleCase from "../../../Functions/toTitleCase"
import CarTrackerModal from "../../../Material/CarTrackerModal"
import Loader from "../../../Material/Loader"
import gql from "graphql-tag"
import DatetimeRenderer from "../../../Material/DatetimeRenderer"
import { ReactComponent as LocationIcon } from '../../../assets/images/icons/location-icon.svg'
import { ReactComponent as Repo } from '../../../assets/images/icons/repo.svg'
import { updateTracker } from "../Mutations"
const CarDetailQuery = gql`query Car($pk: Int!){
    car(id: $pk){
      pk
      id
      dmvPlate
      vin
      model
      color
      trackerStatus
      currentAgreement{
        id
        driver {
            id
            name
            tlcLicense
            phone
            email
            balance
        }
      }
      equipmentSet(removed:false){
        edges {
          node {
            id
            customDeviceId
            dateAdded
            removed
            equipmentItems{
              id
              pk
              name
              description
              equipmentItemsGroup {
                id
                name
              }
            }
          }
        }
      }
    }
  }`



class TrackersTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    render() {
        return (
            <div className="tab-container">
                {this.props.loading ? <Loader /> : <>
                    {this.props.car && this.props.car.equipmentSet && this.props.car.equipmentSet.edges && this.props.car.equipmentSet.edges.length > 0 && this.props.car.equipmentSet.edges.filter(equipment => (equipment.node.equipmentItems.equipmentItemsGroup.name === "GPS")) &&
                        <div>
                            {this.props.car.equipmentSet.edges.filter(equipment => equipment.node.equipmentItems.equipmentItemsGroup.name === "GPS").map((equipment, i) =>
                                <Row className="info-container" style={{ backgroundColor: "#f0a8198c", marginBottom: "20px" }}>
                                    <Col>
                                        <Label>Date Added</Label>
                                        <p>{equipment.node.dateAdded && <DatetimeRenderer datetime={equipment.node.dateAdded} />}</p>
                                    </Col>
                                    <Col>
                                        <Label>Name</Label>
                                        <p>{equipment.node.equipmentItems && equipment.node.equipmentItems.name ? toTitleCase(equipment.node.equipmentItems.name) : "--"}</p>
                                    </Col>
                                    <Col>
                                        <Label>Custom Device Id</Label>
                                        <p>{equipment.node.customDeviceId && equipment.node.customDeviceId ? equipment.node.customDeviceId : "--"}</p>
                                    </Col>
                                    <Col>
                                        <Label>Description</Label>
                                        <p>{equipment.node.equipmentItems ? equipment.node.equipmentItems.description : "--"}</p>
                                    </Col>
                                    <Col>
                                        <Label>Group</Label>
                                        <p>{equipment.node.equipmentItems && equipment.node.equipmentItems.equipmentItemsGroup ? equipment.node.equipmentItems.equipmentItemsGroup.name : "--"}</p>
                                    </Col>
                                    <Col xs={3}>
                                        <ul className="tracker-actions">
                                            {(this.props.car.trackerStatus === "Enabled" || this.props.car.trackerStatus === "") ?
                                                <li>
                                                    Disable<br />
                                                    <i id={"disable" + i} className="fa fa-lg fa-solid fa-power-off danger" aria-hidden="true" onClick={() => this.toggleModal("disable" + i)}></i>
                                                    {this.state.openModal === "disable" + i &&
                                                        <CarTrackerModal trackerId={equipment.node.customDeviceId} open={this.state.openModal === "disable" + i} handleClose={() => this.toggleModal("disable" + i)} command={"Disable"} car={this.props.car} />
                                                    }
                                                </li> : (["Disabled", "Repo"].indexOf(this.props.car.trackerStatus) > -1) ?
                                                    <li>
                                                        Enable<br />
                                                        <i id={"enable" + i} className="fa fa-lg fa-solid fa-power-off success" aria-hidden="true" onClick={() => this.toggleModal("enable" + i)}></i>
                                                        {this.state.openModal === "enable" + i &&
                                                            <CarTrackerModal trackerId={equipment.node.customDeviceId} open={this.state.openModal === "enable" + i} handleClose={() => this.toggleModal("enable" + i)} command={"Enable"} car={this.props.car} />
                                                        }
                                                    </li> : ""
                                            }
                                            <li>
                                                Locate<br />
                                                <LocationIcon id={"locate" + i} width={20} fill="#303E67" onClick={() => this.toggleModal("location" + i)} />
                                                {this.state.openModal === "location" + i &&
                                                    <CarTrackerModal trackerId={equipment.node.customDeviceId} open={this.state.openModal === "location" + i} handleClose={() => this.toggleModal("location" + i)} command={"Location"} car={this.props.car} />
                                                }
                                            </li>
                                            <li>
                                                Repo<br />
                                                <Repo id={"repo" + i} fill="#303E67" width={25} height={25} onClick={() => this.toggleModal("repo" + i)} />
                                                {this.state.openModal === "repo" + i &&
                                                    <CarTrackerModal trackerId={equipment.node.customDeviceId} open={this.state.openModal === "repo" + i} driver={this.props.car && this.props.car.currentAgreement && this.props.car.currentAgreement.driver} handleClose={() => this.toggleModal("repo" + i)} command={"Repo"} car={this.props.car} />
                                                }
                                            </li>
                                            {["Teltonika FMC130", "General Motors OnStar"].includes(equipment.node.equipmentItems.name) &&
                                                <>
                                                    <li>
                                                        Lock<br />
                                                        <i id={"lock" + i} className="fa fa-lg fa-solid fa-lock" onClick={() => this.toggleModal("lock" + i)}></i>
                                                        {this.state.openModal === "lock" + i &&
                                                            <CarTrackerModal trackerId={equipment.node.customDeviceId} open={this.state.openModal === "lock" + i} handleClose={() => this.toggleModal("lock" + i)} command={"Lock"} car={this.props.car} />
                                                        }
                                                    </li>
                                                    <li>
                                                        Unlock<br />
                                                        <i id={"unlock" + i} className="fa fa-lg fa-solid fa-unlock" onClick={() => this.toggleModal("unlock" + i)}></i>
                                                        {this.state.openModal === "unlock" + i &&
                                                            <CarTrackerModal trackerId={equipment.node.customDeviceId} open={this.state.openModal === "unlock" + i} handleClose={() => this.toggleModal("unlock" + i)} command={"Unlock"} car={this.props.car} />
                                                        }
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    }
                </>}
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(CarDetailQuery, {
        options: ({ match }) => ({ variables: { pk: match && match.params && match.params.pk } }),
        props({ data: { car, fetchMore, loading, variables } }) {
            return { car, loading, }
        }
    }),
    graphql(updateTracker, {
        props: ({ mutate }) => ({
            update: (input) => mutate({ variables: { input } }),
        })
    }),
)(TrackersTab)