import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Popover, PopoverBody, PopoverHeader, Button, Input, Progress, Alert } from 'reactstrap'
import Select from "react-select"

const UpdateFinancialAccountMutation = gql`
    mutation updateFinancialAccountMutation($input: UpdateFinancialAccountInput!){
        updateFinancialAccount(input:$input){
            ok
            errors{
                messages
            }
        }
    } `
const AllBranchesQuery = gql`
  query AllBranches($tenantId: ID){
    allBranches(tenantId:$tenantId, orderBy:["name"]){
        edges{
            node{
                id
                name
            }
        }
    }
  }
`
class UpdateFinancialAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            input: {},
            loading: false,
        }
    }

    updateFinancialAccount = () => {

        if (this.state.input.id) {
            this.setState({ loading: true })
            let input = this.state.input
            this.props.client.mutate({
                mutation: UpdateFinancialAccountMutation,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updateFinancialAccount && result.data.updateFinancialAccount.ok) {
                    this.props.refetchDriverQuery()
                    this.props.handleClose()
                    this.setState({ input: {}, loading: false, error: null })
                }
                else {
                    let error = "An error occurred, could not complete request."
                    if (result && result.data && result.data.updateFinancialAccount && result.data.updateFinancialAccount.errors[0] && result.data.updateFinancialAccount.errors[0].messages) {
                        error = result.data.updateFinancialAccount.errors[0].messages.toString()
                    }
                    this.setState({ error: error })
                }
                this.setState({ loading: false })
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occurred. Please contact admin: " + err })
            })
        } else {
        }
    }

    updateInput = (e) => {
        if (this.props.financialAccountId) {
            let name = e.target.name
            let value = e.target.value
            if (e.target.type === "number") {
                if (isNaN(parseFloat(value))) {
                    value = 0
                } else {
                    value = parseFloat(value)
                }
            }
            let input = this.state.input
            input["id"] = this.props.financialAccountId
            input[name] = value
            this.setState({ input: input })
        }
    }

    render() {
        return (
            <div>
                <Popover placement={this.props.placement ? this.props.placement : "bottom"} isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader onClick={this.props.handleClose}>{this.props.title} <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                    <PopoverBody>
                        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                        <div className='pb-2'>
                            {this.props.name === "branchIds" ?
                                this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 ?
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        defaultValue={this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.filter(branch => this.props.value.includes(branch.node.id)).map(branch => ({ value: branch.node.id, label: branch.node.name }))}
                                        options={this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.filter(branch => !this.props.value.includes(branch.node.id)).map(branch => ({ value: branch.node.id, label: branch.node.name }))}
                                        onChange={(item) => this.setState({ input: { ...this.state.input, branchIds: item.map(item => item.value), id: this.props.financialAccountId } })}
                                        isMulti
                                        placeholder={"Add New Branches"}
                                    />
                                    :
                                    <Alert color="info">No existing branch found!</Alert>
                                :
                                <Input autoComplete={"off"} type={this.props.type} maxLength={this.props.maxLength ? this.props.maxLength : 200}
                                    name={this.props.name} id={this.props.name} defaultValue={this.props.value}
                                    placeholder={this.props.title} onChange={this.updateInput}
                                />
                            }
                            {this.state.loading ? <Progress animated color="info" value={100} /> :
                                <Button className="float-right submit-button  my-2 py-1" type="button" size="sm" onClick={this.updateFinancialAccount} disabled={this.state.loading || this.props.loading}>Submit</Button>
                            }
                        </div>
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllBranchesQuery, {
        options: ({ tenantId }) => ({ variables: { tenantId }, fetchPolicy: 'cache-first' }),
        props({ data: { loading, allBranches } }) {
            return { loading, allBranches }
        },
    })

)(UpdateFinancialAccount)
