import React, { Component } from 'react'
import { compose, withApollo } from 'react-apollo'
import { Button, Col, Modal, ModalHeader, ModalBody, Alert, Progress, Label } from 'reactstrap'
import ModalFooter from "reactstrap/lib/ModalFooter"
import "./ConfirmationPopup.css"
class ConfirmationPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customId: null,
            error: null,
            loading: null,
            description: null,
            customId: null,
            input: {},
        }
    }

    render() {
        return (
            <Modal className="confirmation-popup" isOpen={this.props.open}>
                <ModalHeader>Confirm Action</ModalHeader>
                <ModalBody className="text-center">
                    {this.props.error && <Alert color="danger">{this.props.error}</Alert>}
                    {this.props.success && <Alert color="success">{this.props.success}</Alert>}
                    <p className="modal-message">{this.props.message}</p>
                    {this.props.loading && <Progress animated color="info" value="100" />}
                </ModalBody>
                <ModalFooter>
                    <Col className="text-right">
                        <Button className="secondary-btn" onClick={this.props.handleClose}>Close</Button>&nbsp;&nbsp;
                        <Button color={this.props.color} disabled={this.props.loading || this.props.error || this.props.success} className="primary-action-btn" onClick={this.props.confirmAction}>{this.props.action}</Button>
                    </Col>
                </ModalFooter>
            </Modal>
        )
    }
}

export default compose(
    withApollo,
)(ConfirmationPopup)