import React, { Component } from 'react'
import { compose, withApollo, graphql } from 'react-apollo'
import '../DriverDetailNew.css'
import { Button, Col, Row } from 'reactstrap'
import Loader from "../../../../../Material/Loader"
import ProcessInsuranceModal from "../../../../../Material/ProcessInsuranceModal"
import gql from 'graphql-tag'
import { insuranceStatusEnums } from "../../Queries"
import InsuranceDetailSection from "../../../../../Insurances/InsuranceDetailSection"
import { HasPermissionsQuery } from "../../../../../Functions/querys"
const AllInsuranceStatus = gql`query AllInsuranceStatus($driverId: ID!){
    allInsuranceStatus(driverId: $driverId, first:3 , orderBy:["-id"]){
        edges {
            node {
                id
                pk
                notes
                dateAdded
                status
                surcharge
                deposit
                dateToReconsider
                metadata
                history {
                    date
                    user
                    comment
                }
                createdBy{
                    id
                    username
                }
                extraDocsRequired {
                    edges {
                        node {
                            id
                            typeName
                        }
                    }
                }
                insuranceReason{
                    id
                    title
                }
                driverInsuranceType{
                    id 
                    name
                    broker {
                        id
                        name
                    }
                    surcharge
                    deposit
                }
                broker{
                  name
                  id
                  isActive
                }
                driverremovedreasonSet {
                    edges {
                      node {
                        id
                        reason{
                            id
                            reasonName
                        }
                      }
                    }
                }
                driver{
                    id
                    name
                    currentAgreement {
                        id
                    }
                    missingInsuranceDocuments{
                        id
                        typeName
                    }
                    reservationDriver(last:1,status:"Open"){
                        edges {
                          node {
                            id
                          }
                        }
                    }
                }
            }
        }
    }
}
`
class RiskInsuranceDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            error: null,
            loading: false,
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    refetchQuery = () => {
        this.props.refetchallInsuranceStatus()
        this.props.refetchDriverQuery()

    }


    render() {
        if (this.props.loading || this.props.insuranceLoading || !this.props.driver) {
            return <Loader />
        }
        return (
            <div>
                <Row>
                    <Col className="d-flex justify-content-start">
                        <h4>Insurance</h4>
                    </Col>
                    {this.props.driver &&
                        (this.props.driver.canProcessInsurance || (this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_create_insurance_before_denial_period"))) &&
                        (this.props.allInsuranceStatus && this.props.allInsuranceStatus.edges && this.props.allInsuranceStatus.edges.length >= 0 && !this.props.allInsuranceStatus.edges.filter(item => ["A_3", "A_1"].includes(item.node.status)).length > 0) &&
                        <Col className="d-flex justify-content-end">
                            <Button onClick={() => this.toggleModal("ProcessApplicationButton")} id="ProcessApplicationButton">Process Insurance</Button>
                            {this.state.openModal === "ProcessApplicationButton" &&
                                <ProcessInsuranceModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "ProcessApplicationButton"} id={this.props.driver ? this.props.driver.id : null} refetchQuery={this.refetchQuery} />
                            }
                        </Col>
                    }
                </Row>

                {
                    this.props.insuranceLoading ? <Loader /> :
                        <>
                            {this.props.allInsuranceStatus && this.props.allInsuranceStatus.edges && this.props.allInsuranceStatus.edges.length > 0 ?
                                this.props.allInsuranceStatus.edges.map((insurance, i) =>
                                    insurance.node && <div style={{ borderBottom: "2px solid #c7c7c7a8", margin: "20px 0" }} key={i} >
                                        <InsuranceDetailSection
                                            insurance={insurance.node}
                                            refetchQuery={this.refetchQuery}
                                            currentUser={this.props.currentUser}
                                            colSize={"3"}
                                        />
                                    </div>
                                ) : !this.props.loading && <p>No Insurance Found</p>
                            }
                        </>
                }
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["add_subscription", "change_subscription", "custom_can_create_insurance_before_denial_period"] }, fetchPolicy: 'cache-first', notifyOnNetworkStatusChange: true, }),
        props({ data: { hasPermissions, variables } }) {
            return {
                hasPermissions, variables,
            }
        }
    }),
    graphql(insuranceStatusEnums, { props({ data: { loading, driverInsuranceStatusEnums } }) { return { loading, driverInsuranceStatusEnums } } }),
    graphql(AllInsuranceStatus, {
        options: ({ driver }) => ({ variables: { driverId: driver.id }, fetchPolicy: 'cache-first', notifyOnNetworkStatusChange: true, }),
        props({ data: { allInsuranceStatus, loading, refetch, variables } }) {
            return {
                allInsuranceStatus, insuranceLoading: loading, variables,
                refetchallInsuranceStatus: () => {
                    return refetch({
                        query: AllInsuranceStatus,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { allInsuranceStatus: fetchMoreResult.allInsuranceStatus }
                        },
                    })
                },
            }
        }
    }),
)(RiskInsuranceDetails)
