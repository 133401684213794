import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import '../CarDetail.css'
import { Col, Row, Button, UncontrolledTooltip } from 'reactstrap'
import UpdatePopup from "../../../PriceManagement/UpdatePopup"
import { Progress } from 'reactstrap'
import { CarPricingQuery } from '../Queries'
import moment from 'moment'
import Loader from "../../../Material/Loader"
import Label from "reactstrap/lib/Label"
import DatetimeRenderer from "../../../Material/DatetimeRenderer"

class PricingTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    render() {
        const pricingTable = [
            { id: "pk", name: "id", sortable: false, colSize: 1 },
            { id: "interval", name: "Interval", sortable: false, colSize: 1 },
            { id: "price", name: "Price", sortable: false, colSize: 1 },
            { id: "priceRange", name: "Price Range", sortable: false },
            { id: "dateAddded", name: "DATE ADDED", sortable: false },
            { id: "carYearMax", name: "Model Year Range", sortable: false },
            { id: "visibleToCustomers", name: "Customer Visibility", sortable: false },
        ]
        return (
            <div className="pricing-tab-container">
                {this.props.loading ? <Loader /> :
                    <div className="info-container">
                        <Row>
                            {pricingTable.map(header => <Col xs={header.colSize}><Label id={header.id}>{header.name}</Label></Col>)}
                        </Row>
                        <hr />
                        {this.props.allPricingTemplates && this.props.allPricingTemplates.edges && this.props.allPricingTemplates.edges.length > 0 ?
                            this.props.allPricingTemplates.edges.filter(price => price.node !== null).map((price, index) =>
                                <>
                                    <Row key={price.node.id} id={price.node.id}>
                                        <Col scope="row" xs={1}>{index + 1}</Col>
                                        <Col xs={1}>
                                            <p>{price.node.isActive ? <>
                                                <i id={"priceIsActive" + index} className="ml-2 fa fa-circle text-success" />
                                                <UncontrolledTooltip placement="right" target={"priceIsActive" + index}>Active Price</UncontrolledTooltip>
                                            </> : <>
                                                <i id={"priceIsNotActive" + index} className="ml-2 fa fa-circle" style={{ color: '#DC3546' }} />
                                                <UncontrolledTooltip placement="right" target={"priceIsNotActive" + index}>In-Active Price</UncontrolledTooltip>
                                            </>}&nbsp;&nbsp;
                                                {price.node.interval} {price.node.intervalUnit}</p>
                                        </Col>
                                        <Col xs={1}>
                                            <p>${price.node.price}&nbsp;&nbsp;
                                                <small>
                                                    <i id={"updatePrice" + index} onClick={() => this.toggleModal("updatePrice" + index)} className={"fa fa-pencil"} />
                                                </small>
                                            </p>
                                            {this.state.openModal === "updatePrice" + index &&
                                                <UpdatePopup
                                                    target={"updatePrice" + index}
                                                    isOpen={this.state.openModal == "updatePrice" + index}
                                                    header="Update date Price"
                                                    dataId={price.node.id}
                                                    value={price.node.price}
                                                    name="price"
                                                    type="number"
                                                    refetchAllPricingTemplates={() => this.props.refetchQuery()}
                                                    handleClose={() => this.toggleModal("updatePrice" + index)}
                                                />
                                            }
                                        </Col>
                                        <Col>
                                            <p>${price.node.minimumPrice}&nbsp;&nbsp;
                                                <small>
                                                    <i id={"updateMinimumPrice" + index} onClick={() => this.toggleModal("updateMinimumPrice" + index)} className={"fa fa-pencil"} />
                                                </small>
                                                &nbsp;&#8212;&nbsp;${price.node.maximumPrice}&nbsp;&nbsp;
                                                <small>
                                                    <i id={"updatemaximumPrice" + index} onClick={() => this.toggleModal("updatemaximumPrice" + index)} className={"fa fa-pencil"} />
                                                </small>
                                            </p>
                                            {this.state.openModal === "updatemaximumPrice" + index &&
                                                <UpdatePopup
                                                    target={"updatemaximumPrice" + index}
                                                    isOpen={this.state.openModal == "updatemaximumPrice" + index}
                                                    header="Update Template Maximum Price"
                                                    dataId={price.node.id}
                                                    value={price.node.maximumPrice}
                                                    name="maximumPrice"
                                                    type="number"
                                                    refetchAllPricingTemplates={() => this.props.refetchQuery()}
                                                    handleClose={() => this.toggleModal("updatemaximumPrice" + index)}
                                                />
                                            }
                                            {this.state.openModal === "updateMinimumPrice" + index &&
                                                <UpdatePopup
                                                    target={"updateMinimumPrice" + index}
                                                    isOpen={this.state.openModal == "updateMinimumPrice" + index}
                                                    header="Update Template Minimum Price"
                                                    dataId={price.node.id}
                                                    value={price.node.minimumPrice}
                                                    name="minimumPrice"
                                                    type="number"
                                                    refetchAllPricingTemplates={() => this.props.refetchQuery()}
                                                    handleClose={() => this.toggleModal("updateMinimumPrice" + index)}
                                                />
                                            }
                                        </Col>
                                        <Col>
                                            <p>{price.node && price.node.dateAdded ? <DatetimeRenderer datetime={price.node.dateAdded} format={"dddd, MMMM Do, h:mm a"} /> : "---"}</p>
                                        </Col>
                                        <Col>
                                            <p>{price.node.carYearMin}&nbsp;&nbsp;
                                                <small>
                                                    <i id={"updatecarYearMin" + index} onClick={() => this.toggleModal("updatecarYearMin" + index)} className={"fa fa-pencil"} />
                                                </small>
                                                &nbsp;&#8212;&nbsp;{price.node.carYearMax}&nbsp;&nbsp;
                                                <small>
                                                    <i id={"updatecarYearMax" + index} onClick={() => this.toggleModal("updatecarYearMax" + index)} className={"fa fa-pencil"} />
                                                </small>
                                            </p>
                                            {this.state.openModal === "updatecarYearMax" + index &&
                                                <UpdatePopup
                                                    target={"updatecarYearMax" + index}
                                                    isOpen={this.state.openModal == "updatecarYearMax" + index}
                                                    header="Update Template Car Year Maximum"
                                                    dataId={price.node.id}
                                                    value={price.node.carYearMax}
                                                    name="carYearMax"
                                                    type="number"
                                                    refetchAllPricingTemplates={() => this.props.refetchQuery()}
                                                    handleClose={() => this.toggleModal("updatecarYearMax" + index)}
                                                />
                                            }
                                            {this.state.openModal === "updatecarYearMin" + index &&
                                                <UpdatePopup
                                                    target={"updatecarYearMin" + index}
                                                    isOpen={this.state.openModal == "updatecarYearMin" + index}
                                                    header="Update Template Car Year Minimum"
                                                    dataId={price.node.id}
                                                    value={price.node.carYearMin}
                                                    name="carYearMin"
                                                    type="number"
                                                    refetchAllPricingTemplates={() => this.props.refetchQuery()} h
                                                    andleClose={() => this.toggleModal("updatecarYearMin" + index)}
                                                />
                                            }
                                        </Col>
                                        <Col>
                                            <p>{price.node.visibleToCustomers ? "True" : "False"}&nbsp;&nbsp;
                                                <small>
                                                    <i id={"visibleToCustomers" + index} onClick={() => this.toggleModal("visibleToCustomers" + index)} className={"fa fa-pencil"} />
                                                </small>
                                            </p>
                                            {this.state.openModal === "visibleToCustomers" + index &&
                                                <UpdatePopup
                                                    target={"visibleToCustomers" + index}
                                                    isOpen={this.state.openModal == "visibleToCustomers" + index}
                                                    header="Update Template Is visible to Customer"
                                                    dataId={price.node.id}
                                                    value={price.node.visibleToCustomers}
                                                    name="visibleToCustomers"
                                                    type="checkbox"
                                                    refetchAllPricingTemplates={() => this.props.refetchQuery()}
                                                    handleClose={() => this.toggleModal("visibleToCustomers" + index)}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                    <hr />
                                </>
                            ) : <Row> <Col colSpan={20}><p className="column-text">No Templates Found!</p> </Col> </Row>
                        }
                        {this.props.allPricingTemplates && this.props.allPricingTemplates.pageInfo && this.props.allPricingTemplates.pageInfo.hasNextPage &&
                            <Row>
                                <Col colSpan={20}>
                                    <Button onClick={this.props.loadMoreEntries} color="add-new-car link linkHoverEffect">
                                        Show More <i className={"fa fa-caret-down"}></i>
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(CarPricingQuery, {
        options: ({ id }) => ({ variables: { carId: id, first: 10 } }),
        props({ data: { loading, allPricingTemplates, fetchMore, variables } }) {
            return {
                variables,
                loading,
                allPricingTemplates,
                refetchQuery: () => {
                    let currentLength = allPricingTemplates && allPricingTemplates.edges ? allPricingTemplates.edges.length : 30
                    return fetchMore({
                        query: CarPricingQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allPricingTemplates.edges
                            const pageInfo = fetchMoreResult.allPricingTemplates.pageInfo
                            return {
                                allPricingTemplates: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allPricingTemplates.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: CarPricingQuery,
                        variables: {
                            cursor: allPricingTemplates.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allPricingTemplates.edges
                            const pageInfo = fetchMoreResult.allPricingTemplates.pageInfo

                            return {
                                allPricingTemplates: {
                                    edges: [...previousResult.allPricingTemplates.edges, ...newEdges],
                                    pageInfo,
                                    __typename: previousResult.allPricingTemplates.__typename
                                },
                            }
                        },
                    })
                },

            }
        }
    }),
)(PricingTab)