export default function handleConfigurationChange(prevConfigurations, currentConfigurations, urlFilterValues, setFilterValues, history, location, defaultFilters, updateFilters) {
    if ((prevConfigurations === null && urlFilterValues === null)) {
        // 1- when the page is loaded and no filter params are in the url
        updateFilters(defaultFilters, currentConfigurations, history, location, setFilterValues)
    }
    else if (prevConfigurations === null && urlFilterValues !== null) {
        // when the page is loaded and filter params are in the url then set default hidden filters and urls filters
        let visibleFilters = currentConfigurations.filter(item => item.showFilter).map(item => item.name)
        let hiddenDefaultFilterValues = {}
        if (defaultFilters) {
            Object.keys(defaultFilters).map(key => {
                if (!visibleFilters.includes(key) || (location.hash === "#billing" && visibleFilters.includes(key) !== null))
                    hiddenDefaultFilterValues[key] = defaultFilters[key]
            })
        }
        updateFilters({ ...urlFilterValues, ...hiddenDefaultFilterValues }, currentConfigurations, history, location, setFilterValues)
    }
    else {
        // when the tab on the page is changed
        updateFilters(defaultFilters, currentConfigurations, history, location, setFilterValues)
    }
}
