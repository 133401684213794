import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import './SubmitToInsuranceForm.css'
import {
    Button, Col, Modal, ModalHeader,
    ModalBody, Alert, Progress, ModalFooter,
    FormGroup, Label, Row
} from 'reactstrap'
import Select from 'react-select'
import { HasPermissionsQuery } from "../Functions/querys"

const createDriverInsuranceStatusMutation = gql`
mutation createDriverInsuranceStatusMutation($input: CreateDriverInsuranceStatusMutationInput!){
    createDriverInsuranceStatus(input:$input){
    ok
    errors{
        messages
    }
}
} `


const VALIDATIONS_TO_SKIP_OPTIONS = ["ARGYLE"]

class SubmitToInsuranceForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            useCannedMessage: true,
            loading: false,
            error: null,
            insuranceCreated: false,
            openModal: this.props.open ? this.props.open : "",
            selectedBroker: "",
            validationsToSkip: [],
        }
    }

    createInsurance = () => {
        try {
            if (this.props.driverId) {
                this.setState({ loading: true })
                let input = {
                    driverId: this.props.driverId,
                    driverInsuranceTypeId: this.state.driverInsuranceTypeId,
                    status: 3,
                    validationsToSkip: this.state.validationsToSkip,
                }
                this.props.client.mutate({
                    mutation: createDriverInsuranceStatusMutation,
                    variables: { input },
                }).then((result) => {
                    if (result.data && result.data.createDriverInsuranceStatus && result.data.createDriverInsuranceStatus.ok) {
                        this.setState({ insuranceCreated: true, error: null, loading: false })
                        this.props.refetchDriverQuery && this.props.refetchDriverQuery()
                        this.props.refetchListingQuery && this.props.refetchListingQuery()
                        this.toggleModal("")
                    } else {
                        this.setState({ loading: false, insuranceCreated: false, error: result.data.createDriverInsuranceStatus.errors[0].messages[0] })
                    }
                }).catch((error) => {
                    let errorMessage = "An error has occured"
                    this.setState({ error: errorMessage, loading: false, insuranceCreated: false, })
                })

            } else {
                this.setState({ error: "Missing fields", loading: false, insuranceCreated: false })
            }
        } catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false, insuranceCreated: false, })

        }
    };
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        return (
            <Modal className="confirmation-popup" isOpen={this.state.openModal === "SubmitToInsuranceForm"}>
                <ModalHeader>Submit to Insurance</ModalHeader>
                <ModalBody className="text-left">
                    {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                    {this.state.insuranceCreated && <Alert color="success">Action Performed Successfully</Alert>}
                    <p className="modal-message">Please confirm you checked all required doc to submit to UW?</p>
                    {this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_skip_validations") &&
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Validations to skip</Label>
                                    <br />
                                    <Select className="bos-custom-select"
                                        classNamePrefix="bos-select" isLoading={false}
                                        options={
                                            VALIDATIONS_TO_SKIP_OPTIONS && VALIDATIONS_TO_SKIP_OPTIONS && VALIDATIONS_TO_SKIP_OPTIONS.map(
                                                (validation) => ({ value: validation, label: validation }))
                                        }
                                        placeholder="Select validations to skip"
                                        isMulti
                                        onChange={(option) => this.setState({ validationsToSkip: option.map(item => item.value) })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    }
                    {this.state.loading && <Progress animated color="info" value="100" />}
                </ModalBody>
                <ModalFooter>
                    <Col className="text-right">
                        <Button className="secondary-btn" onClick={() => this.toggleModal("")}>Close</Button>&nbsp;&nbsp;
                        <Button color="danger" disabled={this.state.loading || this.state.error || this.state.success} className="primary-action-btn" onClick={this.createInsurance}>Yes</Button>
                    </Col>
                </ModalFooter>
            </Modal>
        )
    }
}

export default compose(
    withApollo,
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["custom_can_skip_validations"] } }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    })
)(SubmitToInsuranceForm)