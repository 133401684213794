import gql from 'graphql-tag'

export const UpdateDriver = gql`
    mutation updateDriverMutation($input: UpdateDriverMutationInput!){
    updateDriver(input:$input){
        ok
        errors{
            field
            messages
        }
    }
} `
export const getMvrReportMutation = gql`
mutation getMvrReport($input: GetMVRReportMutationInput!){
    getMvrReport(input:$input){
        ok
        errors{
            field
            messages
        }
        reportData
    }
}`
export const CreateLatestInvoiceMutation = gql`
mutation createLatestInvoice($input: CreateLatestInvoiceMutationInput!){
    createLatestInvoice(input:$input){
        ok
        errors{
            field
            messages
        }
    }
}`

export const ReActivateDriverOnApp = gql`
    mutation setDriverActiveStatus($input: SetAppDriverAccountStatusMutationInput!){
        setDriverActiveStatus(input:$input){
            ok
        }
    }
`
export const CreateNewDriverMutation = gql`mutation CreateNewDriverMutation($input: CreateNewDriverMutationInput!){
    createNewDriver(input:$input){
        ok
        driver{
            id
        }
        errors{
            messages
        }
    }
} `

export const DeleteDriverMutation = gql`mutation DeleteDriverMutation($input: DeleteDriverMutationInput!){
    deleteDriver(input:$input){
        ok
        errors{
            messages
        }
    }
} `

export const PaymentScheduleQuery = gql`query PaymentScheduleQuery($driverId: ID!){
    paymentSchedule(driverId: $driverId){
        id
        amountToCharge
        scheduleDatetime
    }
}`

export const CREATE_ADDRESS_MUTATION = gql`
    mutation createAddressMutation($input: CreateAddressMutationInput!){
        createAddress(input:$input){
            ok errors{ field messages }
        }}`

export const UPDATE_ADDRESS_MUTATION = gql`
    mutation updateAddressMutation($input: UpdateAddressMutationInput!){
        updateAddress(input:$input){
            ok errors{ field messages }
        }}`
