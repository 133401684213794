import React, { Component } from 'react'
import { graphql, withApollo, compose } from 'react-apollo'
import gql from 'graphql-tag'
import Select from 'react-select'
import { RepsQuery } from "../Functions/querys"
import { Button, Row, Col, Progress, Alert } from "reactstrap"

const assignDriverToRepMutation = gql`
    mutation CreateAssignment($repID:ID!, $drivers:ID!){
      createAssignment(input:{repId:$repID, drivers:[$drivers]}){
        ok
        assignment{
            id
        }
        errors{
              field
              messages
          }
      }
  }`

class RepAssignment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rep: this.props.defaultValue,
            error: null,
        }
    }
    updateRep = () => {
        let currentReps = this.props.allReps.edges.filter((rep) => {
            return rep.node.id === this.state.rep
        }).map(rep => rep.node.id)
        if (!(currentReps === this.props.defaultValue.map(rep => rep.node.id))) {
            let input = { repID: this.state.rep, drivers: [this.props.driver.id] }
            this.props.client.mutate({
                mutation: assignDriverToRepMutation,
                variables: input
            }).then((result) => {
                if (result && result.data && result.data.createAssignment && result.data.createAssignment.ok) {
                    this.setState({ error: null })
                    this.props.refetchDrivers()
                    this.props.handleClose()
                } else {
                    this.setState({ error: "Error assigning rep. Try again" })
                }
            })
        }
    }
    render() {
        return (
            <div className="pb-2">
                {this.props.loading && <Progress animated color="info" value="100" />}
                {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                {!this.props.loading &&
                    <>
                        <Row>
                            <Col>
                                <Select
                                    className="bos-custom-select pb-2" classNamePrefix="bos-select"
                                    placeholder="Select Rep"
                                    options={this.props.allReps && this.props.allReps.edges.map(rep => ({ value: rep.node.id, label: rep.node.name }))}
                                    defaultValue={this.props.allReps && this.props.defaultValue && this.props.allReps.edges.map(rep => ({ value: rep.node.id, label: rep.node.name })).filter(rep => this.props.defaultValue && this.props.defaultValue.map(rep => rep.node.id).includes(rep.value))}
                                    isClearable
                                    onChange={rep => this.setState({ rep: rep ? rep.value : "none" })}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button className="float-right submit-button" onClick={this.updateRep}>Update</Button>
                            </Col>
                        </Row>
                    </>}
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(RepsQuery, {
        options: ({ groups }) => ({ variables: { groups }, fetchPolicy: 'cache-and-network' }),
        props({ data: { loading, allReps } }) {
            return { loading, allReps }
        },
    }),
)(RepAssignment)