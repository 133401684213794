import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import './RepAssignmentModal.css'
import {
    Row, Col, Badge, Alert, Progress,
    Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import { RepsQuery } from "../Functions/querys"
import Select from "react-select"

const RepAssignment = gql`
mutation BulkRepAssignment ($input:BulkRepAssignmentMutationInput!){
    bulkRepAssignment(input:$input){
        errors{
            messages
        }
        ok
    }
}`

class BulkRepAssignment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // selectedReps: [],
            representative: null,
            loading: false,
            error: null,
            success: false
        }
    }

    handleClose = () => {
        this.setState({ success: false, error: null, loading: false })
        this.props.handleClose()
    }


    assignRep = () => {
        this.setState({ loading: true })
        let input = { objectIds: this.props.selectedObjectIds, representative: this.state.representative }
        this.props.client.mutate({
            mutation: RepAssignment,
            variables: { input },
        }).then((result) => {
            if (result.data && result.data.bulkRepAssignment && result.data.bulkRepAssignment.ok) {
                this.setState({ loading: false, error: null, success: true })
                if (this.props.refetchQuery) {
                    this.props.refetchQuery()
                }
                this.handleClose()
            } else {
                let error = "An error occurred, could not complete request."
                if (result && result.data && result.data.bulkRepAssignment && result.data.bulkRepAssignment.errors[0] && result.data.bulkRepAssignment.errors[0].messages) {
                    error = result.data.bulkRepAssignment.errors[0].messages.toString()
                }
                this.setState({ success: false, loading: false, error })
            }
        }).catch((error) => {
            // let errorMessage = "An error has occured"
            this.setState({ error: error.message, loading: false, success: false })
        })
    };

    render() {
        return (
            <div >
                <Modal classNameBulk="repAssignmeal" isOpen={this.props.open}>
                    <ModalHeader>{this.props.title}</ModalHeader>
                    <ModalBody>
                        <Row>
                            {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                            {this.state.success ?
                                <Col xs={12}>
                                    <Alert color="success">Assignments created successfully!</Alert>
                                </Col> :
                                <Col xs={12}>
                                    <Select
                                        className="bos-custom-select pb-2" classNamePrefix="bos-select"
                                        placeholder="Select Rep"
                                        isLoading={this.props.loading}
                                        options={this.props.allReps && this.props.allReps.edges.map(rep => ({ value: rep.node.id, label: rep.node.name }))}
                                        defaultValue={this.props.allReps && this.props.defaultValue && this.props.allReps.edges.map(rep => ({ value: rep.node.id, label: rep.node.name })).filter(rep => this.props.defaultValue && this.props.defaultValue.map(rep => rep.node.id).includes(rep.value))}
                                        isClearable
                                        onChange={rep => this.setState({ representative: rep ? rep.value : "none" })}
                                    />
                                </Col>
                            }
                        </Row>
                        {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                    </ModalBody>
                    <ModalFooter>
                        {!this.state.loading &&
                            <>
                                <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                <Button color="secondary" onClick={this.assignRep} disabled={!this.state.representative}>Assign Rep</Button>
                            </>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(RepsQuery, {
        options: ({ groups, permissions }) => ({ variables: { groups, permissions } }),
        props({ data: { allReps, loading } }) {
            return { allReps, loading }
        },
    })
)(BulkRepAssignment)