import gql from "graphql-tag"

const VendorsQuery = gql`query VendorsQuery($searchTerm: String){
    allVendors(searchTerm: $searchTerm){
        edges{
            node {
                id
                name
                address
                isActive
            }
        }
    } 
}`

const CarsQuery = gql`query CarsQuery($searchTerm: String){
    allCars(searchTerm: $searchTerm, first:5){
        edges{
            node{
                id
                pk
                vin
                plate{
                    dmvPlate
                    fhv
                }
            }
        }
    } 
}`

const JobTypeQuery = gql`query JobTypeQuery{
    allJobTypes{
        edges{
            node {
                id
                name
            }
        }
    } 
}`


const WorkOrderQuery = gql` query WorkOrderQuery($id: ID){
    workOrder(id:$id){
        id
        vendor {
          id
          name
        }
        car {
          id
          pk
          vin
          dmvPlate
        }
        completionDate
        salesTax
        workitemSet {
          edges {
            node {
              id
              jobType {
                name
                id
              }
              price
              quantity
              id
            }
          }
        }
    }
}`


export { VendorsQuery, CarsQuery, JobTypeQuery, WorkOrderQuery }