import gql from "graphql-tag"


const CreateRegistration = gql`
mutation CreateRegistration($input: CreateRegistrationMutationInput!){
    createRegistration(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const UpdateRegistration = gql`
mutation UpdateRegistration($input: UpdateRegistrationMutationInput!){
    updateRegistration(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const CreateCarRegistrationMutation = gql`
mutation CreateCarRegistrationMutation($input: CreateCarRegistrationMutationInput!){
    createCarRegistration(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const UpdateCarRegistrationMutation = gql`
mutation UpdateCarRegistrationMutation($input: UpdateCarRegistrationMutationInput!){
    updateCarRegistration(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const CreateCarInspectionMutation = gql`
mutation CreateCarInspectionMutation($input: CreateCarInspectionMutationInput!){
    createCarInspection(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const UpdateCarInspectionMutation = gql`
mutation updateCarInspectionMutation($input: UpdateCarInspectionMutationInput!){
    updateCarInspection(input:$input){
        ok
        errors{
            messages
        }
    }
} `

export { CreateRegistration, CreateCarRegistrationMutation, UpdateRegistration, UpdateCarRegistrationMutation, CreateCarInspectionMutation, UpdateCarInspectionMutation }
