import React, { Component } from "react";
import {
  Popover,
  PopoverHeader,
  PopoverBody,
  Form,
  FormGroup,
  Input,
  Badge,
  Button,
  Label
} from "reactstrap";
import { compose } from "react-apollo";
import { withApollo } from "react-apollo/index";
import { UpdatePricingTemplateMutation } from "./Mutations";

class UpdatePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pricingTemplate: this.props.dataId,
      value: this.props.value ? this.props.value : "",
      error: "",
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    try {
      let input = {
        pricingTemplate: this.state.pricingTemplate,
        [this.props.name]: ['price', 'minimumPrice', 'maximumPrice'].includes(this.props.name) ? parseFloat(this.state.value) : ['interval', 'carYearMin', 'carYearMax'].includes(this.props.name) ? parseInt(this.state.value) : this.state.value,
      };

      this.setState({ loading: true });
      this.props.client
        .mutate({
          mutation: UpdatePricingTemplateMutation,
          variables: { input },
        })
        .then((result) => {
          if(result.data && result.data.updatePricingTemplate && result.data.updatePricingTemplate.ok){
            this.props.refetchAllPricingTemplates();
            this.props.handleClose();
          }else if(result.data && result.data.updatePricingTemplate && !result.data.updatePricingTemplate.ok && result.data.updatePricingTemplate.errors && result.data.updatePricingTemplate.errors[0]){
            this.setState({ loading: false, error: result.data.updatePricingTemplate.errors[0].messages.toString() });
          }else{
            this.setState({ loading: false, error: "Something went wrong, please contact admin for support!" });
          }
        })
        .catch((err) => {
          this.setState({ loading: false, error: err.message });
        });
    } catch (err) {
      this.setState({ loading: false, error: err });
    }
  };

  render() {
    return (
      <Popover isOpen={this.props.isOpen} target={this.props.target} toggle={this.props.handleClose} placement="left">
        <PopoverHeader>{this.props.header}</PopoverHeader>
        <PopoverBody>
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              {this.props.type === "number" &&
                <Input type="number" step="any" name={this.props.name} id="templatepricing" placeholder={this.props.header} value={this.state.value} onChange={(e)=>this.setState({ value: e.target.value })} />
              }
            </FormGroup>
            <FormGroup check inline>
              {this.props.type === "checkbox" &&
                <Label>
                  <Input type="checkbox" checked = {this.state.value} onChange={(e) => this.setState({ value: e.target.checked ? true : false })}/>
                  {" "}
                  {this.props.name}
                </Label>}
            </FormGroup>
            {this.state.loading ? "Loading..." : <Button color="success" type="submit">Update</Button>}
          </Form>
          {this.state.error && (
            <Badge pill="true" style={{ whiteSpace: "pre-wrap" }} color="danger">
              {this.state.error}
            </Badge>
          )}
        </PopoverBody>
      </Popover>
    );
  }
}

export default compose(withApollo)(UpdatePopup);
