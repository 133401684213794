import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import ApolloClient from 'apollo-client';
import {withClientState} from 'apollo-link-state';
import {ApolloLink} from 'apollo-link';
import gql from "graphql-tag";

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
}
const defaultState = {
    selectedFilter: {
        __typename: 'SelectedFilter',
        active: 0,
        rep: 'all',
        carStatus: 'all'
    },

    currentUser: {
        __typename: 'CurrentUser',
        username: "",
        isManager: false,
        isBilling: false,
        isFrontOffice:false,
        isTopManagement: false,
        authLevel: 0
    }
};

const cache = new InMemoryCache();

const stateLink = withClientState({
    cache,
    defaults: defaultState,
    resolvers: {
        Mutation: {
            updateFilter: (_, { index, value }, { cache }) => {
                const query = gql`
                    query GetCurrentFilter{
                        selectedFilter @client {
                            active
                            rep
                            carStatus
                        }
                    }
                `;
                const previousState = cache.readQuery( { query } );

                const data = {
                    selectedFilter: {
                        ...previousState.selectedFilter,
                        [index]: value
                    }
                };
                cache.writeData({ query, data });
            },
        }
    }
});


const client = new ApolloClient({
    link: ApolloLink.from([stateLink, new HttpLink({ uri: '/graphiql/',
    headers: {"X-CSRFToken":  getCookie('csrftoken')},
    credentials: 'same-origin' })]),
    dataIdFromObject: o => o.id,
    cache: cache,
    defaultOptions:{
        watchQuery: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        }
    }
  });
  export default client;
