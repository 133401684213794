import React, { Component } from 'react'
import { UncontrolledTooltip, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import renderEnumToInt from '../../Functions/renderEnumToInt'
import gql from 'graphql-tag'

const UpdateCarMutation = gql`
    mutation updateCarMutation($input: UpdateCarMutationInput!){
        updateCar(input:$input){
            ok
            errors{
                messages
            }
    }
} `

class CarsColumn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            defaultValue: this.props.value ? this.props.value : "",
            value: this.props.value ? this.props.value : "",
            loading: false,
            error: "",
            options: this.props.options ? this.props.options : [],
            editField: this.props.isNew && this.props.editable ? (this.props.name + this.props.id) : "",
        }
    }

    handleChange = (e) => {
        let value = e.target.value
        if (this.props.isNew && this.props.updateInfo) {
            this.props.updateInfo(e.target.name, value)
        } else {
            this.setState({ ...this.state, value: value })
        }
    }

    handleValid(name = this.props.name, value = this.state.value) {
        return this.props.isValid(name, value)
    }

    updateCar = (carId = this.props.id, name = this.props.name, value = this.state.value) => {
        try {
            if (carId && name && value && value != this.state.defaultValue) {
                this.setState({ loading: true })
                let input = {
                    id: carId,
                    [name]: this.props.name === "stage" ? renderEnumToInt(value) : value
                }
                this.props.client.mutate({
                    mutation: UpdateCarMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateCar && result.data.updateCar.ok) {
                        this.setState({ editField: "", loading: false, error: "", defaultValue: value })
                        // this.props.refetchCars(); 
                    } else if (result && result.data && result.data.updateCar && result.data.updateCar.errors && result.data.updateCar.errors[0] && result.data.updateCar.errors[0].messages) {
                        this.setState({ loading: false, error: result.data.updateCar.errors[0].messages.toString() })
                    } else {
                        this.setState({ loading: false, error: "Something went wrong, Please contact admin for support!" })
                    }
                }).catch((err) => {
                    this.setState({ loading: false, error: err.message })
                })
            } else if (value == this.props.defaultValue) {
                this.setState({ editField: "", loading: false, error: "" })
            }
        } catch (err) {
            this.setState({ loading: false, error: err })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            this.setState({ ...this.state, value: this.props.value, defaultValue: this.props.value })
        } else if (this.props.value !== prevProps.value) {
            this.setState({ ...this.state, value: this.props.value })
        } else if (this.props.options != prevProps.options) {
            this.setState({ ...this.state, options: this.props.options })
        } else if (this.props.isNew != prevProps.isNew) {
            this.setState({ ...this.state, editField: "" })
        } else if (this.props.options != prevProps.options) {
            this.setState({ ...this.state, options: this.props.options ? this.props.options : [] })
        }
    }

    render() {
        return (
            <td onDoubleClick={() => { this.props.editable && this.setState({ editField: this.props.name + this.props.id }) }} className={this.props.className + " " + (this.state.editField && this.state.editField === this.props.name + this.props.id ? this.handleValid() ? " valid-value" : " invalid-value" : this.props.editable ? "" : " disabled")}>
                {this.state.editField && this.state.editField === this.props.name + this.props.id ?
                    <InputGroup>
                        {!this.props.isNew &&
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    {this.state.loading ?
                                        <i className="fa fa-spinner" aria-hidden="true" /> :
                                        <><i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({ editField: "", error: "", value: this.state.defaultValue ? this.state.defaultValue : "" })} />
                                            &nbsp;&nbsp;
                                            {this.state.error && this.state.error != "" ? <><i id={"error" + this.props.name} className="fa fa-exclamation-circle" aria-hidden="true" /> <UncontrolledTooltip placement="bottom" target={"error" + this.props.name}>{this.state.error}</UncontrolledTooltip></> :
                                                <i className="fa fa-check" aria-hidden="true" onClick={() => this.updateCar()} />}</>
                                    }
                                </InputGroupText>
                            </InputGroupAddon>}

                        {this.props.isNew && this.props.loading && this.props.name != "row" &&
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-spinner" aria-hidden="true" />
                                </InputGroupText>
                            </InputGroupAddon>}

                        {["owner", "baseId", "branch", "dealer", "operatingLease", "titleOwner", "stage"].includes(this.props.name) ?
                            <Input className={this.props.isNew ? "px-2" : ""} type="select" name={this.props.name} placeholder={"Car " + this.props.name} value={this.props.name === "stage" ? this.state.value : this.state.value && this.props.options && this.props.options.filter(item => item.node.name === this.state.value).length > 0 ? this.state.value : "null"} onChange={this.handleChange}>
                                <option value={"null"} disabled key={-1}>Select Car {this.props.name}</option>
                                {this.state.options && this.state.options.length > 0 && this.state.options.map((item, i) =>
                                    <option value={item.node && item.node.name ? item.node.name : item.name ? item.name : item} key={i}>
                                        {item.node ? item.node.name : item.name ? (renderEnumToInt(item.name) + (item.description ? " - " + item.description : null)) : item}
                                    </option>)}
                            </Input> :
                            ["group"].includes(this.props.name) ?
                                <Input className={this.props.isNew ? "px-2" : ""} type="select" name={this.props.name} placeholder={"Car " + this.props.name} value={this.state.value && this.props.options && this.props.options.filter(item => item.name === this.state.value).length > 0 ? this.state.value : "null"} onChange={this.handleChange}>
                                    <option value={"null"} disabled key={-1}>Select Car {this.props.name}</option>
                                    {this.state.options && this.state.options.length > 0 && this.state.options.map((item, i) =>
                                        <option value={item.name} key={i}>
                                            {item.name}
                                        </option>)}
                                </Input> :
                                <Input className={this.props.isNew ? "px-2" : ""} type={this.props.type ? this.props.type : "text"} max={this.props.max} min={this.props.min} name={this.props.name} placeholder={this.props.placeholder} value={this.state.value} onChange={this.handleChange} />
                        }
                    </InputGroup>
                    : <>
                        <p id={!this.props.isNew ? (this.props.name + this.props.pk) : ""} style={this.props.name === "carModel" ? { maxWidth: '175px' } : {}} className={"column-text" + (this.props.limitText ? " limit-text" : "") + (this.props.name == "row" ? " text-center" : "")}>
                            {this.props.name == "row" && this.props.isNew ?
                                this.props.loading ? <i className="fa fa-spinner" aria-hidden="true" /> :
                                    this.props.isCreated ? <i className="fa fa-check-square-o" aria-hidden="true" />
                                        : <>
                                            <i className="fa fa-times" aria-hidden="true" onClick={() => this.props.deleteNewCar()}></i>
                                            &nbsp;&nbsp;<i className="fa fa-check" disabled={this.props.findError} style={{ pointerEvents: this.props.findError ? "none" : "auto" }} aria-hidden="true" onClick={() => this.props.handleUploadCar()}></i>
                                            {/* &nbsp;&nbsp;<i className="fa fa-paste" aria-hidden="true" onClick={()=>this.props.handleClipBoardData()}></i> */}
                                            {(this.state.error || this.props.error || this.props.findError) && <>&nbsp;&nbsp;<i id={"error" + this.props.id} className="fa fa-exclamation-circle" aria-hidden="true" /> <UncontrolledTooltip placement="top" target={"error" + this.props.id}>{this.state.error ? this.state.error : this.props.error ? this.props.error : this.props.findError ? `Unable to create car with invalid ${this.props.findError}` : ""}</UncontrolledTooltip></>}
                                        </>
                                : <>{(this.props.additionalText ? this.props.additionalText + " " : "") + (this.state.value ? this.props.name === "stage" ? renderEnumToInt(this.state.value) : this.state.value : "--")}</>
                            }
                        </p>
                        {this.props.tooltip && <UncontrolledTooltip placement="top" style={{ backgroundColor: "white", maxWidth: "100%" }} target={(this.props.name + this.props.pk)}>{this.props.tooltip}</UncontrolledTooltip>}
                        {!this.props.isNew && this.props.limitText && <UncontrolledTooltip placement="top" target={(this.props.name + this.props.pk)}>{this.state.value ? this.state.value : "--"}</UncontrolledTooltip>}
                    </>
                }
            </td>
        )
    }
}

export default compose(
    withApollo,
)(CarsColumn)
