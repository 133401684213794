import React, { Component } from 'react'
import { Label, Badge, UncontrolledTooltip } from 'reactstrap'
import DatetimeRenderer from "../../../../../Material/DatetimeRenderer"
import gql from 'graphql-tag'
import '../DriverDetailNew.css'
import { withApollo, compose, graphql } from "react-apollo"
import moment from 'moment-timezone'
import toTitleCase from "../../../../../Functions/toTitleCase"
import { Table } from "@material-ui/core"

const AllCustomerLeads = gql`query AllCustomerLeads($customerId: ID!){
    allCustomerLeads(customerId: $customerId){
        edges{
            node{
                id
                pk
                dateAdded
                dateModified
                message
                status
                leadType
                source
                referredBy
                metadata
                isArchived
                assignedRep {
                    id
                    username
                }
                branch{
                    id
                    name
                }
                notes
                fullName
                email
                phone
            }
        }
    }
}
`

class LeadsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
        }
    }

    render() {
        return (
            <div className="tab-container">
                <Table>
                    <thead>
                        <tr>
                            <th><Label>Date Added</Label></th>
                            <th><Label>Name</Label></th>
                            <th><Label>Phone</Label></th>
                            <th><Label>Email</Label></th>
                            <th><Label>Type</Label></th>
                            <th><Label>Status</Label></th>
                            <th><Label>Is Archived</Label></th>
                            <th><Label>Rep</Label></th>
                            <th><Label>Reffered By</Label></th>
                            <th><Label>Source</Label></th>
                            <th><Label>Message</Label></th>
                            <th><Label>Notes</Label></th>
                            <th><Label>Metadata</Label></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.allCustomerLeads && this.props.allCustomerLeads.edges && this.props.allCustomerLeads.edges.length > 0 ? this.props.allCustomerLeads.edges.map((lead, i) =>
                            <tr key={i}>
                                <td>
                                    <p className="columnContent">{lead.node.dateAdded ? <DatetimeRenderer datetime={lead.node.dateAdded} format={moment.HTML5_FMT.DATE} /> : "--"}</p>
                                </td>
                                <td>
                                    <p className="columnContent">
                                        {lead.node.fullName ? lead.node.fullName : "--"}
                                        <br />
                                        <Badge className="bos-badge-blue">{lead.node.branch.name}</Badge>
                                    </p>
                                </td>
                                <td>
                                    <p className="columnContent">{lead.node.phone ? lead.node.phone : "--"}</p>
                                </td>
                                <td>
                                    <p className="columnContent">{lead.node.email ? lead.node.email : "--"}</p>
                                </td>
                                <td>
                                    <p className="columnContent">{lead.node.leadType ? toTitleCase(lead.node.leadType) : "--"}</p>
                                </td>
                                <td>
                                    <p className="columnContent">{lead.node.status ? toTitleCase(lead.node.status) : "--"}</p>
                                </td>
                                <td>
                                    <p className="columnContent">{lead.node.isArchived ? "Archived" : "Not Archived"}</p>
                                </td>
                                <td>
                                    <p className="columnContent">{lead.node.assignedRep ? lead.node.assignedRep.username : "--"}</p>
                                </td>
                                <td>
                                    <p className="columnContent">{lead.node.referredBy ? lead.node.referredBy : "--"}</p>
                                </td>
                                <td>
                                    <p className="columnContent">{lead.node.source || "--"}</p>
                                </td>
                                <td>
                                    <p className="columnContent">{lead.node.message ? lead.node.message : "--"}</p>
                                </td>
                                <td>
                                    <p className="columnContent">{lead.node.notes ? lead.node.notes : "--"}</p>
                                </td>
                                <td>
                                    <p className="columnContent limit-text" id={`metadata-${lead.node.pk}`}>{lead.node.metadata ? lead.node.metadata : "--"}</p>
                                    <UncontrolledTooltip placement="bottom" target={`metadata-${lead.node.pk}`}>{lead.node.metadata}</UncontrolledTooltip>
                                </td>
                            </tr>
                        ) : <tr><td><p>No Lead Found!</p></td></tr>
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllCustomerLeads, {
        options: ({ id }) => ({ variables: { customerId: id }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, }),
        props({ data: { allCustomerLeads, loading, refetch, variables } }) {
            return {
                allCustomerLeads, loading, variables,
                refetchAllCustomerLeads: () => {
                    return refetch({
                        query: AllCustomerLeads,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { allCustomerLeads: fetchMoreResult.allCustomerLeads }
                        },
                    })
                },
            }
        }
    }),
)(LeadsTab)