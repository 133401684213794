import React, { Component } from "react"
import { Container, Nav, NavItem, Button, Input, Row, Col, TabContent, TabPane, NavLink } from "reactstrap"
import ArgyleUserList from "./ArgyleUserList"
import ArgyleNeverConnectedUserList from "./ArgyleNeverConnectedUserList"
import ActiveDriverArgyleAcccountListing from "./ActiveDriverArgyleAcccountListing"
import Select from 'react-select'
const ARGYLE_LISTING_TABS = [
    { id: "#connected", name: "Connected" },
    { id: "#neverconnected", name: "Never Connected" },
    { id: "#activedriverargyleaccounts", name: "Active Drivers Argle Account" },
]
class ArgyleUserListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: "",
            tab: "connected",
            searchTerm: "",
            ongoingRefreshStatus: "enabled",
            rendererdTabPanes: [],
            isActive: true
        }
    }

    toggleTab = (tabId) => {
        if (!this.state.rendererdTabPanes.includes(tabId)) {
            this.setState({ rendererdTabPanes: [...this.state.rendererdTabPanes, tabId] })
        }
        this.setState({ tab: tabId })
    }

    componentDidMount() {
        let defaultTab = ARGYLE_LISTING_TABS[0].id
        if (window.location.hash && ARGYLE_LISTING_TABS.map(item => item.id).includes(window.location.hash)) {
            this.setState({ tab: window.location.hash, rendererdTabPanes: [window.location.hash] })
        } else {
            window.location.hash = defaultTab
            this.setState({ tab: defaultTab, rendererdTabPanes: [defaultTab] })
        }
    }
    componentDidUpdate(_, prevState) {
        if (this.state.tab !== prevState.tab) {
            window.location.hash = this.state.tab
        }
    }

    render() {
        return (
            <Container fluid className="bos-listing-container">
                <Nav tabs>
                    {ARGYLE_LISTING_TABS.map(item =>
                        <NavItem key={item.id} className={this.state.tab === item.id ? "active" : "inactive"} style={{ background: "#E0E0E0" }}>
                            <NavLink className={this.state.tab === item.id ? "active" : "inactive"} onClick={() => this.toggleTab(item.id)}>
                                {item.name}
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
                <br />

                <TabContent activeTab={this.state.tab}>
                    {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#connected") && <TabPane tabId="#connected">
                        <ArgyleUserList />
                    </TabPane>}
                    {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#neverconnected") && <TabPane tabId="#neverconnected">
                        <>
                            <Row>
                                <Col xs={4}>
                                    <span className="driver-search-filter">
                                        <Input type="text" name="searchTerm" placeholder="Search by phone, email, name or license" onChange={(e) => this.setState({ searchTerm: e.target.value })} />
                                    </span>
                                </Col>
                                <Col xs={4}>
                                    <div className="pt-2">
                                        <span>Active Drivers</span>
                                        <div className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                className="toggle-switch-checkbox"
                                                name={"isActive"}
                                                id={"isActive"}
                                                checked={!this.state.isActive}
                                                onChange={() => this.setState({ isActive: !this.state.isActive })}
                                            />
                                            <label className="toggle-switch-label" htmlFor={"isActive"}>
                                                <span className="toggle-switch-inner" />
                                                <span className="toggle-switch-switch" />
                                            </label>
                                        </div>
                                        <span>All Drivers</span>
                                    </div>
                                </Col>
                            </Row>
                            <br />
                            <ArgyleNeverConnectedUserList searchTerm={this.state.searchTerm} isActive={this.state.isActive} />
                        </>
                    </TabPane>}
                    {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#activedriverargyleaccounts") && <TabPane tabId="#activedriverargyleaccounts">
                        <>
                            <Row>
                                <Col xs={4}>
                                    <span className="driver-search-filter">
                                        <Select
                                            className="bos-custom-select" classNamePrefix="bos-select"
                                            defaultValue={[{ value: "enabled", label: "ENABLED" }]}
                                            options={[{ value: "enabled", label: "ENABLED" }, { value: "disabled", label: "DISABLED" }, { value: "idle", label: "IDLE" }]}
                                            placeholder="Select Title Owner"
                                            onChange={status => this.setState({ ongoingRefreshStatus: status.value })}
                                        />
                                    </span>
                                </Col>
                            </Row>
                            <br />
                            <ActiveDriverArgyleAcccountListing ongoingRefreshStatus={this.state.ongoingRefreshStatus} />
                        </>
                    </TabPane>}
                </TabContent>
            </Container>
        )
    }
}

export default ArgyleUserListContainer
