import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Button, Table } from 'reactstrap'
import Progress from 'reactstrap/lib/Progress'
import UpdatePlateInfo from './UpdatePlateInfo'
import renderEnumToInt from '../../Functions/renderEnumToInt'
import gql from 'graphql-tag'

const AllPlatesQuery = gql`query AllPlatesQuery($cursor: String, $isActive: Boolean, $first: Int!, $searchTerm: String, $isAvailable: Boolean, $carStages: [Int]){
    allPlates(isActive: $isActive, searchTerm:$searchTerm, isAvailable:$isAvailable, carStages:$carStages, first:$first, after: $cursor, orderBy:["fhvExpiration"]){
        edges {
            node {
                id
                pk
                dmvPlate
                fhv
                fhvExpiration
                isActive
                car{
                    id
                    pk
                    vin
                    stage
                    branch{
                        id
                        name
                    }
                }
                owner{
                    id
                    name
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`

const tableHeaders = [
    { id: "dmv_plate", name: "DMV", sortable: false },
    { id: "fhv", name: "FHV", sortable: false },
    { id: "fhv_expiration", name: "FHV EXPIRATION", sortable: false },
    { id: "owner__name", name: "Owner", sortable: false },
    { id: "car__vin", name: "VIN", sortable: false },
    { id: "car__stage", name: "Stage", sortable: false },
    { id: "car__branch__name", name: "Branch", sortable: false }
]

const fullTableHeaders = [
    { id: "dmv_plate", name: "DMV", sortable: false },
    { id: "fhv", name: "FHV", sortable: false },
    { id: "fhv_expiration", name: "FHV EXPIRATION", sortable: false },
    { id: "owner__name", name: "Owner", sortable: false },
    { id: "is_active", name: "Is Active", sortable: false },
    { id: "car__vin", name: "VIN", sortable: false },
    { id: "car__stage", name: "Stage", sortable: false },
    { id: "car__branch__name", name: "Branch", sortable: false }
]

class PlatesList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            fullView: this.props.setSelectedPlates ? false : true,
            location: null,
            loading: false,
            error: "",
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.transferred != prevProps.transferred) {
            this.props.refetchQuery()
        }
    }

    render() {
        return (<>
            {this.props.loading || this.state.loading ? <Progress animated color="info" value="100" /> : ""}
            <div className="bos-manage-plates-table-wrapper">
                <Table bordered responsive className="bos-manage-plates-table">
                    <thead>
                        <tr>
                            <th key={-1} >#</th>
                            {this.state.fullView ? fullTableHeaders.map(tableHeader => <th key={tableHeader.id}>{tableHeader.name}</th>) :
                                tableHeaders.map(tableHeader => <th key={tableHeader.id}>{tableHeader.name}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.allPlates && this.props.allPlates.edges && this.props.allPlates.edges.length > 0 && this.props.allPlates.edges.map((plate, i) => <>
                            {this.state.openModal && this.state.openModal === "editPlateModalDMV" + plate.node.pk && <UpdatePlateInfo open={this.state.openModal === "editPlateModalDMV" + plate.node.pk} target={"editPlateModalDMV" + plate.node.pk} handleClose={() => this.toggleModal("editPlateModalDMV" + plate.node.pk)} title={"Edit Plate DMV #"} name={"dmvPlate"} type={"text"} value={plate.node.dmvPlate} plate={plate.node} refetchQuery={this.props.refetchQuery} />}
                            {this.state.openModal && this.state.openModal === "editPlateModalOwner" + plate.node.pk && <UpdatePlateInfo open={this.state.openModal === "editPlateModalOwner" + plate.node.pk} target={"editPlateModalOwner" + plate.node.pk} handleClose={() => this.toggleModal("editPlateModalOwner" + plate.node.pk)} title={"Edit Plate Owner"} name={"owner"} value={plate.node.owner} plate={plate.node} allOwners={this.props.allOwners} refetchQuery={this.props.refetchQuery} />}
                            {this.state.openModal && this.state.openModal === "editPlateModalFHV" + plate.node.pk && <UpdatePlateInfo open={this.state.openModal === "editPlateModalFHV" + plate.node.pk} target={"editPlateModalFHV" + plate.node.pk} handleClose={() => this.toggleModal("editPlateModalFHV" + plate.node.pk)} title={"Edit Plate FHV #"} name={"fhv"} type={"number"} value={plate.node.fhv} plate={plate.node} refetchQuery={this.props.refetchQuery} />}
                            {this.state.openModal && this.state.openModal === "editPlateModalFHVEXP" + plate.node.pk && <UpdatePlateInfo open={this.state.openModal === "editPlateModalFHVEXP" + plate.node.pk} target={"editPlateModalFHVEXP" + plate.node.pk} handleClose={() => this.toggleModal("editPlateModalFHVEXP" + plate.node.pk)} title={"Edit FHV Expiration Date #"} name={"fhvExpiration"} type={"date"} value={plate.node.fhvExpiration} plate={plate.node} refetchQuery={this.props.refetchQuery} />}
                            {this.state.openModal && this.state.openModal === "editIsActiveModal" + plate.node.pk && <UpdatePlateInfo open={this.state.openModal === "editIsActiveModal" + plate.node.pk} target={"editIsActiveModal" + plate.node.pk} handleClose={() => this.toggleModal("editIsActiveModal" + plate.node.pk)} title={"Edit Plate Status"} name={"isActive"} type={"boolean"} value={plate.node.isActive} plate={plate.node} refetchQuery={this.props.refetchQuery} />}

                            <tr key={i} className={"bos-manage-plates-item" + (this.props.setSelectedPlates && this.props.selectedPlates.includes(plate.node) ? " selected" : "")}>
                                <td>{this.state.fullView ? <i className="fa fa-square-o" aria-hidden="true" /> : this.props.selectedPlates.includes(plate.node) ?
                                    <i className="fa fa-check-square-o" aria-hidden="true" onClick={() => this.props.setSelectedPlates(this.props.selectedPlates.filter(item => item != plate.node))} /> :
                                    <i className="fa fa-square-o" aria-hidden="true" onClick={() => this.props.setSelectedPlates([...this.props.selectedPlates, plate.node])} />}
                                </td>
                                <td>{plate.node && plate.node.dmvPlate ? plate.node.dmvPlate : <><i className="fa fa-pencil" aria-hidden="true" id={"editPlateModalDMV" + plate.node.pk} onClick={() => this.toggleModal("editPlateModalDMV" + plate.node.pk)} /> --</>}</td>
                                <td>{plate.node && plate.node.fhv ? plate.node.fhv : <><i className="fa fa-pencil" aria-hidden="true" id={"editPlateModalFHV" + plate.node.pk} onClick={() => this.toggleModal("editPlateModalFHV" + plate.node.pk)} /> --</>}</td>
                                <td><i className="fa fa-pencil" aria-hidden="true" id={"editPlateModalFHVEXP" + plate.node.pk} onClick={() => this.toggleModal("editPlateModalFHVEXP" + plate.node.pk)} />&nbsp;&nbsp;{plate.node && plate.node.fhvExpiration ? plate.node.fhvExpiration : "--"}</td>
                                <td>{plate.node && plate.node.owner ? <><i className="fa fa-pencil" aria-hidden="true" id={"editPlateModalOwner" + plate.node.pk} onClick={() => this.toggleModal("editPlateModalOwner" + plate.node.pk)} /> &nbsp;&nbsp;{plate.node.owner.name}</> : <><i className="fa fa-pencil" aria-hidden="true" id={"editPlateModalOwner" + plate.node.pk} onClick={() => this.toggleModal("editPlateModalOwner" + plate.node.pk)} /> --</>}</td>
                                {this.state.fullView && <td>{plate.node.isActive ? "Active" : "InActive"}&nbsp;&nbsp;{plate.node && !plate.node.car && <i className="fa fa-pencil" aria-hidden="true" id={"editIsActiveModal" + plate.node.pk} onClick={() => this.toggleModal("editIsActiveModal" + plate.node.pk)} />}</td>}
                                {plate.node && plate.node.car ? <>
                                    <td>{plate.node && plate.node.car ? plate.node.car.vin : "--"}</td>
                                    <td>{plate.node && plate.node.car ? renderEnumToInt(plate.node.car.stage) : "--"}</td>
                                    <td>{plate.node && plate.node.car && plate.node.car.branch && plate.node.car.branch.id ? plate.node.car.branch.name : "--"}</td>
                                </> : <td colSpan={3}>No car found, with this plate!</td>
                                }
                            </tr>
                        </>)}
                    </tbody>
                </Table>
            </div>
            {this.props.loading && <p>Loading All Available Plates Data...</p>}
            {!this.props.loading && this.props.allPlates && this.props.allPlates.pageInfo && this.props.allPlates.pageInfo.hasNextPage && <Button onClick={this.props.loadMoreEntries} color="add-new-car link linkHoverEffect" >Load More <i className={"fa fa-caret-down"} /></Button>}
        </>)
    }
}

export default compose(
    withApollo,
    graphql(AllPlatesQuery,
        {
            options: ({ searchTerm, isAvailable, carStages, isActive }) => ({
                variables: { searchTerm, carStages: carStages && carStages.length > 0 ? carStages : null, isAvailable, isActive, first: 15 },
                notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only'
            }),
            props({ data: { loading, allPlates, fetchMore, variables } }) {
                return {
                    loading,
                    allPlates,
                    variables,
                    refetchQuery: () => {
                        //No apollo function that refetches in place with pagination considered so this function instead
                        let currentLength = allPlates && allPlates.edges ? allPlates.edges.length : 15
                        return fetchMore({
                            query: AllPlatesQuery,
                            variables: {
                                ...variables,
                                first: currentLength,
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                const newEdges = fetchMoreResult.allPlates.edges
                                const pageInfo = fetchMoreResult.allPlates.pageInfo
                                return {
                                    allPlates: {
                                        edges: [...newEdges],
                                        pageInfo,
                                        searchTerm: previousResult.searchTerm,
                                        __typename: previousResult.allPlates.__typename
                                    },
                                }
                            },
                        })
                    },
                    loadMoreEntries: () => {
                        return fetchMore({
                            query: AllPlatesQuery,
                            variables: {
                                cursor: allPlates.pageInfo.endCursor,
                                ...variables
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                const newEdges = fetchMoreResult.allPlates.edges
                                const pageInfo = fetchMoreResult.allPlates.pageInfo

                                return {
                                    allPlates: {
                                        edges: [...previousResult.allPlates.edges, ...newEdges],
                                        pageInfo,
                                        searchTerm: previousResult.searchTerm,
                                        issueCategory: previousResult.issueCategory,
                                        status: previousResult.status,
                                        currentAgreementIsnull: previousResult.currentAgreementIsnull,
                                        dateAdded: previousResult.dateAdded,
                                        __typename: previousResult.allPlates.__typename
                                    },
                                }
                            },
                        })
                    },
                }
            },
        })
)(PlatesList)