import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Button, Table, Row, Col, CardBody, Card } from 'reactstrap'
import Progress from 'reactstrap/lib/Progress'
import CarColumn from './CarColumn'
import NewCarRow from './NewCarRow'
import ManagePlatesModal from '../ManagePlatesModal/ManagePlatesModal'
import UpdatePolicyModal from '../UpdatePolicyModal'
import gql from 'graphql-tag'
import moment from 'moment'
import { CarStages } from '../../Functions/querys'
import renderEnumToInt from '../../Functions/renderEnumToInt'
import './CarsTable.css'
import { Badge } from '@material-ui/core'
import toTitleCase from '../../Functions/toTitleCase'

const AllBaseQuery = gql`query AllBaseQuery{
    allBase{
        edges{
            node{
                id
                pk
                name
            }
        }
    }   
}`

const AllTitleOwnersQuery = gql`query AllTitleOwnersQuery{
    allTitleOwners:allCarDealEntities(isActive:true, entityType: "Title Owner", orderBy:["name"]){
        edges {
            node {
                id
                pk
                name
                isActive
            }
        }
    }
}`

const AllOperatingLeaseQuery = gql`query AllOperatingLeaseQuery{
    allOperatingLease:allCarDealEntities(isActive:true, entityType: "Operating Lease", orderBy:["name"]){
        edges {
            node {
                id
                pk
                name
                isActive
            }
        }
    }
}`

const AllDealersQuery = gql`query AllDealersQuery{
    allDealers:allCarDealEntities(isActive:true, entityType: "Dealer", orderBy:["name"]){
        edges {
            node {
                id
                pk
                name
                isActive
            }
        }
    }
}`

const AllBranchesQuery = gql`query AllBranchesQuery{
    allBranches{
        edges {
            node {
                id
                pk
                name
            }
        }
    }
}`

const AllCarModelsQuery = gql`query AllCarModelsQuery{
    allCarModels{
        edges {
            node {
                id
                pk
                name
                make
                series
                groupType{
                    id
                    name
                }
            }
        }
    }
}`

const CarsQuery = gql`query CarsQuery($cursor: String, $first: Int!, $searchTerm: String, $orderBy: [String], $locationId: [ID], $modelIds: [ID], $stage: [Int], $isReady: Boolean, $isFleetManagement: Boolean, $isLocationNull: Boolean, $hasDeliveryDate: Boolean) {
    cars: allCars(first:$first, orderBy:$orderBy, after: $cursor, searchTerm:$searchTerm, locationId:$locationId, modelIds:$modelIds, stage: $stage, isReady:$isReady, isFleetManagement:$isFleetManagement, isLocationNull:$isLocationNull, hasDeliveryDate: $hasDeliveryDate){
      edges{
        cursor
        node{
            id
            pk
            vin
            hasOpenIssues
            year
            carModel{
                id
                make
                name
                series
                groupType{
                    id
                    name
                }
            }
            branch{
                id
                name
                customId
            }
            location
            color
            notes
            stage
            carBuying:cardealSet(dealType:"Buying"){
                edges{
                    node{
                        id
                        buyer{
                            id
                            name
                            entityType
                        }
                        seller{
                            id
                            name
                            entityType
                        }
                        carPrice
                        eventDate
                        metadata
                    }
                }
            }
            inspectionDate
            customName
            carpolicySet{
                edges{
                    node{
                        id
                        status
                        insurancePolicy{
                            id
                            startDate
                            endDate
                            status
                            policyNumber
                            broker{
                                id
                                name
                            }
                        }
                    }
                }
            }
            plate{
                id
                dmvPlate
                fhv
                fhvExpiration
                owner{
                    id
                    name
                }
            }
            baseId{
                id
                name
            }
            currentAgreement{
                id
            }
        }
    }
    pageInfo{
        endCursor
        hasNextPage
    }
}}`

const tableHeaders = [
    { id: "pk", name: "Car ID", sortable: false },
    { id: "vin", name: "VIN", sortable: false },
    { id: "carModel", name: "Car Model", sortable: false },
    { id: "group", name: "Group", sortable: false },
    { id: "color", name: "Color", sortable: false },
    { id: "stage", name: "Stage", sortable: false },
    { id: "branch", name: "Branch", sortable: false },
    { id: "custom_name", name: "Custom Name", sortable: false },
    { id: "titleOwner", name: "Title Owner", sortable: false },
    { id: "dealer", name: "Dealer", sortable: false },
    { id: "purchase_date", name: "Purchase Date", sortable: false },
    { id: "purchase_price", name: "Purchase Price", sortable: false },
    { id: "operating_lease", name: "Finance Partner", sortable: false },
    { id: "inspection_date", name: "Inspection Date", sortable: false },
    { id: "base", name: "Base", sortable: false },
    { id: "car_policy", name: "Car Policy", sortable: false },
    { id: "dmv_plates", name: "Plate #", sortable: false },
    { id: "fhv", name: "FHV #", sortable: false },
    { id: "fhv_expiration", name: "FHV Expiration", sortable: false },
    { id: "owner", name: "Owner", sortable: false },
    { id: "notes", name: "Notes", sortable: false },
]

const CAR_DATA_TEMPLATE = {
    vin: null,
    color: null,
    purchaseDate: null,
    purchasePrice: null,
    baseId: null,
    dealer: null,
    branch: null,
    operatingLease: null,
    notes: null,
    isCreated: false,
}

const REQUIRED_FIELDS = ["vin", "dealer", "titleOwner", "group"]

class CarsTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            location: null,
            loading: false,
            error: "",
            editField: "",
            tableRows: null,
            newCarsData: {},
            uploadAll: false,
        }
    }

    isValid = (name, value) => {
        if (REQUIRED_FIELDS.includes(name) && (!value || value == ''))
            return false
        else if (!value || value == '')
            return true
        else {
            if (["carModel", "owner", "baseId", "operatingLease", "dealer", "branch"].includes(name)) {
                let options = []
                if (name === "carModel") options = this.props.allCarModels && this.props.allCarModels.edges && this.props.allCarModels.edges.length > 0 ? this.props.allCarModels.edges : []
                if (name === "owner") options = this.props.allOwners && this.props.allOwners.edges && this.props.allOwners.edges.length > 0 ? this.props.allOwners.edges : []
                if (name === "baseId") options = this.props.allBase && this.props.allBase.edges && this.props.allBase.edges.length > 0 ? this.props.allBase.edges : []
                if (name === "operatingLease") options = this.props.allOperatingLease && this.props.allOperatingLease.edges && this.props.allOperatingLease.edges.length > 0 ? this.props.allOperatingLease.edges : []
                if (name === "dealer") options = this.props.allDealers && this.props.allDealers.edges && this.props.allDealers.edges.length > 0 ? this.props.allDealers.edges : []
                if (name === "titleOwner") options = this.props.allTitleOwners && this.props.allTitleOwners.edges && this.props.allTitleOwners.edges.length > 0 ? this.props.allTitleOwners.edges : []
                if (name === "branch") options = this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 ? this.props.allBranches.edges : []
                return (options && options.filter(item => (item.node && item.node.name == value) || item == value).length > 0)
            }
            else if (name == "vin") return /^[a-zA-Z0-9]{17}$/.test(String(value))
            return true
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) this.setState({ openModal: modalName })
        else this.setState({ openModal: "" })
    }

    addNewCar = () => {
        this.setState({ newCarsData: { ...this.state.newCarsData, [+ new Date()]: CAR_DATA_TEMPLATE } })
    }

    deleteNewCar = (key) => {
        let tmp = this.state.newCarsData
        delete tmp[key]
        this.setState({ newCarsData: tmp })
    }

    updateCarInfo = (name, value, key) => {
        this.setState({ newCarsData: { ...this.state.newCarsData, [key]: { ...this.state.newCarsData[key], [name]: value } } })
        if (name === "isCreated" && Object.keys(this.state.newCarsData).filter(key => this.state.newCarsData[key].isCreated === true).length === Object.keys(this.state.newCarsData).length) {
            this.setState({ newCarsData: {}, uploadAll: false })
            this.props.refetchQuery()
        }
    }

    getClipBoardData = (key) => {
        try {
            let newCarsData = this.state.newCarsData
            let counter = 0
            navigator.clipboard.readText().then(clipText => {
                this.setState({ error: "" })
                let allKeys = Object.keys(newCarsData)
                let keyIndex = allKeys.indexOf(key)
                newCarsData = {}
                this.setState({ uploadAll: false })
                let clipRows
                if (clipText.includes('\n')) {
                    clipRows = clipText.split('\n')
                } else {
                    clipRows = clipText.split(String.fromCharCode(13))
                }
                clipRows.forEach((row, i) => {
                    if (row.toString().trim()) {
                        if (Object.keys(newCarsData).length <= i) {
                            key = (+ new Date()) + counter++
                            newCarsData[key] = CAR_DATA_TEMPLATE
                        } else {
                            if (i > 0) key = allKeys[keyIndex + i]
                        }
                        let cells = row.split(String.fromCharCode(9))
                        if (cells && cells.length <= 8) {
                            cells.forEach((cell, j) => {
                                if (j == 0) newCarsData[key]["vin"] = cell.trim()
                                else if (j == 1) newCarsData[key]["color"] = cell.trim()
                                else if (j == 2) newCarsData[key]["branch"] = cell.trim()
                                else if (j == 3) newCarsData[key]["titleOwner"] = cell.trim()
                                else if (j == 4) newCarsData[key]["dealer"] = cell.trim()
                                else if (j == 5) newCarsData[key]["purchaseDate"] = cell.trim() ? moment(cell.trim()).format(moment.HTML5_FMT.DATE) : null
                                else if (j == 6) newCarsData[key]["purchasePrice"] = cell.trim().length > 0 ? parseFloat(cell.replace("$", "").replace(",", "").trim()) : 0
                                else if (j == 7) newCarsData[key]["operatingLease"] = cell.trim()
                                else if (j == 8) newCarsData[key]["baseId"] = cell.trim()
                            })
                        } else {
                            this.setState({ error: "Invalid data format, please provide valid format to paste. Data should be in respective sequence VIN *, Color *, Purchase Date, Purchase Price, Base, Dealer, Operating Lease" })
                            return
                        }
                    }
                })
                this.setState({ newCarsData })
            })
        } catch (err) {
            this.setState({ error: "Error while pasting data from clipboard, " + err })
        }
    }

    renderCarPolicyDetails = (carPolicies) => {
        return <Table style={{ backgroundColor: "white", color: "black" }}>
            <tr>
                <th style={{ fontSize: "8px" }}>Date Added</th>
                <th style={{ fontSize: "8px" }}>Policy #</th>
                <th style={{ fontSize: "8px" }}>Broker</th>
                <th style={{ fontSize: "8px" }}>Start Date</th>
                <th style={{ fontSize: "8px" }}>End Date</th>
                <th style={{ fontSize: "8px" }}>Status</th>
            </tr>
            {carPolicies.map(policy =>
                <tr>
                    <td style={{ fontSize: "10px" }}>{moment(policy.node.dateAdded).tz("America/New_York").format(moment.HTML5_FMT.DATETIME_LOCAL)}</td>
                    <td style={{ fontSize: "10px" }}>{policy.node.insurancePolicy.policyNumber}</td>
                    <td style={{ fontSize: "10px" }}>{policy.node.insurancePolicy.broker ? policy.node.insurancePolicy.broker.name : "--"}</td>
                    <td style={{ fontSize: "10px" }}>{policy.node.insurancePolicy.startDate}</td>
                    <td style={{ fontSize: "10px" }}>{policy.node.insurancePolicy.endDate}</td>
                    <td style={{ fontSize: "10px" }}>{policy.node.status === 'A_1' ? 'Active' : 'Inactive'}</td>
                </tr>
            )} </Table>
    }

    getCarDealData(buyingDeal = null, name = "carPrice") {
        if (!buyingDeal || !buyingDeal.edges || !buyingDeal.edges.length > 0)
            return
        buyingDeal = buyingDeal.edges[0].node
        if (name === "buyer" || name === "seller") {
            return buyingDeal[name] ? buyingDeal[name].name : null
        } else if (name == "titleOwner") {
            let metaObject = buyingDeal.metadata ? JSON.parse(buyingDeal.metadata.replace(/'/g, '"')) : null
            if (metaObject && metaObject["titleOwner"]) {
                return this.props.allTitleOwners.edges && this.props.allTitleOwners.edges.find(item => item.node.pk == metaObject["titleOwner"]).node.name || ""
            }
            return ""
        } else {
            return buyingDeal[name]
        }
    }
    render() {
        return (<>
            {(this.props.loading || this.state.loading) && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
            <Card className={"cars-management-container"}>
                <CardBody>
                    {this.state.openModal && this.state.openModal === "managePlatesModal" && <ManagePlatesModal handleClose={() => this.toggleModal("managePlatesModal")} open={this.state.openModal === "managePlatesModal"} refetchCars={this.props.refetchQuery} />}
                    {this.state.openModal && this.state.openModal === "updatePolicyModal" && <UpdatePolicyModal handleClose={() => this.toggleModal("updatePolicyModal")} open={this.state.openModal === "updatePolicyModal"} refetchCars={this.props.refetchQuery} />}

                    <Row className="mb-3">
                        {!(this.props.loading || this.state.loading) && <Col xs={{ size: 6, offset: 6 }}>
                            <Button onClick={() => Object.keys(this.state.newCarsData).length == 0 ? this.addNewCar() : this.getClipBoardData(Object.keys(this.state.newCarsData)[0])} color="add-new-car link linkHoverEffect" className="float-right">{Object.keys(this.state.newCarsData).length == 0 ? <><i className={"fa fa-plus"} /> Add Car/s</> : <><i className={"fa fa-paste"} /> Paste from Clipboard</>}</Button>
                            {Object.keys(this.state.newCarsData).filter(key => this.state.newCarsData[key] && !this.state.newCarsData[key].isCreated).length > 1 && <Button onClick={() => this.setState({ uploadAll: true })} color="add-new-car link linkHoverEffect" className="float-right"><i className="fa fa-cloud-upload" aria-hidden="true" /> Bulk Create New Cars</Button>}
                            <Button onClick={() => this.toggleModal("managePlatesModal")} color="add-new-car link linkHoverEffect" className="float-right"><i className={"fa fa-exchange"}></i> Manage Plates</Button>
                            <Button onClick={() => this.toggleModal("updatePolicyModal")} color="add-new-car link linkHoverEffect" className="float-right"><i className={"fa fa-paper-plane-o"}></i> Update Policy</Button>
                        </Col>}
                    </Row>
                    {this.state.error && <Badge color="danger">{this.state.error}</Badge>}
                    <Table bordered responsive className="car-management-table" style={{ maxHeight: "55vh", overflow: "auto", display: "block" }}>
                        <thead>
                            <tr>
                                <th key={-1} >#</th>
                                {tableHeaders.map(tableHeader => tableHeader.sortable ?
                                    <th key={tableHeader.id} onClick={() => this.props.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{tableHeader.name}</th> :
                                    <th key={tableHeader.id}>{tableHeader.name}</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(this.state.newCarsData).length > 0 && Object.keys(this.state.newCarsData).map((key) => this.state.newCarsData[key] &&
                                <NewCarRow key={key}
                                    uploadCar={this.state.uploadAll}
                                    carData={this.state.newCarsData[key]} id={key}
                                    updateInfo={(name, value) => this.updateCarInfo(name, value, key)}
                                    deleteNewCar={() => this.deleteNewCar(key)}
                                    handleClipBoardData={() => this.getClipBoardData(key)}
                                    isValid={this.isValid}
                                    allCarModels={this.props.allCarModels}
                                    allOwners={this.props.allOwners}
                                    allBase={this.props.allBase}
                                    allTitleOwners={this.props.allTitleOwners}
                                    allDealers={this.props.allDealers}
                                    allBranches={this.props.allBranches}
                                    allOperatingLease={this.props.allOperatingLease}
                                />
                            )}
                            {this.props.cars && this.props.cars.edges && this.props.cars.edges.length > 0 ? this.props.cars.edges.map((car, i) =>
                                <tr key={i}>
                                    <CarColumn id={car.node.id} pk={car.node.pk} name="row" value={i + 1} isValid={this.isValid} />
                                    <CarColumn id={car.node.id} pk={car.node.pk} name="pk" value={car.node.pk} />
                                    <CarColumn id={car.node.id} pk={car.node.pk} name="vin" placeholder="Car VIN" value={car.node.vin} isValid={this.isValid} />
                                    <CarColumn limitText id={car.node.id} pk={car.node.pk} name="carModel" value={car.node.year + " | " + toTitleCase(car.node.carModel.make) + " " + toTitleCase(car.node.carModel.name) + (car.node.carModel.series ? " | " + car.node.carModel.series : "")} />
                                    {((props = this.props) => {
                                        if (props.allCarModels && props.allCarModels.edges && props.allCarModels.edges.length > 0) {
                                            let groups = props.allCarModels.edges.filter(model => car.node.carModel && model.node.name == car.node.carModel.name && model.node.series == car.node.carModel.series && model.node.make == car.node.carModel.make).map(model => model.node.groupType)
                                            if (groups) groups = groups.filter(group => group ? group : false)
                                            return <CarColumn editable={groups.length > 1} id={car.node.id} pk={car.node.pk} name="group" value={car.node.carModel.groupType ? car.node.carModel.groupType.name : null} options={groups} isValid={this.isValid} />
                                        }
                                        return <CarColumn id={car.node.id} pk={car.node.pk} name="group" />
                                    })()}
                                    <CarColumn editable id={car.node.id} pk={car.node.pk} name="color" placeholder="Car Color" value={car.node.color} refetchCars={this.props.refetchQuery} isValid={this.isValid} />
                                    <CarColumn editable={!car.node.branch || (car.node.branch.customId == "BROOKLYN_NY" && car.node.stage && ![7, 8, 12, 13, 10, 14, 15].includes(renderEnumToInt(car.node.stage))) || (car.node.branch.customId != "BROOKLYN_NY" && car.node.stage && ![10].includes(renderEnumToInt(car.node.stage)))} id={car.node.id} pk={car.node.pk} name="stage" placeholder="Car Stage" value={car.node.stage} refetchCars={this.props.refetchQuery} options={this.props.carStages && this.props.carStages.states.length > 0 ? car.node.branch.customId != "BROOKLYN_NY" ? this.props.carStages.states.filter(stage => !["A_1", "A_2", "A_3", "A_4", "A_5", "A_15"].includes(stage.name)) : this.props.carStages.states.filter(stage => ["A_9", "A_11", "A_6"].includes(stage.name)) : null} isValid={this.isValid} />
                                    <CarColumn editable={!(renderEnumToInt(car.node.stage) === 10 && car.node.branch && car.node.branch.customId === "BROOKLYN_NY")} id={car.node.id} pk={car.node.pk} name="branch" placeholder="Car branch" value={car.node.branch && car.node.branch.name ? car.node.branch.name : null} options={this.props.allBranches && this.props.allBranches.edges.length > 0 ? this.props.allBranches.edges : null} refetchCars={this.props.refetchQuery} isValid={this.isValid} />
                                    <CarColumn editable id={car.node.id} pk={car.node.pk} name="customName" placeholder="Car Custom Name" value={car.node.customName} refetchCars={this.props.refetchQuery} isValid={this.isValid} />
                                    <CarColumn editable id={car.node.id} pk={car.node.pk} className="car-info-deal-column" name="titleOwner" placeholder="Title Owner" value={this.getCarDealData(car.node.carBuying, "titleOwner")} options={this.props.allTitleOwners && this.props.allTitleOwners.edges.length > 0 ? this.props.allTitleOwners.edges : null} refetchCars={this.props.refetchQuery} isValid={this.isValid} />
                                    <CarColumn editable id={car.node.id} pk={car.node.pk} className="car-info-deal-column" name="dealer" placeholder="Car Dealer" value={this.getCarDealData(car.node.carBuying, "seller")} options={this.props.allDealers && this.props.allDealers.edges.length > 0 ? this.props.allDealers.edges : null} refetchCars={this.props.refetchQuery} isValid={this.isValid} />
                                    <CarColumn editable id={car.node.id} pk={car.node.pk} className="car-info-deal-column" type="date" max={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} name="purchaseDate" placeholder="Purchase Date" value={this.getCarDealData(car.node.carBuying, "eventDate")} refetchCars={this.props.refetchQuery} isValid={this.isValid} />
                                    <CarColumn editable id={car.node.id} pk={car.node.pk} className="car-info-deal-column" type="number" min={0} name="purchasePrice" placeholder="Purchase Price" value={this.getCarDealData(car.node.carBuying, "carPrice")} refetchCars={this.props.refetchQuery} additionalText="$" isValid={this.isValid} />
                                    <CarColumn editable id={car.node.id} pk={car.node.pk} className="car-info-deal-column" name="operatingLease" placeholder="Operating Lease" value={this.getCarDealData(car.node.carBuying, "buyer")} options={this.props.allOperatingLease && this.props.allOperatingLease.edges.length > 0 ? this.props.allOperatingLease.edges : null} refetchCars={this.props.refetchQuery} isValid={this.isValid} />
                                    <CarColumn editable id={car.node.id} pk={car.node.pk} type="date" min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} name="inspectionDate" placeholder="Inspection Date" value={car.node.inspectionDate} refetchCars={this.props.refetchQuery} isValid={this.isValid} />
                                    <CarColumn editable limitText id={car.node.id} pk={car.node.pk} name="baseId" placeholder="Car Base" value={car.node.baseId && car.node.baseId.name ? car.node.baseId.name : null} refetchCars={this.props.refetchQuery} options={this.props.allBase && this.props.allBase.edges.length > 0 ? this.props.allBase.edges : null} isValid={this.isValid} />
                                    <CarColumn id={car.node.id} pk={car.node.pk} name="carPolicy" tooltip={car.node.carpolicySet && car.node.carpolicySet.edges && car.node.carpolicySet.edges.length > 0 && this.renderCarPolicyDetails(car.node.carpolicySet.edges)} value={car.node.carpolicySet && car.node.carpolicySet.edges && car.node.carpolicySet.edges.length > 0 && car.node.carpolicySet.edges.filter(policy => policy.node.insurancePolicy && policy.node.status === 'A_1').length > 0 ? car.node.carpolicySet.edges.filter(policy => policy.node.insurancePolicy && policy.node.status === 'A_1')[0].node.insurancePolicy.policyNumber : null} />
                                    <CarColumn id={car.node.id} pk={car.node.pk} name="dmvPlate" className="car-info-plate-column" value={car.node.plate ? car.node.plate.dmvPlate : null} />
                                    <CarColumn id={car.node.id} pk={car.node.pk} name="fhv" className="car-info-plate-column" value={car.node.plate ? car.node.plate.fhv : null} />
                                    <CarColumn id={car.node.id} pk={car.node.pk} name="fhvExpiration" className="car-info-plate-column" value={car.node.plate && car.node.plate.fhvExpiration ? moment(car.node.plate.fhvExpiration).tz("America/New_York").format(moment.HTML5_FMT.DATE) : null} />
                                    <CarColumn limitText id={car.node.id} pk={car.node.pk} name="owner" className="car-info-plate-column" value={car.node.plate && car.node.plate.owner && car.node.plate.owner.name ? car.node.plate.owner.name : null} />
                                    <CarColumn editable id={car.node.id} pk={car.node.pk} name="notes" placeholder="Car Notes" value={car.node.notes} refetchCars={this.props.refetchQuery} isValid={this.isValid} />
                                </tr>) :
                                <tr>
                                    <td colSpan={20}><p className="column-text">{this.props.loading ? "Loading Cars Listing..." : "No Cars Found!"}</p></td>
                                </tr>
                            }

                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            {this.props.cars && this.props.loading && <Progress animated color="info" value="100" />}
            {/* {!this.props.loading && this.props.cars && this.props.cars.pageInfo && this.props.cars.pageInfo.hasNextPage && <Button onClick={this.props.loadMoreEntries} color="add-new-car link linkHoverEffect" >Show More <i className={"fa fa-caret-down"}></i></Button>} */}
            <div>
                <Button disabled={this.props.loading} className="float-left mt-2 btn btn-secondary" onClick={() => this.props.refetchQuery()}>Reload &nbsp;&nbsp;<i className="fa fa-refresh"></i></Button>
                {!this.props.loading && this.props.cars && this.props.cars.pageInfo && this.props.cars.pageInfo.hasNextPage && <Button onClick={this.props.loadMoreEntries} className="float-right mt-2 btn btn-primary-showmore-outline">Show More <i className={"fa fa-caret-down"}></i></Button>}
            </div>
        </>)
    }
}

export default compose(
    withApollo,
    graphql(CarStages, { props({ data: { carStages } }) { return { carStages } } }),
    graphql(AllBaseQuery, {
        options: { fetchPolicy: 'cache-first' }, props({ data: { allBase, fetchMore } }) {
            return {
                allBase,
                refetchAllBase: () => {
                    return fetchMore({
                        query: AllBaseQuery,
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allBase.edges
                            const pageInfo = fetchMoreResult.allBase.pageInfo
                            return {
                                allBase: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allBase.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    }),
    graphql(AllBranchesQuery, {
        options: { fetchPolicy: 'cache-and-network' }, props({ data: { allBranches, fetchMore } }) {
            return {
                allBranches,
                refetchAllBranches: () => {
                    return fetchMore({
                        query: AllBranchesQuery,
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allBranches.edges
                            const pageInfo = fetchMoreResult.allBranches.pageInfo
                            return {
                                allBranches: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allBranches.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    }),
    graphql(AllCarModelsQuery, {
        options: { fetchPolicy: 'cache-and-network' }, props({ data: { allCarModels, fetchMore } }) {
            return {
                allCarModels,
                refetchAllCarModels: () => {
                    return fetchMore({
                        query: AllCarModelsQuery,
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allCarModels.edges
                            const pageInfo = fetchMoreResult.allCarModels.pageInfo
                            return {
                                allCarModels: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allCarModels.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    }),
    graphql(AllTitleOwnersQuery, {
        options: { fetchPolicy: 'cache-and-network' }, props({ data: { allTitleOwners, fetchMore } }) {
            return {
                allTitleOwners,
                refetchAllTitleOwners: () => {
                    return fetchMore({
                        query: AllTitleOwnersQuery,
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allTitleOwners.edges
                            const pageInfo = fetchMoreResult.allTitleOwners.pageInfo
                            return {
                                allTitleOwners: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allTitleOwners.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    }),
    graphql(AllOperatingLeaseQuery, {
        options: { fetchPolicy: 'cache-and-network' }, props({ data: { allOperatingLease, fetchMore } }) {
            return {
                allOperatingLease,
                refetchAllOperatingLease: () => {
                    return fetchMore({
                        query: AllOperatingLeaseQuery,
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allOperatingLease.edges
                            const pageInfo = fetchMoreResult.allOperatingLease.pageInfo
                            return {
                                allOperatingLease: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allOperatingLease.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    }),
    graphql(AllDealersQuery, {
        options: { fetchPolicy: 'cache-and-network' }, props({ data: { allDealers, fetchMore } }) {
            return {
                allDealers,
                refetchAllDealers: () => {
                    return fetchMore({
                        query: AllDealersQuery,
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allDealers.edges
                            const pageInfo = fetchMoreResult.allDealers.pageInfo
                            return {
                                allDealers: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allDealers.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    }),
    graphql(CarsQuery,
        {
            options: ({ searchTerm, orderBy, isReady, stage, locationId, isFleetManagement, isLocationNull, modelIds, hasDeliveryDate }) => ({
                variables: { searchTerm, orderBy, isReady, stage, isFleetManagement, locationId, first: 30, isLocationNull, modelIds, hasDeliveryDate },
                notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only'
            }),
            props({ data: { loading, cars, fetchMore, variables } }) {
                return {
                    loading,
                    cars,
                    variables,
                    refetchQuery: () => {
                        //No apollo function that refetches in place with pagination considered so this function instead
                        let currentLength = cars && cars.edges ? cars.edges.length : 30
                        return fetchMore({
                            query: CarsQuery,
                            variables: {
                                ...variables,
                                first: currentLength,
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                const newEdges = fetchMoreResult.cars.edges
                                const pageInfo = fetchMoreResult.cars.pageInfo
                                return {
                                    cars: {
                                        edges: [...newEdges],
                                        pageInfo,
                                        searchTerm: previousResult.searchTerm,
                                        __typename: previousResult.cars.__typename
                                    },
                                }
                            },
                        })
                    },
                    loadMoreEntries: () => {
                        return fetchMore({
                            query: CarsQuery,
                            variables: {
                                cursor: cars.pageInfo.endCursor,
                                ...variables
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                const newEdges = fetchMoreResult.cars.edges
                                const pageInfo = fetchMoreResult.cars.pageInfo
                                return {
                                    cars: {
                                        edges: [...previousResult.cars.edges, ...newEdges],
                                        pageInfo,
                                        searchTerm: previousResult.searchTerm,
                                        orderBy: previousResult.orderBy,
                                        isReady: previousResult.isReady,
                                        stage: previousResult.stage,
                                        locationId: previousResult.locationId,
                                        isFleetManagement: previousResult.isFleetManagement,
                                        isLocationNull: previousResult.isLocationNull,
                                        __typename: previousResult.cars.__typename
                                    },
                                }
                            },
                        })
                    },
                }
            },
        })
)(CarsTable)
