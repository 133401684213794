export default function getFilterValuesFromQueryString(searchParams, configurations) {
    let queryParams = new URLSearchParams(searchParams)
    let urlFilterValues = null
    queryParams.forEach((value, key) => {
        if (urlFilterValues === null) {
            urlFilterValues = {}
        }
        if (value.includes(",")) {
            urlFilterValues[key] = value.split(',').map(item => (+item).toString() === item ? +item : item)
        } else {
            if (configurations.find(setting => setting.name == key) && configurations.find(setting => setting.name == key).type === "select" && configurations.find(setting => setting.name == key).isMulti) {
                urlFilterValues[key] = [(+value).toString() === value ? +value : value]
            } else {
                urlFilterValues[key] = value === "true" || value === "false" ? value.toLowerCase() === "true" : (+value).toString() === value ? +value : value
            }
        }
    })

    return urlFilterValues
}