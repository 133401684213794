import React, { Component } from 'react'
import { compose, withApollo } from 'react-apollo'
import {
  Popover,
  PopoverBody,
  PopoverHeader,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Badge,
} from 'reactstrap'
import { UpdateEquipmentMutation } from '../Mutations'



class UpdateEquipment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customId: null,
      error: null,
      loading: null,
      description: null,
      customId: null,
      input: {},
      value: this.props.value
    }
  }
  updateInput = (...args) => {
    let input = { ...this.state.input }
    input[args[0]] = args[1]
    this.setState({ input: input })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    try {
      let input = { ...this.state.input }
      input["equipmentId"] = this.props.id

      this.setState({ loading: true })
      this.props.client
        .mutate({
          mutation: UpdateEquipmentMutation,
          variables: { input },
        })
        .then((result) => {
          if (result.data && result.data.updateEquipment && result.data.updateEquipment.ok) {
            this.props.refetchEquipments()
            this.props.handleClose()
          } else if (result.data && result.data.updateEquipment && !result.data.updateEquipment.ok && result.data.updateEquipment.errors && result.data.updateEquipment.errors[0]) {
            this.setState({ loading: false, error: result.data.updateEquipment.errors[0].messages.toString() })
          } else {
            this.setState({ loading: false, error: "Something went wrong, please contact admin for support!" })
          }
        })
        .catch((err) => {
          this.setState({ loading: false, error: err.message })
        })
    } catch (err) {
      this.setState({ loading: false, error: err })
    }
  };

  render() {
    return (
      <div>
        <Popover isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose} placement="right">
          <PopoverHeader onClick={this.props.handleClose}>Update Equipment</PopoverHeader>
          <PopoverBody>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label for="carEquipment" className="float-left"><b>{this.props.placeholder}</b></Label>
                <Input type="text" name={this.props.name} id="carEquipment" defaultValue={this.props.value} onChange={(e) => this.updateInput(e.target.name, e.target.value)} placeholder={this.props.placeholder} />
              </FormGroup>
              {this.state.loading ? "Loading..." : <Button color="success" type="submit">Update</Button>}
            </Form>
            {this.state.error && (
              <Badge pill="true" style={{ whiteSpace: "pre-wrap" }} color="danger">
                {this.state.error}
              </Badge>
            )}
          </PopoverBody>
        </Popover>
      </div>
    )
  }
}

export default compose(
  withApollo,
)(UpdateEquipment)