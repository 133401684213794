import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Row, Col, Alert, Progress, Label, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { AllBrokers } from '../ReservationsNew/ReservationQueries'
import "../CustomerLeads/LeadPreviewModal.css"
import moment from 'moment'

const updateInsuranceStatusMutation = gql`
mutation updateInsuranceStatusMutation($input: UpdateInsuranceStatusMutationInput!){
    updateInsuranceStatus(input:$input){
    ok
    errors{
        messages
    }
}
} `
const createDriverInsuranceStatusMutation = gql`
mutation createDriverInsuranceStatusMutation($input: CreateDriverInsuranceStatusMutationInput!){
    createDriverInsuranceStatus(input:$input){
    ok
    errors{
        messages
    }
}
} `
const AllInsuranceReasonCategory = gql`
    query AllInsuranceReasonCategory{
        allInsuranceReasonCategory(isActive:true){
            edges{
                node{
                    id
                    title
                    dateAdded
                    dateModified
                    insurancereasonSet(isActive:true){
                        edges{
                            node{
                                id
                                title
                                description
                                isActive
                            }
                        }
                    }
                }
            }
      }
    }
`

class DenyInsurance extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            insuranceDenied: false,
            openModal: "",
            insuranceReasonId: null,
            dateToReconsider: null
        }
    }
    handleClose = (e) => {
        this.props.handleClose()
    };
    denyInsurance = () => {
        if (this.props.insurance && this.props.insurance.id) {
            try {
                this.setState({ loading: true })
                let input = {
                    insuranceStatusId: this.props.insurance.id,
                    status: 5,
                    insuranceReasonId: this.state.insuranceReasonId,
                    dateToReconsider: this.state.dateToReconsider
                }
                this.props.client.mutate({
                    mutation: updateInsuranceStatusMutation,
                    variables: { input },
                }).then((result) => {
                    if (result.data && result.data.updateInsuranceStatus && result.data.updateInsuranceStatus.ok) {
                        this.setState({ insuranceDenied: true, error: null, loading: false })
                        this.props.refetchDriverQuery && this.props.refetchDriverQuery()
                        this.props.refetchListingQuery && this.props.refetchListingQuery()
                        this.props.handleClose()
                    } else {
                        this.setState({ loading: false, insuranceDenied: false, error: result.data.updateInsuranceStatus.errors[0].messages[0] })
                    }
                }).catch((error) => {
                    let errorMessage = "An error has occured"
                    this.setState({ error: errorMessage, loading: false, insuranceDenied: false, })
                })
            } catch (err) {
                let errorMessage = "An error has occured"
                this.setState({ error: errorMessage, loading: false, insuranceDenied: false, })
            }
        }
    };
    createInsurance = () => {
        try {
            this.setState({ loading: true })
            let input = {
                driverId: this.props.driverId,
                insuranceReasonId: this.state.insuranceReasonId,
                surcharge: this.state.surcharge,
                status: 5,
                dateToReconsider: this.state.dateToReconsider
            }
            this.props.client.mutate({
                mutation: createDriverInsuranceStatusMutation,
                variables: { input },
            }).then((result) => {
                if (result.data && result.data.createDriverInsuranceStatus && result.data.createDriverInsuranceStatus.ok) {
                    this.setState({ insuranceDenied: true, error: null, loading: false })
                    this.props.refetchDriverQuery && this.props.refetchDriverQuery()
                    this.props.refetchListingQuery && this.props.refetchListingQuery()
                    this.props.handleClose()
                } else {
                    this.setState({ loading: false, insuranceDenied: false, error: result.data.createDriverInsuranceStatus.errors[0].messages[0] })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured: " + error
                this.setState({ error: errorMessage, loading: false, insuranceDenied: false, })
            })
        } catch (err) {
            let errorMessage = "An error has occured: " + err
            this.setState({ error: errorMessage, loading: false, insuranceDenied: false, })
        }
    };
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        // To change string inputs to boolean because only strings can be passed to html options
        if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        this.setState({ [name]: value })
    };
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        return (
            <div>
                <Modal className="approveInsurance" isOpen={this.props.open}>
                    {this.props.driverId &&
                        <>
                            <ModalHeader>Deny Insurance</ModalHeader>
                            <ModalBody>
                                {this.state.error && !this.state.insuranceDenied && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                                {this.state.insuranceDenied && !this.state.error && <Row><Col xs={12}><Alert color="success">Insurance denied successfully!</Alert></Col></Row>}
                                {!this.state.insuranceDenied &&
                                    <Row>
                                        <Col xs={12}>
                                            <FormGroup>
                                                <Label for="insuranceReasonId">Why are you denying this insurance?</Label>
                                                <Input type="select" name="insuranceReasonId" id="insuranceReasonId" onChange={this.updateInput} placeholder="Select Driver Insurance Reason">
                                                    <option key={-1} value={null}>Choose Reason</option>
                                                    {this.props.allInsuranceReasonCategory && this.props.allInsuranceReasonCategory.edges && this.props.allInsuranceReasonCategory.edges.filter(item => item.node.insurancereasonSet && item.node.insurancereasonSet.edges && item.node.insurancereasonSet.edges.length > 0).map((reasonCategory, i) =>
                                                        <optgroup key={i} label={reasonCategory.node.title}>
                                                            {reasonCategory.node.insurancereasonSet && reasonCategory.node.insurancereasonSet.edges && reasonCategory.node.insurancereasonSet.edges.map((reason, i) =>
                                                                <option key={i} value={reason.node.id} title={reason.node.description}>{reason.node.title}</option>
                                                            )}
                                                        </optgroup>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <FormGroup>
                                                <Label for="dateToReconsider">Will the driver be good in the future? If so, when will this be?</Label>
                                                <Input type="date" name="dateToReconsider" id="dateToReconsider" onChange={this.updateInput} min={moment().format('YYYY-MM-DD')} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }
                                {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                            </ModalBody>
                        </>}
                    <ModalFooter>
                        {!this.state.loading &&
                            <Row>
                                <Col xs={12}>
                                    <Button className="lead-preview-modal-secondary-btn" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    <Button className="lead-preview-modal-primary-btn" style={{ backgroundColor: "red" }} onClick={this.props.insurance ? this.denyInsurance : this.createInsurance}
                                        disabled={this.state.insuranceDenied || this.state.loading || !this.state.insuranceReasonId}
                                    >Deny Insurance
                                    </Button>&nbsp;&nbsp;
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllInsuranceReasonCategory, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allInsuranceReasonCategory } }) {
            return { loading, allInsuranceReasonCategory }
        },
    }),
    graphql(AllBrokers, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allBrokers } }) {
            return { loading, allBrokers }
        },
    }),
)(DenyInsurance)