import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Row, Col, Alert, UncontrolledAlert, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, ButtonDropdown, Input, DropdownToggle, DropdownMenu, DropdownItem, FormFeedback, FormText } from 'reactstrap'
import gql from 'graphql-tag'
import Select from 'react-select'
import moment from 'moment-timezone'
import toTitleCase from '../Functions/toTitleCase'
import { CarDealTypes, CarDealEntityTypes } from '../Functions/querys'
// import './Cardeal.css'

const CarsQuery = gql`query CarsQuery($searchTerm: String){
    allCars(searchTerm: $searchTerm, first:5){
        edges{
            node{
                id
                pk
                vin
                plate{
                    dmvPlate
                    fhv
                }
            }
        }
    } 
}`

const CreateCarDeal = gql`
mutation CreateCarDeal($input: CreateCarDealMutationInput!){
    createCarDeal(input:$input){
    ok
    errors{
        messages
    }
}
} `
const UpdateCarDeal = gql`
mutation UpdateCarDeal($input: UpdateCarDealMutationInput!){
    updateCarDeal(input:$input){
    ok
    errors{
        messages
    }
}
} `
const AllCarDealEntitiesQuery = gql`query AllCarDealEntitiesQuery{
    allCarDealEntities(isActive:true){
        edges {
            node {
                id
                pk
                name
                entityType
                isActive
            }
        }
    }
}`

const AllOperatingLeaseQuery = gql`query AllOperatingLeaseQuery{
    allOperatingLease:allCarDealEntities(isActive:true, entityType: "Operating Lease", orderBy:["name"]){
        edges {
            node {
                id
                pk
                name
                isActive
            }
        }
    }
}`

const AllBuyerQuery = gql`query AllOperatingLeaseQuery{
    allBuyers:allCarDealEntities(isActive:true, entityType: "BUYER", orderBy:["name"]){
        edges {
            node {
                id
                pk
                name
                isActive
            }
        }
    }
}`

const AllDealersQuery = gql`query AllDealersQuery{
    allDealers:allCarDealEntities(isActive:true, entityType: "Dealer", orderBy:["name"]){
        edges {
            node {
                id
                pk
                name
                isActive
            }
        }
    }
}`

const AllTitleOwnersQuery = gql`query AllTitleOwnersQuery{
    allTitleOwners:allCarDealEntities(isActive:true, entityType: "Title Owner", orderBy:["name"]){
        edges {
            node {
                id
                pk
                name
                isActive
            }
        }
    }
}`
class CarDealModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCar: this.props.isUpdate && this.props.carDeal && this.props.carDeal.car ? this.props.carDeal.car : this.props.carDetailPage ? this.props.car : null,
            dealType: this.props.isUpdate && this.props.carDeal && this.props.carDeal.dealType ? this.props.carDeal.dealType : "SELLING",
            eventDate: this.props.isUpdate && this.props.carDeal && this.props.carDeal.eventDate ? this.props.carDeal.eventDate : null,
            carPrice: this.props.isUpdate && this.props.carDeal && this.props.carDeal.carPrice ? this.props.carDeal.carPrice : null,
            buyerType: null,
            buyer: this.props.isUpdate && this.props.carDeal && this.props.carDeal.buyer ? this.props.carDeal.buyer.id : null,
            sellerType: null,
            seller: this.props.isUpdate && this.props.carDeal && this.props.carDeal.seller ? this.props.carDeal.seller.id : null,
            metadata: this.props.isUpdate && this.props.carDeal && this.props.carDeal.metadata ? this.props.carDeal.metadata : null,
            metaObject: {
                mileage: null,
                delivery_disposal_date: null,
                claim: null,
                reason: null,
                estimated_price: null,
                titleOwner: null,
            },
            carSuggestions: null,
            openModal: "",
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.type == "checkbox" || e.target.type == "radio") {
            value = !this.state[name]
        }
        this.setState({ [name]: value })
    };
    handleClose = (e) => {
        let state = {
            selectedCar: null,
            dealType: null,
            eventDate: null,
            carPrice: null,
            buyerType: null,
            buyer: null,
            sellerType: null,
            seller: null,
            metadata: null,
            metaObject: {
                mileage: null,
                delivery_disposal_date: null,
                claim: null,
                reason: null,
                estimated_price: null,
                titleOwner: null,
            },
            carSuggestions: null,
            openModal: "",
        }
        this.setState({ state })
        this.props.handleClose()
    };
    handleSubmit = () => {
        try {
            this.setState({ loading: true, error: "" })
            if (!this.state.selectedCar || !this.state.selectedCar.id || !this.state.dealType) {
                throw 'Error: Required fields are missing'
            } else if (this.state.dealType.toUpperCase() === "SELLING" && !this.state.metaObject.delivery_disposal_date) {
                throw 'Error: Required fields are missing'
            } else if (this.state.seller && this.state.buyer && this.state.seller === this.state.buyer) {
                throw 'Error: Buyer & Seller cannot of the same types'
            } else if (this.state.eventDate && this.state.metaObject.delivery_disposal_date >= this.state.eventDate && this.state.dealType.toUpperCase() === "SELLING") {
                throw 'Error: Selling Date (Stage 13) Can Not Be Less Than Event Date (Stage 12)'
            }
            let input = {
                carPrice: this.state.carPrice ? parseFloat(this.state.carPrice) : null,
                eventDate: this.state.eventDate ? this.state.eventDate : null,
                buyer: this.state.buyer ? this.state.buyer : null,
                seller: this.state.seller ? this.state.seller : null,
                mileage: this.state.metaObject.mileage ? this.state.metaObject.mileage : null,
                deliveryDisposalDate: this.state.metaObject.delivery_disposal_date ? this.state.metaObject.delivery_disposal_date : null,
                claim: this.state.metaObject.claim ? this.state.metaObject.claim : null,
            }
            if (this.props.isUpdate) {
                input["dealId"] = this.props.carDeal.id
                input["titleOwner"] = this.state.metaObject.titleOwner ? this.state.metaObject.titleOwner : null
            } else {
                input["dealType"] = toTitleCase(this.state.dealType)
                input["car"] = this.state.selectedCar.id
            }

            if (this.state.dealType && this.state.dealType.toUpperCase() === "SELLING") {
                input["reason"] = this.state.metaObject.reason
                input["estimatedPrice"] = this.state.metaObject.estimated_price
            }

            this.props.client.mutate({
                mutation: this.props.isUpdate ? UpdateCarDeal : CreateCarDeal,
                variables: { input },
            }).then((result) => {
                if (result.data && ((this.props.isUpdate && result.data.updateCarDeal && result.data.updateCarDeal.ok) || (!this.props.isUpdate && result.data.createCarDeal && result.data.createCarDeal.ok))) {
                    this.setState({ loading: false, error: null })
                    this.props.refetchQuery()
                    this.handleClose()
                } else {
                    let errorMessage = "An error has occured. Please contact system admin"
                    if (!this.props.isUpdate && result.data && result.data.createCarDeal && result.data.createCarDeal.errors && result.data.createCarDeal.errors[0] && result.data.createCarDeal.errors[0].messages[0])
                        errorMessage = result.data.createCarDeal.errors[0].messages[0]
                    else if (this.props.isUpdate && result.data.updateCarDeal && result.data.updateCarDeal.errors && result.data.updateCarDeal.errors[0] && result.data.updateCarDeal.errors[0].messages[0])
                        errorMessage = result.data.updateCarDeal.errors[0].messages[0]
                    else
                        errorMessage = "An error has occured, Please contact Admin!"
                    this.setState({ loading: false, error: errorMessage })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured" + error
                this.setState({ error: errorMessage, loading: false })
            })
        } catch (err) {
            let errorMessage = "An error has occured " + err
            this.setState({ error: err, loading: false })
        }
    };
    handleUpdateCarSearch = (e) => {
        if (e.target.value.length >= 3) {
            this.props.client.query({
                query: CarsQuery,
                variables: { searchTerm: e.target.value }
            }).then((response) => {
                if (response && response.data && response.data.allCars && response.data.allCars.edges) {
                    this.setState({ carSuggestions: response.data.allCars.edges })
                } else {
                    this.setState({ carSuggestions: null })
                }
            }).catch((err) => {
                this.setState({ carSuggestions: null })
            })
        } else {
            this.setState({ carSuggestions: null })
        }
    }
    renderTitleOwnerInput = (titleOwner) => {
        let defaultTitleValue = null
        if (titleOwner) {
            defaultTitleValue = this.props.allCarDealEntities && this.props.allCarDealEntities.edges.find(item => item.node.pk == titleOwner || item.node.id === titleOwner)
            if (defaultTitleValue && defaultTitleValue.node) {
                defaultTitleValue = { value: defaultTitleValue.node.id, label: defaultTitleValue.node.name }
            } else {
                defaultTitleValue = null
            }
        }
        return <Select
            className="bos-custom-select" classNamePrefix="bos-select"
            value={defaultTitleValue}
            options={this.props.allTitleOwners && this.props.allTitleOwners.edges && this.props.allTitleOwners.edges.length > 0 && this.props.allTitleOwners.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
            placeholder="Select Title Owner"
            onChange={titleOwner => this.setState({ metaObject: { ...this.state.metaObject, titleOwner: titleOwner ? titleOwner.value : null } })}
        />
    }
    enumToVal = (str) => {
        if (str) {
            str = str.replace('_', ' ')
            return toTitleCase(str)
        }
    }



    componentDidMount() {
        if (this.state.dealType === "SELLING")
            this.setState({ buyerType: "BUYER", sellerType: "OPERATING_LEASE" })
        else
            this.setState({ buyerType: "OPERATING_LEASE", sellerType: "DEALER" })

        try {
            this.setState({ metaObject: JSON.parse(this.state.metadata.replace(/'/g, '"')) })
        } catch {

        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.open} className="car-deal-modal">
                    <ModalHeader>{this.props.isUpdate ? "Update" : "Create"} Car Deal</ModalHeader>
                    <ModalBody>
                        {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value={100} /></Col></Row>}
                        {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                        {this.props.isUpdate && <Row className="deal-extra-info-wrapper">
                            <Col>
                                <FormGroup>
                                    <small>Date Added</small>
                                    <br /><b>{this.props.carDeal && this.props.carDeal.dateAdded ? moment(this.props.carDeal.dateAdded).tz("America/New_York").format('lll') : "--"} </b>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <small>Created By</small>
                                    <br /><b>{this.state.metaObject && this.state.metaObject.created_by ? this.state.metaObject.created_by : "--"} </b>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <small>Last Updated By</small>
                                    <br /><b>{this.state.metaObject && this.state.metaObject.updated_by ? this.state.metaObject.updated_by : "--"} </b>
                                </FormGroup>
                            </Col>
                        </Row>}
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="carId"><small>What's the Car ID? *</small></Label>
                                    {this.state.selectedCar ? <><br /><b>{this.state.selectedCar.pk}</b>&nbsp;&nbsp;&nbsp;{!this.props.isUpdate && <i className="fa fa-times" onClick={() => this.setState({ selectedCar: null, carSuggestions: null })} />} </> :
                                        <ButtonDropdown className="w-100" isOpen={this.state.openModal === "CarFinder"} toggle={() => this.toggleModal("CarFinder")}>
                                            <DropdownToggle caret>Choose Car for deal</DropdownToggle>
                                            <DropdownMenu className='w-100'>
                                                <Label for="carSearch" className='pl-3'><small>Search by id, vin, dmv plate</small></Label>
                                                <Input type="text" onChange={this.handleUpdateCarSearch} name="carSearch" id="carSearch" />
                                                {this.state.carSuggestions && this.state.carSuggestions.length > 0 && this.state.carSuggestions.map((car, i) =>
                                                    <DropdownItem key={i} onClick={() => this.setState({ selectedCar: car.node })} style={{ display: "content" }} >{car.node.pk} · {car.node.vin} · {car.node.plate ? car.node.plate.dmvPlate : "No Plate"}</DropdownItem>
                                                )}
                                            </DropdownMenu>
                                        </ButtonDropdown>}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="dealType" className="d-block"><small>Deal Type *</small></Label>
                                    <Select
                                        isDisabled={true}
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!this.props.carDealTypes || !this.props.carDealTypes.states || !this.props.carDealTypes.states.length > 0}
                                        defaultValue={this.props.isUpdate && this.props.carDeal && this.props.carDeal.dealType ? ({ value: this.props.carDeal.dealType, label: toTitleCase(this.props.carDeal.dealType) }) : { value: "SELLING", label: "Selling" }}
                                        options={this.props.carDealTypes && this.props.carDealTypes.states && this.props.carDealTypes.states.length > 0 && this.props.carDealTypes.states.map(item => ({ value: item.name, label: item.description }))}
                                        placeholder="Select Deal Type"
                                        onChange={dealType => this.setState({ dealType: dealType ? dealType.label : null })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <UncontrolledAlert color='warning'>
                                    <h4 className="alert-heading">Note!</h4>
                                    <p>
                                        Car stage will be updated to stage {this.state.dealType.toLowerCase() === "buying" ? 8 : 13} on creating/updating this deal if it includes the <b>{this.state.dealType.toUpperCase() === "BUYING" ? "Buying" : "Selling"} Date</b> and <b>Car Price</b>. Otherwise the car stage will be updated to stage {this.state.dealType.toLowerCase() === "buying" ? 7 : 12}.
                                    </p>
                                    <hr />
                                    <p className="mb-0">
                                        This car stage rule is only applicable when the existing stage of the car is in [7, 8, 11, 12, 13]!
                                    </p>
                                </UncontrolledAlert>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="buyerType" className="d-block"><small>Buyer</small></Label>
                                    <Row>
                                        <Col xs={5}>
                                            <Select
                                                isDisabled={true}
                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                isLoading={!this.props.carDealEntityTypes || !this.props.carDealEntityTypes.states || !this.props.carDealEntityTypes.states.length > 0}
                                                defaultValue={this.state.dealType === "SELLING" ? { value: "BUYER", label: this.enumToVal("BUYER") } : { value: "OPERATING_LEASE", label: this.enumToVal("OPERATING_LEASE") }}
                                                options={this.props.carDealEntityTypes && this.props.carDealEntityTypes.states && this.props.carDealEntityTypes.states.length > 0 && this.props.carDealEntityTypes.states.map(item => ({ value: item.name, label: (item.description) }))}
                                                placeholder="Select Buyer Type"
                                                onChange={buyerType => this.setState({ buyerType: buyerType ? buyerType.value : null })}
                                            />
                                        </Col>
                                        <Col>
                                            <Select
                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                isLoading={!this.props.allCarDealEntities || !this.props.allCarDealEntities.edges || !this.props.allCarDealEntities.edges.length > 0}
                                                isDisabled={!this.state.buyerType}
                                                defaultValue={this.props.isUpdate && this.props.carDeal && this.props.carDeal.buyer ? ({ value: this.props.carDeal.buyer.id, label: this.props.carDeal.buyer.name }) : null}
                                                options={this.state.dealType.toUpperCase() === "SELLING" ?
                                                    this.props.allBuyers && this.props.allBuyers.edges && this.props.allBuyers.edges.length > 0 && this.props.allBuyers.edges.map(item => ({ value: item.node.id, label: item.node.name }))
                                                    : this.props.allOperatingLease && this.props.allOperatingLease.edges && this.props.allOperatingLease.edges.length > 0 && this.props.allOperatingLease.edges.map(item => ({ value: item.node.id, label: item.node.name }))
                                                }
                                                placeholder="Select Car Buyer"
                                                onChange={buyer => this.setState({ buyer: buyer ? buyer.value : null })}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                        {this.state.dealType.toUpperCase() === "BUYING" && <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="seller" className="d-block"><small>Seller</small></Label>
                                    <Row>
                                        <Col xs={5}>
                                            <Select
                                                isDisabled={true}
                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                isLoading={!this.props.carDealEntityTypes || !this.props.carDealEntityTypes.states || !this.props.carDealEntityTypes.states.length > 0}
                                                defaultValue={this.state.dealType === "SELLING" ? { value: "OPERATING_LEASE", label: this.enumToVal("OPERATING_LEASE") } : { value: "DEALER", label: this.enumToVal("DEALER") }}
                                                options={this.props.carDealEntityTypes && this.props.carDealEntityTypes.states && this.props.carDealEntityTypes.states.length > 0 && this.props.carDealEntityTypes.states.map(item => ({ value: item.name, label: (item.description) }))}
                                                placeholder="Select Seller Type"
                                                onChange={sellerType => this.setState({ sellerType: sellerType ? sellerType.value : null })}
                                            />
                                        </Col>
                                        <Col>
                                            <Select
                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                isLoading={!this.props.allCarDealEntities || !this.props.allCarDealEntities.edges || !this.props.allCarDealEntities.edges.length > 0}
                                                isDisabled={!this.state.sellerType}
                                                defaultValue={this.props.isUpdate && this.props.carDeal && this.props.carDeal.seller ? ({ value: this.props.carDeal.seller.id, label: this.props.carDeal.seller.name }) : null}
                                                options={this.props.allDealers && this.props.allDealers.edges && this.props.allDealers.edges.length > 0 && this.props.allDealers.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                                placeholder="Select Car Seller"
                                                onChange={seller => this.setState({ seller: seller ? seller.value : null })}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>}
                        {this.state.dealType.toUpperCase() === "BUYING" && <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="titleOwner" className="d-block"><small>Title Owner</small></Label>
                                    <Row>
                                        <Col xs={5}>
                                            <Select
                                                isDisabled={true}
                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                isLoading={!this.props.carDealEntityTypes || !this.props.carDealEntityTypes.states || !this.props.carDealEntityTypes.states.length > 0}
                                                defaultValue={{ value: "TITLE_OWNER", label: this.enumToVal("TITLE_OWNER") }}
                                                options={this.props.carDealEntityTypes && this.props.carDealEntityTypes.states && this.props.carDealEntityTypes.states.length > 0 && this.props.carDealEntityTypes.states.map(item => ({ value: item.name, label: (item.description) }))}
                                            />
                                        </Col>
                                        <Col>
                                            {this.props.allTitleOwners && this.props.allTitleOwners.edges && this.props.allTitleOwners.edges.length > 0 ? this.renderTitleOwnerInput(this.state.metaObject.titleOwner) : "No Title Owner Found"}
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>}
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="mileage"><small>Mileage</small></Label>
                                    <Input type="number" min="0" name="mileage" value={this.state.metaObject && this.state.metaObject.mileage ? this.state.metaObject.mileage : ""} onChange={(e) => { this.setState({ metaObject: { ...this.state.metaObject, mileage: e.target.value } }) }} placeholder={`Enter Car Mileage When ${toTitleCase(this.state.dealType)}`} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="claim"><small>Claim #</small></Label>
                                    <Input name="claim" value={this.state.metaObject && this.state.metaObject.claim ? this.state.metaObject.claim : ""} onChange={(e) => { this.setState({ metaObject: { ...this.state.metaObject, claim: e.target.value } }) }} placeholder="Enter the Claim #, if any" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            {this.state.dealType.toUpperCase() === "SELLING" &&
                                <Col>
                                    {/* <Label for="deliveryDisposalDate"><small>{this.state.dealType.toUpperCase()==="BUYING" ? "Delivery" : "Disposal"} Date</small></Label> */}
                                    <FormGroup>
                                        <Label for="deliveryDisposalDate"><small><b>Event Date (Stage 12)</b></small></Label>
                                        <Input type="date" name="deliveryDisposalDate" value={this.state.metaObject && this.state.metaObject.delivery_disposal_date ? this.state.metaObject.delivery_disposal_date : null} onChange={(e) => { this.setState({ metaObject: { ...this.state.metaObject, delivery_disposal_date: e.target.value } }) }} />
                                    </FormGroup>
                                </Col>
                            }
                            {this.state.dealType.toUpperCase() === "BUYING" &&
                                <Col>
                                    {/* <Label for="deliveryDisposalDate"><small>{this.state.dealType.toUpperCase()==="BUYING" ? "Delivery" : "Disposal"} Date</small></Label> */}
                                    <FormGroup>
                                        <Label for="deliveryDisposalDate"><small>Delivery Date</small></Label>
                                        <Input type="date" name="deliveryDisposalDate" value={this.state.metaObject && this.state.metaObject.delivery_disposal_date ? this.state.metaObject.delivery_disposal_date : null} onChange={(e) => { this.setState({ metaObject: { ...this.state.metaObject, delivery_disposal_date: e.target.value } }) }} />
                                    </FormGroup>
                                </Col>
                            }
                            <Col>
                                <FormGroup>
                                    <Label for="estimatedPrice"><small><b>{this.state.dealType.toUpperCase() === "BUYING" ? "Booking Price" : "Estimated Price"}</b></small></Label>
                                    <Input type="number" min="0" name="estimatedPrice" disabled={this.state.dealType.toUpperCase() === "BUYING"} value={this.state.metaObject && this.state.metaObject.estimated_price ? this.state.metaObject.estimated_price : ""} onChange={(e) => { this.setState({ metaObject: { ...this.state.metaObject, estimated_price: e.target.value } }) }} placeholder={this.state.dealType.toUpperCase() === "BUYING" ? "Enter The Booking Price" : "Enter The Estimated Price"} />
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="reason"><small>Reason</small></Label>
                                    <Input name="reason" disabled={this.state.dealType.toUpperCase() === "BUYING"} value={this.state.metaObject && this.state.metaObject.reason ? this.state.metaObject.reason : ""} onChange={(e) => { this.setState({ metaObject: { ...this.state.metaObject, reason: e.target.value } }) }} placeholder={`Enter Reason of ${toTitleCase(this.state.dealType)}`} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="eventDate"><small><b>{this.state.dealType.toUpperCase() === "BUYING" ? "Buying Date" : "Selling Date (Stage 13)"}</b></small></Label>
                                    <Input valid={!this.props.isUpdate && this.state.carPrice && this.state.eventDate} type="date" name="eventDate" value={this.state.eventDate} onChange={this.updateInput} placeholder={`Enter the ${toTitleCase(this.state.dealType)} Date`} />
                                    <FormFeedback valid={!this.props.isUpdate && this.state.carPrice && this.state.eventDate} >This will change the car stage to 13</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="carPrice"><small><b>Car Price</b></small></Label>
                                    <Input valid={!this.props.isUpdate && this.state.carPrice && this.state.eventDate} type="number" min="0" name="carPrice" value={this.state.carPrice} onChange={this.updateInput} placeholder={`Enter the ${toTitleCase(this.state.dealType)} Price`} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className='d-block'>
                        {!this.state.loading &&
                            <Row><Col className='text-right'>
                                <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                <Button color="primary" onClick={this.handleSubmit} disabled={this.state.loading}>
                                    {this.props.isUpdate ? "Update" : "Create"} Car Deal
                                </Button>
                            </Col></Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(CarDealEntityTypes, { props({ data: { carDealEntityTypes } }) { return { carDealEntityTypes } } }),
    graphql(CarDealTypes, { props({ data: { carDealTypes } }) { return { carDealTypes } } }),
    graphql(AllCarDealEntitiesQuery, { options: { fetchPolicy: 'cache-first' }, props({ data: { loading, allCarDealEntities } }) { return { loading, allCarDealEntities } } }),
    graphql(AllOperatingLeaseQuery, {
        options: { fetchPolicy: 'cache-and-network' }, props({ data: { allOperatingLease, fetchMore } }) {
            return {
                allOperatingLease,
                refetchAllOperatingLease: () => {
                    return fetchMore({
                        query: AllOperatingLeaseQuery,
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allOperatingLease.edges
                            const pageInfo = fetchMoreResult.allOperatingLease.pageInfo
                            return {
                                allOperatingLease: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allOperatingLease.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    }),

    graphql(AllBuyerQuery, {
        options: { fetchPolicy: 'cache-and-network' }, props({ data: { allBuyers, fetchMore } }) {
            return {
                allBuyers,
                refetchAllBuyers: () => {
                    return fetchMore({
                        query: AllBuyerQuery,
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allBuyers.edges
                            const pageInfo = fetchMoreResult.allBuyers.pageInfo
                            return {
                                allBuyers: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allBuyers.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    }),

    graphql(AllDealersQuery, {
        options: { fetchPolicy: 'cache-and-network' }, props({ data: { allDealers, fetchMore } }) {
            return {
                allDealers,
                refetchAllDealers: () => {
                    return fetchMore({
                        query: AllDealersQuery,
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allDealers.edges
                            const pageInfo = fetchMoreResult.allDealers.pageInfo
                            return {
                                allDealers: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allDealers.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    }),
    graphql(AllTitleOwnersQuery, {
        options: { fetchPolicy: 'cache-and-network' }, props({ data: { allTitleOwners, fetchMore } }) {
            return {
                allTitleOwners,
                refetchAllTitleOwners: () => {
                    return fetchMore({
                        query: AllTitleOwnersQuery,
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allTitleOwners.edges
                            const pageInfo = fetchMoreResult.allTitleOwners.pageInfo
                            return {
                                allTitleOwners: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allTitleOwners.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    }),
)(CarDealModal)
