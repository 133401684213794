import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo/index'
import { Button, Table, Row, Col, Progress, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import AddMemberModal from "./AddMemberModal"
import DatetimeRenderer from "../../Material/DatetimeRenderer"
import "../../Material/ListingHeader.css"
import PrimaryListingContainer from "../../Material/PrimaryListingContainer"
import Label from "reactstrap/lib/Label"

const BranchesQuery = gql`query BranchesQuery($cursor: String,$first:Int!, $name: String, $stateCode: String, $city: String, $tenant: ID, $orderBy: [String], $isActive: Boolean) {
    branches: allBranches(first:$first, after: $cursor, name_Icontains:$name, stateCode: $stateCode, city: $city, tenantId: $tenant, orderBy: $orderBy, isActive: $isActive){
      edges{
        cursor
        node{
            id
            pk
            name
            isActive
            customId
            dateAdded
            dateModified
            allowCcSurcharge
            city
            stateCode
            postalCode
            country
            requiresInsurance
            forTlc
            missingRequiredSettingKeys {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            tenant{
                name
                logoImage
            }
        }
      }
      pageInfo{
          endCursor
          hasNextPage
          length
      }
    } 
}`


const tableHeaders = [
    { id: "id", name: "ID", sortable: false },
    { id: "date_modified", name: "Last Modified", sortable: true },
    { id: "tenant", name: "Tenant", sortable: true },
    { id: "name", name: "Name", sortable: true },
    { id: "address", name: "Address", sortable: false },
    { id: "for_tlc", name: "For TLC", sortable: true },
    { id: "requires_insurance", name: "Requires Insurance", sortable: true },
    { id: "allow_cc_surcharge", name: "Allow CC Surcharge", sortable: true },
    { id: "is_active", name: "Is Active", sortable: true },
    { id: "action", name: "Action", sortable: false },
]

class BranchList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }
    render() {
        return (
            <PrimaryListingContainer listLoading={this.props.loading} totalFetchedRows={this.props.branches && this.props.branches.edges.length} pageInfo={this.props.branches && this.props.branches.pageInfo} loadMoreEntries={this.props.branches && this.props.branches.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchQuery}>
                <Table responsive>
                    <thead>
                        <tr>
                            {tableHeaders.map(tableHeader => {
                                return (
                                    tableHeader.sortable ?
                                        <th key={tableHeader.id} onClick={() => this.props.updateOrder(tableHeader.id)}>{this.props.orderBy.some(order => order.includes(tableHeader.id)) ? <i className={"fa " + (this.props.orderBy.includes(tableHeader.id) ? 'fa-sort-asc' : 'fa-sort-desc')}></i> : <i className='fa fa-sort'></i>}&nbsp;&nbsp;{tableHeader.name}</th> :
                                        <th key={tableHeader.id}>{tableHeader.name}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.branches && this.props.branches.edges && this.props.branches.edges.length > 0 ? this.props.branches.edges.map((branch, i) =>
                            <tr key={branch.node.id}>
                                <td>
                                    <strong>{branch.node.pk}</strong>
                                </td>
                                <td>
                                    {branch.node.dateModified ? <span><DatetimeRenderer datetime={branch.node.dateModified} /></span> : '--'}
                                </td>
                                <td className="text-uppercase">
                                    {branch.node.tenant.logoImage ? <img width={50} src={branch.node.tenant.logoImage} alt={branch.node.tenant.name + " Logo"} /> : <i className="fa fa-image fa-lg"></i>}
                                    <br /><small>{branch.node.tenant.name}</small>
                                </td>
                                <td className="d-flex justify-content-between">
                                    <div className="d-inline-block">
                                        <Link color="secondary" to={'/organization/branch/' + branch.node.id}>{branch.node.name}</Link>
                                        <br /><small>{branch.node.customId}</small>
                                    </div>
                                    {branch.node.missingRequiredSettingKeys && branch.node.missingRequiredSettingKeys.edges && branch.node.missingRequiredSettingKeys.edges.length > 0 && <div className="d-flex flex-column align-self-center pr-4">
                                        <i id={"missing-settings" + i} className="fa fa-exclamation-circle text-danger fa-lg" aria-hidden="true" />
                                        <UncontrolledTooltip target={"missing-settings" + i} className="missing-required-settings-tooltip">
                                            <Label>Missing Required Settings:</Label>
                                            <ul>
                                                {branch.node.missingRequiredSettingKeys.edges.map((setting, i) => <li key={i}>{setting.node.name}</li>)}
                                            </ul>
                                        </UncontrolledTooltip>
                                    </div>
                                    }
                                </td>
                                <td>
                                    {branch.node.city}, {branch.node.stateCode}, {branch.node.country} {branch.node.postalCode}
                                </td>
                                <td>
                                    {branch.node.forTlc ? <i className={"fa fa-check-circle text-success fa-lg"} aria-hidden="true" /> : <i className={"fa fa-times-circle text-danger fa-lg"} aria-hidden="true" />}
                                </td>
                                <td>
                                    {branch.node.requiresInsurance ? <i className={"fa fa-check-circle text-success fa-lg"} aria-hidden="true" /> : <i className={"fa fa-times-circle text-danger fa-lg"} aria-hidden="true" />}
                                </td>
                                <td>
                                    {branch.node.allowCcSurcharge ? <i className={"fa fa-check-circle text-success fa-lg"} aria-hidden="true" /> : <i className={"fa fa-times-circle text-danger fa-lg"} aria-hidden="true" />}
                                </td>
                                <td>
                                    {branch.node.isActive ? <i className={"fa fa-check-circle text-success fa-lg"} aria-hidden="true" /> : <i className={"fa fa-times-circle text-danger fa-lg"} aria-hidden="true" />}
                                </td>
                                <td>
                                    {this.state.openModal === "addMember" + i && <AddMemberModal branchId={branch.node.id} open={this.state.openModal === "addMember" + i} handleClose={() => this.toggleModal("addMember" + i)} handleSuccess={() => this.props.refetchQuery()} />}
                                    {branch.node.isActive && <><i id="add-member" onClick={() => this.toggleModal("addMember" + i)} className="fa fa-user-plus fa-lg text-primary" aria-hidden="true" /> <UncontrolledTooltip target="add-member">Add Member/s</UncontrolledTooltip></>}
                                </td>
                            </tr>) :
                            <tr>
                                <td colSpan={7}>No Branch Found</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </PrimaryListingContainer>
        )
    }
}
export default compose(
    withApollo,
    graphql(BranchesQuery, {
        options: ({ searchTerm, stateCode, city, tenant, orderBy, isActive }) => ({
            variables: {
                name: searchTerm, stateCode, city, orderBy, isActive,
                first: 30, tenant
            }
        }),
        // This function re-runs every time `data` changes, including after `updateQuery`,
        // meaning our loadMoreEntries function will always have the right cursor
        props({ data: { loading, branches, fetchMore, variables, refetch } }) {
            return {
                loading,
                branches,
                variables,
                refetch,
                refetchQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = branches && branches.edges ? branches.edges.length : 30
                    return fetchMore({
                        query: BranchesQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.branches.edges
                            const pageInfo = fetchMoreResult.branches.pageInfo
                            return {
                                branches: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    __typename: previousResult.branches.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: BranchesQuery,
                        variables: {
                            cursor: branches.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.branches.edges
                            const pageInfo = fetchMoreResult.branches.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                branches: {
                                    edges: [...previousResult.branches.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.branches.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    })
)(BranchList)
