import React, { Component } from 'react'
import { Progress, FormGroup, Label, Row, Col } from 'reactstrap'
import RelatedProfilesList from "./RelatedProfilesList"
import Select from 'react-select'
class RelatedProfileContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            relationType: ["DMV", "Phone", "Email"],
            basedOnTenant: this.props.basedOnTenant,
        }
    }

    render() {
        return (
            <div>
                <div>
                    <Row>
                        <Col xs={{ size: this.props.colspan ? this.props.colspan : "5" }}>
                            <FormGroup>
                                <Label><b>Based On</b></Label>
                                <Select
                                    className="bos-custom-select" classNamePrefix="bos-select"
                                    options={[{ value: "Phone", label: "PHONE" }, { value: "Email", label: "EMAIL" }, { value: "DMV", label: "DMV LICENSE" }, { value: "TLC", label: "TLC LICENSE" }]}
                                    defaultValue={[{ value: "Phone", label: "PHONE" }, { value: "Email", label: "EMAIL" }, { value: "DMV", label: "DMV LICENSE" }]}
                                    placeholder="Choose One"
                                    isMulti
                                    isClearable={false}
                                    onChange={relationType => this.setState({ relationType: (relationType && relationType.length > 0 ? relationType.map(item => item.value) : []) })}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label><b>Based On Same Tenant</b></Label><br />
                                <div>
                                    <span>Yes</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            id="basedOnTenant"
                                            name={"basedOnTenant"}
                                            checked={!this.state.basedOnTenant}
                                            onChange={() => this.setState({ basedOnTenant: !this.state.basedOnTenant })}
                                        />
                                        <label className="toggle-switch-label" htmlFor={"basedOnTenant"}>
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>No</span>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <RelatedProfilesList
                        relationType={this.state.relationType.length > 0 ? this.state.relationType : ["Phone", "Email", "DMV"]}
                        driverId={this.props.driverId}
                        leadId={this.props.leadId}
                        basedOnTenant={this.state.basedOnTenant}
                        dmvLicense={this.props.dmvLicense}
                        listingType={this.props.listingType}
                        isBlocked={this.props.isBlocked}
                    />
                </div>
            </div>
        )
    }
}

export default (RelatedProfileContainer)
