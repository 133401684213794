import React, { Component, Fragment } from 'react'
import { Card, Alert, Progress, Button, Badge, UncontrolledTooltip } from 'reactstrap'
import "./DocumentListNew.css"
import DocumentModalNew from "./DocumentModalNew"
import { CarDocumentsQuery, DriverDocumentsQuery } from "./Quries"
import { Query, withApollo } from "react-apollo"
import DatetimeRenderer from "../DatetimeRenderer"
import { ReactComponent as DocVerified } from "../../assets/images/icons/doc-verified.svg"
import { HasPermissionsQuery } from "../../Functions/querys"

class DocumentsListNew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            selectedDocument: null,
        }
    }

    next = () => {
        const { documents } = this.props
        const currentIndex = documents.findIndex(item => item.id === this.state.selectedDocument.id)
        if (currentIndex === documents.length - 1) return
        this.setState({ selectedDocument: documents[currentIndex + 1] })
    }

    prev = () => {
        const { documents } = this.props
        const currentIndex = documents.findIndex(item => item.id === this.state.selectedDocument.id)
        if (currentIndex === 0) return
        this.setState({ selectedDocument: documents[currentIndex - 1] })
    }
    fetchMetaData = (metadata = null, name) => {
        if (!metadata || metadata === "")
            return null
        try {
            metadata = JSON.parse(metadata.replace(/'/g, '"').replace(/True/g, 'true').replace(/False/g, 'false'))
        } catch (error) {
            metadata = null
        }
        return metadata && metadata[name] ? metadata[name] : null
    }

    render() {
        let { documents, loading, hasNextPage, loadMore } = this.props
        if (!documents || documents.length === 0) {
            return loading ? <Progress animated color="info" value="100">Loading Documents...</Progress> : <div>No documents found</div>
        }
        return (
            < Fragment >
                <div className="DocumentCardContainer" >
                    {this.state.selectedDocument &&
                        <DocumentModalNew
                            document={this.state.selectedDocument}
                            handleClose={() => this.setState({ selectedDocument: null })}
                            next={this.next}
                            prev={this.prev}
                            objectType={this.props.objectType}
                            refetch={this.props.refetch}
                            currentUser={this.props.currentUser}
                            driverId={this.props.driverId}
                        />
                    }
                    {documents.map((document, index) =>
                        <Card className="DocumentCard" key={index}>
                            <div className="DocumentCardHeader" onClick={() => this.setState({ selectedDocument: document })}>
                                <p className="DocumentCardTitle">
                                    <span><i className={document.name && document.name.toUpperCase().slice(-4) === ".PDF" ? "fa fa-file-pdf-o" : "fa fa-picture-o"} aria-hidden="true"></i></span>&nbsp;&nbsp;
                                    <span>
                                        {document.documentType ? document.documentType.typeName : document.name}&nbsp;
                                        {document.isVerified && <>
                                            <UncontrolledTooltip target="verified">{"Verified By " + (this.fetchMetaData(document.metadata, "verified_by") || "System")}</UncontrolledTooltip>
                                            {this.fetchMetaData(document.metadata, "verified_by") != "System" ? <DocVerified id="verified" width="20" height="20" className="fa fa-check-circle" fill="green" /> : <i id="verified" className="fa fa-check-circle" style={{ color: "green" }}></i>}
                                        </>}
                                    </span>
                                </p>
                                <p><i className="fa fa-arrows-alt"></i></p>
                            </div>
                            <div className="DocumentCardPreview" >
                                {document ? (
                                    isImage(document.documentUrl, document.name) ?
                                        <img className="DocumentCardPreviewEmbed DocViewerImage" src={document.documentUrl} alt="Document" /> :
                                        <iframe scrolling="no" className="DocumentCardPreviewEmbed" src={document.documentUrl} />
                                ) : ''
                                }
                            </div>

                            <div className="DocumentCardFooter" onClick={() => this.setState({ selectedDocument: document })}>
                                {document.isExpired ?
                                    <Badge color="danger">Expired on <DatetimeRenderer datetime={document.expiration} /></Badge>
                                    :
                                    document.expiration ?
                                        <Badge>Expires on <DatetimeRenderer datetime={document.expiration} /></Badge>
                                        :
                                        ""
                                }
                            </div>
                        </Card>

                    )}
                </div >
                {hasNextPage && loadMore && <Button className="btn btn-primary" onClick={() => loadMore()}>Load more</Button>}
            </Fragment >
        )
    }
}

const cleanGDriveURl = (url, name) => {
    if (isImage(url, name)) {
        if (!(url.includes("/d/") || url.includes("uc?id=")))
            return url
        let id = url.includes("/d/") ? url.split("/d/")[1] : url.split("uc?id=")[1]
        id = id.includes("&export=download") ? id.replace("&export=download", "") : id.split("/view")[0]
        url = "https://drive.google.com/thumbnail?id=" + id + "&sz=w1000"
    } else {
        if (url.includes("&export=download")) {
            url = "https://drive.google.com/file/d/" + url.split("uc?id=")[1].replace("&export=download", "/preview?usp=drivesdk")
        } else {
            url = url.replace("view", "preview")
        }
    }
    url = url.replace('%2F', '/')
    url = url.replace('&export=download', '')
    return url
}

const cleanDocumentUrl = (url) => {
    // Prepare document urls by removing unnecessary parts
    url = url.replace('%2F', '/')
    url = url.replace('&export=download', '')
    url = url.replace('https://storage.googleapis.com/download/storage/v1/b/buggy_files/o', 'https://storage.googleapis.com/buggy_files')
    // Remove everythin starting from ?generation= until the end of the string
    url = url.replace(/\?generation=.*/g, '')
    return url
}

const isImage = (url, name) => {
    const regex = /\.(jpg|jpeg|png|gif|bmp)$/i
    return regex.test(url || '') || regex.test(name || '')
}

// ############################################DRIVER DOCUMENTS #######################################

class DriverDocumentsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            selectedDocument: null,
        }
    }



    render() {
        const objectType = "driver"
        return (
            <Query
                query={DriverDocumentsQuery}
                variables={{ id: this.props.driverId, categoriesIn: this.props.categoriesIn, typesIn: this.props.typesIn, isDeleted: this.props.isArchived, first: 10, orderBy: ["-date_added"] }}
                fetchPolicy='cache-and-network'>
                {({ loading, error, data, fetchMore, refetch }) =>
                    <DocumentsListNew
                        documents={data && data.documents && data.documents.edges && data.documents.edges.length > 0 ? data.documents.edges.map(item => ({ ...item.node, documentUrl: item.node.documentUrl.includes("drive.google") ? cleanGDriveURl(item.node.documentUrl, item.node.name) : cleanDocumentUrl(item.node.documentUrl) })) : []}
                        currentUser={this.props.currentUser}
                        loading={loading}
                        refetch={refetch}
                        objectType={objectType}
                        driverId={this.props.driverId}
                        hasNextPage={data && data.documents && data.documents.pageInfo && data.documents.pageInfo.hasNextPage}
                        loadMore={() => fetchMore({
                            variables: {
                                cursor: data.documents.pageInfo.endCursor,
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev
                                return Object.assign({}, prev, {
                                    documents: {
                                        ...prev.documents,
                                        edges: [...prev.documents.edges, ...fetchMoreResult.documents.edges],
                                        pageInfo: fetchMoreResult.documents.pageInfo,
                                    }
                                })
                            }
                        })}
                    />
                }
            </Query>
        )
    }
}

// ############################################ CAR DOCUMENTS #######################################

class CarDocuments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            selectedDocument: null,
            hasTlcPermissions: true,
            loading: true
        }
    }

    componentDidMount() {
        this.props.client.query({
            query: HasPermissionsQuery,
            variables: { userPermissions: ["custom_can_view_tlc_docs"] }
        }).then(res => {
            this.setState({ hasTlcPermissions: res.data.hasPermissions, loading: false })
        })
    }

    render() {
        const objectType = "car"
        return (
            <div>
                <Alert color="info">For car registrations, please visit <a href={`https://fleetsync.io/cars/${this.props.carPk || ''}`}>Fleetsync</a>.</Alert>
                {!this.state.loading && <Query
                    query={CarDocumentsQuery}
                    variables={{ id: this.props.carId, categoriesIn: this.props.categoriesIn, typesIn: this.props.typesIn, isDeleted: this.props.isArchived, first: 9, orderBy: ["-id"] }}
                    fetchPolicy='cache-and-network'>
                    {({ loading, error, data, fetchMore, refetch }) =>
                        <DocumentsListNew
                            documents={data && data.documents && data.documents.edges && data.documents.edges.length > 0 ? data.documents.edges.filter(item => item.node.documentType.documentCategory.name !== "TLC" || this.state.hasTlcPermissions).map(item => ({ ...item.node, documentUrl: item.node.documentUrl.includes("drive.google") ? cleanGDriveURl(item.node.documentUrl, item.node.name) : cleanDocumentUrl(item.node.documentUrl) })) : []}
                            currentUser={this.props.currentUser}
                            loading={loading}
                            refetch={refetch}
                            objectType={objectType}
                            hasNextPage={data && data.documents && data.documents.pageInfo && data.documents.pageInfo.hasNextPage}
                            loadMore={() => fetchMore({
                                variables: {
                                    cursor: data.documents.pageInfo.endCursor,
                                },
                                updateQuery: (prev, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) return prev
                                    return Object.assign({}, prev, {
                                        documents: {
                                            ...prev.documents,
                                            edges: [...prev.documents.edges, ...fetchMoreResult.documents.edges],
                                            pageInfo: fetchMoreResult.documents.pageInfo,
                                        }
                                    })
                                }
                            })}
                        />
                    }
                </Query>}
            </div>
        )
    }
}

const CarDocumentsList = withApollo(CarDocuments)

export { DriverDocumentsList, CarDocumentsList, DocumentsListNew, cleanDocumentUrl, isImage }