import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Popover, PopoverBody, PopoverHeader, Button, FormGroup, Label, Input, Progress } from 'reactstrap'
import Alert from 'reactstrap/lib/Alert'
const AllCarLocationsQuery = gql`
  query AllCarLocations{
    allCarLocations{
        edges{
            node{
                name
                id
                pk
            }
        }
    }
  }
`
const UpdateCar = gql`
mutation updateCarMutation($input: UpdateCarMutationInput!){
    updateCar(input:$input){
        ok
        errors{
            messages
        }
}
} `
class UpdateCarInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: null,
            car: props.car,
            input: {},
            loading: false
        }
    }

    updateCar = () => {
        try {
            if (this.state.input.id) {
                this.setState({ loading: true })
                let input = this.state.input
                this.props.client.mutate({
                    mutation: UpdateCar,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateCar && result.data.updateCar.ok) {
                        this.props.handleClose()
                        this.props.refetchQuery()
                        this.setState({ input: "", loading: false })
                    }
                    else if (result && result.data && result.data.updateCar && result.data.updateCar.errors[0] && result.data.updateCar.errors[0].messages) {
                        this.setState({ errorMessage: result.data.updateCar.errors[0].messages, loading: false })
                    }
                }).catch((err) => {
                    this.setState({ loading: false })
                })
            } else {
            }
        } catch (err) {
            this.setState({ loading: false })
        }

    }

    updateInput = (e) => {
        if (this.state.car) {
            let name = e.target.name
            let value = e.target.value
            let input = {
                id: this.state.car.id,
                [name]: value
            }
            this.setState({ input: input })
        }
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.car !== prevProps.car) {
            let car = this.props.car
            this.setState({ car: car })
        }
    }

    render() {
        return (
            <div>
                <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader>{this.props.title}</PopoverHeader>
                    <PopoverBody>
                        {this.state.errorMessage && <Alert color="danger">{this.state.errorMessage}</Alert>}
                        <FormGroup>
                            <Label for={this.props.name}>{this.props.title}</Label>
                            {this.props.name === "location" ?
                                this.props.allCarLocations && this.props.allCarLocations.edges.length > 0 ?
                                    <Input type="select" name="location" id="location" onChange={this.updateInput} placeholder="Select Location">
                                        <option value={""}>--Select Location--</option>
                                        {this.props.allCarLocations.edges.filter((location => location.node.name !== this.props.value)).map((location, i) =>
                                            <option key={i} value={location.node.pk}>{location.node.name}</option>
                                        )}
                                    </Input> :
                                    <Alert color="info">You need to add location options before changing car location</Alert>
                                : <Input type={this.props.type} maxLength={this.props.maxLength ? this.props.maxLength : 200} name={this.props.name}
                                    id={this.props.name} defaultValue={this.props.value} placeholder={this.props.title} onChange={this.updateInput}
                                />
                            }
                        </FormGroup>
                        {this.state.loading ? <Progress animated color="info" value={100} /> : <Button type="button" size="sm" onClick={this.updateCar}>Submit</Button>}
                    </PopoverBody>
                </Popover>
            </div >
        )
    }
}

export default compose(
    withApollo,
    graphql(AllCarLocationsQuery, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allCarLocations } }) {
            return { loading, allCarLocations }
        },
    })
)(UpdateCarInfo)