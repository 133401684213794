import gql from 'graphql-tag'
import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Row, Col, Label, Progress, Badge, Button, UncontrolledTooltip, Alert } from 'reactstrap'
import { Link } from 'react-router-dom'
import MergeDriverModal from "../MergeDriverModal"
import { UpdateCustomerLead } from "../../CustomerLeads/Mutations"
import { HasPermissionsQuery } from "../../Functions/querys"


const AllRelatedProfilesQuery = gql`query AllRelatedProfilesQuery($driverId: ID, $relationTypes: [String], $cursor: String,$first:Int!, $basedOnTenant: Boolean, $leadId:ID, $dmvLicenseId: String) {
    allRelatedProfiles(driverId: $driverId, relationTypes: $relationTypes, first:$first,after: $cursor, basedOnTenant: $basedOnTenant, leadId: $leadId, dmvLicenseId:$dmvLicenseId){
      edges{    
        node{
          id
          pk
          name
          phone
          email
          balance
          balanceWriteOff
          isBlocked
          branch{
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    } 
}`

const tableHeaders = [
    { id: "name", name: "Driver Name", views: ["relatedProfiles", "insurance", "leads"] },
    { id: "email", name: "Email", views: ["relatedProfiles", "insurance", "leads"] },
    { id: "phone", name: "Phone", views: ["relatedProfiles", "insurance", "leads"] },
    { id: "balance", name: "Balance", views: ["relatedProfiles"] },
    { id: "balanceWriteOff", name: "Balance Write Off", views: ["relatedProfiles"] },
    { id: "branch__name", name: "Branch", views: ["relatedProfiles", "insurance", "leads"] },
    { id: "action", name: "Action", views: ["relatedProfiles", "leads"] },
]

class RelatedProfileList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            openModal: "",
            error: "",
            msgSuccess: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    assignLeadToDriver = (driverId, leadId) => {
        this.setState({ loading: true, error: "", msgSuccess: "" })
        this.props.client.mutate({
            mutation: UpdateCustomerLead,
            variables: { input: { id: leadId, customerId: driverId } }
        }).then(result => {
            if (result && result.data && result.data.updateCustomerLead && result.data.updateCustomerLead.ok) {
                this.setState({ input: {}, loading: false, error: "", msgSuccess: "Successfully assigned lead to the driver, please refresh page." })
                this.props.refetchRelatedProfilesQuery()
            }
            else {
                let error = "An error occurred, could not complete request."
                if (result && result.data && result.data.updateCustomerLead && result.data.updateCustomerLead.errors[0] && result.data.updateCustomerLead.errors[0].messages) {
                    error = result.data.updateCustomerLead.errors[0].messages.toString()
                }
                this.setState({ error: error, loading: false })
            }
        }).catch((err) => {
            this.setState({ loading: false, error: "An error has occurred. Please contact admin: " + err })
        })
    }

    render() {
        return (
            <>
                {(this.props.allRelatedProfilesLoading || this.state.loading) && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                {this.state.msgSuccess && <Row><Col xs={12}><Alert color="success">{this.state.msgSuccess}</Alert></Col></Row>}
                <Row>
                    {tableHeaders.filter(header => header.views.includes(this.props.listingType) && (header.id !== "action" || (this.props.listingType !== "leads" || this.props.leadId !== ""))).map(tableHeader =>
                        <Col key={tableHeader.id}><Label>{tableHeader.name}</Label></Col>
                    )}
                </Row>
                <hr />
                <br />
                {this.props.allRelatedProfiles && this.props.allRelatedProfiles.edges && this.props.allRelatedProfiles.edges.length > 0 &&
                    this.props.allRelatedProfiles.edges.filter(item => item.node.id !== this.props.driverId).length > 0 ? this.props.allRelatedProfiles.edges.filter(item => item.node.id !== this.props.driverId).map((driver, i) =>
                        <>
                            <Row>
                                <Col>
                                    <p>
                                        <Link color="secondary" to={'/driver/' + driver.node.id} target="_blank"> {driver.node.name ? driver.node.name : "--"}</Link>
                                        {driver.node.isBlocked && <>
                                            &nbsp;&nbsp;<i id={"isBlocked" + i} class="fa fa-ban fa-lg text-danger" aria-hidden="true" ></i>&nbsp;&nbsp;
                                            <UncontrolledTooltip target={"isBlocked" + i}>Blocked</UncontrolledTooltip>
                                        </>}
                                    </p>
                                </Col>
                                <Col><p>{driver.node.email ? driver.node.email : "--"}</p></Col>
                                <Col><p>{driver.node.phone ? driver.node.phone : "--"}</p></Col>
                                {!["leads", "insurance"].includes(this.props.listingType) && <Col><p>{driver.node.balance ? "$" + driver.node.balance : "0"}</p></Col>}
                                {!["leads", "insurance"].includes(this.props.listingType) && <Col><p>{driver.node.balanceWriteOff ? "$" + driver.node.balanceWriteOff : "0"}</p></Col>}
                                <Col>{driver.node.branch ? <Badge className="bos-badge-blue">{driver.node.branch.name}</Badge> : "--"}</Col>
                                {["relatedProfiles"].includes(this.props.listingType) &&
                                    <Col>
                                        <Button
                                            color="primary"
                                            id={"mergeDriver" + driver.node.pk}
                                            onClick={() => this.setState({ openModal: "mergeDriverModal" + driver.node.pk })}
                                            disabled={!(this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_transfer_driver_data"))}
                                        >
                                            <i className="fa fa-exchange" aria-hidden="true"></i>
                                        </Button>
                                        <UncontrolledTooltip target={"mergeDriver" + driver.node.pk}>Merge Driver</UncontrolledTooltip>
                                        {this.state.openModal === "mergeDriverModal" + driver.node.pk &&
                                            <MergeDriverModal
                                                handleClose={() => this.toggleModal("mergeDriverModal" + driver.node.pk)}
                                                open={this.state.openModal === "mergeDriverModal" + driver.node.pk}
                                                toDriverId={this.props.driverId}
                                                fromDriverId={driver.node.id}
                                                refetchQuery={this.props.refetchRelatedProfilesQuery}
                                            />
                                        }
                                    </Col>
                                }
                                {["leads"].includes(this.props.listingType) && this.props.leadId !== "" &&
                                    < Col >
                                        <Button
                                            color="primary"
                                            id={"assignLead" + driver.node.pk}
                                            onClick={() => this.assignLeadToDriver(driver.node.id, this.props.leadId)}
                                        >
                                            <i className="fa fa-long-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Assign Lead
                                        </Button>
                                        <UncontrolledTooltip target={"assignLead" + driver.node.pk}>Assign Lead To This Driver</UncontrolledTooltip>
                                    </Col>
                                }
                            </Row ><hr /><br />
                        </>) : !this.props.allRelatedProfilesLoading &&
                <Row>
                    <Col>
                        <p>No Related Profiles Found!</p>
                    </Col>
                </Row>
                }
                {
                    this.props.allRelatedProfiles && this.props.allRelatedProfiles.pageInfo.hasNextPage &&
                    <Row>
                        <Col className="d-flex justify-content-end"><Button onClick={this.props.loadMoreEntries} className="primary">Show More <i className={"fa fa-caret-down"}></i></Button></Col>
                    </Row>
                }
            </>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllRelatedProfilesQuery, {
        options: ({ driverId, relationType, basedOnTenant, leadId, dmvLicense }) => ({
            variables: {
                driverId: driverId,
                basedOnTenant: basedOnTenant,
                relationTypes: relationType,
                leadId: leadId,
                dmvLicenseId: dmvLicense,
                orderBy: ["-date_added"],
                first: 20,
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),

        props({ data: { loading, allRelatedProfiles, variables, fetchMore, refetch } }) {
            return {
                allRelatedProfilesLoading: loading, allRelatedProfiles, variables, refetch,
                refetchRelatedProfilesQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = allRelatedProfiles && allRelatedProfiles.edges ? allRelatedProfiles.edges.length : 30
                    return fetchMore({
                        query: AllRelatedProfilesQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allRelatedProfiles.edges
                            const pageInfo = fetchMoreResult.allRelatedProfiles.pageInfo
                            return {
                                allRelatedProfiles: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allRelatedProfiles.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: AllRelatedProfilesQuery,
                        variables: {
                            cursor: allRelatedProfiles.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allRelatedProfiles.edges
                            const pageInfo = fetchMoreResult.allRelatedProfiles.pageInfo
                            return {
                                allRelatedProfiles: {
                                    edges: [...previousResult.allRelatedProfiles.edges, ...newEdges],
                                    pageInfo,
                                    __typename: previousResult.allRelatedProfiles.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    }),
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["custom_can_transfer_driver_data"] } }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    })
)(RelatedProfileList)
