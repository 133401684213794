import React, { Component } from 'react';
import {compose, graphql, withApollo} from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Alert, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input} from 'reactstrap';

const changeReturnMutation = gql `
  mutation ChangeReturn ($input:ChangeReturnInput!){
      changeReturn(input:$input){
          ok
          errors{
              field
              messages
          }
      }
  }`;
const returnReasons = gql`
query returnReasons{
    globalSettings (settingsName_Icontains:"return_reasons") {
        edges {
          node {
            settingsName
            settingsValue
          }
        }
    }
}`
class CancelReturn extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            error:null,
            returnCanceled:false,
            cancelReason:null
        };
    }
    updateInput = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({[name]:value})
    };
    handleClose = (e) => {
        //Reset state
        let state = {
            loading:false,
            error:null,
            returnCanceled:false,
            cancelReason:null
        };
        this.setState({...state})
        this.props.handleClose();
        if(this.props.refetchQuery){
            this.props.refetchQuery();
        }
    };
    cancelReturn = () => {
        try {
            this.setState({loading: true});
            if(!this.state.cancelReason){
                this.setState({loading: false,returnCanceled:false,error:"Please select a reason to cancel the return!"});
                return
            }
            let stage = null
            if (this.props.returnStages && this.props.returnStages.states && this.props.returnStages.states.length>0 && this.props.returnStages.states.filter(stage => stage.description == "Cancelled").length > 0){
                stage = this.props.returnStages.states.filter(stage  => stage.description == "Cancelled")[0].name.replace("A_", "")
            }else{
                this.setState({loading: false,returnCanceled:false,error:"Unable to find cancel stage in return. Please contact admin.!"});
                return
            }
            let input = {
                carReturnId: this.props.carReturnId,
                returnStage: parseInt(stage),
                returnReason: this.state.cancelReason,
            };
            this.props.client.mutate({
                mutation: changeReturnMutation,
                variables: {input},
            }).then((result)=>{
                if(result.data && result.data.changeReturn && result.data.changeReturn.ok){
                    this.setState({loading: false,returnCanceled:true,error:null});
                    this.handleClose();
                }else{
                    this.setState({loading: false,returnCanceled:false,error:"An error has occured. Please contact system admin"});
                }
            }).catch((error)=>{
                let errorMessage = "An error has occured";
                this.setState({error:errorMessage,loading:false,returnCanceled:false,})
            })
        }catch(err) {
            let errorMessage = "An error has occured";
            this.setState({error:errorMessage,loading:false,returnCanceled:false,})
        }
    };

    render() {

        let cancelReasons = ["Other"]
        if (!this.props.loading && this.props.globalSettings && this.props.globalSettings.edges && this.props.globalSettings.edges[0]){
            let returnReasons = JSON.parse(this.props.globalSettings.edges[0].node.settingsValue.replace(/'/g, '"'));
            cancelReasons = cancelReasons && returnReasons.cancelReasons ? Object.keys(returnReasons.cancelReasons) : ["Other"];
        }
        return (
            <div>
                <Modal className="CancelReturn" isOpen={this.props.open}>
                    {this.props.carReturnId &&
                    <>
                        <ModalHeader>Cancel Return</ModalHeader>
                        <ModalBody>
                            {this.state.error && !this.state.returnCanceled &&<Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                            {this.state.returnScheduled && !this.state.error && <Row><Col xs={12}><Alert color="success">Return canceled successfully!</Alert></Col></Row>}
                            {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value={100} /></Col></Row>}
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="cancelReason">Why are you cancelling this return?</Label>
                                        <Input type="select" name="cancelReason" id="cancelReason" defaultValue="default" onChange={this.updateInput} placeholder="Cancel Return Reason">
                                            <option key="-1" value="default" disabled>-- Please select a reason to cancel the return --</option>
                                            {cancelReasons.map((reason,i)=>
                                                <option key={i} value={reason}>{reason}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </>}
                    <ModalFooter>
                        {!this.state.loading &&
                            <Row>
                                <Col xs={12}>
                                    <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    <Button color="danger" onClick={this.cancelReturn} disabled={this.state.loading}>Cancel return</Button>&nbsp;&nbsp;
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default compose(
    withApollo,
    graphql(returnReasons, {
        props({data: { globalSettings }}) { return {globalSettings}}
    }),
)(CancelReturn);