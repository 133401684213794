import gql from "graphql-tag"

export const UpdateActionMutation = gql`mutation UpdateAction($input: UpdateActionMutationInput!){
    updateAction(input:$input){
        ok
        errors{
            messages
        }
    }
} `


export const CreateActionMutation = gql`mutation CreateAction($input: CreateActionMutationInput!){
    createAction(input:$input){
        ok
        errors{
            messages
        }
    }
} `

export const CreateTriggerMutation = gql`mutation CreateTrigger($input: CreateTriggerMutationInput!){
    createTrigger(input:$input){
        ok
        errors{
            messages
        }
    }
} `

export const UpdateTriggerMutation = gql`mutation UpdateTrigger($input: UpdateTriggerMutationInput!){
    updateTrigger(input:$input){
        ok
        errors{
            messages
        }
    }
} `

export const CREATE_AUTOMATION_WORKFLOW = gql`
  mutation createAutomationWorkflow($input: CreateAutomationWorkflowMutationInput!) {
    createAutomationWorkflow(input: $input) {
      ok
      errors {
        messages
        field
      }
      automation {
        id
        name
        isActive
      }
    }
  }
`

export const DeleteActionMutation = gql`
    mutation DeleteAction($input: DeleteActionMutationInput!){
        deleteAction(input:$input){
            ok
            errors{
                messages
            }
        }
    } `

export const DeleteTriggerMutation = gql`
    mutation DeleteTrigger($input: DeleteTriggerMutationInput!){
        deleteTrigger(input:$input){
            ok
            errors{
                messages
            }
        }
    } `

export const UpdateAutomationMutation = gql`
    mutation UpdateAutomation($input: UpdateAutomationMutationInput!){
        updateAutomation(input:$input){
            ok
            errors{
                messages
            }
        }
    } `

export const CreateConditionMutation = gql`
    mutation CreateCondition($input: CreateConditionMutationInput!){
        createCondition(input:$input){
            ok
            errors{
                messages
            }
        }
    } `

export const UpdateConditionMutation = gql`
    mutation UpdateCondition($input: UpdateConditionMutationInput!){
        updateCondition(input:$input){
            ok
            errors{
                messages
            }
        }
    } `

export const DeleteConditionMutation = gql`
    mutation DeleteCondition($input: DeleteConditionMutationInput!){
        deleteCondition(input:$input){
            ok
            errors{
                messages
            }
        }
    } `