import gql from "graphql-tag"

const UserDetailQuery = gql`
query UserQuery{
    currentUser{
      id
      username
      firstName
      lastName
      email
      isSuperuser
      isActive
      dateJoined
      lastLogin
      member {
        id
        globalView
        preferredDateFormat
        preferredTimeFormat
        preferredTimezone
        tenant { id name }
        branches {
          edges { node { id name } }
        }
        defaultBranch{ id name }
      }
    }
}
`

export { UserDetailQuery }