import React, { Component } from 'react';
import { Button, FormGroup, Label, Alert, ButtonDropdown, Input, DropdownToggle, DropdownMenu, DropdownItem, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { CarSearchQuery } from '../Queries';
import {compose, withApollo} from 'react-apollo';
import {CreateCarRegistrationMutation} from '../Mutations';

class InstallCarPopover extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: '',
            carSuggestions: null,
            selectedCar: null,
            showCarFinder: false,
        };
    } 

    handleCarSearch = (e) => {
        if (e.target.value.length >= 3) {
            this.props.client.query({
                query: CarSearchQuery,
                variables: {searchTerm: e.target.value}
            }).then((response) => {
                if (response && response.data && response.data.allCars && response.data.allCars.edges) {
                    this.setState({carSuggestions: response.data.allCars.edges})
                }else{
                    this.setState({carSuggestions: null})
                }
            }).catch((err) => {
                this.setState({carSuggestions: null})
            });
        }else{
            this.setState({carSuggestions: null})
        }
    }

    handleInstallation = () => {
        try {
            this.setState({loading: true, error: ""});
            if(!this.state.selectedCar || !this.props.registrationId){
                throw 'Error: Required fields are missing'
            }
            let input = {
                carId: this.state.selectedCar.id,
                registrationId: this.props.registrationId,
            }
            this.props.client.mutate({
                mutation: CreateCarRegistrationMutation,
                variables: {input},
            }).then((result)=>{
                if(result.data &&  result.data.createCarRegistration && result.data.createCarRegistration.ok){
                    this.setState({loading: false, error:null, showCarFinder: false});
                    this.props.refetchQuery();
                    this.props.handleClose();
                }else{
                    let errorMessage = "An error has occured. Please contact system admin"
                    if(result.data && result.data.createCarRegistration && result.data.createCarRegistration.errors && result.data.createCarRegistration.errors[0] && result.data.createCarRegistration.errors[0].messages[0])
                        errorMessage = result.data.createCarRegistration.errors[0].messages[0]
                    else
                        errorMessage = "An error has occured, Please contact Admin!"
                    this.setState({loading:false, error:errorMessage});
                }
            }).catch((error)=>{
                let errorMessage = "An error has occured"+error;
                this.setState({error:errorMessage,loading:false})
            })
        }catch(err) {
            let errorMessage = "An error has occured "+err;
            this.setState({error:errorMessage,loading:false})
        }
    };

    render() {
        return (<Popover placement={"bottom"} isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
            <PopoverHeader>Install Registration On Car&nbsp;&nbsp;&nbsp;&nbsp;<i style={{float:"right"}} className="fa fa-times-circle" aria-hidden="true" onClick={this.props.handleClose}> </i></PopoverHeader>
            <PopoverBody>
                <FormGroup>
                    <Label for="carId"><small>What's the Car ID? *</small></Label>
                    {this.state.selectedCar ? 
                    <><br/><b>{this.state.selectedCar.pk}</b>&nbsp;&nbsp;&nbsp;<i className="fa fa-times" onClick={()=>this.setState({selectedCar: null, carSuggestions: null})}/> </>
                    :<ButtonDropdown className="w-100" isOpen={this.state.showCarFinder} toggle={()=>this.setState({showCarFinder: !this.state.showCarFinder})}>
                        <DropdownToggle caret>Choose Car For Registration</DropdownToggle>
                        <DropdownMenu className='w-100 px-2'>
                            <Label for="carSearch"><small>Search by vin, car id</small></Label>
                            <Input type="text" onChange={this.handleCarSearch} name="carSearch" id="carSearch"/>
                            {this.state.carSuggestions && this.state.carSuggestions.length > 0 && this.state.carSuggestions.map((car,i)=>
                                <DropdownItem key={i} onClick={()=>this.setState({selectedCar: car.node})} style={{display:"content"}} >{car.node.pk} · {car.node.vin}</DropdownItem>
                                )}
                        </DropdownMenu>
                    </ButtonDropdown>
                    }
                    <br/>
                    {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                    {this.state.loading ? "Loading..." : this.state.selectedCar && <Button className="bos-btn-primary float-right my-3" onClick={()=>this.handleInstallation()}>Install</Button>}
                </FormGroup>
            </PopoverBody>
        </Popover>);
    }
}

export default compose(
    withApollo
)(InstallCarPopover);


