import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import {
    Row, Col, Label, Alert, Button, FormGroup, Input,
    Modal, ModalHeader, ModalBody, ModalFooter, DropdownItem
} from 'reactstrap'
import Select from 'react-select'
import "./BranchListContainer.css"
import { BulkCreateUpdateMember } from "../Mutations"
import { AllBranches, AllReps, MembersQuery } from "../Queries"

class AddMemberModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            parentBranchId: this.props.branchId ? this.props.branchId : null,
            selectedMemberBranchID: "",
            addMemberBy: "byMember",
            loading: false,
            error: "",
            suggestedReps: null,
            selectedMemberIDs: [],
            openModal: "",
            repsName: ""
        }
    }

    addMembers = () => {
        try {
            this.setState({ loading: true, error: "" })
            let input = {
                branchIds: this.state.parentBranchId ? this.state.parentBranchId : "",
            }

            if (this.state.selectedMemberIDs && this.state.addMemberBy === "byMember")
                input["userByIds"] = this.state.selectedMemberIDs ? this.state.selectedMemberIDs.map(rep => rep.id) : []

            if (this.state.selectedMemberBranchID && this.state.addMemberBy === "byBranch")
                input["usersByBranch"] = this.state.selectedMemberBranchID ? this.state.selectedMemberBranchID : ""

            this.props.client.mutate({
                mutation: BulkCreateUpdateMember,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.bulkCreateUpdateMember && result.data.bulkCreateUpdateMember.ok) {
                    this.props.handleSuccess()
                    this.props.handleClose()
                } else {
                    let error = "An error occurred, could not complete request."
                    if (result.data && result.data.bulkCreateUpdateMember && result.data.bulkCreateUpdateMember.errors && result.data.bulkCreateUpdateMember.errors[0] && result.data.bulkCreateUpdateMember.errors[0].messages) {
                        error = result.data.bulkCreateUpdateMember.errors[0].messages.toString()
                    }
                    this.setState({ error: error })
                }
                this.setState({ loading: false })
            }).catch((err) => {
                let error = "An error has occured " + err
                this.setState({ error: error, loading: false })
            })
        }
        catch (err) {
            let error = "An error has occured " + err
            this.setState({ error: error, loading: false })
        }
    }

    renderSelectedBranch = () => {
        let selectedBranch = null
        if (this.props.branchId && this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0) {
            selectedBranch = this.props.allBranches.edges.find(item => item.node.id == this.props.branchId)
            selectedBranch = { value: selectedBranch.node.id, label: selectedBranch.node.name }
        }
        return <Select
            className="bos-custom-select" classNamePrefix="bos-select"
            value={selectedBranch}
            options={this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
            placeholder="Select Branch To Add Members"
            onChange={(branch) => this.setState({ parentBranchId: branch.value })}
            isDisabled={!!this.props.branchId}
        />
    }
    handleUpdateRepSearch = (e) => {
        this.setState({ repsName: e.target.value })
        if (e.target.value === "") {
            this.setState({ suggestedReps: null })
        } else {
            this.props.client.query({
                variables: { username: e.target.value },
                query: AllReps
            }).then(result => {
                if (result && result.data && result.data.reps) {
                    this.setState({ suggestedReps: result.data.reps.edges })
                }
            })
        }
    }

    updateRepsList = (action, selectedRep) => {
        if (action === "add") {
            if (!this.state.selectedMemberIDs.find(rep => rep.id === selectedRep.node.id)) {
                this.setState({ selectedMemberIDs: [...this.state.selectedMemberIDs, selectedRep.node] })
            }
            this.setState({ suggestedReps: null, repsName: "" })
        } else {
            let selectedMemberIDs = this.state.selectedMemberIDs
            selectedMemberIDs = selectedMemberIDs.filter(rep => rep.id !== selectedRep.id)
            this.setState({ selectedMemberIDs: selectedMemberIDs })
        }
    }

    render() {
        return (
            <div className="add-members">
                <Modal isOpen={this.props.open}>
                    <ModalHeader>Add Member/s</ModalHeader>
                    <ModalBody>
                        <Row>
                            {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="notes">Select Branch</Label>
                                    {this.renderSelectedBranch()}
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="notes">How do you want to add members?</Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        options={[{ value: "byMember", label: "Specific Members" }, { value: "byBranch", label: "By Branch" }]}
                                        defaultValue={[{ value: "byMember", label: "Specific Members" }]}
                                        placeholder="How do you want to add members?"
                                        onChange={(type) => this.setState({ addMemberBy: type.value })}
                                    />
                                </FormGroup>
                            </Col>

                            {this.state.addMemberBy === "byBranch" ?
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="notes">Select Branch</Label>
                                        <Select
                                            className="bos-custom-select" classNamePrefix="bos-select"
                                            options={this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.map(item => ({ value: item.node.id, label: item.node.name }))}
                                            placeholder="Search By Branch Name"
                                            isClearable
                                            onChange={(branch) => this.setState({ selectedMemberBranchID: branch.value })}
                                        />
                                    </FormGroup>
                                </Col> :
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="reps">Select Members</Label>
                                        <>
                                            {this.state.selectedMemberIDs.length > 0 &&
                                                <Row>
                                                    <Col xs={12}>
                                                        {this.state.selectedMemberIDs.map((rep) => <>
                                                            {rep.name}&nbsp;&nbsp;<i className="fa fa-times text-danger" onClick={() => this.updateRepsList("remove", rep)}></i> &nbsp;&nbsp;
                                                        </>)}
                                                    </Col>
                                                </Row>
                                            }
                                            <Input
                                                type="text"
                                                onChange={this.handleUpdateRepSearch}
                                                name="repSearch"
                                                id="repSearch"
                                                placeholder="Search By Rep Name"
                                                value={this.state.repsName}
                                            />
                                            <div className="members-options-wrapper">
                                                {this.state.suggestedReps &&
                                                    this.state.suggestedReps.length > 0 &&
                                                    this.state.suggestedReps.map((rep, i) =>
                                                        <DropdownItem key={i}
                                                            onClick={() => this.updateRepsList("add", rep)}
                                                            style={{ display: "content" }}
                                                        >
                                                            {rep.node.name}
                                                        </DropdownItem>
                                                    )}
                                            </div>
                                        </>
                                    </FormGroup>
                                </Col>
                            }
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.addMembers} disabled={this.state.loading}>{this.state.loading ? "Loading..." : "Add Member/s"}</Button>{' '}
                        <Button onClick={this.props.handleClose}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllBranches, { props({ data: { allBranches } }) { return { allBranches } } }),
)(AddMemberModal)
