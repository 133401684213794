import gql from "graphql-tag"

export const CreateTaskMutation = gql`
  mutation createTask($input: CreateTaskMutationInput!) {
    createTask(input: $input) {
      ok
      errors {
        messages
      }
    }
  }
`

export const CreateBoardMutation = gql`
    mutation createBoard($input: CreateBoardMutationInput!) {
        createBoard(input: $input) {
        ok
        errors {
            messages
        }
        }
    }
`

export const UpdateTaskMutation = gql`
    mutation updateTask($input: UpdateTaskMutationInput!) {
        updateTask(input: $input) {
        ok
        errors {
            messages
        }
        }
    }
    `

export const CreateTaskCommentMutation = gql`
    mutation createTaskComment($input: CreateTaskCommentMutationInput!) {
        createTaskComment(input: $input) {
        ok
        errors {
            messages
        }
        }
    }
    `


export const CreateTaskCommentReactionMutation = gql`
    mutation createTaskCommentReaction($input: CreateTaskCommentReactionMutationInput!) {
        createTaskCommentReaction(input: $input) {
        ok
        errors {
            messages
        }
        }
    }
    `

export const DeleteTaskCommentReactionMutation = gql`
    mutation deleteTaskCommentReaction($input: DeleteTaskCommentReactionMutationInput!) {
        deleteTaskCommentReaction(input: $input) {
            ok
            errors { messages }
        }
    }
    `

export const UpdateTaskChecklistMutation = gql`
    mutation updateTaskChecklist($input: UpdateTaskChecklistMutationInput!) {
        updateTaskChecklist(input: $input) {
            ok
            errors { messages }
        }
    }
    `

export const CreateTaskChecklistMutation = gql`
    mutation createTaskChecklist($input: CreateTaskChecklistMutationInput!) {
        createTaskChecklist(input: $input) {
        ok
        errors {
            messages
        }
        }
    }
    `
