

export default function toTitleCase(text) {
    if (text) {
        text = String(text).toLowerCase()
        // text = text.split(' ')
        text = text.split(/[_ -]+/)

        if (Array.isArray(text)) {
            for (var i = 0; i < text.length; i++)
                text[i] = text[i].charAt(0).toUpperCase() + text[i].slice(1)
            return text.join(' ')
        } else {
            return text.charAt(0).toUpperCase() + text.slice(1)
        }
    }
    return ""
}