import React, { Component } from "react"
import { compose } from "react-apollo"
import { withApollo } from "react-apollo/index"
import { Container } from "reactstrap"
import PricingTable from "./PricingTable"
import PromoTable from "./PromoTable"
import './Pricing.css'
import PricingListHeader from "./PricingListHeader"
import RestrictedSection from "../Components/RestrictedSection"

class PricingContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      agreementTypeId: null,
      openModal: "",
      isActive: true,
      promoManageView: false,
      refetchPricing: false,
      filterValues: {
        orderBy: ["is_active"],
        isActive: true,
      },
      selectedPricing: [],
    }
  }

  toggleModal = (name) => {
    this.setState({ openModal: this.state.openModal === name ? "" : name })
  };

  updateOrder = (string) => {
    let orderBy = [...this.state.filterValues.orderBy]
    let index = orderBy.indexOf(string)
    if (index > -1) {
      orderBy[index] = "-" + string
    } else {
      index = orderBy.indexOf("-" + string)
      index > -1 ? orderBy.splice(index, 1) : orderBy.unshift(string)
    }
    this.setState({ filterValues: { ...this.state.filterValues, orderBy } })
  };

  render() {
    return (
      <RestrictedSection requiredPermission="view_pricingtemplate">
        <Container fluid className="pricing-list-container">
          <PricingListHeader
            filterValues={this.state.filterValues}
            setFilterValues={(filterValues) => this.setState({ filterValues })}
            refetchQuery={() => this.setState((prevState) => ({ refetchPricing: !prevState.refetchPricing }))}
            selectedPricing={this.state.selectedPricing} location={this.props.location} history={this.props.history}
            defaultFilters={this.state.filterValues}
            resetSelectedPricing={() => this.setState({ selectedPricing: [] })}
          />
          <PricingTable
            key={String(this.state.refetchPricing)}
            searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
            modelTypeIds={this.state.filterValues && this.state.filterValues.modelTypeId ? this.state.filterValues.modelTypeId : null}
            modelGroupTypeId={this.state.filterValues && this.state.filterValues.modelGroupTypeId ? this.state.filterValues.modelGroupTypeId : null}
            isActive={this.state.filterValues && this.state.filterValues.isActive !== null ? this.state.filterValues.isActive : null}
            isDefault={this.state.filterValues && this.state.filterValues.isDefault !== null ? this.state.filterValues.isDefault : null}
            orderBy={this.state.filterValues.orderBy}
            updateOrder={(headerId) => this.updateOrder(headerId)}
            selectedPricing={this.state.selectedPricing}
            setSelectedPricing={(selectedPricing) => this.setState({ selectedPricing })}
          />
        </Container>
      </RestrictedSection>
    )
  }
}

export default compose(
  withApollo,
)(PricingContainer)
