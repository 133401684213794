import React, { Component } from "react"
import { Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Label, Button, Input, FormFeedback, Badge, Row, Col } from "reactstrap"
import Select from "react-select"
import { withApollo } from "react-apollo/index"
import { compose, graphql } from "react-apollo"
import { AllCarModels, AllAgreementTypes } from "./Queries"
import { CreatePricingTemplateMutation } from './Mutations'
import toTitleCase from "../Functions/toTitleCase"

class AddPricingModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      input: {
        price: null,
        minimumPrice: null,
        maximumPrice: null,
        interval: null,
        intervalUnit: '',
        carYearMin: null,
        carYearMax: null,
        visibleToCustomers: false,
        isDefault: false,
        carModels: null,
      },
      createAnother: false,
      loading: false,
      error: "",
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    let input = this.state.input
    if (!input.price || !input.minimumPrice || !input.maximumPrice || !input.interval || !input.intervalUnit || !input.carModels || !input.agreementType) {
      this.setState({ loading: false, error: "Please provide all required fields, for creating a pricing template!" })
      return
    }
    try {
      input = { ...input, price: parseFloat(input.price), minimumPrice: parseFloat(input.minimumPrice), maximumPrice: parseFloat(input.maximumPrice), interval: parseInt(input.interval), carYearMin: parseInt(input.carYearMin), carYearMax: parseInt(input.carYearMax) }
      this.setState({ loading: true })
      this.props.client.mutate({
        mutation: CreatePricingTemplateMutation,
        variables: { input },
      }).then((result) => {
        if (result.data && result.data.createPricingTemplate && result.data.createPricingTemplate.ok) {
          this.props.refetch()
          if (!this.state.createAnother) {
            this.props.toggleModal()
          }
          this.setState({ loading: false, error: "" })
        } else if (result.data && result.data.createPricingTemplate && !result.data.createPricingTemplate.ok && result.data.createPricingTemplate.errors && result.data.createPricingTemplate.errors[0]) {
          this.setState({ loading: false, error: result.data.createPricingTemplate.errors[0].messages.toString() })
        } else {
          this.setState({ loading: false, error: "Something went wrong, please contact admin for support!" })
        }
      }).catch((err) => {
        this.setState({ loading: false, error: err.message })
      })
    } catch (err) {
      this.setState({ loading: false, error: err })
    }
  };

  handleChange = (e, number = false) => {
    if (e.target.type === "checkbox")
      this.setState({ input: { ...this.state.input, [e.target.name]: !this.state.input[e.target.name] } })
    else if (number) {
      this.setState({ input: { ...this.state.input, [e.target.name]: parseFloat(e.target.value) } })
    }
    else
      this.setState({ input: { ...this.state.input, [e.target.name]: e.target.value } })
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} className="bos-car-attr-modal">
        <ModalHeader>Add New Pricing Templates</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col xs={4}>
                <FormGroup>
                  <Label for="templatePrice">Default Price *</Label>
                  <Input type="number" step="any" name="price" id="templatePrice" placeholder="$ Price" value={this.state.input.price} onChange={(e) => this.handleChange(e, true)} />
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup>
                  <Label for="templateMinimumPrice">Minimum Price *</Label>
                  <Input valid={this.state.input.price && this.state.input.minimumPrice && this.state.input.minimumPrice < this.state.input.price}
                    invalid={this.state.input.price && this.state.input.minimumPrice && this.state.input.minimumPrice > this.state.input.price}
                    type="number" step="any" name="minimumPrice" id="templateMinimumPrice" placeholder="$ Minimum Price"
                    value={this.state.input.minimumPrice} onChange={(e) => this.handleChange(e, true)} />
                  <FormFeedback>Minimum Price must be less than the default Price</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup>
                  <Label for="templatemaximumPrice">Maximum Price *</Label>
                  <Input
                    valid={this.state.input.price && this.state.input.maximumPrice && this.state.input.maximumPrice > this.state.input.price}
                    invalid={this.state.input.price && this.state.input.maximumPrice && this.state.input.maximumPrice < this.state.input.price}
                    type="number" step="any" name="maximumPrice" id="templatemaximumPrice" placeholder="$ Maximum Price"
                    value={this.state.input.maximumPrice} onChange={(e) => this.handleChange(e, true)} />
                  <FormFeedback>Maximum Price must be higher than the default Price</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="title">Pricing Template Title</Label>
                  <Input type="string" name="title" id="title" placeholder="Pricing Template Title" value={this.state.input.title} onChange={this.handleChange} />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="agreementType">Agreement Type *</Label>
                  <Select isLoading={!(this.props.allAgreementTypes && this.props.allAgreementTypes.edges && this.props.allAgreementTypes.edges.length > 0)} options={this.props.allAgreementTypes && this.props.allAgreementTypes.edges && this.props.allAgreementTypes.edges.length > 0 && this.props.allAgreementTypes.edges.map((item) => ({ value: item.node.id, label: item.node.name }))}
                    placeholder="Select Agreement Type" onChange={(agreementType) => this.setState({ input: { ...this.state.input, agreementType: agreementType && agreementType.value ? agreementType.value : null } })} />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="templateInterval">Interval *</Label>
                  <Input type="number" name="interval" id="templateInterval" placeholder="Interval" min="1" max="9" value={this.state.input.interval} onChange={this.handleChange} />
                </FormGroup>
              </Col>
              <Col xs={6}><FormGroup>
                <Label for="templateIntervalUnit">Interval Unit *</Label>
                <Input type="select" name="intervalUnit" id="templateIntervalUnit" placeholder="Interval Unit" value={this.state.input.intervalUnit} onChange={this.handleChange}>
                  <option value='' disabled>Select Interval Unit</option>
                  {['Hour', 'Day', 'Week', 'Month', 'Year', 'Mile', 'Trip'].map(item => <option value={item} disabled={['Hour', 'Year', 'Mile', 'Trip'].includes(item)}>{item}</option>)}
                </Input>
              </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label for="carModel">Car Model *</Label>
                  <Select
                    isLoading={!(this.props.allCarModels && this.props.allCarModels.edges && this.props.allCarModels.edges.length > 0)} isMulti
                    options={this.props.allCarModels && this.props.allCarModels.edges.length > 0 && this.props.allCarModels.edges.filter(item => item.node.make && item.node.name).map((item) => ({
                      value: item.node.id, label: toTitleCase(item.node.make) + " " + item.node.name + (item.node.series ? " | " + item.node.series : "") + (item.node.groupType ? " | " + item.node.groupType.name : " | No Group Selected")
                    }))}
                    placeholder="Select Model" onChange={(carModel) => this.setState({ input: { ...this.state.input, carModels: carModel && carModel.length > 0 ? carModel.map(model => model.value) : null } })} />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="carYearMin">Car Year Min</Label>
                  <Input type="number" name="carYearMin" id="carYearMin" placeholder="Car Year Minimum" min={2000} max={3000} value={this.state.input.carYearMin} onChange={this.handleChange} />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="carYearMax">Car Year Max</Label>
                  <Input type="number" name="carYearMax" id="carYearMax" placeholder="Car Year Maximum" min={this.state.input.carYearMin ? this.state.input.carYearMin : 2000} max={3000} value={this.state.input.carYearMax} onChange={this.handleChange} />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup check>
                  <Label check>
                    <Input name="isDefault" placeholder="Is Default" type="checkbox" onChange={this.handleChange} />
                    {" "}
                    Is Default
                  </Label>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup check>
                  <Label check>
                    <Input name='visibleToCustomers' placeholder="Visible To Customers" type="checkbox" onChange={this.handleChange} />
                    {" "}
                    Visible to Customers
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <br />
            <ModalFooter>
              {this.state.error && <Badge pill='true' style={{ whiteSpace: 'pre-wrap' }} color="danger">{this.state.error}</Badge>}
              {this.state.loading ? "Loading..." : <>
                <FormGroup check>
                  <Label check>
                    <Input name='createAnother' type="checkbox" onChange={() => this.setState({ createAnother: !this.state.createAnother })} />
                    {" "}
                    Create another
                  </Label>
                </FormGroup>
                <Button color="primary" type="submit">Create Price</Button>
              </>
              }
              <Button color="secondary" onClick={() => this.props.toggleModal()}>Close</Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    )
  }
}

export default compose(
  withApollo,
  graphql(AllCarModels, {
    options: { fetchPolicy: "cache-first" },
    props({ data: { loading, allCarModels } }) {
      return { loading, allCarModels }
    },
  }),
  graphql(AllAgreementTypes, {
    options: { fetchPolicy: "cache-first" },
    props({ data: { loading, allAgreementTypes } }) {
      return { loading, allAgreementTypes }
    },
  })
)(AddPricingModal)
