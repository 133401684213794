import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Popover, PopoverBody, PopoverHeader, Button } from 'reactstrap'
import moment from 'moment-timezone'

const RegistrationNotesHistoryQuery = gql`
    query RegistrationNotesHistoryQuery($registrationId:ID!, $noteType:String, $objectId:ID, $first:Int){
        registrationNotesHistory(registrationId:$registrationId, noteType: $noteType, objectId: $objectId, first:$first){
            edges{
                node{
                  details
                  dateAdded
                  user {
                    username
                  }
                }
            }
            pageInfo{
                hasNextPage
            }
      }
    }
`


class RegistrationNotesHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            registrationId: props.registration && props.registration.id ? props.registration.id : null,
            registrationNotesHistory: null,
            loading: false,
            limit: 3,
            hasNextPage: false
        }
    }
    fetchData = (registrationId) => {
        if (registrationId) {
            this.setState({ loading: true })
            let input = { registrationId: registrationId, first: this.state.limit }
            if (this.props.noteType)
                input["noteType"] = this.props.noteType
            else
                input["noteType"] = 'General'
            if (this.props.objectId)
                input["objectId"] = this.props.objectId
            this.props.client.query({
                query: RegistrationNotesHistoryQuery,
                variables: input,
            }).then((result) => {
                if (result && result.data && result.data.registrationNotesHistory) {
                    let edges = result.data.registrationNotesHistory.edges
                    let hasNextPage = false
                    if (result.data.registrationNotesHistory.pageInfo && result.data.registrationNotesHistory.pageInfo.hasNextPage) {
                        hasNextPage = result.data.registrationNotesHistory.pageInfo.hasNextPage
                    }
                    this.setState({ registrationNotesHistory: edges, hasNextPage: hasNextPage, limit: this.state.limit + 3, loading: false })
                }
            }).catch((err) => {
                this.setState({ loading: false })
            })
        } else {
        }
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.registration !== prevProps.registration || (this.props.registration && prevProps.open !== this.props.open)) {
            this.fetchData(this.props.registration.id)
        }
    }
    componentDidMount() {
        if (this.props.registration) {
            this.fetchData(this.props.registration.id)
        }
    }

    render() {
        return (
            <div>
                <Popover placement="right" isOpen={this.props.open} target={this.props.target}>
                    <PopoverHeader onClick={this.props.handleClose}>Registration Notes <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                    <PopoverBody>
                        {this.state.loading ?
                            <p>Loading Registration Notes...</p> :
                            <>
                                {this.state.registrationNotesHistory && this.state.registrationNotesHistory.map((note, i) =>
                                    <p key={i}><b>On {moment(note.node.dateAdded).tz("America/New_York").format("dddd, MMMM Do YYYY, h:mm a")}&nbsp;&nbsp;</b>{note.node.details}</p>
                                )}
                                {this.state.hasNextPage && this.state.registrationId &&
                                    <Button type="button" size="sm" onClick={() => this.fetchData(this.state.registrationId)}>Show More</Button>
                                }
                            </>
                        }

                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

export default compose(
    withApollo,
)(RegistrationNotesHistory)