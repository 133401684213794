import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Button, Alert, Row, Col, Label, Badge, Modal, ModalBody, ModalHeader, ModalFooter, UncontrolledTooltip, Nav, NavItem, NavLink } from 'reactstrap'
import DatetimeRenderer from "../Material/DatetimeRenderer"
import UpdateLeadInfo from "./UpdateLeadInfo"
import { CustomerLeadQuery } from "./Queries"
import Languages from "../Material/Language.json"
import DocumentViewer from "../Material/DocumentViewer"
import ProcessInsuranceModal from "../Material/ProcessInsuranceModal"
import toTitleCase from "../Functions/toTitleCase"
import UpdateDriverInfo from "../ApplicationsNew/UpdateDriverInfo"
import "./LeadPreviewModal.css"
import "../Material/DocumentModal/DocumentList.css"
import DocumentListContainer from "../Material/DocumentModal/DocumentListContainer"
import AddToDidntTakeCar from "../Material/AddToDidntTakeCar"
import { getMvrReportMutation } from "../Drivers/Driver/Detail/Mutations"
import ConfirmationPopup from "../Material/ConfirmationPopup"
import InteractionsContainer from "../Drivers/Interactions/InteractionsContainer"
import CustomerHandleDetailModal from "../Material/CustomerHandleDetailModal"
import { HasPermissionsQuery } from "../Functions/querys"
import RelatedProfileContainer from "../Material/RelatedProfiles/RelatedProfileContainer"
class LeadPreviewModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: "",
            openModal: "",
            documentUrl: "",
            openSection: ["lead"],
            runMvrLoading: false,
            actionSuccess: false,
            tab: "LeadTab",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }

    getLanguageVal(langCode) {
        if (Languages.hasOwnProperty(langCode)) {
            return `${Languages[langCode].name} - ${Languages[langCode].nativeName}`
        } else {
            return null
        }
    }

    refetchQuery = () => {
        this.props.refetchLeadsQuery()
        this.props.refetchAllCustomerLeadsQuery()
    }

    toggleSection = (section) => {
        if (this.state.openSection.includes(section)) {
            this.setState({ openSection: this.state.openSection.filter((sec) => sec !== section) })
        } else {
            this.setState({ openSection: [...this.state.openSection, section] })
        }
    }

    getMvrReport = () => {
        this.setState({ runMvrLoading: true, error: "" })
        let input = { driverId: this.props.customerLead && this.props.customerLead.customer ? this.props.customerLead.customer.id : "" }
        this.props.client.mutate({
            mutation: getMvrReportMutation,
            variables: { input }
        }).then((result) => {
            if (result.data && result.data.getMvrReport && result.data.getMvrReport.ok && result.data.getMvrReport.reportData) {
                var a = window.document.createElement('a')
                a.href = "data:application/pdf;base64," + result.data.getMvrReport.reportData
                a.download = 'mvr.pdf'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                this.setState({ runMvrLoading: false, actionSuccess: true })
            } else if (result.data && result.data.getMvrReport && result.data.getMvrReport.errors && result.data.getMvrReport.errors[0] && result.data.getMvrReport.errors[0].messages) {
                let error = result.data.getMvrReport.errors[0].messages.toString()
                this.setState({ runMvrLoading: false, error: error, actionSuccess: false })
            } else {
                this.setState({ runMvrLoading: false, error: "An error has occured while running the MVR, please contact admin.", actionSuccess: false })
            }
        })
    };

    handleClose = () => {
        this.setState({ openModal: "" })
        this.props.handleClose()
    }

    fetchMetaData = (metadata = null, name) => {
        console.log("metadata", metadata)
        if (!metadata || metadata === "")
            return
        try {
            metadata = JSON.parse(metadata.replace(/'/g, '"'))
        } catch {
            metadata = null
        }
        return metadata && metadata[name] ? metadata[name] : null
    }

    componentDidUpdate(prevProps) {
        if (this.props.customerLead !== prevProps.customerLead && this.props.customerLead && this.props.customerLead.status && ("Onboarding" === toTitleCase(this.props.customerLead.status) || this.props.customerLead.isArchived)) {
            this.setState({ openSection: ["customer"] })
        }
    }

    render() {
        return (
            <Modal className="lead-preview-modal" isOpen={this.props.open}>
                <ModalHeader className="title" >
                    {this.props.customerLead && this.props.customerLead.fullName ? this.props.customerLead.fullName : "--"}&nbsp;<Badge className='bos-badge-blue'>{this.props.customerLead && this.props.customerLead.branch ? this.props.customerLead.branch.name : "--"}</Badge>
                </ModalHeader>
                <ModalBody>
                    <Nav tabs className="lead-preview-modal-nav">
                        <NavItem className="lead-preview-modal-nav-item">
                            <NavLink className={this.state.tab === "LeadTab" ? "active" : ""} onClick={() => this.setState({ tab: "LeadTab" })}>
                                Lead Info&nbsp;&nbsp;<i className="fa fa-user-o" aria-hidden="true"></i>
                            </NavLink>
                        </NavItem>
                        <NavItem className="lead-preview-modal-nav-item">
                            <NavLink className={this.state.tab === "RelatedProfilesTab" ? "active" : ""} onClick={() => this.setState({ tab: "RelatedProfilesTab" })}>
                                Related Profiles&nbsp;&nbsp;<i className="fa fa-users" aria-hidden="true"></i>
                            </NavLink>
                        </NavItem>
                        <NavItem className="lead-preview-modal-nav-item">
                            <NavLink className={this.state.tab === "ChatTab" ? "active" : ""} onClick={() => this.setState({ tab: "ChatTab" })}>
                                Chat&nbsp;&nbsp;<i className="fa fa-comments-o" aria-hidden="true"></i>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <br />
                    {this.state.tab === "RelatedProfilesTab" &&
                        <RelatedProfileContainer
                            leadId={this.props.customerLead && !this.props.customerLead.customer ? this.props.customerLead.id : ""}
                            driverId={this.props.customerLead && this.props.customerLead.customer && this.props.customerLead.customer.id ? this.props.customerLead.customer.id : ""}
                            currentUser={this.props.currentUser}
                            basedOnTenant={false}
                            dmvLicense={this.props.customerLead && this.props.customerLead.metadata && this.props.customerLead.status && ["Need Review"].includes(toTitleCase(this.props.customerLead && this.props.customerLead.status)) ? this.fetchMetaData(this.props.customerLead.metadata, "dmv_license_id") ? this.fetchMetaData(this.props.customerLead.metadata, "dmv_license_id") : "" : this.props.customerLead && this.props.customerLead.customer && this.props.customerLead.customer.dmvLicense ? this.props.customerLead.customer.dmvLicense : ""}
                            listingType={"leads"}
                            isBlocked={["Need Review"].includes(toTitleCase(this.props.customerLead && this.props.customerLead.status)) ? this.props.customerLead.isBlocked : false}
                        />
                    }
                    {this.state.tab === "ChatTab" && <><InteractionsContainer driverId={this.props.customerLead && this.props.customerLead.customer && this.props.customerLead.customer.id} /></>}
                    {this.state.tab === "LeadTab" && this.props.customerLead && <>
                        <Label className={`toggle-section-heading ${this.state.openSection.includes("lead") ? "active" : ""}`} onClick={() => this.toggleSection("lead")}>Lead Info {this.state.openSection.includes("lead") ? <i className="fa fa-minus" aria-hidden="true"></i> : <i className="fa fa-plus" aria-hidden="true"></i>}</Label>
                        {this.state.openSection.includes("lead") && <>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col xs={4}>
                                    <Label>Date Added</Label>
                                    <p>{this.props.customerLead.dateAdded ? <DatetimeRenderer datetime={this.props.customerLead.dateAdded ? this.props.customerLead.dateAdded : "---"} /> : "---"}</p>
                                </Col>
                                <Col xs={4}>
                                    <Label>Date Modified</Label>
                                    <p>{this.props.customerLead.dateModified ? <DatetimeRenderer datetime={this.props.customerLead.dateModified ? this.props.customerLead.dateModified : "---"} /> : "---"}</p>
                                </Col>
                                <Col xs={4}>
                                    <Label>Status {!this.props.customerLead.isArchived && ["Open", "Contacted"].includes(toTitleCase(this.props.customerLead.status)) && <i id={"statusEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("statusEdit")}></i>}</Label>
                                    <p>{this.props.customerLead ? toTitleCase(this.props.customerLead.status) : "--"}</p>
                                    {this.state.openModal === "statusEdit" &&
                                        <UpdateLeadInfo
                                            id={this.props.customerLead ? this.props.customerLead.id : "--"}
                                            isOpen={this.state.openModal === "statusEdit"}
                                            type="select"
                                            handleClose={() => this.toggleModal("statusEdit")}
                                            name="status"
                                            target={"statusEdit"}
                                            title="Update Lead Status"
                                            refetchQuery={this.refetchQuery}
                                            defaultValue={this.props.customerLead.status ? { value: this.props.customerLead.status, label: this.props.customerLead.status } : null}
                                        />
                                    }
                                </Col>

                            </Row>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col xs={4}>
                                    <Label>Full Name</Label>
                                    <p>{this.props.customerLead.fullName ? this.props.customerLead.fullName : "---"}</p>
                                </Col>
                                <Col xs={4}>
                                    <Label>Email {this.props.customerLead.hasDuplicateEmail && <i className="fa fa-exclamation-circle text-danger" />} {!this.props.customerLead.isArchived && ["Need Review"].includes(toTitleCase(this.props.customerLead.status)) && <i id={"emailEdit" + this.props.customerLead.pk} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("emailEdit" + this.props.customerLead.pk)}></i>}</Label>
                                    <p>{this.props.customerLead.email ? this.props.customerLead.email : "--"}</p>
                                    {this.state.openModal === "emailEdit" + this.props.customerLead.pk &&
                                        <UpdateLeadInfo
                                            id={this.props.customerLead ? this.props.customerLead.id : "--"}
                                            isOpen={this.state.openModal === "emailEdit" + this.props.customerLead.pk}
                                            type="text"
                                            handleClose={() => this.toggleModal("emailEdit" + this.props.customerLead.pk)}
                                            name="email"
                                            target={"emailEdit" + this.props.customerLead.pk}
                                            title="Update Email"
                                            refetchQuery={this.refetchQuery}
                                            defaultValue={this.props.customerLead.email ? this.props.customerLead.email : null}
                                        />
                                    }
                                </Col>
                                <Col xs={4}>
                                    <Label>Phone {this.props.customerLead.hasDuplicatePhone && <i className="fa fa-exclamation-circle text-danger" />} {!this.props.customerLead.isArchived && ["Need Review"].includes(toTitleCase(this.props.customerLead.status)) && <i id={"phoneEdit" + this.props.customerLead.pk} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("phoneEdit" + this.props.customerLead.pk)}></i>}</Label>
                                    <p>{this.props.customerLead.phone ? this.props.customerLead.phone : "--"}</p>
                                    {this.state.openModal === "phoneEdit" + this.props.customerLead.pk &&
                                        <UpdateLeadInfo
                                            id={this.props.customerLead ? this.props.customerLead.id : "--"}
                                            isOpen={this.state.openModal === "phoneEdit" + this.props.customerLead.pk}
                                            type="text"
                                            handleClose={() => this.toggleModal("phoneEdit" + this.props.customerLead.pk)}
                                            name="phone"
                                            target={"phoneEdit" + this.props.customerLead.pk}
                                            title="Update Phone"
                                            refetchQuery={this.refetchQuery}
                                            defaultValue={this.props.customerLead.phone ? this.props.customerLead.phone : null}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col xs={4}>
                                    <Label>Lead Type <i id={"editLeadType"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("editLeadType")}></i></Label>
                                    <p><Badge className='bos-badge-blue'>{this.props.customerLead.leadType ? this.props.customerLead.leadType : "--"}</Badge></p>
                                    {this.state.openModal === "editLeadType" &&
                                        <UpdateLeadInfo
                                            id={this.props.customerLead ? this.props.customerLead.id : "--"}
                                            isOpen={this.state.openModal === "editLeadType"}
                                            type="select"
                                            handleClose={() => this.toggleModal("editLeadType")}
                                            name="leadType"
                                            target={"editLeadType"}
                                            title="Update Lead Type"
                                            refetchQuery={this.refetchQuery}
                                            defaultValue={this.props.customerLead.leadType ? { value: this.props.customerLead.leadType, label: this.props.customerLead.leadType } : null}
                                        />
                                    }
                                </Col>
                                <Col xs={4}>
                                    <Label>Source</Label>
                                    <p>{this.props.customerLead.source ? this.props.customerLead.source : "--"}</p>
                                </Col>
                                <Col xs={4}>
                                    <Label>Reffered By</Label>
                                    <p>{this.props.customerLead ? this.props.customerLead.referredBy : "---"}</p>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col xs={4}>
                                    <Label>Assigned Rep <i id={"editAssignedReps"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("editAssignedReps")}></i></Label>
                                    <p>{this.props.customerLead.assignedRep ? this.props.customerLead.assignedRep.username : "--"}</p>
                                    {this.state.openModal === "editAssignedReps" &&
                                        <UpdateLeadInfo
                                            id={this.props.customerLead ? this.props.customerLead.id : "--"}
                                            isOpen={this.state.openModal === "editAssignedReps"}
                                            type="select"
                                            handleClose={() => this.toggleModal("editAssignedReps")}
                                            name="assignedRep"
                                            target={"editAssignedReps"}
                                            title="Update Assigned Rep"
                                            groups={["Sales Team"]}
                                            refetchQuery={this.refetchQuery}
                                            defaultValue={this.props.customerLead.assignedRep ? { value: this.props.customerLead.assignedRep.id, label: this.props.customerLead.assignedRep.username } : null}
                                        />
                                    }
                                </Col>
                                <Col xs={4}>
                                    <Label>Message</Label>
                                    <p>{this.props.customerLead ? this.props.customerLead.message : "--"}</p>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col>
                                    <Label>Notes <i id={"notesEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("notesEdit")}></i></Label>
                                    <p>{this.props.customerLead ? this.props.customerLead.notes : "--"}</p>
                                    {this.state.openModal === "notesEdit" &&
                                        <UpdateLeadInfo
                                            id={this.props.customerLead ? this.props.customerLead.id : "--"}
                                            isOpen={this.state.openModal === "notesEdit"}
                                            type="text"
                                            handleClose={() => this.toggleModal("notesEdit")}
                                            name="notes"
                                            target={"notesEdit"}
                                            title="Update Lead Notes"
                                            refetchQuery={this.refetchQuery}
                                            defaultValue={this.props.customerLead ? this.props.customerLead.notes : "--"}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row>
                                {this.props.customerLead.documents && this.props.customerLead.documents.map((document, index) =>
                                    <Col xs={6} className="document-box">
                                        <Row className={"document-main" + (this.state.openModal === ("details" + index) ? " active" : "")}>
                                            <Col xs={10}>
                                                <Label className={this.state.openModal === ("details" + index) ? "selected-document" : "not-selected-document"} id={"editDocumentType" + index}>Document Type</Label>
                                                <p className={this.state.openModal === ("details" + index) ? "selected-document" : "not-selected-document" + "columnContent"}>
                                                    {document.documentType ? document.documentType : "Document"}
                                                </p>
                                            </Col>
                                            <Col xs={2} className="text-right pt-3">
                                                <span className="arrow-up-down-button" onClick={() => this.toggleModal("details" + index)}><i className={this.state.openModal === ("details" + index) ? "fa fa-chevron-up" : "fa fa-chevron-down"} aria-hidden="true" size={20}></i></span>
                                            </Col>
                                        </Row>
                                        {this.state.openModal === ("details" + index) &&
                                            <Row className="document-popup">
                                                <Col xs={12} className="document-popup-button-container">
                                                    <Button><a style={{ color: "#007bff" }} href={document.documentUrl} target="_blank">DOWNLOAD&nbsp;<i className="fa fa-download" aria-hidden="true"></i></a></Button>&nbsp;
                                                    <Button style={{ color: "#007bff" }} onClick={() => this.setState({ documentURL: document.documentUrl.replace("&export=download", ""), openModal: "viewDocument" + index })}>PREVIEW&nbsp;<i className="fa fa-eye" aria-hidden="true"></i> </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                                </Col>
                                            </Row>
                                        }
                                        {this.state.openModal === "viewDocument" + index &&
                                            <DocumentViewer documentName={this.state.documentName} documentURL={this.state.documentURL} handleClose={() => this.toggleModal("")} />
                                        }
                                    </Col>
                                )}
                            </Row></>}
                        {this.props.customerLead && this.props.customerLead.customer && <>
                            <hr />
                            <Label className={`toggle-section-heading ${this.state.openSection.includes("customer") ? "active" : ""}`} onClick={() => this.toggleSection("customer")}>Driver Info {this.state.openSection.includes("customer") ? <i className="fa fa-minus" aria-hidden="true"></i> : <i className="fa fa-plus" aria-hidden="true"></i>}</Label>
                            {this.state.openSection.includes("customer") && <>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col xs={4}>
                                        <Label>First Name</Label>
                                        <p>{this.props.customerLead.customer.firstName ? this.props.customerLead.customer.firstName : "---"}</p>
                                    </Col>
                                    <Col xs={4}>
                                        <Label>Last Name</Label>
                                        <p>{this.props.customerLead.customer.lastName ? this.props.customerLead.customer.lastName : "---"}</p>
                                    </Col>
                                    <Col xs={4}>
                                        {this.state.openModal === "dmvLicenseEdit" && <UpdateDriverInfo driverId={this.props.customerLead.customer.id} title="Edit DMV License" name="dmvLicense" value={this.props.customerLead.customer.dmvLicense} target="dmvLicenseEdit" id="dmvLicense" type="text" open={this.state.openModal === "dmvLicenseEdit"} handleClose={() => this.toggleModal("")} refetchDriverQuery={this.refetchQuery} />}
                                        <Label>DMV License <i id={"dmvLicenseEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("dmvLicenseEdit")}></i></Label>
                                        <p>{this.props.customerLead.customer.dmvLicense ? this.props.customerLead.customer.dmvLicense : "---"}</p>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "10px" }}>
                                    {this.props.customerLead.customer.isTlc && <Col xs={4}>
                                        {this.state.openModal === "tlcLicenseEdit" && <UpdateDriverInfo driverId={this.props.customerLead.customer.id} title="Edit TLC License" name="tlcLicense" value={this.props.customerLead.customer.tlcLicense} target="tlcLicenseEdit" id="tlcLicense" type="text" open={this.state.openModal === "tlcLicenseEdit"} handleClose={() => this.toggleModal("")} refetchDriverQuery={this.refetchQuery} />}
                                        <Label>TLC License <i id={"tlcLicenseEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("tlcLicenseEdit")}></i></Label>
                                        <p>{this.props.customerLead.customer.tlcLicense ? this.props.customerLead.customer.tlcLicense : "---"}</p>
                                    </Col>}
                                    <Col xs={4}>
                                        {this.state.openModal === "emailEdit" &&
                                            <CustomerHandleDetailModal
                                                handleType={["email"]}
                                                customerId={this.props.customerLead && this.props.customerLead.customer ? this.props.customerLead.customer.id : "--"}
                                                open={this.state.openModal === "emailEdit"}
                                                handleClose={() => this.toggleModal("")}
                                                refetchQuery={this.refetchQuery}
                                            />
                                        }
                                        <Label>Email <i id={"emailEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("emailEdit")}></i></Label>
                                        <p>{this.props.customerLead.customer.email ? this.props.customerLead.customer.email : "---"}</p>
                                    </Col>
                                    <Col xs={4}>
                                        {this.state.openModal === "phoneEdit" &&
                                            <CustomerHandleDetailModal
                                                handleType={["phone"]}
                                                customerId={this.props.customerLead && this.props.customerLead.customer ? this.props.customerLead.customer.id : "--"}
                                                open={this.state.openModal === "phoneEdit"}
                                                handleClose={() => this.toggleModal("")}
                                                refetchQuery={this.refetchQuery}
                                            />
                                        }
                                        <Label>Phone <i id={"phoneEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("phoneEdit")}></i></Label>
                                        <p>{this.props.customerLead.customer.phone ? this.props.customerLead.customer.phone : "---"}</p>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col xs={4}>
                                        {this.state.openModal === "dobEdit" && <UpdateDriverInfo driverId={this.props.customerLead.customer.id} title="Edit DOB" name="dob" value={this.props.customerLead.customer.dob} target={"dobEdit"} id="dob" type="date" open={this.state.openModal === "dobEdit"} handleClose={() => this.toggleModal("")} refetchDriverQuery={this.refetchQuery} />}
                                        <Label>DOB <i id={"dobEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("dobEdit")}></i></Label>
                                        <p>{this.props.customerLead.customer.dob ? this.props.customerLead.customer.dob : "---"}</p>
                                    </Col>
                                    <Col xs={4}>
                                        <Label>Age</Label>
                                        <p>{this.props.customerLead.customer.age ? this.props.customerLead.customer.age : "---"}</p>
                                    </Col>
                                    <Col xs={4}>
                                        {this.state.openModal === "genderEdit" && <UpdateDriverInfo driverId={this.props.customerLead.customer.id} title="Edit Gender" name="gender" value={this.props.customerLead.customer.gender} target="genderEdit" id="gender" type="gender" open={this.state.openModal === "genderEdit"} handleClose={() => this.toggleModal("")} refetchDriverQuery={this.refetchQuery} />}
                                        <Label>Gender <i id={"genderEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("genderEdit")}></i></Label>
                                        <p>{this.props.customerLead.customer.gender ? this.props.customerLead.customer.gender === "O" ? "Other" : this.props.customerLead.customer.gender === "F" ? "Female" : "Male" : "N/A"}</p>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "10px" }}>
                                    <Col xs={4}>
                                        {this.state.openModal === "stateEdit" && <UpdateDriverInfo driverId={this.props.customerLead.customer.id} title="Edit State" name="state" value={this.props.customerLead.customer.state} target={"stateEdit"} id="state" type="text" open={this.state.openModal === "stateEdit"} handleClose={() => this.toggleModal("")} refetchDriverQuery={this.refetchQuery} />}
                                        <Label>State <i id={"stateEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("stateEdit")}></i></Label>
                                        <p>{this.props.customerLead.customer.state ? this.props.customerLead.customer.state : "---"}</p>
                                    </Col>
                                    <Col xs={4}>
                                        {this.state.openModal === "cityEdit" && <UpdateDriverInfo driverId={this.props.customerLead.customer.id} title="Edit City (Search Ex. New york)" selectedState={this.props.customerLead.customer.state} name="city" value={this.props.customerLead.customer.city} target={"cityEdit"} id="city" type="text" open={this.state.openModal === "cityEdit"} handleClose={() => this.toggleModal("")} refetchDriverQuery={this.refetchQuery} />}
                                        <Label>City <i id={"cityEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("cityEdit")}></i></Label>
                                        <p>{this.props.customerLead.customer.city ? this.props.customerLead.customer.city : "--"}</p>
                                    </Col>
                                    <Col xs={4}>
                                        {this.state.openModal === "addressEdit" && <UpdateDriverInfo driverId={this.props.customerLead.customer.id} title="Edit Address (Search: 445 Empire Blvd, Brooklyn NY, 11225)" name="streetAddress" value={this.props.customerLead.customer.streetAddress} target="addressEdit" id="streetAddress" type="text" open={this.state.openModal === "addressEdit"} handleClose={() => this.toggleModal("")} refetchDriverQuery={this.refetchQuery} />}
                                        <Label>Street Address <i id={"addressEdit"} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("addressEdit")}></i></Label>
                                        <p>{this.props.customerLead.customer.streetAddress ? this.props.customerLead.customer.streetAddress : "---"}</p>
                                    </Col>
                                </Row>
                                <div style={{ padding: "10px" }}>
                                    <DocumentListContainer
                                        colSize={"12"}
                                        hideFilters
                                        id={this.props.customerLead.customer.id}
                                        objectType={"driver"}
                                        currentUser={this.props.currentUser}
                                    />
                                </div>
                            </>}
                        </>}
                    </>}
                </ModalBody>
                <ModalFooter style={{ width: "100%" }}>
                    <Col className="d-flex justify-content-start">
                        {this.props.customerLead && "Need Review" !== toTitleCase(this.props.customerLead.status) && !this.props.customerLead.isArchived && this.props.customerLead.customer && this.props.customerLead.customer.id &&
                            <Button className="lead-preview-modal-danger-btn" onClick={() => this.toggleModal("AddToDidntTakeCar")}>Add To Didn't Take Car</Button>
                        }
                        <AddToDidntTakeCar handleClose={() => this.toggleModal("")} open={this.state.openModal === "AddToDidntTakeCar"} driverId={this.props.customerLead && this.props.customerLead.customer && this.props.customerLead.customer.id} refetchQuery={this.refetchQuery} />
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button className="lead-preview-modal-secondary-btn" onClick={this.props.handleClose}>Close</Button>
                        {this.props.customerLead && this.props.customerLead.customer && "Onboarding" === toTitleCase(this.props.customerLead.status) && <>
                            {this.props.customerLead && this.props.customerLead.customer && this.props.customerLead.customer.driverinsurancestatusSet && this.props.customerLead.customer.driverinsurancestatusSet.edges &&
                                this.props.customerLead.customer.driverinsurancestatusSet.edges.length >= 0 && !this.props.customerLead.customer.driverinsurancestatusSet.edges.filter(item => ["A_1", "A_3"].includes(item.node.status)).length > 0 &&
                                (this.props.customerLead.customer.canProcessInsurance || (this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_create_insurance_before_denial_period"))) &&
                                <>&nbsp;&nbsp;<Button onClick={() => this.toggleModal("ProcessApplicationButton")} id="ProcessApplicationButton" className={"lead-preview-modal-primary-btn"} disabled={this.props.customerLead && (this.props.customerLead.hasDuplicateEmail || this.props.customerLead.hasDuplicatePhone)}>{(this.props.customerLead.hasDuplicateEmail || this.props.customerLead.hasDuplicatePhone) ? "Duplicate Phone Or Email" : "Process Insurance"}</Button></>
                            }
                            &nbsp;&nbsp;<Button className="lead-preview-modal-primary-btn" onClick={() => this.toggleModal("runDriverMVR")} id={"runDriverMVR"}>Run MVR</Button>
                            <UncontrolledTooltip placement="top" target={"runDriverMVR"}>Genarate Driver MVR</UncontrolledTooltip>
                            {this.state.openModal === "runDriverMVR" &&
                                <ConfirmationPopup
                                    open={this.state.openModal === "runDriverMVR"}
                                    message={"ARE YOU SURE YOU WANT TO RUN MVR?"}
                                    loading={this.state.runMvrLoading}
                                    handleClose={() => this.toggleModal("")}
                                    action={"Yes"}
                                    confirmAction={this.getMvrReport}
                                    color="danger"
                                    error={this.state.error ? this.state.error : ""}
                                    success={this.state.actionSuccess ? "Action Performed Successfully" : ""}
                                />
                            }
                        </>
                        }
                        {this.state.openModal === "ProcessApplicationButton" &&
                            <ProcessInsuranceModal handleClose={() => this.toggleModal("ProcessApplicationButton")} open={this.state.openModal === "ProcessApplicationButton"} id={this.props.customerLead && this.props.customerLead.customer ? this.props.customerLead.customer.id : ""} refetchQuery={this.refetchLeadsQuery} />
                        }
                    </Col>
                </ModalFooter>
            </Modal>
        )
    }
}

export default compose(
    withApollo,
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["custom_can_create_insurance_before_denial_period"] }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, }),
        props({ data: { hasPermissions, variables } }) {
            return {
                hasPermissions, variables,
            }
        }
    }),
    graphql(CustomerLeadQuery, {
        options: ({ id }) => ({ variables: { id } }),
        props({ data: { customerLead, loading, fetchMore, variables } }) {
            return {
                customerLead, loading,
                refetchLeadsQuery: () => {
                    return fetchMore({
                        query: CustomerLeadQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return {
                                customerLead: fetchMoreResult.customerLead
                            }
                        },
                    })
                },
            }
        }
    }),
)(LeadPreviewModal)

