import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import {
    Row, Col, Alert, Button,
    Input, Modal, ModalHeader, ModalBody, ModalFooter, FormText
} from 'reactstrap'
import './NotificationsList.css'
import StyledToast from '../Components/StyledToast'

const sendMessageMutation = gql`
mutation sendMessage($driverIDs:[ID]!, $messageBody:String!, $subject:String!){
  sendMessage(input:{drivers:$driverIDs, messageText: $messageBody, messageSubject: $subject}){
    ok
  }
}`


class NotificationsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
        }
    }
    handleClose = (e) => {
        //Reset state
        let state = {
        }
        this.setState({ ...state })
        this.props.handleClose()
    };
    sendReport = () => {
        // try {
        //     this.setState({loading: true});
        //     let input = {
        //         driverIDs: ["niyi@joinbuggy.com"],
        //         messageBody: this.state.messageBody,
        //         subject: "DB Issue"
        //     };
        //     if(this.props.currentUser){
        //         let from_ = "From: "+this.props.currentUser.username+"<br/>"
        //         let email = "From: "+this.props.currentUser.email+"<br/>"
        //         input["messageBody"] = from_+email+input["messageBody"]
        //     }
        //     this.props.client.mutate({
        //         mutation: sendMessageMutation,
        //         variables: input,
        //     }).then((result)=>{
        //         this.setState({loading : false});
        //         if(result.data && result.data.sendMessage.ok){
        //             this.setState({messageSent:true,messageBody:""});
        //             this.handleClose&&this.handleClose();
        //         }else{
        //             this.setState({loading: false,messageSent:false,error:"An error has occured. Please contact system admin"});
        //         }
        //     }).catch((error)=>{
        //         let errorMessage = "An error has occured";
        //         this.setState({error:errorMessage,loading:false,messageSent:false})
        //     })
        // }catch(err) {
        //     let errorMessage = "An error has occured";
        //     this.setState({error:errorMessage,loading:false,messageSent:false})
        // }
    };

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        // To change string inputs to boolean because only strings can be passed to html options
        if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        this.setState({ [name]: value })
    };

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        return (
            <Modal className="NotificationsListModal" isOpen={this.props.open} toggle={this.props.handleClose}>
                <ModalHeader>Hi, you have 3 open items on your list</ModalHeader>
                <ModalBody>
                    {this.state.error && !this.state.messageSent && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                    <Row>
                        <Col xs={12} className="notificationContent">
                            <StyledToast title="Created by John">
                                Charge driver<br />SCHEDULED FOR: <strong>07-05/2020 10PM</strong>
                            </StyledToast>
                        </Col>
                        <Col xs={12} className="notificationContent">
                            <StyledToast title="Created by John">
                                Charge driver<br />SCHEDULED FOR: <strong>07-05/2020 10PM</strong>
                            </StyledToast>
                        </Col>
                        <Col xs={12} className="notificationContent">
                            <StyledToast title="Created by John">
                                Charge driver<br />SCHEDULED FOR: <strong>07-05/2020 10PM</strong>
                            </StyledToast>
                        </Col>
                        <Col xs={12} className="notificationContent">
                            <StyledToast title="Created by John">
                                Charge driver<br />SCHEDULED FOR: <strong>07-05/2020 10PM</strong><br /><span>MARK AS COMPLETE</span>
                            </StyledToast>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {!this.state.loading &&
                        <Row>
                            <Col xs={12}>
                                <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                            </Col>
                        </Row>
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

export default compose(
    withApollo,
)(NotificationsList)