import React, { Component } from 'react'
import gql from "graphql-tag"
import { compose } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import MassMessageModal from "../../Material/MassMessageModal"
import ListingFilters from '../../Material/ListingFilters'
import { debounce } from 'lodash'
import toTitleCase from "../../Functions/toTitleCase"
import { Popover, PopoverBody, PopoverHeader, Row, Col, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap"
import moment from "moment"
import { DateRange } from "react-date-range"
import RepAssignmentModal from "../../Material/RepAssignmentModal"
import renderEnumToInt from "../../Functions/renderEnumToInt"
import updateFilters from "../../Functions/updateFilters"
import handleConfigurationChange from "../../Functions/handleConfigurationChange"
import getFilterValuesFromQueryString from "../../Functions/getFilterValuesFromQueryString"

const Statuses = gql`
    query Statuses{
        optionsList:__type(name: "SubscriptionStatus") {
            states: enumValues {
                name
                description
            }
        }
    }
`
const SubscriptionTypes = gql`
    query SubscriptionTypes {
        optionsList: allSubscriptionTypes{
            edges {
                node {
                    id
                    name
                    description
                }
        }}
    }
`

class SubscriptionsListHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            configurations: null,
            filterValues: this.props.filterValues
        }
    }

    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }

    updateSearchTerm = (searchTerm) => {
        updateFilters({ ...this.props.filterValues, searchTerm }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
    }
    removeFilter = (filterName) => {
        let filterValues = this.props.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            updateFilters({ ...this.props.filterValues }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
        }
    }

    getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type == "select") {
            let options = filterConfigs["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value == item).label)
                } else {
                    if (value === true) {
                        value = 'true'
                    }
                    else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean") {
            value = toTitleCase(value.toString())
        } else if (filterConfigs.type === "date-range")
            value = value[0].startDate && value[0].endDate ? value[0].startDate : ""
        return Array.isArray(value) ? value.join(", ") : value
    }

    getFilterConfigurations = () => {
        return [
            { type: "select", name: "status", title: "Status", optionsQuery: Statuses, valueSelector: "name", extractValue: true, labelSelector: "description", placeholder: "Filter By Status", showFilter: true },
            { type: "select", name: "types", title: "Subscription Types", optionsQuery: SubscriptionTypes, valueSelector: "id", labelSelector: "name", placeholder: "Filter By Subscription Types", showFilter: true, isMulti: true },
            { type: "number", name: "driverPk", title: "Driver ID", placeholder: "1234", showFilter: true },
            {
                type: "boolean", name: "isDriverActive", title: "Active Driver", optionOne: "YES",
                optionTwo: "NO", showFilter: true
            },
        ]
    }
    setConfigurations = () => {
        let conf = this.getFilterConfigurations()
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                this.props.client.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0) {
                        options = result.data.optionsList.edges.map(options => options.node && ({
                            value: options.node[filter.valueSelector], label: options.node[filter.labelSelector]
                        }))
                    } else if (result && result.data.optionsList && result.data.optionsList.states && result.data.optionsList.states.length > 0) {
                        options = result.data.optionsList.states.map(options => options.node ? ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }) : ({ value: filter.extractValue ? renderEnumToInt(options[filter.valueSelector]) : options[filter.valueSelector], label: options[filter.labelSelector] }))
                    }
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ configurations: configurations })
    }
    componentDidMount() {
        this.setConfigurations()
    }
    componentDidUpdate(prevProps, prevState) {

        if (this.state.configurations !== prevState.configurations) {
            let urlFilterValues = getFilterValuesFromQueryString(this.props.location.search, this.state.configurations)
            handleConfigurationChange(prevState.configurations, this.state.configurations, urlFilterValues, this.props.setFilterValues, this.props.history, this.props.location, this.props.defaultFilters, updateFilters)
        }
    }

    applyDateFitler = () => {
        this.setState({ showDateRangePopup: true })
        this.props.setFilterValues({ ...this.props.filterValues, selectionRange: [{ startDate: new Date(), endDate: null, key: 'selection' }] })
    }
    clearDateFilter = () => {
        let filterValues = this.props.filterValues
        filterValues = delete filterValues['selectionRange']
        this.props.setFilterValues({ ...this.props.filterValues })
    }


    render() {
        return (
            <Row className="pb-2">
                {this.state.openModal === "viewFilters" &&
                    <ListingFilters open={this.state.openModal === "viewFilters"} handleClose={() => this.toggleModal("")}
                        target="viewFilters" filterValues={this.props.filterValues} setFilterValues={(filters => updateFilters(filters, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues))}
                        filters={this.state.configurations}
                    />}
                {this.state.openModal === "MassMessageModal" && <MassMessageModal handleClose={() => this.toggleModal("MassMessageModal")} open={this.state.openModal === "MassMessageModal"} drivers={this.props.selectedCustomers && this.props.selectedCustomers.length > 0 ? this.props.selectedCustomers : null} />}
                {this.state.openModal === "RepAssignmentModal" && <RepAssignmentModal
                    open={this.state.openModal === "RepAssignmentModal"}
                    title={"Assign " + this.props.selectedCustomers.length + " Selected Drivers to Reps"}
                    drivers={this.props.selectedCustomers}
                    handleClose={() => this.toggleModal(false)}
                    refetchDrivers={this.props.refetchQuery}
                    groups={["Sales Team"]}
                />}
                <Col xs={4}>
                    <span className="driver-search-filter">
                        <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => this.updateSearchTerm(e.target.value)} />
                        <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                    </span>
                </Col>
                <Col xs={3}>
                    <InputGroup className="mb-0 date-range-group">
                        <InputGroupAddon addonType="prepend">
                            <a id="customDateSelection" onClick={this.applyDateFitler}><InputGroupText className="h-100"><i className="fa fa-calendar" aria-hidden="true"></i></InputGroupText></a>
                        </InputGroupAddon>
                        <Input disabled placeholder="Select Custom Date Range" value={this.props.filterValues && this.props.filterValues.selectionRange && this.props.filterValues.selectionRange[0].startDate && this.props.filterValues.selectionRange[0].endDate ? moment(this.props.filterValues.selectionRange[0].startDate).tz("America/New_York").format("ddd, MMM D YYYY") + " - " + moment(this.props.filterValues.selectionRange[0].endDate).tz("America/New_York").format("ddd, MMM D YYYY") : ""} />
                        {this.props.filterValues && this.props.filterValues.selectionRange && this.props.filterValues.selectionRange[0].startDate && this.props.filterValues.selectionRange[0].endDate && <i className="fa fa-times-circle-o reset-date-range" onClick={() => this.clearDateFilter()} aria-hidden="true"></i>}
                        <Popover placement="bottom" isOpen={this.state.showDateRangePopup} target="customDateSelection" toggle={() => this.setState({ showDateRangePopup: false })}>
                            <PopoverHeader className="text-center">Select Custom Date Range</PopoverHeader>
                            <PopoverBody>
                                <DateRange ranges={this.props.filterValues && this.props.filterValues.selectionRange} onChange={(ranges) => this.props.setFilterValues({ ...this.props.filterValues, selectionRange: [ranges.selection] })} editableDateInputs={true} moveRangeOnFirstSelection={true} />
                            </PopoverBody>
                        </Popover>
                    </InputGroup>
                </Col>
                <Col xs={5} className="text-right mt-2">
                    <a id="viewFilters" className="driver-list-button" onClick={() => this.toggleModal("viewFilters")}>
                        Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {this.props.filterValues && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).length || ""}
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Col>
                <Col xs={12} className="mt-2">
                    {Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).map(key =>
                        <span className="search-filter-preview">
                            <span>
                                <i className="fa fa-times-circle" onClick={() => this.removeFilter(this.state.configurations.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                                {this.state.configurations.find(setting => setting.name == key).title}
                            </span>
                            {this.getValueByFilterType(this.state.configurations.find(setting => setting.name == key), this.props.filterValues[key])}
                        </span>
                    )}
                    {this.props.selectedCustomers && this.props.selectedCustomers.length > 0 && <span className="search-filter-preview">
                        <span> <i className="fa fa-times-circle" onClick={this.props.resetSelectedCustomers}></i> &nbsp;&nbsp; Selected Customers</span>
                        {this.props.selectedCustomers.length}
                    </span>}
                </Col>
            </Row >
        )
    }
}

export default compose(
    withApollo,
)(SubscriptionsListHeader)